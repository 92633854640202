<script>
import { WDrawer } from '@WebiumTeam/ui_kit';
import { createNamespacedHelpers } from 'vuex';
import { MODALS } from '~/constants/modals';
import BaseModalWrapper from '~/components/_base/_base-modal-wrapper.vue';
import StreaksPairedInvite from '~/components/streaks/streaks-paired/streaks-paired-invite/streaks-paired-invite.vue';

const { mapState, mapActions } = createNamespacedHelpers('modals');
const bgImage = require('../images/bg.svg');
const bgImageMobile = require('../images/bg-mobile.svg');

export const messages = {
  ru: {
    title: 'пригласи бадди в парный стрик',
  },
};

export default {
  components: {
    WDrawer,
    BaseModalWrapper,
    StreaksPairedInvite,
  },
  i18n: {
    messages,
  },
  computed: {
    ...mapState(['visibleModalName']),
    isVisible() {
      return this.visibleModalName === MODALS.streaksPairedInviteModal;
    },
    useDrawerComponent() {
      return !!this.$device.isSmallScreen;
    },
    wrapperComponent() {
      return this.useDrawerComponent ? WDrawer : BaseModalWrapper;
    },
    wrapperProps() {
      return this.useDrawerComponent
        ? {
            visible: true,
            rounded: true,
            side: 'bottom',
          }
        : {
            hideHeader: true,
          };
    },
    imageUrl() {
      return this.$device.isSmallScreen ? bgImageMobile : bgImage;
    },
  },
  methods: {
    ...mapActions(['closeModal']),
  },
};
</script>

<template>
  <component
    :is="wrapperComponent"
    v-if="isVisible || useDrawerComponent"
    :visible="isVisible"
    :class="$style.root"
    v-bind="wrapperProps"
    bottom
    @close="closeModal"
  >
    <div :class="$style.content">
      <img :class="$style.image" :src="imageUrl" alt="" />
      <strong :class="$style.title">{{ $t('title') }}</strong>
      <StreaksPairedInvite />
    </div>
  </component>
</template>

<style lang="postcss" module>
.root {
  --modal-max-width: 600px;
  --w-overlay-content-margin: 160px 0 0;

  text-align: left;
}

.content {
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: flex-start;
  padding: 32px;
  color: var(--primary-500);
}

.image {
  margin-bottom: 10px;
  max-width: 100%;
}

.title {
  font-size: 28px;
  line-height: 34px;
}

@media (--small-vp) {
  .root {
    --max-height: calc(100vh - 130px);
  }

  .content {
    padding: 8px 20px 35px;
  }

  .image {
    width: 100%;
    margin-bottom: 6px;
  }
}
</style>

// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".streaks-min-xp-required__root-U64mT{display:flex;align-items:center;justify-content:center;width:100%}.streaks-min-xp-required__root-U64mT>div{padding:0 4px}.streaks-min-xp-required__popper-ZpSD9{z-index:var(--base-tooltip-z-index);margin-top:4px}.streaks-min-xp-required__popoverContent-rtZS0{width:248px;padding:11px 18px;font-size:14px}.streaks-min-xp-required__popoverListWrapper-2JYOA ul{display:flex;flex-direction:column;grid-gap:6px;gap:6px;margin-top:8px;padding-left:16px;list-style-type:disc}.streaks-min-xp-required__popoverListWrapper-2JYOA ul li{font-size:14px;line-height:18px}.streaks-min-xp-required__xpSignInPopover-tzDe7{margin-right:2px}.streaks-min-xp-required__xpSignWrapper-ldsjg button{position:relative;bottom:1.5px;left:1px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "streaks-min-xp-required__root-U64mT",
	"popper": "streaks-min-xp-required__popper-ZpSD9",
	"popoverContent": "streaks-min-xp-required__popoverContent-rtZS0",
	"popoverListWrapper": "streaks-min-xp-required__popoverListWrapper-2JYOA",
	"xpSignInPopover": "streaks-min-xp-required__xpSignInPopover-tzDe7",
	"xpSignWrapper": "streaks-min-xp-required__xpSignWrapper-ldsjg"
};
module.exports = ___CSS_LOADER_EXPORT___;

// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".tg-notifications-widget__allowButton-yV7Wl{color:var(--primary-100);background-color:var(--tg-color)}.tg-notifications-widget__allowButton-yV7Wl:hover{background-color:var(--tg-color-hover)}.tg-notifications-widget__allowButton-yV7Wl:active{background-color:var(--tg-color-active)}.tg-notifications-widget__denyButton-YxRN-{color:var(--primary-500);background-color:var(--grey-200)}.tg-notifications-widget__denyButton-YxRN-:hover{background-color:var(--grey-300)}.tg-notifications-widget__denyButton-YxRN-:active{background-color:var(--grey-400)}.tg-notifications-widget__root-TaIQN .tg-notifications-widget__allowButton-yV7Wl,.tg-notifications-widget__root-TaIQN .tg-notifications-widget__denyButton-YxRN-{display:inline-flex;grid-gap:4px;gap:4px;align-items:center;height:30px;padding:3px 12px 3px 8px;font-size:14px;line-height:18px;border-radius:8px}.tg-notifications-widget__root-TaIQN .tg-notifications-widget__allowButton-yV7Wl svg,.tg-notifications-widget__root-TaIQN .tg-notifications-widget__denyButton-YxRN- svg{font-size:24px}.tg-notifications-widget__root-TaIQN .tg-notifications-widget__allowButton-yV7Wl span,.tg-notifications-widget__root-TaIQN .tg-notifications-widget__denyButton-YxRN- span{margin-top:-2px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"allowButton": "tg-notifications-widget__allowButton-yV7Wl",
	"denyButton": "tg-notifications-widget__denyButton-YxRN-",
	"root": "tg-notifications-widget__root-TaIQN"
};
module.exports = ___CSS_LOADER_EXPORT___;

// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".notification-list__content-JSkRx{display:flex;flex-direction:column;overflow:hidden;background-color:var(--primary-100);border-radius:8px;box-shadow:var(--card-shadow)}.notification-list__title-emxkR{font-size:24px;line-height:28px}.notification-list__header-R0aZN{justify-content:space-between;margin-bottom:12px;color:var(--primary-500)}.notification-list__desktopButtons-QOm47,.notification-list__header-R0aZN{display:flex;align-items:center}.notification-list__desktopButtons-QOm47{grid-gap:8px;gap:8px}.notification-list__empty-6tNAJ{display:flex;justify-content:center;max-width:230px;padding:56px 0;margin:0 auto;font-size:16px;line-height:20px;color:var(--primary-500);text-align:center;letter-spacing:.02em}.notification-list__loading-PUtfz{padding:40px}.notification-list___popover-phZfc{display:flex;flex-direction:column}.notification-list___popover-phZfc .notification-list__header-R0aZN{position:relative;padding:10px 10px 12px 18px;margin-bottom:0}.notification-list___popover-phZfc .notification-list__header-R0aZN:after{position:absolute;bottom:0;left:0;width:100%;height:2px;content:\"\";background:var(--grey-200)}.notification-list___popover-phZfc .notification-list__title-emxkR{margin-bottom:2px;font-size:18px;line-height:22px}.notification-list___popover-phZfc .notification-list__content-JSkRx{max-height:calc(100% - 54px);overflow-x:hidden;overflow-y:auto;border-radius:0;box-shadow:none}@media (max-width:1023px){.notification-list__header-R0aZN{justify-content:left;padding:0}.notification-list__title-emxkR{margin-right:8px;font-size:20px;line-height:24px}.notification-list___popover-phZfc .notification-list__header-R0aZN,.notification-list__desktopButtons-QOm47{display:none}.notification-list___popover-phZfc .notification-list__content-JSkRx{max-height:100%}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": "notification-list__content-JSkRx",
	"title": "notification-list__title-emxkR",
	"header": "notification-list__header-R0aZN",
	"desktopButtons": "notification-list__desktopButtons-QOm47",
	"empty": "notification-list__empty-6tNAJ",
	"loading": "notification-list__loading-PUtfz",
	"_popover": "notification-list___popover-phZfc"
};
module.exports = ___CSS_LOADER_EXPORT___;

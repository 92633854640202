// if you change these values, users will see tooltips again
export const TOOLTIPS = {
  whereIsOnboarding: 'whereIsOnboarding',
  contactsAndUtilities: 'contactsAndUtilities',
  streaksCalendarDesktop: 'streaksCalendarDesktop',
  streaksCalendarMobile: 'streaksCalendarMobile',
  notificationsSettings: 'notificationsSettings',
  webinarVideoSourceControl: 'webinarVideoSourceControl',
  consultationsCard: 'consultationsCard',
};

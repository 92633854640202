<script>
import StreaksNumberStroke from '~/components/streaks/streaks-number-stroke.vue';

const messages = {
  ru: {
    label: 'стриков | стрик | стрика | стриков',
  },
};

export default {
  i18n: {
    messages,
  },
  components: { StreaksNumberStroke },
  props: {
    count: {
      type: Number,
      default: 0,
    },
    isOutline: {
      type: Boolean,
      default: false,
    },
    isActive: {
      type: Boolean,
      default: false,
    },
    showLabel: {
      type: Boolean,
      default: false,
    },
    labelText: {
      type: String,
      default: '',
    },
    isPaired: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: 'm',
      validate: (value) => ['s', 'm', 'l', 'xl'].includes(value),
    },
  },
  computed: {
    iconName() {
      const outlinePostfix = this.isOutline ? `-outline` : ``;
      const pairedPrefix = this.isPaired ? `paired-` : ``;

      return this.isActive
        ? `${pairedPrefix}fire-active${outlinePostfix}`
        : `${pairedPrefix}fire-inactive${outlinePostfix}`;
    },
    rootClasses() {
      return {
        [this.$style.root]: true,
        [this.$style._active]: this.isActive,
        [this.$style._paired]: this.isPaired,
        [this.$style[`_${this.size}`]]: true,
      };
    },
    numberTheme() {
      if (this.isActive && this.isPaired) return 'purple';
      if (this.isActive) return 'orange';
      return 'grey';
    },
  },
};
</script>

<template>
  <div :class="rootClasses">
    <div :class="$style.iconWrapper">
      <img
        :class="$style.icon"
        :src="require(`./images/${iconName}.svg`)"
        alt=""
      />
      <div :class="$style.countWrapper">
        <StreaksNumberStroke :number="count" :theme="numberTheme" />
      </div>
    </div>
    <span v-if="showLabel" :class="$style.label">
      {{ labelText || $tc('label', count) }}
    </span>
  </div>
</template>

<style lang="postcss" module>
.root {
  --text-color: var(--grey-500);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.iconWrapper {
  position: relative;
}

._s {
  --icon-size: 28px;
  --count-size: 15px;
  --text-stroke-width: 4px;
}

._m {
  --icon-size: 32px;
  --count-size: 16px;
  --text-stroke-width: 4px;
}

._l {
  --icon-size: 72px;
  --count-size: 44px;
  --label-size: 14px;
  --label-gap: 20px;
  --text-stroke-width: 6px;
}

._xl {
  --icon-size: 88px;
  --count-size: 48px;
  --label-size: 18px;
  --label-gap: 20px;
  --text-stroke-width: 8px;
}

._active {
  --text-color: var(--streaks-active-color);

  &._paired {
    --text-color: var(--paired-streaks-active-color);
  }
}

.icon {
  width: var(--icon-size);
  height: var(--icon-size);
}

.countWrapper {
  position: absolute;
  bottom: 32%;
  left: 50%;
  font-size: var(--count-size);
  line-height: 1;
  transform: translate(-50%, 100%);
}

.label {
  margin-top: var(--label-gap);
  font-size: var(--label-size);
  line-height: 1.2;
  color: var(--text-color);
}
</style>

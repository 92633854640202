import { gtmEvents, gtmPushEvent } from '~/services/gtm/gtm';
import { gtmPushUserId } from './helpers/gtm-push-user-id';

const initHandler = ($auth) => {
  gtmPushUserId($auth);
};

const loginHandler = ($auth) => {
  gtmPushUserId($auth);
  gtmPushEvent(gtmEvents.login);
};

const logoutHandler = ($auth) => {
  gtmPushUserId($auth);
  gtmPushEvent(gtmEvents.logout);
};

export { initHandler, loginHandler, logoutHandler };

function serializeQuery(params) {
  const result = [];
  Object.keys(params).forEach((parameter) => {
    if (Object.prototype.hasOwnProperty.call(params, parameter)) {
      result.push(
        `${encodeURIComponent(parameter)}=${encodeURIComponent(
          params[parameter]
        )}`
      );
    }
  });

  return result.join('&');
}

export function facebookShareUrl({ url } = {}) {
  const queryParams = {
    u: url,
  };

  return `https://www.facebook.com/sharer/sharer.php?${serializeQuery(
    queryParams
  )}`;
}

export function telegramShareUrl({ url, message } = {}) {
  const queryParams = {
    url,
    text: message,
  };

  return `https://t.me/share/url?${serializeQuery(queryParams)}`;
}

export function whatsappShareUrl({ url, message } = {}) {
  const queryParams = {
    text: `${url}\n\n${message}`,
  };

  return `https://api.whatsapp.com/send?${serializeQuery(queryParams)}`;
}

export function vkShareUrl({ url, message, image } = {}) {
  const queryParams = {
    url,
    image,
    title: message,
    noparse: true,
  };

  return `https://vk.com/share.php?${serializeQuery(queryParams)}`;
}

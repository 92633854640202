<script>
import { createNamespacedHelpers } from 'vuex';
import LeftRightPagination from '~/components/left-right-pagination.vue';

const { mapGetters } = createNamespacedHelpers('timetable');

export const messages = {
  ru: {
    previousButtonDisabled: 'нет более ранних занятий',
    nextButtonDisabled: 'нет более поздних занятий',
  },
};

export default {
  i18n: { messages },
  components: {
    LeftRightPagination,
  },
  props: {
    value: {
      type: [Date, Object, String],
      required: true,
    },
  },
  computed: {
    ...mapGetters(['hasPastEvents', 'timetableEndReached']),
    previousButtonDisabled() {
      return this.hasPastEvents(this.value) === false;
    },
    nextButtonDisabled() {
      return this.timetableEndReached(this.value);
    },
  },
};
</script>

<template>
  <LeftRightPagination
    :value="value"
    v-bind="$attrs"
    :left-disabled="previousButtonDisabled"
    :left-disabled-text="$t('previousButtonDisabled')"
    :right-disabled="nextButtonDisabled"
    :right-disabled-text="$t('nextButtonDisabled')"
    v-on="$listeners"
  />
</template>

// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".notification-settings-modal__inner-uRvk5{height:100%}@media (max-width:1023px){.notification-settings-modal__root-0cnPT{--modal-max-width:100%}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inner": "notification-settings-modal__inner-uRvk5",
	"root": "notification-settings-modal__root-0cnPT"
};
module.exports = ___CSS_LOADER_EXPORT___;

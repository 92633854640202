// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".streaks-counter-button-mobile-dialog-content__root-tDoiB{--calendar-padding-x:16px;display:flex;flex-direction:column}.streaks-counter-button-mobile-dialog-content__attentionTooltipPopper-qEr9U{top:-20px;z-index:var(--base-tooltip-z-index)}.streaks-counter-button-mobile-dialog-content__calendarWrapper-QlzrK{margin:0 var(--calendar-padding-x) 32px;padding:24px 16px 4px;border:2px solid var(--grey-300);border-radius:8px}.streaks-counter-button-mobile-dialog-content__calendarFooter-SYuTl{position:relative;left:calc(var(--calendar-padding-x)*-1);display:flex;justify-content:center;align-items:center;width:calc(100% + var(--calendar-padding-x)*2);height:56px;border-top:2px solid var(--grey-200)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "streaks-counter-button-mobile-dialog-content__root-tDoiB",
	"attentionTooltipPopper": "streaks-counter-button-mobile-dialog-content__attentionTooltipPopper-qEr9U",
	"calendarWrapper": "streaks-counter-button-mobile-dialog-content__calendarWrapper-QlzrK",
	"calendarFooter": "streaks-counter-button-mobile-dialog-content__calendarFooter-SYuTl"
};
module.exports = ___CSS_LOADER_EXPORT___;

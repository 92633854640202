import { PHONE_MAX_WIDTH } from '~/constants/adaptive';
import { getLSItem, setLSItem } from '~/services/local-storage';

export const DEFAULT_CALENDAR_TYPE = 'week';
export const MOBILE_CALENDAR_TYPE = 'month';
export const CALENDAR_TYPE_LS_KEY = 'timetable_calendar_type';

export const timetablePreferencesPlugin = (store) => {
  if (process.server) return;

  const handleActualPreferences = () =>
    store.dispatch('timetable-preferences/handleActualPreferences');
  window.addEventListener('resize', handleActualPreferences);
  window.onNuxtReady(handleActualPreferences);
};

export const state = () => ({
  calendarType: DEFAULT_CALENDAR_TYPE,
  isCalendarTypeVisible: true,
});

export const mutations = {
  SET_CALENDAR_TYPE(state, type) {
    state.calendarType = type;
  },
  SET_CALENDAR_TYPE_VISIBILITY(state, visibility) {
    state.isCalendarTypeVisible = !!visibility;
  },
};

export const actions = {
  setCalendarType({ commit }, type) {
    commit('SET_CALENDAR_TYPE', type);
    setLSItem(CALENDAR_TYPE_LS_KEY, type);
  },
  handleActualPreferences({ commit }) {
    const isMobileScreen = window.innerWidth <= PHONE_MAX_WIDTH;
    const actualType = isMobileScreen
      ? MOBILE_CALENDAR_TYPE
      : getLSItem(CALENDAR_TYPE_LS_KEY) || DEFAULT_CALENDAR_TYPE;
    commit('SET_CALENDAR_TYPE', actualType);
    commit('SET_CALENDAR_TYPE_VISIBILITY', isMobileScreen === false);
  },
};

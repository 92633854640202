// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".streaks-paired-how-work__root-x6Erb .streaks-paired-how-work__title-9AdDu{margin-bottom:7px}.streaks-paired-how-work__root-x6Erb svg{font-size:20px}.streaks-paired-how-work__root-x6Erb li{display:flex;grid-gap:7px;gap:7px;margin-bottom:4px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "streaks-paired-how-work__root-x6Erb",
	"title": "streaks-paired-how-work__title-9AdDu"
};
module.exports = ___CSS_LOADER_EXPORT___;

import { formatDate } from '~/services/datetime';
import { http } from '~/services/http';

const dateFormat = 'YYYY-MM-DD';

export function getLastInfo() {
  const url = `/api/v2/student/motivation/streaks/last-info/`;
  return http.get(url);
}

export function getStreaksHistory({ dateFrom, dateTo } = {}) {
  const url = `/api/v2/student/motivation/streaks/`;
  const params = {
    date_from: formatDate({ value: dateFrom, format: dateFormat }),
    date_to: formatDate({ value: dateTo, format: dateFormat }),
  };
  return http.get(url, { params });
}

export function createPairedStreakInvitation() {
  const requestUrl = `/api/v2/student/motivation/streaks/invitations/`;

  return http.post(requestUrl);
}

export function getPairedStreakInvitation({ invitationId }) {
  const requestUrl = `/api/v2/student/motivation/streaks/invitations/${invitationId}/`;

  return http.get(requestUrl);
}

export function acceptPairedStreakInvitation({
  invitationId,
  // eslint-disable-next-line unicorn/no-null
  unbindBuddyId = null,
}) {
  const requestUrl = `/api/v2/student/motivation/streaks/invitations/${invitationId}/accept/`;

  return http.post(requestUrl, { unbindBuddyId });
}

export function deletePairedStreakBuddy() {
  const requestUrl = `/api/v2/student/motivation/streaks/buddy/`;

  return http.delete(requestUrl);
}

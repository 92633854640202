<template>
  <div :class="$style.root">
    <div :class="$style.content">
      <div :class="$style.code">{{ code }}</div>
      <p :class="$style.header">{{ header }}</p>
      <p v-if="message" :class="$style.message">{{ message }}</p>
      <div v-if="$slots.action" :class="$style.action">
        <slot name="action" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    iconName: {
      type: String,
      default: undefined,
    },
    code: {
      type: Number,
      required: true,
    },
    header: {
      type: String,
      required: false,
      default: '',
    },
    message: {
      type: String,
      required: false,
      default: '',
    },
  },
};
</script>

<style lang="postcss" module>
.root {
  --_default-code-font-size: 120px;
  --_default-action-gutter: 22px;

  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  text-align: center;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 400px;
}

.header {
  margin: 0;
  margin-bottom: 12px;
  font-size: 26px;
  text-transform: lowercase;
}

.message {
  margin: 0;
  margin-bottom: 20px;
  font-size: 20px;
}

.code {
  font-size: var(--code-font-size, var(--_default-code-font-size));
}

.action {
  margin-top: var(--action-gutter, var(--_default-action-gutter));
}
</style>

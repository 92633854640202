// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".streaks-freeze-counter__popper-eDqB0{z-index:var(--base-tooltip-z-index);margin-top:4px}.streaks-freeze-counter__content-R-RAo{display:flex;grid-gap:2px;gap:2px;align-items:center;padding:0 1px 1px;font-size:14px;line-height:18px;color:var(--streaks-freeze-color);cursor:default;border-bottom:1px solid var(--grey-400)}.streaks-freeze-counter__content-R-RAo:hover{border-bottom:1px solid var(--grey-500)}.streaks-freeze-counter__popoverContent-L1nmH{width:310px;padding:16px 20px;font-size:14px;line-height:18px;white-space:pre-line}.streaks-freeze-counter__popoverContent-L1nmH hr{display:block;height:8px;border:none}.streaks-freeze-counter__freezeLabel-M6Ays{display:inline-block;margin-right:2px;color:var(--streaks-freeze-color)}.streaks-freeze-counter__freezeLabel-M6Ays .streaks-freeze-counter__contentIcon-NogNN{padding-bottom:3px}@media (max-width:1023px){.streaks-freeze-counter__content-R-RAo{font-size:16px;line-height:20px}.streaks-freeze-counter__icon-D0JJr{width:14px;height:14px}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"popper": "streaks-freeze-counter__popper-eDqB0",
	"content": "streaks-freeze-counter__content-R-RAo",
	"popoverContent": "streaks-freeze-counter__popoverContent-L1nmH",
	"freezeLabel": "streaks-freeze-counter__freezeLabel-M6Ays",
	"contentIcon": "streaks-freeze-counter__contentIcon-NogNN",
	"icon": "streaks-freeze-counter__icon-D0JJr"
};
module.exports = ___CSS_LOADER_EXPORT___;

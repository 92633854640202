import pick from 'lodash/pick';
import { INITIAL_TASK_SOLUTION } from '~/constants/task-solution';
import { isRequired } from '~/utils/is-required';
import { http } from '~/services/http';

const baseUrl = '/api/v2/student/courses';

export async function getCourseProgressThemes({ courseId = isRequired() }) {
  const url = `${baseUrl}/${courseId}/progress-themes/`;

  const response = await http.get(url);
  return response;
}

export async function getCourseProgressTheme({
  courseId = isRequired(),
  themeId = isRequired(),
}) {
  const url = `${baseUrl}/${courseId}/progress-themes/${themeId}/`;

  const response = await http.get(url);
  return response;
}

export async function getCourseProgressKimNumbers({
  courseId = isRequired(),
  page = 1,
  pageSize = 5,
}) {
  const params = {
    page,
    page_size: pageSize,
  };
  const url = `${baseUrl}/${courseId}/progress-kim-numbers/`;

  const { results, count, next } = await http.get(url, { params });
  return {
    results,
    totalCount: count,
    hasNextPage: !!next,
  };
}

export function getCourseProgressKimNumber({
  courseId = isRequired(),
  topicKimNumberId = isRequired(),
}) {
  const url = `${baseUrl}/${courseId}/progress-kim-numbers/${topicKimNumberId}/`;

  return http.get(url);
}

export async function getCourseProgressSubThemes({
  courseId = isRequired(),
  themeId = isRequired(),
}) {
  const url = `${baseUrl}/${courseId}/progress-themes/${themeId}/progress-subthemes/`;

  const response = await http.get(url);
  return response;
}

export async function getCourseProgressSubTheme({
  courseId = isRequired(),
  themeId = isRequired(),
  subThemeId = isRequired(),
}) {
  const url = `${baseUrl}/${courseId}/progress-themes/${themeId}/progress-subthemes/${subThemeId}/`;

  const response = await http.get(url);
  return response;
}

export async function getCourseProgressThemeTasks({
  courseId = isRequired(),
  themeId = isRequired(),
}) {
  const url = `${baseUrl}/${courseId}/progress-themes/${themeId}/tasks/`;

  const response = await http.get(url);
  return response;
}

export async function getCourseProgressKimNumbersTasks({
  courseId = isRequired(),
  topicKimNumberId = isRequired(),
}) {
  const url = `${baseUrl}/${courseId}/progress-kim-numbers/${topicKimNumberId}/tasks/`;

  const response = await http.get(url);
  return response;
}

export async function getCourseProgressSubThemeTasks({
  courseId = isRequired(),
  themeId = isRequired(),
  subThemeId = isRequired(),
}) {
  const url = `${baseUrl}/${courseId}/progress-themes/${themeId}/progress-subthemes/${subThemeId}/tasks/`;

  const response = await http.get(url);
  return response;
}

export async function addCourseProgressSolution({ courseId, taskId }, params) {
  const url = `${baseUrl}/${courseId}/progress/tasks/${taskId}/add-solution/`;
  const allowedParamsKeys = Object.keys(INITIAL_TASK_SOLUTION);
  const allowedParams = pick(params, allowedParamsKeys);

  const response = await http.post(url, allowedParams);
  return response;
}

export async function addCourseProgressReview(
  { courseId, taskSolutionId },
  params
) {
  const url = `${baseUrl}/${courseId}/progress/task-solutions/${taskSolutionId}/add-review/`;

  const response = await http.post(url, params);
  return response;
}

export async function getCourseProgressTaskAnswer({ taskId }) {
  const url = `/api/v2/student/progress/tasks/${taskId}/show-answers/`;

  const response = await http.get(url);
  return response;
}

export async function getCourseWorkoutLastTheme({ courseId = isRequired() }) {
  const url = `${baseUrl}/${courseId}/progress-themes/workout/`;

  const response = await http.get(url);
  return response;
}

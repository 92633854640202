import { isRequired } from '~/utils/is-required';
import { http } from '~/services/http';

const baseUrl = '/api/v2/student/courses';

export async function getCourse({ courseId = isRequired() }) {
  const url = `${baseUrl}/${courseId}/`;
  const response = await http.get(url);
  return response;
}

export async function getCoursePreview({ courseId = isRequired() }) {
  const url = `${baseUrl}/previews/${courseId}/`;
  const response = await http.get(url);
  return response;
}

export async function getCourseByPublicId(publicId = isRequired()) {
  const url = `/api/v2/student/courses-by-public-id/${publicId}/`;
  const response = await http.get(url);
  return response;
}

export async function getCourseProgress({ courseId = isRequired() }) {
  const url = `${baseUrl}/${courseId}/progress/`;
  const response = await http.get(url, { courseId });
  return response;
}

export async function getAllCourses({
  excludeWithoutDates = false,
  archived,
} = {}) {
  const url = `${baseUrl}/`;
  const params = {};
  if (excludeWithoutDates) params.has_dates_disabled = false;
  params.archived = archived;

  const { results, count, next } = await http.get(url, {
    params,
  });
  return {
    results,
    totalCount: count,
    hasNextPage: !!next,
  };
}

export async function getAllCoursesPreviews() {
  const url = `${baseUrl}/previews/`;

  const { results, count, next } = await http.get(url);
  return {
    results,
    totalCount: count,
    hasNextPage: !!next,
  };
}

export async function getCourses({
  page = 1,
  pageSize = 8,
  archived,
  ordering,
} = {}) {
  const url = `${baseUrl}/`;
  const params = { page, archived, page_size: pageSize, ordering };
  const { results, count, next } = await http.get(url, { params });
  return {
    results,
    totalCount: count,
    hasNextPage: !!next,
  };
}

export async function setLearningGoal({
  courseId = isRequired(),
  goal,
  comment,
}) {
  const url = `${baseUrl}/${courseId}/set-learning-goal/`;
  const response = await http.post(url, { goal, comment });
  return response;
}

export function archiveCourse(id) {
  const url = `${baseUrl}/${id}/archive/`;
  return http.post(url);
}

export function restoreCourse(id) {
  const url = `${baseUrl}/${id}/unarchive/`;
  return http.post(url);
}

export async function getCourseLessonContentItems({
  courseId = isRequired(),
  nearestToDate = undefined,
}) {
  const url = '/api/v2/student/course-timetable/';
  const response = await http.get(url, {
    params: { course: courseId, nearest_to_date: nearestToDate },
  });
  return response;
}

export async function getIsOnboardingCouldBeShown(courseId = isRequired()) {
  const url = `${baseUrl}/${courseId}/onboarding/could-be-shown/`;
  const response = await http.get(url);
  return response;
}

export async function closeOnboarding(courseId = isRequired()) {
  const url = `${baseUrl}/${courseId}/onboarding/close/`;
  const response = await http.post(url);
  return response;
}

export async function finishOnboarding(courseId = isRequired()) {
  const url = `${baseUrl}/${courseId}/onboarding/complete/`;
  const response = await http.post(url);
  return response;
}

export async function getCoursesWithNextLessons({
  excludeWithoutDates = false,
} = {}) {
  const url = `${baseUrl}/next-lessons/`;

  const params = {};
  if (excludeWithoutDates) params.has_dates_disabled = false;

  const results = await http.get(url, {
    params,
  });

  return {
    results,
  };
}

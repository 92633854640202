<template>
  <ol :class="$style.root">
    <li v-for="weekday in weekdays" :key="weekday" :class="itemClasses">
      {{ weekday }}
    </li>
  </ol>
</template>

<script>
const weekdaysByLocale = Object.freeze({
  ru: ['пн', 'вт', 'ср', 'чт', 'пт', 'сб', 'вс'],
  en: ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'],
});
const defaultPosition = 'left';

export default {
  props: {
    labelPosition: {
      type: String,
      default: defaultPosition,
      validate: (value) => ['left', 'center'].includes(value),
    },
  },
  computed: {
    weekdays() {
      return weekdaysByLocale[this.$i18n.locale];
    },
    itemClasses() {
      return {
        [this.$style.weekday]: true,
        [this.$style[`_${this.labelPosition || defaultPosition}`]]: true,
      };
    },
  },
};
</script>

<style lang="postcss" module>
.root {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  color: var(--calendar-secondary-color);
  letter-spacing: 0.02em;
}

.weekday {
  &,
  &._left {
    padding-left: 8px;
  }

  &._center {
    justify-self: center;
    padding: 0;
  }
}
</style>

// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".streaks-number-stroke__root-Mz9jm{position:relative;font-size:inherit;line-height:inherit}.streaks-number-stroke__count-cWSAm{line-height:1}.streaks-number-stroke__countStroke-04wdU{position:absolute;top:0;left:0;z-index:-1;-webkit-text-stroke:12px var(--primary-100);-webkit-text-stroke:var(--text-stroke-width,12px) var(--primary-100)}.streaks-number-stroke___grey-yzyhL .streaks-number-stroke__count-cWSAm{color:var(--grey-500)}.streaks-number-stroke___orange-6YZkh .streaks-number-stroke__count-cWSAm{color:var(--streaks-active-color)}.streaks-number-stroke___purple-C860o .streaks-number-stroke__count-cWSAm{color:var(--paired-streaks-active-color)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "streaks-number-stroke__root-Mz9jm",
	"count": "streaks-number-stroke__count-cWSAm",
	"countStroke": "streaks-number-stroke__countStroke-04wdU",
	"_grey": "streaks-number-stroke___grey-yzyhL",
	"_orange": "streaks-number-stroke___orange-6YZkh",
	"_purple": "streaks-number-stroke___purple-C860o"
};
module.exports = ___CSS_LOADER_EXPORT___;

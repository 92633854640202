const SENTRY_URL = 'https://sentry.webium.ru/';
const SENTRY_DSN =
  'https://ad2276d64f4a40508f4921574b4c8dd6@sentry.webium.ru/9';
const SENTRY_ORG = 'webium';
const SENTRY_PROJECT = 'webium-lms-frontend';
const SENTRY_DISABLED = process.env.NODE_ENV !== 'production';

module.exports = {
  SENTRY_URL,
  SENTRY_DSN,
  SENTRY_ORG,
  SENTRY_PROJECT,
  SENTRY_DISABLED,
};

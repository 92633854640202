<script>
export const messages = {
  ru: {
    text: 'твой бадди уже позанимался. догоняй, \nчтобы увеличить парный стрик',
  },
};

export default {
  i18n: { messages },
};
</script>

<template>
  <p :class="$style.root">{{ $t('text') }}</p>
</template>

<style lang="postcss" module>
.root {
  padding: 12px 20px;
  font-size: 14px;
  line-height: 18px;
  white-space: break-spaces;
}
</style>

// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".nps-form__root-APGrh{display:flex;flex-direction:column;padding:24px 32px}.nps-form__root-APGrh .nps-form__numberItem-quCIk{--size:32px;--border-color:var(--grey-300);flex-shrink:0;align-items:center;min-width:var(--size);max-width:var(--size);min-height:var(--size);max-height:var(--size);padding:0;border-color:var(--border-color);border-radius:100%}.nps-form__root-APGrh .nps-form__numberItem-quCIk.nps-form___selected-ZdRM3{--border-color:var(--primary-500)}.nps-form__titleWrapper-\\+vGdr{margin-bottom:20px}.nps-form__title-84A54{margin-bottom:8px;font-size:18px;line-height:22px}.nps-form__caption-PZNjB{font-size:14px;line-height:18px;color:var(--grey-600)}.nps-form__numberItems-\\+O5T5{display:flex;grid-gap:8px;gap:8px}.nps-form__buttons-2jIcR{margin-top:24px}.nps-form__formItem--vhIW{display:flex;flex-direction:column;grid-gap:4px;gap:4px;margin-top:16px}.nps-form__formLabel-5pFPR{font-size:14px;line-height:18px;color:var(--grey-600)}@media (max-width:1023px){.nps-form__root-APGrh{padding:0}.nps-form__root-APGrh .nps-form__numberItem-quCIk{--size:44px}.nps-form__title-84A54{font-size:16px;line-height:20px}.nps-form__numberItems-\\+O5T5{flex-wrap:wrap;justify-content:center;max-width:350px;margin:0 auto;text-align:center}.nps-form__buttons-2jIcR{margin-top:32px}.nps-form__buttons-2jIcR>*{width:100%}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "nps-form__root-APGrh",
	"numberItem": "nps-form__numberItem-quCIk",
	"_selected": "nps-form___selected-ZdRM3",
	"titleWrapper": "nps-form__titleWrapper-+vGdr",
	"title": "nps-form__title-84A54",
	"caption": "nps-form__caption-PZNjB",
	"numberItems": "nps-form__numberItems-+O5T5",
	"buttons": "nps-form__buttons-2jIcR",
	"formItem": "nps-form__formItem--vhIW",
	"formLabel": "nps-form__formLabel-5pFPR"
};
module.exports = ___CSS_LOADER_EXPORT___;

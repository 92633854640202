import { buildServerListModule } from '~/store-lib/server-list';
import Vue from 'vue';
import {
  addOnboardingQuestionsAnswer,
  getOnboardingQuestions,
} from '~/api/surveys';
import { handleError } from '~/services/error-handling/error-handling';

const storeModule = buildServerListModule(getOnboardingQuestions, {
  itemsFieldName: 'questions',
  fetchMethodName: 'fetchQuestions',
  fetchOnlyOnce: true,
  responseItemsFieldName: false,
});

const { state } = storeModule;

const mutations = {
  ...storeModule.mutations,
  ADD_USER_ANSWER(state, { questionId, userAnswer }) {
    const index = state.questions.findIndex(
      (question) => question.id === questionId
    );
    Vue.set(state.questions, index, {
      ...state.questions[index],
      userAnswer,
    });
  },
};

const getters = {
  ...storeModule.getters,
  unansweredQuestions: (state) =>
    state.questions.filter((question) => !question.userAnswer),
};

const actions = {
  ...storeModule.actions,
  async addUserAnswer({ commit }, { questionId, userAnswer }) {
    try {
      await addOnboardingQuestionsAnswer(questionId, userAnswer);
      commit('ADD_USER_ANSWER', { questionId, userAnswer });
    } catch (error) {
      handleError(error);
    }
  },
};

export { state, mutations, getters, actions };

// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".notification-modal__root-I-S5Y{--modal-max-width:100%}.notification-modal__menu-wt9oo{margin-right:auto;margin-left:8px}.notification-modal__content-nmDim{padding-bottom:40px}.notification-modal__notificationList-lzrwR{position:relative}.notification-modal__notificationList-lzrwR:after{position:absolute;bottom:0;left:0;width:100%;height:2px;content:\"\";background:var(--grey-200)}@media (max-width:1023px){.notification-modal__content-nmDim{height:calc(100vh - 62px);padding-bottom:0}.notification-modal__notificationList-lzrwR{height:100%}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "notification-modal__root-I-S5Y",
	"menu": "notification-modal__menu-wt9oo",
	"content": "notification-modal__content-nmDim",
	"notificationList": "notification-modal__notificationList-lzrwR"
};
module.exports = ___CSS_LOADER_EXPORT___;

import { init } from '~/services/http';

export default ({ $axios }) => {
  init({
    get: $axios.$get,
    post: $axios.$post,
    put: $axios.$put,
    patch: $axios.$patch,
    delete_: $axios.$delete,
  });
};

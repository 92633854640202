export const state = () => ({
  mentorsSatisfactionModalVisible: false,
});

export const mutations = {
  SET_MODAL_VISIBILITY(state, value) {
    state.mentorsSatisfactionModalVisible = !!value;
  },
};

export const actions = {
  openMentorsSatisfactionModal({ commit }) {
    commit('SET_MODAL_VISIBILITY', true);
  },
  closeMentorsSatisfactionModal({ commit }) {
    commit('SET_MODAL_VISIBILITY', false);
  },
};

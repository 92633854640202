// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("./close.svg");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".wbm-toast-container.toasted-container.bottom-right{right:5%;bottom:5%;z-index:var(--vue-toasted-z-index)}.wbm-toast-container.toasted-container .wbm-toast.toasted{font-family:var(--font-family);width:400px;padding:20px;font-size:18px;border-radius:var(--standard-border-radius);color:var(--primary-500);background:var(--primary-100);box-shadow:var(--dropdown-shadow)}.wbm-toast-container.toasted-container .wbm-toast.toasted .action{padding:0;margin:0}.wbm-toast-container.toasted-container .wbm-toast.toasted .action:after{--toast-close-icon-size:18px;content:\"\";position:absolute;top:calc(50% - var(--toast-close-icon-size)/2);right:20px;height:var(--toast-close-icon-size);width:18px;margin:auto;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") 0 0 no-repeat}@media (max-width:767px){.wbm-toast-container.toasted-container.bottom-right{min-width:auto;right:8px;left:8px}.wbm-toast-container.toasted-container .wbm-toast.toasted{width:100%}}", ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;

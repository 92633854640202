// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".streaks-workout-progress-popover-content__root-qz79c{display:flex;flex-direction:column;grid-gap:12px;gap:12px;width:136px;padding:16px;text-align:center}.streaks-workout-progress-popover-content__text-UqI-Y{font-size:14px;line-height:18px}.streaks-workout-progress-popover-content__progress-rsYdl{--completed-color:var(--streaks-active-color)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "streaks-workout-progress-popover-content__root-qz79c",
	"text": "streaks-workout-progress-popover-content__text-UqI-Y",
	"progress": "streaks-workout-progress-popover-content__progress-rsYdl"
};
module.exports = ___CSS_LOADER_EXPORT___;

<script>
import { WIconButton } from '@WebiumTeam/ui_kit';
import BasePopover from '~/components/_base/popover/_base-popover.vue';
import VoiceRecord from '~/components/voice-record.vue';

export const messages = {
  ru: {
    audio: 'аудиозапись',
    file: 'файл',
    micAudioPermissionError:
      'Для использования аудиосообщений предоставьте доступ к микрофону',
    attach: 'прикрепить',
  },
};

export default {
  i18n: { messages },
  components: {
    BasePopover,
    WIconButton,
    VoiceRecord,
  },
  props: {
    attachExtended: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isVoiceMessageAvailable() {
      return this.attachExtended;
    },
  },
  methods: {
    changeFiles(event) {
      this.$emit('attach', event.target.files);
    },
    onAudio() {
      navigator.mediaDevices
        .getUserMedia({ audio: true })
        .then(() => {
          this.$refs.voiceRecord.startRecord();
        })
        .catch(() => {
          this.$toast.show(this.$t('micAudioPermissionError'));
        });
    },
    selectFileClick() {
      this.$refs.inputFile.click();
    },
  },
};
</script>

<template>
  <div>
    <template v-if="isVoiceMessageAvailable">
      <BasePopover
        :class="$style.menuButton"
        data-testid="select-attachment-menu"
        placement="bottom-end"
      >
        <WIconButton
          :alt="$t('attach')"
          icon-name="attach"
          :size="$device.isSmallScreen ? 's' : 'xs'"
          theme="emptyLight"
        />
        <template #dropdown>
          <div :class="$style.menuItems">
            <WIconButton
              :class="$style.option"
              icon-name="mic_L"
              :size="$device.isSmallScreen ? 'm' : 's'"
              theme="emptyLight"
              @click="onAudio"
            >
              {{ $t('audio') }}
            </WIconButton>
            <WIconButton
              :class="$style.option"
              icon-name="docs_S"
              :size="$device.isSmallScreen ? 'm' : 's'"
              theme="emptyLight"
              @click="selectFileClick"
            >
              {{ $t('file') }}
            </WIconButton>
          </div>
        </template>
      </BasePopover>
      <VoiceRecord
        ref="voiceRecord"
        :class="$style.voiceRecord"
        @start="$emit('start-processing')"
        @stop="$emit('stop-processing')"
        @record="$emit('attach', $event)"
      />
    </template>
    <label
      v-show="!isVoiceMessageAvailable"
      ref="inputFile"
      :class="$style.attach"
      data-testid="upload-file-label"
    >
      <WIcon name="attach" />
      <input
        :class="$style.inputFile"
        type="file"
        :multiple="multiple"
        @change="changeFiles"
      />
    </label>
  </div>
</template>

<style lang="postcss" module>
.attach {
  display: inline-flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  min-width: 32px;
  min-height: 32px;
  padding: 2px;
  font-size: 24px;
  cursor: pointer;
  border: 2px solid transparent;
  border-radius: 6px;
  transition: background-color var(--default-transition);

  &:hover {
    background-color: var(--grey-200);
  }

  &:active {
    background-color: var(--grey-300);
  }
}

.inputFile {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0 0 0 0);
  border: 0;
}

.menuItems {
  --button-justify-content: flex-start;
  --button-align-items: center;

  display: flex;
  flex-direction: column;

  & .option {
    justify-content: flex-start;
    width: 156px;
  }
}

.voiceRecord {
  position: absolute;
  top: -8px;
  right: -8px;
}
</style>

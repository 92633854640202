<script>
import { WHint } from '@WebiumTeam/ui_kit';

export default {
  components: {
    WHint,
  },
  props: {
    text: {
      type: String,
      required: true,
    },
    placement: {
      type: String,
      default: 'bottom',
    },
    popperClass: {
      type: String,
      default: '',
    },
    boundary: {
      type: Element,
      default: undefined,
    },
  },
  computed: {
    tooltipTriggers() {
      return this.$device.isSmallScreen ? ['click'] : ['hover'];
    },
  },
};
</script>

<template>
  <WHint
    :triggers="tooltipTriggers"
    :placement="placement"
    :text="text"
    :popper-class="popperClass"
    :boundary="boundary"
  />
</template>

<script>
import { createPairedStreakInvitation } from '~/api/streaks';
import { telegramShareUrl, vkShareUrl } from '~/utils/social-networks';
import { handleError } from '~/services/error-handling/error-handling';
import ButtonSocial from '~/components/button-social.vue';
import CopyButton from '~/components/copy-button/copy-button.vue';
import StreaksPairedHowWork from '~/components/streaks/streaks-paired/streaks-paired-how-work.vue';

export const messages = {
  ru: {
    description:
      'чтобы подготовка к экзаменам была ещё интереснее, занимайтесь вместе с бадди и мотивируйте друг друга',
    sendIt:
      'отправляй ссылку с приглашением своему бадди и начинайте собирать самый большой стрик вместе!',
    vk: 'поделиться в вконтакте',
    tg: 'поделиться в телеграме',
    shareMessage:
      'Переходи по ссылке — будем заниматься каждый день и собирать самый большой парный стрик',
    share: 'поделиться:',
  },
};

export default {
  i18n: { messages },
  components: { ButtonSocial, CopyButton, StreaksPairedHowWork },
  props: {
    description: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      invitation: undefined,
    };
  },
  computed: {
    shareUrl() {
      const url = this.$router.resolve({
        name: 'paired-streak-invitation',
        params: { id: this.invitation?.id },
      });

      return window.location.origin + url.href;
    },
    socialLinks() {
      return [
        {
          modifier: 'vk',
          icon: 'vk-icon-white',
          ariaLabel: this.$t('vk'),
          href: vkShareUrl({
            url: this.shareUrl,
            image: `${window.location.origin}/images/share_paired_streak_vk.jpg`,
            message: this.$t('shareMessage'),
          }),
        },
        {
          modifier: 'tg',
          icon: 'telegram-icon-white',
          ariaLabel: this.$t('tg'),
          href: telegramShareUrl({
            url: this.shareUrl,
            message: this.$t('shareMessage'),
          }),
        },
      ];
    },
    isButtonsDisabled() {
      return !this.invitation?.id && this.$auth.loggedIn;
    },
    buttonsWrapperComponent() {
      return this.$auth.loggedIn ? 'div' : 'a';
    },
    shopAuthUrl() {
      if (this.$auth.loggedIn) return '';
      const authModalUrl = new URL(this.$config.serviceUrls.SHOP_AUTH_URL);
      const openerUrl = new URL(window.location.href);
      authModalUrl.searchParams.set('opener', openerUrl);

      return authModalUrl;
    },
  },
  async created() {
    if (!this.$auth.loggedIn) return;
    try {
      this.invitation = await createPairedStreakInvitation();
    } catch (error) {
      handleError(error);
    }
  },
};
</script>

<template>
  <div :class="$style.root">
    <div :class="$style.description">
      <p v-if="description">{{ description }}</p>
      <template v-else>
        <p>{{ $t('description') }}</p>
        <StreaksPairedHowWork :class="$style.howItsWork" />
        <p>{{ $t('sendIt') }}</p>
      </template>
    </div>
    <component
      :is="buttonsWrapperComponent"
      :class="$style.buttons"
      :href="shopAuthUrl"
    >
      <CopyButton
        :class="$style.copyButton"
        :copy-content="shareUrl"
        :disabled="isButtonsDisabled"
        :without-animation="!$auth.loggedIn"
        theme="primary"
        :size="$device.isSmallScreen ? 'l' : 'm'"
      />
      <span :class="$style.share">{{ $t('share') }}</span>
      <div :class="$style.socialButtons">
        <ButtonSocial
          v-for="link in socialLinks"
          :key="link.modifier"
          :size="$device.isSmallScreen ? 'l' : 'm'"
          :modifier="link.modifier"
          :href="link.href"
          :icon-name="link.icon"
          :aria-label="link.ariaLabel"
          :disabled="isButtonsDisabled"
          target="_blank"
          :tag="$auth.loggedIn ? 'a' : 'button'"
        />
      </div>
    </component>
  </div>
</template>

<style lang="postcss" module>
.root {
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: flex-start;
}

.description {
  font-size: var(--invite-font-size, 16px);
  line-height: var(--invite-line-height, 20px);

  & hr {
    height: 4px;
    background: none;
    border: none;
  }
}

.howItsWork {
  margin: 12px 0;
}

.buttons {
  display: flex;
  gap: 8px;
  align-items: center;
  margin-top: 22px;
}

.socialButtons {
  display: flex;
  gap: 10px;
}

.copyButton {
  flex-grow: 1;
}

.share {
  position: relative;
  bottom: 2px;
  margin-left: 18px;
  color: var(--grey-600);
}

@media (--small-vp) {
  .buttons {
    flex-direction: column;
    gap: 14px;
    width: 100%;
  }

  .socialButtons {
    gap: 16px;
  }

  .share {
    margin-left: 0;
  }

  .copyButton {
    width: 100%;
    margin-bottom: 14px;
  }
}
</style>

<script>
export const messages = {
  ru: {
    btnAriaLabel: 'открыть тех.чат',
  },
};

export default {
  i18n: { messages },
  props: {
    fixed: {
      type: Boolean,
      default: false,
    },
    absolute: {
      type: Boolean,
      default: false,
    },
    desktop: {
      type: Boolean,
      default: false,
    },
    tablet: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    classes() {
      return {
        [this.$style.root]: true,
        [this.$style._fixed]: this.fixed,
        [this.$style._absolute]: this.absolute,
        [this.$style._desktop]: this.desktop,
        [this.$style._tablet]: this.tablet,
      };
    },
  },
  methods: {
    openCarrotquest() {
      if (!window.carrotquest) return;
      window.carrotquest.open();
    },
  },
};
</script>

<template>
  <div :class="classes">
    <button
      :class="$style.btnChat"
      :aria-label="$t('btnAriaLabel')"
      type="button"
      @click="openCarrotquest"
    >
      <WIcon name="icon-chat" />
    </button>
  </div>
</template>

<style lang="postcss" module>
.root {
  display: flex;
}

._fixed {
  position: fixed;
  right: 20px;
  bottom: 20px;
  z-index: 50;
}

._absolute {
  position: absolute;
  right: 20px;
  bottom: 125px;
  z-index: 10;
}

._tablet {
  display: none;
}

.btnChat {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background-color: var(--primary-500);
  border-radius: 50%;
  transition: opacity var(--default-transition);

  & svg {
    width: 24px;
    height: 24px;
  }
}

@media (hover: hover) {
  .btnChat {
    &:hover,
    &:focus {
      opacity: 0.7;
    }
  }
}

@media (--small-vp) {
  .btnChat {
    width: 44px;
    height: 44px;
  }

  ._desktop {
    display: none;
  }

  ._tablet {
    display: flex;
  }
}
</style>

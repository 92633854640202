<script>
import { WIconButton } from '@WebiumTeam/ui_kit';
import { createNamespacedHelpers } from 'vuex';
import { markAsViewedNotifications } from '~/api/notifications';
import BaseModalWrapper from '~/components/_base/_base-modal-wrapper.vue';
import BasePopover from '~/components/_base/popover/_base-popover.vue';

import BasePopoverMenu from '~/components/_base/popover/_base-popover-menu.vue';
import NotificationList from '~/components/notifications/notification-list.vue';

export const messages = {
  ru: {
    title: 'уведомления',
    markAllAsViewed: 'отметить все как прочитанные',
    notificationSettings: 'настройки уведомлений',
  },
};

const popoverMenuValues = {
  markAll: 'markAll',
  settings: 'settings',
};

const { mapActions: mapSettingsActions } = createNamespacedHelpers(
  'notification-settings'
);

const { mapState, mapActions } = createNamespacedHelpers('notification-list');

export default {
  i18n: { messages },
  components: {
    BaseModalWrapper,
    NotificationList,
    BasePopover,
    BasePopoverMenu,
    WIconButton,
  },
  computed: {
    ...mapState(['nonViewedCount']),
    popoverMenuItems() {
      return [
        { text: this.$t('markAllAsViewed'), value: popoverMenuValues.markAll },
        {
          text: this.$t('notificationSettings'),
          value: popoverMenuValues.settings,
        },
      ];
    },
  },
  methods: {
    ...mapActions(['fetchNotifications']),
    ...mapSettingsActions(['openSettingsModal']),
    async markAllAsViewed() {
      if (this.nonViewedCount !== 0) {
        await markAsViewedNotifications();
        await this.fetchNotifications();
      }
    },
    selectContext(type) {
      if (type === popoverMenuValues.markAll) {
        this.markAllAsViewed();
      }
      if (type === popoverMenuValues.settings) {
        this.openSettings();
      }
    },
    openSettings() {
      this.openSettingsModal();
    },
  },
};
</script>

<template>
  <BaseModalWrapper
    :title="$t('title')"
    :class="$style.root"
    @close="$emit('close')"
  >
    <template #header>
      <BasePopover :class="$style.menu" placement="bottom" over-modal>
        <WIconButton theme="emptyLight" icon-name="menu-L" size="m" />
        <template #dropdown>
          <BasePopoverMenu :items="popoverMenuItems" @select="selectContext" />
        </template>
      </BasePopover>
    </template>
    <div :class="$style.content">
      <NotificationList is-popover :class="$style.notificationList" />
    </div>
  </BaseModalWrapper>
</template>

<style lang="postcss" module>
.root {
  --modal-max-width: 100%;
}

.menu {
  margin-right: auto;
  margin-left: 8px;
}

.content {
  padding-bottom: 40px;
}

.notificationList {
  position: relative;

  &::after {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    content: '';
    background: var(--grey-200);
  }
}

@media (--small-vp) {
  .content {
    height: calc(100vh - 62px);
    padding-bottom: 0;
  }

  .notificationList {
    height: 100%;
  }
}
</style>

// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._base-radiobutton-group__root-57zby{display:flex;flex-direction:column;align-items:flex-start}._base-radiobutton-group__radioItem-4Bz6j:not(:last-child){margin-bottom:16px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "_base-radiobutton-group__root-57zby",
	"radioItem": "_base-radiobutton-group__radioItem-4Bz6j"
};
module.exports = ___CSS_LOADER_EXPORT___;

<template>
  <div :class="$style.root">
    <IconsSprite />
    <div :class="$style.main">
      <Nuxt />
    </div>
  </div>
</template>

<script>
import IconsSprite from '~/components/icon/icons-sprite.vue';

export default {
  components: {
    IconsSprite,
  },
};
</script>

<style lang="postcss" module>
.root {
  --background-brand-rgb: 255 246 237;
  --background-brand: rgb(var(--background-brand-rgb));

  min-height: 100vh;
  background-color: var(--background-brand);
}

.main {
  max-width: var(--max-content-width);
  min-height: 968px;
  margin: 0 auto;
}

@media (--small-vp) {
  .main {
    min-height: 100vh;
    padding: 0 12px;
    overflow: hidden;
  }
}
</style>

import { buildServerListWithLoadMoreModule } from '~/store-lib/server-list-with-load-more';
import { getHomeworkList } from '~/api/homeworks';

const storeModule = buildServerListWithLoadMoreModule(getHomeworkList);

const { state, mutations, actions, getters: listGetters } = storeModule;

const getters = {
  ...listGetters,
  incompletedCount(state) {
    return state.items.filter((item) => item.isCompleted === false).length;
  },
};

export { state, mutations, actions, getters };

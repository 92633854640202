<template>
  <div
    :id="popperId"
    ref="popover"
    class="v-popper__popper"
    :class="rootClasses"
    :style="rootStyle"
    :aria-hidden="shown ? 'false' : 'true'"
    :tabindex="autoHide ? 0 : undefined"
    :data-popper-placement="result ? result.placement : undefined"
    @keyup.esc="autoHide && $emit('hide')"
  >
    <div
      class="v-popper__backdrop"
      @click="autoHide && $emit('hide')"
      @keyup.enter="autoHide && $emit('hide')"
    />
    <div class="v-popper__wrapper" :style="wrapperStyle">
      <div ref="inner" class="v-popper__inner">
        <template v-if="mounted">
          <div>
            <slot />
          </div>
          <ResizeObserver
            v-if="handleResize"
            @notify="$emit('resize', $event)"
          />
        </template>
      </div>
      <div ref="arrow" class="v-popper__arrow-container" :style="arrowStyle">
        <div class="v-popper__arrow-outer" />
        <div class="v-popper__arrow-inner" />
      </div>
    </div>
  </div>
</template>

<script>
import { ThemeClass } from 'floating-vue';
import { ResizeObserver } from 'vue-resize';

export default {
  name: 'VPopperContent',
  components: {
    ResizeObserver,
  },
  mixins: [ThemeClass],
  props: {
    popperId: {
      type: String,
      required: true,
    },
    theme: {
      type: String,
      required: true,
    },
    shown: Boolean,
    mounted: Boolean,
    skipTransition: Boolean,
    autoHide: Boolean,
    handleResize: Boolean,
    classes: {
      type: Object,
      required: true,
    },
    result: {
      type: Object,
      required: true,
    },
  },
  computed: {
    rootClasses() {
      return [
        this.themeClass,
        this.classes.popperClass,
        {
          'v-popper__popper--shown': this.shown,
          'v-popper__popper--hidden': !this.shown,
          'v-popper__popper--show-from': this.classes.showFrom,
          'v-popper__popper--show-to': this.classes.showTo,
          'v-popper__popper--hide-from': this.classes.hideFrom,
          'v-popper__popper--hide-to': this.classes.hideTo,
          'v-popper__popper--skip-transition': this.skipTransition,
          'v-popper__popper--arrow-overflow':
            this.sresult && this.result.arrow.overflow,
          'v-popper__popper--no-positioning': !this.result,
        },
      ];
    },
    rootStyle() {
      return this.result
        ? {
            position: this.result.strategy,
            left: this.toPx(this.result.x),
            top: this.toPx(this.result.y),
          }
        : undefined;
    },
    arrowStyle() {
      return this.result
        ? {
            left: this.toPx(this.result.arrow.x),
            top: this.toPx(this.result.arrow.y),
          }
        : undefined;
    },
    wrapperStyle() {
      return this.result
        ? {
            transformOrigin: this.result.transformOrigin,
          }
        : undefined;
    },
  },
  methods: {
    toPx(value) {
      if (value !== undefined && !Number.isNaN(value)) {
        return `${Math.round(value)}px`;
      }
      return undefined;
    },
  },
};
</script>

<!-- eslint-disable vue-scoped-css/enforce-style-type-->
<style>
/* stylelint-disable */

.v-popper__popper {
  z-index: var(--base-popper-z-index);
  top: 0;
  left: 0;
}

.v-popper__popper.v-popper__popper--hidden {
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.15s, visibility 0.15s;
  pointer-events: none;
}

.v-popper__popper.v-popper__popper--shown {
  visibility: visible;
  opacity: 1;
  transition: opacity 0.15s;
}

.v-popper__popper.v-popper__popper--skip-transition,
.v-popper__popper.v-popper__popper--skip-transition > .v-popper__wrapper {
  transition: none !important;
}

.v-popper__backdrop {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
}

.v-popper__inner {
  position: relative;
  box-sizing: border-box;
  overflow-y: auto;
}

.v-popper__inner > div {
  position: relative;
  z-index: 1;
  max-width: inherit;
  max-height: inherit;
}

.v-popper__arrow-container {
  position: absolute;
  width: 10px;
  height: 10px;
}

.v-popper__popper--arrow-overflow .v-popper__arrow-container,
.v-popper__popper--no-positioning .v-popper__arrow-container {
  display: none;
}

.v-popper__arrow-inner,
.v-popper__arrow-outer {
  border-style: solid;
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
}

.v-popper__arrow-inner {
  visibility: hidden;
  border-width: 7px;
}

.v-popper__arrow-outer {
  border-width: 6px;
}

.v-popper__popper[data-popper-placement^='top'] .v-popper__arrow-inner,
.v-popper__popper[data-popper-placement^='bottom'] .v-popper__arrow-inner {
  left: -2px;
}

.v-popper__popper[data-popper-placement^='top'] .v-popper__arrow-outer,
.v-popper__popper[data-popper-placement^='bottom'] .v-popper__arrow-outer {
  left: -1px;
}

.v-popper__popper[data-popper-placement^='top'] .v-popper__arrow-inner,
.v-popper__popper[data-popper-placement^='top'] .v-popper__arrow-outer {
  border-bottom-width: 0;
  border-left-color: transparent !important;
  border-right-color: transparent !important;
  border-bottom-color: transparent !important;
}

.v-popper__popper[data-popper-placement^='top'] .v-popper__arrow-inner {
  top: -2px;
}

.v-popper__popper[data-popper-placement^='bottom'] .v-popper__arrow-container {
  top: 1px !important;
}

.v-popper__popper[data-popper-placement^='bottom'] .v-popper__arrow-inner,
.v-popper__popper[data-popper-placement^='bottom'] .v-popper__arrow-outer {
  border-top-width: 0;
  border-left-color: transparent !important;
  border-right-color: transparent !important;
  border-top-color: transparent !important;
}

.v-popper__popper[data-popper-placement^='bottom'] .v-popper__arrow-inner {
  top: -4px;
}

.v-popper__popper[data-popper-placement^='bottom'] .v-popper__arrow-outer {
  top: -6px;
}

.v-popper__popper[data-popper-placement^='left'] .v-popper__arrow-inner,
.v-popper__popper[data-popper-placement^='right'] .v-popper__arrow-inner {
  top: -2px;
}

.v-popper__popper[data-popper-placement^='left'] .v-popper__arrow-outer,
.v-popper__popper[data-popper-placement^='right'] .v-popper__arrow-outer {
  top: -1px;
}

.v-popper__popper[data-popper-placement^='right'] .v-popper__arrow-inner,
.v-popper__popper[data-popper-placement^='right'] .v-popper__arrow-outer {
  border-left-width: 0;
  border-left-color: transparent !important;
  border-top-color: transparent !important;
  border-bottom-color: transparent !important;
}

.v-popper__popper[data-popper-placement^='right'] .v-popper__arrow-inner {
  left: -4px;
}

.v-popper__popper[data-popper-placement^='right'] .v-popper__arrow-outer {
  left: -6px;
}

.v-popper__popper[data-popper-placement^='left'] .v-popper__arrow-container {
  right: -10px;
}

.v-popper__popper[data-popper-placement^='left'] .v-popper__arrow-inner,
.v-popper__popper[data-popper-placement^='left'] .v-popper__arrow-outer {
  border-right-width: 0;
  border-top-color: transparent !important;
  border-right-color: transparent !important;
  border-bottom-color: transparent !important;
}

.v-popper__popper[data-popper-placement^='left'] .v-popper__arrow-inner {
  left: -2px;
}
</style>

<script>
export default {
  model: {
    prop: 'selected',
    event: 'change',
  },
  props: {
    selected: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
    indeterminate: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
  },
  computed: {
    classes() {
      return {
        [this.$style.root]: true,
        [this.$style._small]: this.small,
        [this.$style._selected]: this.selected,
        [this.$style._disabled]: this.disabled,
        [this.$style._indeterminate]: this.indeterminate,
        [this.$style[`_${this.type}`]]: this.type !== '',
      };
    },
    checkboxIconName() {
      if (this.selected) return 'check';
      if (this.indeterminate) return 'dash';
      return '';
    },
  },
  methods: {
    clickCheckbox() {
      if (!this.disabled && this.type === '') {
        this.$emit('change', !this.selected);
      }
    },
  },
};
</script>

<template>
  <label :class="classes" data-testid="checkbox">
    <input
      class="visually-hidden"
      type="checkbox"
      :checked="selected"
      :disabled="disabled"
      @change="clickCheckbox"
    />
    <span :class="$style.checkbox">
      <WIcon v-if="selected || indeterminate" :name="checkboxIconName" />
    </span>
    <span v-if="label" :class="$style.text">{{ label }}</span>
  </label>
</template>

<style lang="postcss" module>
.root {
  display: flex;
  column-gap: var(--column-gap, 8px);
  align-items: flex-start;
  cursor: pointer;
}

.checkbox {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  background-color: var(--primary-100);
  border: 2px solid var(--primary-500);
  border-radius: 5px;
  transition: background-color var(--default-transition),
    border-color var(--default-transition);
}

.text {
  font-size: 16px;
  line-height: 20px;
  color: var(--text-main-color);
  letter-spacing: 0.02em;
}

._small {
  & .checkbox {
    width: 16px;
    height: 16px;
    border-radius: 4px;
  }

  & .text {
    margin-top: -3px;
  }
}

._selected,
._indeterminate {
  & .checkbox {
    background-color: var(--primary-500);
  }
}

._warning {
  cursor: default;

  & .checkbox {
    border-color: var(--danger-500);
  }
}

._success {
  cursor: default;

  & .checkbox {
    border-color: var(--success-500);
  }
}

._disabled {
  cursor: default;

  & .text {
    color: var(--grey-600);
  }
}

._disabled:not(._warning, ._success) {
  & .checkbox {
    border-color: var(--grey-600);
  }
}

._warning._selected,
._warning._indeterminate {
  & .checkbox {
    background-color: var(--danger-500);
    border-color: var(--danger-500);
  }
}

._success._selected,
._success._indeterminate {
  & .checkbox {
    background-color: var(--success-500);
    border-color: var(--success-500);
  }
}

._disabled._selected:not(._warning, ._success),
._disabled._indeterminate:not(._warning, ._success) {
  & .checkbox {
    background-color: var(--grey-600);
    border-color: var(--grey-600);
  }
}

.root:hover {
  & .checkbox {
    background-color: var(--hover-gray-color);
    border-color: var(--primary-400);
  }
}

._selected:hover,
._indeterminate:hover {
  & .checkbox {
    background-color: var(--primary-400);
    border-color: var(--primary-400);
  }
}

.root._disabled:not(._warning, ._success):hover {
  & .checkbox {
    background-color: var(--primary-100);
    border-color: var(--grey-600);
  }
}

._disabled._selected:not(._warning, ._success):hover,
._disabled._indeterminate:not(._warning, ._success):hover {
  & .checkbox {
    background-color: var(--grey-600);
    border-color: var(--grey-600);
  }
}

.root._success:hover {
  & .checkbox {
    background-color: var(--primary-100);
    border-color: var(--success-500);
  }
}

.root._warning:hover {
  & .checkbox {
    background-color: var(--primary-100);
    border-color: var(--danger-500);
  }
}

._success._selected:hover,
._success._indeterminate:hover {
  & .checkbox {
    background-color: var(--success-500);
    border-color: var(--success-500);
  }
}

._warning._selected:hover,
._warning._indeterminate:hover {
  & .checkbox {
    background-color: var(--danger-500);
    border-color: var(--danger-500);
  }
}
</style>

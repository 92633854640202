<script>
import { createNamespacedHelpers } from 'vuex';
import { MAX_FREEZE_COUNT } from '~/constants/streaks';
import BasePopover from '~/components/_base/popover/_base-popover.vue';

const { mapState } = createNamespacedHelpers('streaks');

export const messages = {
  ru: {
    freeze: 'заморозка',
    counter: '{left} / {max}',
    description: `{freezeLabel} позволит сохранить стрик, если ты пропустишь день занятий {hr} каждый месяц тебе доступно ${MAX_FREEZE_COUNT} заморозок, они списываются автоматически`,
  },
};

const icon = require('~/components/streaks/images/freeze.svg');

export default {
  i18n: {
    messages,
  },
  components: {
    BasePopover,
  },
  computed: {
    ...mapState(['availableFreezes', 'maxFreezesPerMonth']),
    counter() {
      return this.$t('counter', {
        left: this.availableFreezes,
        max: this.maxFreezesPerMonth,
      });
    },
    icon() {
      return icon;
    },
  },
};
</script>

<template>
  <BasePopover
    :popper-class="$style.popper"
    placement="bottom"
    :trigger="['hover', 'click']"
    :distance="$device.isSmallScreen ? 4 : 0"
    show-arrow
  >
    <div :class="$style.content">
      <span>{{ counter }}</span>
      <img :src="icon" :class="$style.icon" alt="" />
    </div>
    <template #dropdown>
      <div :class="$style.popoverContent">
        <i18n path="description" tag="p">
          <template #hr><hr /></template>
          <template #freezeLabel>
            <span :class="$style.freezeLabel">
              <span>{{ $t('freeze') }}</span>
              <img :class="$style.contentIcon" :src="icon" alt="" />
            </span>
          </template>
        </i18n>
      </div>
    </template>
  </BasePopover>
</template>

<style lang="postcss" module>
.popper {
  z-index: var(--base-tooltip-z-index);
  margin-top: 4px;
}

.content {
  display: flex;
  gap: 2px;
  align-items: center;
  padding: 0 1px 1px;
  font-size: 14px;
  line-height: 18px;
  color: var(--streaks-freeze-color);
  cursor: default;
  border-bottom: solid 1px var(--grey-400);

  &:hover {
    border-bottom: solid 1px var(--grey-500);
  }
}

.popoverContent {
  width: 310px;
  padding: 16px 20px;
  font-size: 14px;
  line-height: 18px;
  white-space: pre-line;

  & hr {
    display: block;
    height: 8px;
    border: none;
  }
}

.freezeLabel {
  display: inline-block;
  margin-right: 2px;
  color: var(--streaks-freeze-color);

  & .contentIcon {
    padding-bottom: 3px;
  }
}

@media (--small-vp) {
  .content {
    font-size: 16px;
    line-height: 20px;
  }

  .icon {
    width: 14px;
    height: 14px;
  }
}
</style>

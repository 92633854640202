<script>
import kebabCase from 'lodash/kebabCase';
import { createNamespacedHelpers } from 'vuex';
import { ONBOARDING_STEPS } from '~/constants/onboarding';
import BaseModalWrapper from '~/components/_base/_base-modal-wrapper.vue';

const { mapState: mapCurrentCourseState } =
  createNamespacedHelpers('current-course');

export const messages = {
  ru: {
    defaultTitle: 'привет! давай настроим твой курс',
    continue: 'продолжить',
    finish: 'завершить',
    finishSubmit: 'всё понятно!',
    doLater: {
      0: 'выбрать позже',
    },
  },
};

const cachedStepComponents = {};

export default {
  components: {
    BaseModalWrapper,
  },
  i18n: {
    messages,
  },
  props: {
    isCompleted: {
      type: Boolean,
      default: false,
    },
    initialStepKey: {
      type: String,
      default: '',
      required: false,
      validator: (value) =>
        !value || Object.values(ONBOARDING_STEPS).includes(value),
    },
  },
  data() {
    return {
      currentTitle: this.$t('defaultTitle'),
      groupedSteps: [],
      currentStepGlobalIndex: 0,
    };
  },
  computed: {
    ...mapCurrentCourseState(['currentCourse']),
    currentStepKey() {
      return this.groupedSteps.flat()[this.currentStepGlobalIndex];
    },
    nextStepKey() {
      return this.groupedSteps.flat()[this.currentStepGlobalIndex + 1];
    },
    isLastStep() {
      return this.currentStepGlobalIndex + 1 >= this.groupedSteps.flat().length;
    },
    currentStepComponent() {
      if (cachedStepComponents[this.currentStepKey])
        return cachedStepComponents[this.currentStepKey];
      const componentName = `step-${kebabCase(this.currentStepKey)}`;
      cachedStepComponents[this.currentStepKey] = () =>
        import(`./steps/${componentName}.vue`);
      return cachedStepComponents[this.currentStepKey];
    },
    showFinishButton() {
      return (
        this.isCompleted ||
        this.currentStepGlobalIndex >= this.groupedSteps.flat().length - 1
      );
    },
    progressSegments() {
      return this.groupedSteps.map((groupItems) => groupItems.length);
    },
    mobileCloseButtonText() {
      if (!this.$device.isSmallScreen) return '';
      const doLaterText =
        messages[this.$i18n.locale].doLater[this.currentStepGlobalIndex];
      return this.showFinishButton ? this.$t('finish') : doLaterText;
    },
  },
  watch: {
    currentStepKey: {
      handler(stepKey) {
        this.$emit('step-change', stepKey);
      },
    },
  },
  created() {
    this.resolveGroupedSteps();
    this.resolveCurrentGlobalIndex();
  },
  methods: {
    resolveCurrentGlobalIndex() {
      if (!this.initialStepKey) return;
      const currentStepGlobalIndex = this.groupedSteps
        .flat()
        .indexOf(this.initialStepKey);
      this.currentStepGlobalIndex =
        currentStepGlobalIndex === -1 ? 0 : currentStepGlobalIndex;
    },
    resolveGroupedSteps() {
      if (this.isCompleted) {
        this.groupedSteps = [
          this.currentCourse.onboardingConfig.welcomePopupText
            ? ONBOARDING_STEPS.courseInfo
            : undefined,
        ];
        return;
      }
      const hasCourseGoals = (this.currentCourse.courseGoals || []).length > 0;
      const groupedSteps = [
        [
          ...(hasCourseGoals && !this.currentCourse.learningGoal?.goal
            ? [ONBOARDING_STEPS.goals, ONBOARDING_STEPS.goals]
            : []),
        ],
        [
          this.currentCourse.onboardingConfig?.welcomePopupText
            ? ONBOARDING_STEPS.courseInfo
            : undefined,
        ],
      ];
      this.groupedSteps = groupedSteps
        .map((group) => group.filter((step) => !!step))
        .filter((group) => group && group.length > 0);
    },
    close() {
      this.$emit('close');
    },
    finish() {
      if (this.isCompleted) {
        this.$emit('close');
        return;
      }
      this.$emit('finish');
    },
    onUpdateTitle(title) {
      this.currentTitle = title;
    },
    onFinish() {
      this.finish();
    },
    onNextStep() {
      if (this.isLastStep) {
        this.finish();
      } else {
        this.currentStepGlobalIndex += 1;
      }
    },
    onPreviousStep() {
      if (this.currentStepGlobalIndex === 0) return;
      this.currentStepGlobalIndex -= 1;
    },
    onDoLater() {
      this.close();
    },
    onCloseClick() {
      return this.showFinishButton ? this.onFinish() : this.onDoLater();
    },
  },
};
</script>

<template>
  <BaseModalWrapper
    :class="$style.root"
    hide-close
    hide-header
    mobile-bottom
    stretch
    :external-button-text="mobileCloseButtonText"
    external-button-position="top"
    @external-button-click="onCloseClick"
    @close="close"
  >
    <div :class="$style.inner">
      <strong :class="$style.title">{{ currentTitle }}</strong>
      <div>
        <component
          :is="currentStepComponent"
          v-if="currentStepKey"
          :next-step-key="nextStepKey"
          @next-step="onNextStep"
          @previous-step="onPreviousStep"
          @update-title="onUpdateTitle"
          @do-later="onDoLater"
        />
      </div>
    </div>
  </BaseModalWrapper>
</template>

<style lang="postcss" module>
.root {
  --modal-max-width: 700px;
  --w-overlay-content-margin: 80px 0 0;
}

.inner {
  --modal-padding-x: 40px;

  position: relative;
  display: flex;
  flex-direction: column;
  padding: 32px var(--modal-padding-x) 40px;
}

.title {
  margin-right: 16px;
  margin-bottom: 16px;
  font-size: 28px;
  line-height: 34px;
}

@media (--small-vp) {
  .root {
    --modal-max-width: 100%;
    --w-overlay-content-margin: auto 0;
    --modal-mobile-top-space: 62px;
  }

  .inner {
    --modal-padding-x: 20px;

    padding: 20px var(--modal-padding-x) 20px;
  }

  .title {
    margin-right: 0;
  }
}
</style>

// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".streaks-calendar-mini__root-r3PPm{grid-gap:24px;gap:24px}.streaks-calendar-mini__item-3HLpJ,.streaks-calendar-mini__root-r3PPm{display:flex}.streaks-calendar-mini__item-3HLpJ{--border-color:transparent;flex-direction:column;grid-gap:2px;gap:2px;align-items:center;justify-content:center;width:40px;height:64px;border:2px solid var(--border-color);border-radius:64px}.streaks-calendar-mini__item-3HLpJ,.streaks-calendar-mini__item-3HLpJ.streaks-calendar-mini___empty-Ym\\+2G,.streaks-calendar-mini__item-3HLpJ.streaks-calendar-mini___missed-E6mpD{--font-color:var(--grey-500)}.streaks-calendar-mini__item-3HLpJ.streaks-calendar-mini___freeze-MVI-I{--font-color:var(--streaks-freeze-color)}.streaks-calendar-mini__item-3HLpJ.streaks-calendar-mini___streak-epH3A{--font-color:var(--streaks-active-color)}.streaks-calendar-mini__item-3HLpJ.streaks-calendar-mini___current-8pzmR{--border-color:var(--grey-400)}.streaks-calendar-mini__item-3HLpJ.streaks-calendar-mini___buddy-l7kxa,.streaks-calendar-mini__item-3HLpJ.streaks-calendar-mini___paired--A0\\+6{--font-color:var(--support-purple)}.streaks-calendar-mini__dayName-vQF7v{font-size:14px;line-height:18px;color:var(--font-color)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "streaks-calendar-mini__root-r3PPm",
	"item": "streaks-calendar-mini__item-3HLpJ",
	"_empty": "streaks-calendar-mini___empty-Ym+2G",
	"_missed": "streaks-calendar-mini___missed-E6mpD",
	"_freeze": "streaks-calendar-mini___freeze-MVI-I",
	"_streak": "streaks-calendar-mini___streak-epH3A",
	"_current": "streaks-calendar-mini___current-8pzmR",
	"_buddy": "streaks-calendar-mini___buddy-l7kxa",
	"_paired": "streaks-calendar-mini___paired--A0+6",
	"dayName": "streaks-calendar-mini__dayName-vQF7v"
};
module.exports = ___CSS_LOADER_EXPORT___;

const firstPageIndex = 1;
const defaultHasNextPage = true;

export function buildServerListWithLoadMoreModule(getItemsMethod) {
  return {
    state: () => ({
      items: [],
      totalCount: 0,
      nextPage: firstPageIndex,
      hasNextPage: defaultHasNextPage,
      nextPageLoading: false,
      firstPageLoading: false,
    }),
    getters: {
      canLoadMoreItems: (state) => state.hasNextPage,
      moreItemsLoading: (state) => state.nextPageLoading,
      firstItemsLoading: (state) => state.firstPageLoading,
    },
    mutations: {
      SET_ITEMS(state, items) {
        state.items = items;
      },
      APPEND_ITEMS(state, items) {
        state.items.push(...items);
      },
      RESET_TOTAL_COUNT(state) {
        state.totalCount = 0;
      },
      SET_TOTAL_COUNT(state, count) {
        state.totalCount = count;
      },
      RESET_PAGINATION(state) {
        state.nextPage = firstPageIndex;
        state.hasNextPage = defaultHasNextPage;
      },
      INCREMENT_NEXT_PAGE(state) {
        state.nextPage += 1;
      },
      SET_HAS_NEXT_PAGE(state, hasNextPage) {
        state.hasNextPage = hasNextPage;
      },
      SET_NEXT_PAGE_LOADING(state, nextPageLoading) {
        state.nextPageLoading = nextPageLoading;
      },
      SET_FIRST_PAGE_LOADING(state, firstPageLoading) {
        state.firstPageLoading = firstPageLoading;
      },
    },
    actions: {
      async fetchItems({ commit, state }, params) {
        if (state.firstPageLoading || state.nextPageLoading) return;

        commit('RESET_PAGINATION');
        commit('RESET_TOTAL_COUNT');
        commit('SET_FIRST_PAGE_LOADING', true);
        const { results, hasNextPage, totalCount } = await getItemsMethod(
          params
        );
        commit('SET_ITEMS', results);
        commit('SET_TOTAL_COUNT', totalCount);
        commit('INCREMENT_NEXT_PAGE');
        commit('SET_HAS_NEXT_PAGE', hasNextPage);
        commit('SET_FIRST_PAGE_LOADING', false);
      },

      async fetchMoreItems({ commit, state }, params) {
        if (state.firstPageLoading || state.nextPageLoading) return;

        const requestParams = { page: state.nextPage, ...params };
        commit('SET_NEXT_PAGE_LOADING', true);
        const { results, hasNextPage } = await getItemsMethod(requestParams);
        commit('APPEND_ITEMS', results);
        commit('INCREMENT_NEXT_PAGE');
        commit('SET_HAS_NEXT_PAGE', hasNextPage);
        commit('SET_NEXT_PAGE_LOADING', false);
      },
    },
  };
}

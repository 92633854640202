// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".streaks-paired-remove-button__root-OyXpo.streaks-paired-remove-button___visible-c9lBF{visibility:visible;opacity:1}.streaks-paired-remove-button__popover-xoGnS{margin-left:12px}.streaks-paired-remove-button__menu-DFMsC{max-width:calc(100vw - 28px)}.streaks-paired-remove-button__confirmationModal-3HTAG{text-align:left}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "streaks-paired-remove-button__root-OyXpo",
	"_visible": "streaks-paired-remove-button___visible-c9lBF",
	"popover": "streaks-paired-remove-button__popover-xoGnS",
	"menu": "streaks-paired-remove-button__menu-DFMsC",
	"confirmationModal": "streaks-paired-remove-button__confirmationModal-3HTAG"
};
module.exports = ___CSS_LOADER_EXPORT___;

import { appContext } from '~/services/app-context';

export function buildShopModalUrl(opener, { modal }) {
  if (process.server) return '';
  const profileModalUrl = new URL(
    appContext().$config.serviceUrls.SHOP_PROFILE_URL
  );
  const openerUrl = new URL(opener || window.location.href);
  profileModalUrl.searchParams.set('opener', openerUrl.href);
  profileModalUrl.searchParams.set('modal', modal);
  return profileModalUrl;
}

export function buildCartUrl(cartItems) {
  const cartQuery = cartItems.map((cartItem) => ({
    publicId: cartItem.publicId,
    months: cartItem.months,
  }));

  return `${
    appContext().$config.serviceUrls.SHOP_CART_URL
  }?courses=${JSON.stringify(cartQuery)}`;
}

export function buildCatalogByTopicUrl(topicSlug) {
  return `${appContext().$config.serviceUrls.SHOP_CATALOG_URL}${topicSlug}/`;
}

export function buildUpgradeCourseUrlByTopic(topicSlug) {
  return appContext().$config.serviceUrls.SHOP_UPGRADE_COURSE_URL.replace(
    ':slug',
    topicSlug
  );
}

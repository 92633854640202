export default function authenticated({ $auth, route, redirect, $config }) {
  const publicPages = ['auth', 'paired-streak-invitation'];

  if (!$auth.loggedIn && !publicPages.includes(route.name)) {
    const authModalUrl = new URL($config.serviceUrls.SHOP_AUTH_URL);
    if (
      !process.server &&
      route.matched.some((record) => record.meta.sendOpenerToLogin)
    ) {
      const openerUrl = new URL(window.location.href);
      authModalUrl.searchParams.set('opener', openerUrl);
    }

    redirect(authModalUrl.href);
  }
}

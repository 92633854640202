<script>
export const messages = {
  ru: {
    howItsWork: 'как работает парный стрик?',
    streak:
      'стрик — непрерывная полоса занятий, которая обозначается огоньками. занимайся ежедневно, чтобы стрик увеличивался',
    pairedStreak:
      'парный стрик — это ваш с бадди общий стрик. чтобы его увеличить, тебе и твоему бадди нужно получить личный стрик в один день',
    pairedStreakReset:
      'парный стрик обнулится, если кто-то из вас потеряет личный стрик',
  },
};

export default {
  i18n: { messages },
  props: {
    withStreak: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<template>
  <div :class="$style.root">
    <p :class="$style.title">{{ $t('howItsWork') }}</p>
    <ul>
      <li v-if="withStreak">
        <div><WIcon name="bullet" /></div>
        <span>{{ $t('streak') }}</span>
      </li>
      <li>
        <div><WIcon name="bullet" /></div>
        <span>{{ $t('pairedStreak') }}</span>
      </li>
      <li>
        <div><WIcon name="bullet" /></div>
        <span>{{ $t('pairedStreakReset') }}</span>
      </li>
    </ul>
  </div>
</template>

<style lang="postcss" module>
.root {
  & .title {
    margin-bottom: 7px;
  }

  & svg {
    font-size: 20px;
  }

  & li {
    display: flex;
    gap: 7px;
    margin-bottom: 4px;
  }
}
</style>

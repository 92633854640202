<script>
import BannerCloseButton from '~/components/banner-close-button.vue';

const rootRef = 'root';
const dataAttribute = 'data-toast-message';

export default {
  rootRef,
  components: {
    BannerCloseButton,
  },
  props: {
    hideClose: {
      type: Boolean,
      default: false,
    },
    duration: {
      type: Number,
      default: 7000,
    },
    autoHide: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isVisible: false,
    };
  },
  watch: {
    isVisible(value) {
      if (value) {
        this.$nextTick(() => {
          this.$refs[rootRef].setAttribute(dataAttribute, true);
        });
        if (this.autoHide) {
          setTimeout(() => {
            this.hide();
          }, this.duration);
        }
      }
    },
  },
  methods: {
    show() {
      this.isVisible = true;
    },
    hide() {
      if (!this.$refs[rootRef]) return;
      this.$refs[rootRef].removeAttribute(dataAttribute);
      this.isVisible = false;
      this.$emit('close');
    },
  },
};
</script>

<template>
  <Transition name="messageTransition">
    <div
      v-if="isVisible"
      :ref="$options.rootRef"
      v-touch:swipe.up="hide"
      :class="$style.root"
    >
      <slot></slot>
      <BannerCloseButton v-if="!hideClose" @click="hide" />
    </div>
  </Transition>
</template>

<!-- eslint-disable vue-scoped-css/enforce-style-type-->
<style>
.messageTransition-enter-active,
.messageTransition-leave-active {
  transition: all 0.5s ease;
}

.messageTransition-leave-to {
  opacity: 0;
}

.messageTransition-enter {
  opacity: 0;
  transform: translateX(1000px);
}

@media (--small-vp) {
  .messageTransition-enter {
    opacity: 0;
    transform: translateY(-1000px);
  }
}
</style>

<style lang="postcss" module>
.root {
  position: relative;
  width: 400px;
  background: var(--primary-100);
  border: solid 2px var(--primary-500);
  border-radius: 8px;
  box-shadow: var(--dropdown-shadow);
}

@media (--small-vp) {
  .root {
    width: initial;
    max-width: 100%;
  }
}
</style>

// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".the-navbar-links__item-5vr5\\+ a,.the-navbar-links__item-5vr5\\+ button{display:inline-flex;align-items:center;justify-content:center;padding:15px 0 17px;font-size:16px;line-height:20px;color:var(--primary-500);letter-spacing:.02em;transition:var(--default-transition)}@media (hover:hover){.the-navbar-links__item-5vr5\\+ a:hover,.the-navbar-links__item-5vr5\\+ button:hover{color:var(--primary-200)}.the-navbar-links__item-5vr5\\+ a:active,.the-navbar-links__item-5vr5\\+ button:active{color:var(--primary-500)}}.the-navbar-links__root-4UPJq{display:flex;grid-gap:48px;gap:48px;align-items:center;padding:0}.the-navbar-links__root-4UPJq.the-navbar-links___column-hh5BX{flex-direction:column;grid-gap:0;gap:0;align-items:flex-start}.the-navbar-links__root-4UPJq.the-navbar-links___column-hh5BX .the-navbar-links__item-5vr5\\+{display:inline-flex;align-items:center;height:var(--navbar-height)}@media (max-width:1023px){.the-navbar-links__item-5vr5\\+ a,.the-navbar-links__item-5vr5\\+ button{align-items:flex-start;justify-content:flex-start;width:100%;height:var(--navbar-height);padding:14px 30px;font-size:20px;line-height:24px}.the-navbar-links__root-4UPJq{padding:8px 0}.the-navbar-links__root-4UPJq,.the-navbar-links__root-4UPJq.the-navbar-links___column-hh5BX .the-navbar-links__item-5vr5\\+{width:100%}.the-navbar-links___desktopOnly-UAMs2{display:none}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"item": "the-navbar-links__item-5vr5+",
	"root": "the-navbar-links__root-4UPJq",
	"_column": "the-navbar-links___column-hh5BX",
	"_desktopOnly": "the-navbar-links___desktopOnly-UAMs2"
};
module.exports = ___CSS_LOADER_EXPORT___;

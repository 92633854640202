<script>
import { WIconButton } from '@WebiumTeam/ui_kit';

export default {
  components: {
    WIconButton,
  },
};
</script>

<template>
  <WIconButton
    :class="$style.root"
    size="xxs"
    theme="emptyLight"
    icon-name="close-S"
    data-testid="close-button"
    v-bind="$attrs"
    v-on="$listeners"
  />
</template>

<style lang="postcss" module>
.root {
  position: absolute;
  top: 8px;
  right: 8px;
}

@media (--small-vp) {
  .root {
    top: 0;
    right: 0;
  }
}
</style>

// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._base-confirmation-modal__root-2M\\+\\+Q{--modal-max-width:380px;color:var(--text-main-color)}._base-confirmation-modal__wrapper-BdrlP{padding:16px 24px 24px;font-size:16px;line-height:20px}._base-confirmation-modal__actions-nhl8w{display:flex;grid-gap:16px;gap:16px;justify-content:flex-end;padding-top:40px}@media (max-width:1023px){._base-confirmation-modal__wrapper-BdrlP{padding:20px}._base-confirmation-modal__actions-nhl8w{flex-direction:column-reverse;grid-gap:12px;gap:12px;padding-top:32px}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "_base-confirmation-modal__root-2M++Q",
	"wrapper": "_base-confirmation-modal__wrapper-BdrlP",
	"actions": "_base-confirmation-modal__actions-nhl8w"
};
module.exports = ___CSS_LOADER_EXPORT___;

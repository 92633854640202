// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".support-controls__root-XIhDh{display:flex}.support-controls___fixed-RUv\\+X{position:fixed;right:20px;bottom:20px;z-index:50}.support-controls___absolute-bptYh{position:absolute;right:20px;bottom:125px;z-index:10}.support-controls___tablet-LpSW3{display:none}.support-controls__btnChat-x0w6y{display:inline-flex;align-items:center;justify-content:center;width:40px;height:40px;background-color:var(--primary-500);border-radius:50%;transition:opacity var(--default-transition)}.support-controls__btnChat-x0w6y svg{width:24px;height:24px}@media (hover:hover){.support-controls__btnChat-x0w6y:focus,.support-controls__btnChat-x0w6y:hover{opacity:.7}}@media (max-width:1023px){.support-controls__btnChat-x0w6y{width:44px;height:44px}.support-controls___desktop-Uetz\\+{display:none}.support-controls___tablet-LpSW3{display:flex}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "support-controls__root-XIhDh",
	"_fixed": "support-controls___fixed-RUv+X",
	"_absolute": "support-controls___absolute-bptYh",
	"_tablet": "support-controls___tablet-LpSW3",
	"btnChat": "support-controls__btnChat-x0w6y",
	"_desktop": "support-controls___desktop-Uetz+"
};
module.exports = ___CSS_LOADER_EXPORT___;

<script>
import { createNamespacedHelpers } from 'vuex';
import { generateBannerDataForGaEvent } from '~/services/banners';
import { gtmEvents, gtmPushEvent } from '~/services/gtm/gtm';

const { mapGetters } = createNamespacedHelpers('banners');

export default {
  computed: {
    ...mapGetters(['randomCommonBanner']),
  },
  methods: {
    onClick() {
      gtmPushEvent(
        gtmEvents.lmsBannerClick,
        generateBannerDataForGaEvent(this.randomCommonBanner)
      );
    },
  },
};
</script>

<template>
  <div v-if="randomCommonBanner" :class="$style.root">
    <a
      :href="randomCommonBanner.link"
      :class="$style.link"
      target="_blank"
      @click="onClick"
    >
      <img
        :src="randomCommonBanner.imageForDesktop"
        width="180"
        :alt="randomCommonBanner.title"
      />
    </a>
  </div>
</template>

<style lang="postcss" module>
.root {
  display: flex;
  width: 100%;
}

.link {
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity var(--default-transition);

  &:hover {
    opacity: 0.7;
  }

  & img {
    border-radius: var(--standard-border-radius);
  }
}
</style>

export const COURSE_ITEM_TYPE = {
  homework: 'homework',
  webinar: 'webinar',
  attachment: 'attachment',
  info: 'infopage',
};

export const BASE_CONTENT_TYPE = [
  COURSE_ITEM_TYPE.homework,
  COURSE_ITEM_TYPE.webinar,
];

export const contentTypeValidator = (value) =>
  value === '' || BASE_CONTENT_TYPE.includes(value);

import {
  LARGE_MIN_WIDTH,
  PHONE_MAX_WIDTH,
  TABLET_MAX_WIDTH,
} from '~/constants/adaptive';

function handleResize({ store }) {
  store.dispatch('responsive/setWindowWidth');
}

export const windowSizeEventHandlersPlugin = (store) => {
  if (process.server) return;

  handleResize({ store });
  window.addEventListener('resize', () => {
    handleResize({ store });
  });
};

export const state = () => ({
  windowWidth: undefined,
});

export const getters = {
  isDesktopScreen(state) {
    return state.windowWidth ? state.windowWidth > TABLET_MAX_WIDTH : undefined;
  },
  isPhoneScreen(state) {
    return state.windowWidth <= PHONE_MAX_WIDTH;
  },
  isTabletScreen(state) {
    return (
      state.windowWidth > PHONE_MAX_WIDTH &&
      state.windowWidth <= TABLET_MAX_WIDTH
    );
  },
  isSmallScreen(state) {
    return state.windowWidth
      ? state.windowWidth <= TABLET_MAX_WIDTH
      : undefined;
  },
  isLargeScreen(state) {
    return state.windowWidth ? state.windowWidth >= LARGE_MIN_WIDTH : undefined;
  },
};

export const mutations = {
  SET_WINDOW_WIDTH(state, width) {
    state.windowWidth = width;
  },
};

export const actions = {
  setWindowWidth({ commit }) {
    commit('SET_WINDOW_WIDTH', window.innerWidth);
  },
};

import { SENTRY_DISABLED } from '~/constants/sentry';

export function captureExceptionWithSentry({ sentry, error }) {
  if (SENTRY_DISABLED) {
    // eslint-disable-next-line no-console
    console.error(
      'Tried to capture exception with Sentry, but Sentry is disabled.',
      error
    );
    return;
  }
  sentry.captureException(error);
}

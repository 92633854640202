<script>
import { createNamespacedHelpers } from 'vuex';
import BaseLuringDot from '~/components/_base/_base-luring-dot.vue';

const { mapGetters } = createNamespacedHelpers('motivation');

export default {
  components: {
    BaseLuringDot,
  },
  computed: {
    ...mapGetters(['isLuringDotVisible']),
  },
};
</script>

<template>
  <Transition name="fade">
    <BaseLuringDot v-if="isLuringDotVisible" :class="$style.root" />
  </Transition>
</template>

<style lang="postcss" module>
.root {
  display: inline-block;
  margin-left: 8px;
}
</style>

import { buildServerListWithLoadMoreModule } from '~/store-lib/server-list-with-load-more';
import Vue from 'vue';
import { getCourseProgressKimNumbers } from '~/api/workout';

const progressKimNumbersList = buildServerListWithLoadMoreModule(
  getCourseProgressKimNumbers
);

export const state = () => ({
  ...progressKimNumbersList.state(),
  courseId: undefined,
});

export const mutations = {
  ...progressKimNumbersList.mutations,
  SET_COURSE_ID(state, value) {
    state.courseId = value;
  },
  UPDATE_ITEM(state, value) {
    const index = state.items.findIndex(
      (item) => item.topicKimNumber.id === value.topicKimNumber.id
    );
    if (index === -1) return;

    Vue.set(state.items, index, value);
  },
};

export const actions = {
  ...progressKimNumbersList.actions,
  updateItemScore({ commit, getters }, { topicKimNumber, score }) {
    if (!topicKimNumber) return;
    const item = getters.getItemByTopicKimNumber({ topicKimNumber });
    if (!item) return;
    const newItem = { ...item };
    newItem.userScore += score;
    newItem.prevWeekUserScore += score;

    commit('UPDATE_ITEM', newItem);
  },
  setCourseId({ commit }, courseId) {
    commit('SET_COURSE_ID', courseId);
  },
};

export const getters = {
  ...progressKimNumbersList.getters,
  unloadedCount(state) {
    return state.totalCount - state.items.length;
  },
  getItemByTopicKimNumber:
    (state) =>
    ({ topicKimNumber }) =>
      state.items?.find((item) => item.topicKimNumber.id === topicKimNumber.id),
};

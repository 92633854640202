// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".the-navbar-buttons__root-v0mSO{--gap:28px;display:flex;grid-gap:var(--gap);gap:var(--gap);align-items:center;justify-content:flex-end}@media (max-width:1023px){.the-navbar-buttons__root-v0mSO{--gap:16px;margin:0 6px}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "the-navbar-buttons__root-v0mSO"
};
module.exports = ___CSS_LOADER_EXPORT___;

import {
  onClientAuthInit,
  onClientAuthLogin,
  onClientAuthLogout,
} from '~/services/auth-functions';
import { initHandler, loginHandler, logoutHandler } from './handlers';

function initEventListeners() {
  onClientAuthInit(initHandler);
  onClientAuthLogin(loginHandler);
  onClientAuthLogout(logoutHandler);
}

function init() {
  initEventListeners();
}

export { init };

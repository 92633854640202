<script>
import { createNamespacedHelpers } from 'vuex';
import { STREAK_DAY_STATE } from '~/constants/streaks';
import { formatDate, isSameDay } from '~/services/datetime';
import BasePopover from '~/components/_base/popover/_base-popover.vue';
import StreaksPairedReceivedStreakTodayPopoverContent from '~/components/streaks/streaks-paired/streaks-paired-buddy-received-streak-today-popover-content.vue';

const { mapGetters } = createNamespacedHelpers('streaks-history');

export default {
  components: {
    BasePopover,
    StreaksPairedReceivedStreakTodayPopoverContent,
  },
  props: {
    dateString: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapGetters(['calendarDayItem']),
    isBuddyReceivedStreakDay() {
      return this.dayItem.state === STREAK_DAY_STATE.buddy;
    },
    wrapperComponent() {
      return this.isBuddyReceivedStreakDay ? BasePopover : 'div';
    },
    dayItem() {
      return this.calendarDayItem(this.dateString);
    },
    dayLabel() {
      return formatDate({ value: this.dateString, format: 'D' });
    },
    nextDateString() {
      return new Date(
        new Date(this.dateString).setDate(
          new Date(this.dateString).getDate() + 1
        )
      );
    },
    isHighlighted() {
      return this.isDayItemHighlighted(this.dayItem);
    },
    withHighlightJoint() {
      const lastWeekDayIndex = 0;
      const nextDayItem = this.calendarDayItem(this.nextDateString);
      return (
        this.isHighlighted &&
        this.dayItem.dayIndex !== lastWeekDayIndex &&
        this.isDayItemHighlighted(nextDayItem)
      );
    },
    rootClasses() {
      return {
        [this.$style.root]: true,
        [this.$style._highlighted]: this.isHighlighted,
        [this.$style._withHighlightJoint]: this.withHighlightJoint,
      };
    },
    numberClasses() {
      const { date, isPastDate, state } = this.dayItem;
      return {
        [this.$style.number]: true,
        [this.$style[`_${state}`]]: !!state,
        [this.$style._past]: !!isPastDate,
        [this.$style._current]: isSameDay(Date.now(), date),
      };
    },
  },
  methods: {
    isDayItemHighlighted(dayItem) {
      return (
        dayItem.state === STREAK_DAY_STATE.freeze ||
        dayItem.state === STREAK_DAY_STATE.streak ||
        dayItem.state === STREAK_DAY_STATE.paired
      );
    },
  },
};
</script>

<template>
  <component
    :is="wrapperComponent"
    :class="rootClasses"
    placement="bottom"
    trigger="hover"
    in-modal
    show-arrow
  >
    <span :class="numberClasses" data-testid="number">{{ dayLabel }}</span>
    <template v-if="isBuddyReceivedStreakDay" #dropdown>
      <StreaksPairedReceivedStreakTodayPopoverContent />
    </template>
  </component>
</template>

<style lang="postcss" module>
.root {
  --size: 36px;

  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  width: var(--size);
  height: var(--size);
  border-radius: 100%;

  &._highlighted {
    background: var(--streaks-active-color-light);
    border-radius: 40px;
  }

  &._withHighlightJoint {
    &::before {
      position: absolute;
      left: 4px;
      display: block;
      height: inherit;
      width: 80px;
      content: '';
      background: inherit;
      border-radius: inherit;
    }
  }
}

.popoverContent {
  padding: 12px 18px 14px;

  & p {
    font-size: 14px;
    line-height: 18px;
    white-space: break-spaces;
  }
}

.popover {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
}

.popper {
  margin-top: 10px;
}

.number {
  --size: 28px;

  z-index: 1;
  display: block;
  width: var(--size);
  height: var(--size);
  text-align: center;
  color: var(--primary-500);
  font-size: 16px;
  line-height: var(--size);
  border-radius: 100%;

  &._past {
    color: var(--calendar-secondary-color);
    opacity: 0.7;
  }

  &._freeze {
    color: var(--primary-100);
    background-color: var(--streaks-freeze-color);
    opacity: 1;
  }

  &._streak {
    color: var(--primary-100);
    background-color: var(--streaks-active-color);
    opacity: 1;
  }

  &._current:not(._freeze, ._streak, ._buddy, ._paired) {
    box-shadow: 0 0 0 2px var(--grey-400);
  }

  &._paired {
    color: var(--primary-100);
    background-color: var(--streaks-paired-color);
    opacity: 1;
  }

  &._buddy {
    background: center / contain no-repeat url('./images/day-buddy-m.svg');
  }
}

@media (--small-vp) {
  .root {
    --size: 40px;

    &._withHighlightJoint {
      &::before {
        left: 2px;
      }
    }
  }

  .number {
    --size: 32px;

    font-size: 18px;

    &._buddy {
      background: center / contain no-repeat url('./images/day-buddy-l.svg');
    }
  }
}
</style>

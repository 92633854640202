import isEqual from 'lodash/isEqual';
import { EVENT_BUS_KEYS } from '~/constants/event-bus';
import { DAILY_WORKOUT_TASKS_GOAL } from '~/constants/streaks';
import { getLastInfo } from '~/api/streaks';
import { onClientAuthLogin } from '~/services/auth-functions';
import { handleError } from '~/services/error-handling/error-handling';
import { eventBus } from '~/services/event-bus';

const eventBusKeysForFetch = [
  EVENT_BUS_KEYS.homeworkCompleted,
  EVENT_BUS_KEYS.webinarWatched,
  EVENT_BUS_KEYS.lessonRated,
  EVENT_BUS_KEYS.mentorRated,
  EVENT_BUS_KEYS.workoutTaskSolved,
  EVENT_BUS_KEYS.buddyAdded,
  EVENT_BUS_KEYS.buddyRemoved,
];

export const state = () => ({
  availableFreezes: 0,
  maxFreezesPerMonth: 0,
  dailyTasksCount: undefined,
  currentStreak: undefined,
  maxUserStreak: 0,
  streakReceivedToday: undefined,
  streakReceivedNow: undefined,
  isLastInfoFetched: false,
  buddyReceivedStreakToday: undefined,
  currentPairedStreak: undefined,
  pairedStreakReceivedToday: undefined,
  buddy: undefined,
  isLoading: false,
});

export const getters = {
  dailyWorkoutGoalReached(state) {
    if (!state.isLastInfoFetched) return undefined;
    return state.dailyTasksCount >= DAILY_WORKOUT_TASKS_GOAL;
  },
  isRecordStreak(state) {
    return (
      state.currentStreak === state.maxUserStreak && state.currentStreak > 0
    );
  },
};

export const mutations = {
  SET_DAILY_TASKS_COUNT(state, dailyTasksCount) {
    state.dailyTasksCount = dailyTasksCount;
  },
  SET_AVAILABLE_FREEZES(state, availableFreezes) {
    state.availableFreezes = availableFreezes;
  },
  SET_MAX_FREEZES_PER_MONTH(state, maxFreezesPerMonth) {
    state.maxFreezesPerMonth = maxFreezesPerMonth;
  },
  SET_CURRENT_STREAK(state, currentStreak) {
    state.currentStreak = currentStreak || 0;
  },
  SET_MAX_USER_STREAK(state, maxUserStreak) {
    state.maxUserStreak = maxUserStreak;
  },
  SET_STREAK_RECEIVED_TODAY(state, value) {
    state.streakReceivedToday = !!value;
  },
  SET_STREAK_RECEIVED_NOW(state, value) {
    state.streakReceivedNow = !!value;
  },
  SET_IS_LAST_INFO_FETCHED(state) {
    state.isLastInfoFetched = true;
  },
  SET_BUDDY_RECEIVED_STREAK_TODAY(state, value) {
    state.buddyReceivedStreakToday = !!value;
  },
  SET_CURRENT_PAIRED_STREAK(state, currentPairedStreak) {
    state.currentPairedStreak = currentPairedStreak || 0;
  },
  SET_PAIRED_STREAK_RECEIVED_TODAY(state, value) {
    state.pairedStreakReceivedToday = !!value;
  },
  SET_BUDDY(state, buddy) {
    state.buddy = buddy;
  },
  SET_IS_LOADING(state, value) {
    state.isLoading = !!value;
  },
};

export const actions = {
  async fetchStreaksInfo(
    { state, commit, dispatch, rootState },
    { ignoreStreakReceivedNow = false } = {}
  ) {
    try {
      if (!rootState.auth?.loggedIn) return;
      commit('SET_IS_LOADING', true);
      const {
        dailyTasksCount,
        availableFreezes,
        maxFreezesPerMonth,
        currentStreak,
        maxStreak,
        streakReceivedToday,
        streakReceivedNow,
        buddyReceivedStreakToday,
        currentPairedStreak,
        pairedStreakReceivedToday,
        buddy,
      } = await getLastInfo();
      const isBuddyChanged = !isEqual(state.buddy, buddy);
      const pairedStreakReceivedNow =
        pairedStreakReceivedToday &&
        state.pairedStreakReceivedToday !== pairedStreakReceivedToday;
      commit('SET_CURRENT_STREAK', currentStreak);
      commit('SET_MAX_USER_STREAK', maxStreak);
      commit('SET_STREAK_RECEIVED_TODAY', streakReceivedToday);
      commit('SET_BUDDY_RECEIVED_STREAK_TODAY', buddyReceivedStreakToday);
      commit('SET_CURRENT_PAIRED_STREAK', currentPairedStreak);
      commit('SET_PAIRED_STREAK_RECEIVED_TODAY', pairedStreakReceivedToday);
      commit('SET_BUDDY', buddy);
      if (!ignoreStreakReceivedNow) {
        commit('SET_STREAK_RECEIVED_NOW', streakReceivedNow);
      }
      commit('SET_DAILY_TASKS_COUNT', dailyTasksCount);
      commit('SET_AVAILABLE_FREEZES', availableFreezes);
      commit('SET_MAX_FREEZES_PER_MONTH', maxFreezesPerMonth);
      if (
        streakReceivedNow ||
        dailyTasksCount === DAILY_WORKOUT_TASKS_GOAL ||
        (state.isLastInfoFetched && (isBuddyChanged || pairedStreakReceivedNow))
      ) {
        dispatch(
          'streaks-history/fetchStreaksHistory',
          {
            options: {
              forceReload: true,
            },
          },
          {
            root: true,
          }
        );
        dispatch('motivation/fetchMotivationInfo', {}, { root: true });
      }
      commit('SET_IS_LAST_INFO_FETCHED', true);
    } catch (error) {
      handleError(error);
    } finally {
      commit('SET_IS_LOADING', false);
    }
  },
  markNewStreakAsViewed({ commit }) {
    commit('SET_STREAK_RECEIVED_NOW', false);
  },
};

export const streaksPlugin = (store) => {
  if (process.server) return;
  const fetchStreaksInfo = () => store.dispatch('streaks/fetchStreaksInfo');

  const silentFetchStreaksInfo = () =>
    store.dispatch('streaks/fetchStreaksInfo', {
      ignoreStreakReceivedNow: true,
    });

  window.onNuxtReady(silentFetchStreaksInfo);
  onClientAuthLogin(silentFetchStreaksInfo);
  eventBusKeysForFetch.forEach((eventKey) => {
    eventBus.$on(eventKey, fetchStreaksInfo);
  });
};

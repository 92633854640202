<script>
import { WButton } from '@WebiumTeam/ui_kit';
import {
  VK_CONNECT_CTA_TYPES,
  getVkConnectCtaType,
} from '~/services/vk-connect';
import BaseModalWrapper from '~/components/_base/_base-modal-wrapper.vue';

export const messages = {
  ru: {
    ok: 'супер',
    titleByCta: {
      [VK_CONNECT_CTA_TYPES.tutor]: 'наставник скоро напишет тебе',
    },
  },
};

const fallbackCtaType = VK_CONNECT_CTA_TYPES.tutor;

export default {
  i18n: {
    messages,
  },
  components: {
    WButton,
    BaseModalWrapper,
  },
  computed: {
    ctaType() {
      return getVkConnectCtaType() || fallbackCtaType;
    },
  },
  methods: {
    close() {
      this.$emit('close');
    },
  },
};
</script>

<template>
  <BaseModalWrapper
    :class="$style.root"
    :mobile-bottom="$device.isSmallScreen"
    @close="close"
  >
    <div :class="$style.inner">
      <img :class="$style.sticker" alt="" :src="require(`./sticker.svg`)" />
      <strong :class="$style.title">
        {{ $t(`titleByCta.${ctaType}`) }}
      </strong>
      <div :class="$style.buttons">
        <WButton :size="$device.isSmallScreen ? 'l' : 'm'" @click="close">
          {{ $t('ok') }}
        </WButton>
      </div>
    </div>
  </BaseModalWrapper>
</template>

<style lang="postcss" module>
.root {
  --modal-max-width: 274px;
}

.inner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px;
  text-align: center;
}

.sticker {
  width: 96px;
  margin-bottom: 28px;
}

.title {
  margin-bottom: 4px;
  font-size: 20px;
  line-height: 24px;
}

.buttons {
  display: flex;
  flex-direction: column;
  gap: 16px;
  min-width: 85%;
  margin-top: 32px;

  & > * {
    width: 100%;
  }
}

@media (--small-vp) {
  .buttons {
    min-width: 100%;

    & > * {
      width: 100%;
    }
  }
}
</style>

<script>
import { WButton } from '@WebiumTeam/ui_kit';
import { addNpsAnswer } from '~/api/surveys';
import { handleError } from '~/services/error-handling/error-handling';
import BaseTextarea from '~/components/_base/_base-textarea.vue';

export const messages = {
  ru: {
    title:
      'какова вероятность, что ты порекомендуешь<br class="desktop-only">&nbsp;Вебиум&nbsp;знакомым?',
    caption: '0 — точно не порекомендую, 10 — точно порекомендую',
    submit: 'Отправить',
    descriptionLabel: 'расскажи, почему ты поставил(а) такую оценку',
  },
};

const descriptionInputRef = 'descriptionInput';
const scoreNumbersCount = 11;

export default {
  descriptionInputRef,
  i18n: {
    messages,
  },
  components: {
    WButton,
    BaseTextarea,
  },
  data() {
    return {
      score: undefined,
      description: '',
      isLoading: false,
      scoreNumbers: Array.from(
        { length: scoreNumbersCount },
        (x, index) => index
      ),
    };
  },
  computed: {
    isDisabled() {
      return this.score === undefined || this.isLoading;
    },
  },
  methods: {
    selectScore(scoreNumber) {
      if (this.score === scoreNumber) return;
      this.score = scoreNumber;
      this.$refs[descriptionInputRef].focus();
    },
    async onSubmit() {
      try {
        this.isLoading = true;
        await addNpsAnswer({
          score: this.score,
          description: this.description,
        });
        this.$emit('success');
      } catch (error) {
        handleError(error);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<template>
  <div :class="$style.root">
    <div :class="$style.titleWrapper">
      <p :class="$style.title" v-html="$t('title')"></p>
      <span :class="$style.caption">{{ $t('caption') }}</span>
    </div>
    <div :class="$style.numberItems">
      <WButton
        v-for="scoreNumber in scoreNumbers"
        :key="scoreNumber"
        data-testid="score-number-button"
        :class="{
          [$style.numberItem]: true,
          [$style._selected]: score === scoreNumber,
        }"
        :size="$device.isSmallScreen ? 'l' : 'xs'"
        theme="secondary"
        @click="selectScore(scoreNumber)"
      >
        {{ scoreNumber }}
      </WButton>
    </div>
    <div :class="$style.formItem" data-testid="details">
      <span :class="$style.formLabel">{{ $t('descriptionLabel') }}</span>
      <BaseTextarea
        :ref="$options.descriptionInputRef"
        v-model="description"
        :max-height="240"
        :max-length="500"
        :rows="2"
      />
    </div>
    <div :class="$style.buttons">
      <WButton
        :size="$device.isSmallScreen ? 'l' : 'm'"
        :disabled="isDisabled"
        data-testid="submit-button"
        @click="onSubmit"
      >
        {{ $t('submit') }}
      </WButton>
    </div>
  </div>
</template>

<style lang="postcss" module>
.root {
  display: flex;
  flex-direction: column;
  padding: 24px 32px;

  & .numberItem {
    --size: 32px;
    --border-color: var(--grey-300);

    flex-shrink: 0;
    align-items: center;
    min-width: var(--size);
    max-width: var(--size);
    min-height: var(--size);
    max-height: var(--size);
    padding: 0;
    border-color: var(--border-color);
    border-radius: 100%;

    &._selected {
      --border-color: var(--primary-500);
    }
  }
}

.titleWrapper {
  margin-bottom: 20px;
}

.title {
  margin-bottom: 8px;
  font-size: 18px;
  line-height: 22px;
}

.caption {
  font-size: 14px;
  line-height: 18px;
  color: var(--grey-600);
}

.numberItems {
  display: flex;
  gap: 8px;
}

.buttons {
  margin-top: 24px;
}

.formItem {
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-top: 16px;
}

.formLabel {
  font-size: 14px;
  line-height: 18px;
  color: var(--grey-600);
}

@media (--small-vp) {
  .root {
    padding: 0;

    & .numberItem {
      --size: 44px;
    }
  }

  .title {
    font-size: 16px;
    line-height: 20px;
  }

  .numberItems {
    flex-wrap: wrap;
    justify-content: center;
    max-width: 350px;
    margin: 0 auto;
    text-align: center;
  }

  .buttons {
    margin-top: 32px;

    & > * {
      width: 100%;
    }
  }
}
</style>

// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".streaks-paired-invite__root-Seal3{display:flex;flex-direction:column;grid-gap:12px;gap:12px;align-items:flex-start}.streaks-paired-invite__description-\\+KmY2{font-size:16px;font-size:var(--invite-font-size,16px);line-height:20px;line-height:var(--invite-line-height,20px)}.streaks-paired-invite__description-\\+KmY2 hr{height:4px;background:none;border:none}.streaks-paired-invite__howItsWork-ICT--{margin:12px 0}.streaks-paired-invite__buttons-h0jXE{display:flex;grid-gap:8px;gap:8px;align-items:center;margin-top:22px}.streaks-paired-invite__socialButtons-Z4hxw{display:flex;grid-gap:10px;gap:10px}.streaks-paired-invite__copyButton-0u7KJ{flex-grow:1}.streaks-paired-invite__share-ZAZ3R{position:relative;bottom:2px;margin-left:18px;color:var(--grey-600)}@media (max-width:1023px){.streaks-paired-invite__buttons-h0jXE{flex-direction:column;grid-gap:14px;gap:14px;width:100%}.streaks-paired-invite__socialButtons-Z4hxw{grid-gap:16px;gap:16px}.streaks-paired-invite__share-ZAZ3R{margin-left:0}.streaks-paired-invite__copyButton-0u7KJ{width:100%;margin-bottom:14px}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "streaks-paired-invite__root-Seal3",
	"description": "streaks-paired-invite__description-+KmY2",
	"howItsWork": "streaks-paired-invite__howItsWork-ICT--",
	"buttons": "streaks-paired-invite__buttons-h0jXE",
	"socialButtons": "streaks-paired-invite__socialButtons-Z4hxw",
	"copyButton": "streaks-paired-invite__copyButton-0u7KJ",
	"share": "streaks-paired-invite__share-ZAZ3R"
};
module.exports = ___CSS_LOADER_EXPORT___;

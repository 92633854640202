// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".streaks-paired-award-popover-content__root-\\+6vBA{position:relative;width:380px;padding:8px 24px 24px}.streaks-paired-award-popover-content__content-JGjOH,.streaks-paired-award-popover-content__contentWrapper-ea-P0,.streaks-paired-award-popover-content__root-\\+6vBA{display:flex;flex-direction:column;align-items:center}.streaks-paired-award-popover-content__content-JGjOH,.streaks-paired-award-popover-content__contentWrapper-ea-P0{grid-gap:12px;gap:12px;width:100%;text-align:center}.streaks-paired-award-popover-content__pictureWrapper-BH70U{position:relative;width:256px;height:224px}.streaks-paired-award-popover-content__picture-3OjnQ{max-width:100%}.streaks-paired-award-popover-content__title-0tf4X{font-size:20px;line-height:24px}.streaks-paired-award-popover-content__description--kWuT{font-size:14px;line-height:18px;white-space:break-spaces}.streaks-paired-award-popover-content__button-MV0Q6{width:100%;margin-top:12px}.streaks-paired-award-popover-content__closeButton-OkFwl{position:absolute;top:8px;right:8px}.streaks-paired-award-popover-content__countWrapper-K8N5b{position:absolute;bottom:0;left:50%;font-size:80px;line-height:1;transform:translateX(-50%)}.streaks-paired-award-popover-content__count-2qsUT{line-height:1;color:var(--support-purple)}.streaks-paired-award-popover-content__countStroke-I8Aa4{position:absolute;top:0;left:0;z-index:-1;-webkit-text-stroke:12px var(--primary-100)}@media (max-width:1023px){.streaks-paired-award-popover-content__root-\\+6vBA{max-width:100%}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "streaks-paired-award-popover-content__root-+6vBA",
	"content": "streaks-paired-award-popover-content__content-JGjOH",
	"contentWrapper": "streaks-paired-award-popover-content__contentWrapper-ea-P0",
	"pictureWrapper": "streaks-paired-award-popover-content__pictureWrapper-BH70U",
	"picture": "streaks-paired-award-popover-content__picture-3OjnQ",
	"title": "streaks-paired-award-popover-content__title-0tf4X",
	"description": "streaks-paired-award-popover-content__description--kWuT",
	"button": "streaks-paired-award-popover-content__button-MV0Q6",
	"closeButton": "streaks-paired-award-popover-content__closeButton-OkFwl",
	"countWrapper": "streaks-paired-award-popover-content__countWrapper-K8N5b",
	"count": "streaks-paired-award-popover-content__count-2qsUT",
	"countStroke": "streaks-paired-award-popover-content__countStroke-I8Aa4"
};
module.exports = ___CSS_LOADER_EXPORT___;

<template>
  <li
    :class="classes"
    :style="dayItemStyle"
    @click.self="$emit('open')"
    @keyup.enter="$emit('open')"
  >
    <button
      v-if="!hideNumber"
      :class="$style.dayLabel"
      @click.self="$emit('open')"
    >
      {{ dayLabel }}
    </button>
    <slot></slot>
  </li>
</template>

<script>
import { formatDate } from '~/services/datetime';

export default {
  inject: {
    dayItemStyle: {
      default: () => {},
    },
  },
  props: {
    day: {
      type: Object,
      required: true,
    },
    isToday: {
      type: Boolean,
      default: false,
    },
    isInteractive: {
      type: Boolean,
      default: false,
    },
    hideNumber: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    classes() {
      return {
        [this.$style.root]: true,
        [this.$style._today]: this.isToday,
        [this.$style._interactive]: this.isInteractive,
      };
    },
    dayLabel() {
      return formatDate({ value: this.day.date, format: 'D' });
    },
  },
};
</script>

<style lang="postcss" module>
.root {
  --day-label-size: 24px;

  position: relative;
  min-height: 100px;
  padding: 4px;
  font-size: 16px;
  background-color: var(--content-bg-main-color);
}

.dayLabel {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: var(--day-label-size);
  padding: 3px;
  margin-bottom: 4px;
}

._today {
  & .dayLabel {
    justify-content: center;
    width: var(--day-label-size);
    color: var(--text-main-inverse-color);
    background-color: var(--content-bg-main-inverse-color);
    border-radius: 50%;
  }
}

._interactive {
  cursor: pointer;

  &:hover {
    background-color: var(--grey-100);
  }

  &:active {
    background-color: var(--grey-200);
  }
}

@media (--x-small-vp) {
  .root {
    min-height: 78px;
    padding: 3px 4px;
  }
}
</style>

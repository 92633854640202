// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".motivation-luring-dot-indicator__root-t\\+Kkr{display:inline-block;margin-left:8px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "motivation-luring-dot-indicator__root-t+Kkr"
};
module.exports = ___CSS_LOADER_EXPORT___;

<script>
import { WIconButton } from '@WebiumTeam/ui_kit';
import { logout } from '~/services/auth-functions';
import BasePopover from '~/components/_base/popover/_base-popover.vue';
import BasePopoverMenu from '~/components/_base/popover/_base-popover-menu.vue';

export const messages = {
  ru: {
    myProfile: 'мой профиль',
    logout: 'выйти',
  },
};

export default {
  i18n: { messages },
  components: {
    BasePopover,
    WIconButton,
    BasePopoverMenu,
  },
  computed: {
    menuItems() {
      return [
        { value: 'profile', text: this.$t('myProfile') },
        { value: 'logout', text: this.$t('logout') },
      ];
    },
  },
  methods: {
    async logout() {
      await logout(this.$config.serviceUrls.MAIN_AUTH_URL);
    },
    handleMenuItemSelect(action) {
      if (action === 'profile') {
        window.location.href = this.$config.serviceUrls.SHOP_PROFILE_URL;
      }
      if (action === 'logout') {
        this.logout();
      }
    },
  },
};
</script>

<template>
  <BasePopover
    :class="$style.root"
    :popper-class="$style.popper"
    placement="bottom-end"
  >
    <WIconButton
      icon-name="burger-menu"
      theme="emptyLight"
      :size="$device.isSmallScreen ? 'l' : 'm'"
    />
    <template #dropdown>
      <BasePopoverMenu :items="menuItems" @select="handleMenuItemSelect" />
    </template>
  </BasePopover>
</template>

<style lang="postcss" module>
.root {
  color: var(--text-main-color);
}

.popper {
  margin-top: 5px;
}

@media (--small-vp) {
  .root {
    width: 100%;
  }
}
</style>

// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".xp-sign__root-njA0v{display:inline-flex;grid-gap:4px;gap:4px;align-items:flex-start;justify-content:center;cursor:default}.xp-sign__root-njA0v.xp-sign___green-lGLD6{color:var(--success-600)}.xp-sign__root-njA0v.xp-sign___gray-Fghrx{color:var(--grey-600)}.xp-sign__root-njA0v.xp-sign___black-aQW6K{color:rgba(var(--primary-500-rgb)/60%)}.xp-sign__root-njA0v.xp-sign___white-AsdnF{color:var(--primary-100)}.xp-sign__root-njA0v.xp-sign___clickable-\\+vsUE{position:relative}.xp-sign__root-njA0v.xp-sign___clickable-\\+vsUE:before{position:absolute;bottom:-1px;left:0;display:block;width:100%;height:1px;content:\"\";background-color:var(--grey-400);transition:background-color var(--default-transition)}.xp-sign__root-njA0v.xp-sign___clickable-\\+vsUE:hover:before{background-color:var(--grey-500)}.xp-sign__root-njA0v.xp-sign___xs-ll2Kb{font-size:12px;line-height:18px;letter-spacing:.24px}.xp-sign__root-njA0v.xp-sign___xs-ll2Kb .xp-sign__icon-dWVDN{margin-top:4px;font-size:10px}.xp-sign__root-njA0v.xp-sign___s-tr8Zd{font-size:14px;line-height:18px;letter-spacing:.28px}.xp-sign__root-njA0v.xp-sign___s-tr8Zd .xp-sign__icon-dWVDN{margin-top:3px;font-size:12px}.xp-sign__root-njA0v.xp-sign___l-13miB{font-size:16px;line-height:20px;letter-spacing:.32px}.xp-sign__root-njA0v.xp-sign___l-13miB .xp-sign__icon-dWVDN{margin-top:3px;font-size:14px}.xp-sign__root-njA0v.xp-sign___xl-ioWPn{font-size:20px;line-height:24px;letter-spacing:.4px}.xp-sign__root-njA0v.xp-sign___xl-ioWPn .xp-sign__icon-dWVDN{margin-top:6px;font-size:14px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "xp-sign__root-njA0v",
	"_green": "xp-sign___green-lGLD6",
	"_gray": "xp-sign___gray-Fghrx",
	"_black": "xp-sign___black-aQW6K",
	"_white": "xp-sign___white-AsdnF",
	"_clickable": "xp-sign___clickable-+vsUE",
	"_xs": "xp-sign___xs-ll2Kb",
	"icon": "xp-sign__icon-dWVDN",
	"_s": "xp-sign___s-tr8Zd",
	"_l": "xp-sign___l-13miB",
	"_xl": "xp-sign___xl-ioWPn"
};
module.exports = ___CSS_LOADER_EXPORT___;

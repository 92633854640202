// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._base-toast-message__root-\\+Q8-m{position:relative;width:400px;background:var(--primary-100);border:2px solid var(--primary-500);border-radius:8px;box-shadow:var(--dropdown-shadow)}@media (max-width:1023px){._base-toast-message__root-\\+Q8-m{width:auto;max-width:100%}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "_base-toast-message__root-+Q8-m"
};
module.exports = ___CSS_LOADER_EXPORT___;

export function durationSecondsToHumanTime(seconds) {
  let secondsNumber = Number.parseInt(seconds, 10);
  if (!Number.isInteger(secondsNumber)) return secondsNumber;

  const hours = Math.floor(secondsNumber / 3600);
  let minutes = Math.floor((secondsNumber - hours * 3600) / 60);
  secondsNumber = secondsNumber - hours * 3600 - minutes * 60;
  secondsNumber = secondsNumber < 10 ? `0${secondsNumber}` : secondsNumber;
  const isHours = Boolean(hours);
  const isMinutes = Boolean(minutes);

  if (!isHours && !isMinutes) return `00:${secondsNumber}`;

  minutes = minutes < 10 ? `0${minutes}` : minutes;

  if (isHours) return `${hours}:${minutes}:${secondsNumber}`;

  return `${minutes}:${secondsNumber}`;
}

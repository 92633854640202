// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".error-description__root-ppf6k{--_default-code-font-size:120px;--_default-action-gutter:22px;justify-content:center;width:100%;height:100%;text-align:center}.error-description__content-\\+krBN,.error-description__root-ppf6k{display:flex;align-items:center}.error-description__content-\\+krBN{flex-direction:column;max-width:400px}.error-description__header-pP1wb{margin:0 0 12px;font-size:26px;text-transform:lowercase}.error-description__message-7gWbT{margin:0 0 20px;font-size:20px}.error-description__code-umJ\\+g{font-size:var(--_default-code-font-size);font-size:var(--code-font-size,var(--_default-code-font-size))}.error-description__action-\\+Zx8V{margin-top:var(--action-gutter,var(--_default-action-gutter))}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "error-description__root-ppf6k",
	"content": "error-description__content-+krBN",
	"header": "error-description__header-pP1wb",
	"message": "error-description__message-7gWbT",
	"code": "error-description__code-umJ+g",
	"action": "error-description__action-+Zx8V"
};
module.exports = ___CSS_LOADER_EXPORT___;

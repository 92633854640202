/* global carrotquest */
const carrotquestEvents = Object.freeze({
  startViewVideo: 'start_view_video',
  videoHalfViewed: 'video_is_half_viewed',
  endViewVideo: 'end_view_video',
});

function carrotquestTrackEvent(eventName, params) {
  if (!carrotquest || typeof carrotquest.track !== 'function') return;

  carrotquest.track(eventName, params);
}

function carrotquestEmulateButtonClick(buttonId) {
  let button = document.querySelector(`#${buttonId}`);
  if (!button) {
    button = document.createElement('button');
    button.setAttribute('id', buttonId);
    document.body.append(button);
  }
  button.click();
}

export {
  carrotquestTrackEvent,
  carrotquestEmulateButtonClick,
  carrotquestEvents,
};

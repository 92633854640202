export default ({ store }, inject) => {
  inject('systemNotifications', {
    hideSystemNotification(slug) {
      return store.dispatch(
        'system-notifications/hideSystemNotification',
        slug
      );
    },
    markSystemNotificationsAsViewed(slugs) {
      return store.dispatch(
        'system-notifications/markSystemNotificationsAsViewed',
        slugs
      );
    },
    setSystemNotificationSlugsToDebouncedFetch(slugs) {
      return store.dispatch(
        'system-notifications/setSystemNotificationSlugsToDebouncedFetch',
        slugs
      );
    },
    get getSystemNotification() {
      return store.getters['system-notifications/getSystemNotification'];
    },
  });
};

<script>
export default {
  props: {
    alternative: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    classes() {
      return {
        [this.$style.root]: true,
        [this.$style._alternative]: this.alternative,
      };
    },
  },
};
</script>

<template>
  <div :class="classes"></div>
</template>

<style lang="postcss" module>
.root {
  width: 16px;
  height: 16px;
  background-color: var(--content-bg-main-color);
  border: 6px solid var(--danger-500);
  border-radius: 100%;
}

._alternative {
  background-color: var(--danger-500);
  border: 2px solid var(--content-bg-main-color);
}
</style>

export const COURSE_ONBOARDING_TYPES = {
  disabled: 'disabled',
  minimal: 'minimal',
  full: 'full',
};

export const ONBOARDING_STATES = {
  completed: 'completed',
  closed: 'closed',
};

export const ONBOARDING_STEPS = {
  vkConnect: 'vkConnect',
  notifications: 'notifications',
  goals: 'goals',
  survey: 'survey',
  courseInfo: 'courseInfo',
};

export const ONBOARDING_URL_PARAM = 'onboardingStep';

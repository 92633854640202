<script>
import { createNamespacedHelpers } from 'vuex';
import { COURSE_TYPE_SLUGS } from '~/constants/course-types';
import { MODALS } from '~/constants/modals';
import { getLSItem, setLSItem } from '~/services/local-storage';
import { hasVkSignature, isRedirectedByVkConnect } from '~/services/vk-connect';
import ModalVkConnect from '~/components/modal-vk-connect/modal-vk-connect.vue';
import ModalVkConnectSuccess from '~/components/modal-vk-connect-success/modal-vk-connect-success.vue';

const { mapState } = createNamespacedHelpers('current-course');
const { mapState: mapModalsState, mapActions: mapModalsActions } =
  createNamespacedHelpers('modals');

const maxCloseCount = 7;
const openDelayInMS = 90 * 60 * 1000;
const closeCountLSKey = 'modal_vk_connect_close_count';
const lastOpenedAtLSKey = 'modal_vk_connect_last_opened_at';

export default {
  MODALS,
  components: {
    ModalVkConnect,
    ModalVkConnectSuccess,
  },
  data() {
    return {
      modalVkConnectSuccessVisible: false,
      unsuccessfulConnect: false,
      closeCount: 0,
      /* временно скрыли показ модалки */
      isModalVkConnectHidden: true,
    };
  },
  computed: {
    ...mapState(['currentCourse']),
    ...mapModalsState(['visibleModalName']),
    hasVkSignature() {
      return hasVkSignature(this.$auth.user);
    },
    currentCourseIsValidForOpen() {
      return (
        this.currentCourse &&
        [COURSE_TYPE_SLUGS.refresh, COURSE_TYPE_SLUGS.practice].includes(
          this.currentCourse.courseType?.slug
        )
      );
    },
    maxCloseCountReached() {
      return this.closeCount >= maxCloseCount;
    },
    showInputIdButton() {
      return this.closeCount > 0;
    },
  },
  watch: {
    currentCourse(currentCourse) {
      if (currentCourse) {
        this.handleOpenModalVkConnect();
      }
    },
    $route() {
      this.$nextTick(this.handleModals);
    },
  },
  mounted() {
    this.closeCount = Number.parseInt(getLSItem(closeCountLSKey) || 0, 10);
    this.handleModals();
  },
  methods: {
    ...mapModalsActions([
      'closeModal',
      'setVisibleModalNameIfPriorityIsHigher',
    ]),
    onClose() {
      setLSItem(closeCountLSKey, this.closeCount + 1);
      this.closeModal();
    },
    handleModals() {
      if (this.isModalVkConnectHidden) {
        return;
      }
      this.successfulConnect = isRedirectedByVkConnect() && this.hasVkSignature;

      this.unsuccessfulConnect =
        isRedirectedByVkConnect() && !this.hasVkSignature;
      if (this.successfulConnect) {
        this.openModalVkSuccessConnect();
      } else {
        this.handleOpenModalVkConnect();
      }
    },
    handleOpenModalVkConnect() {
      if (process.server) return;
      if (!this.$auth.loggedIn) return;
      if (this.hasVkSignature) return;
      if (this.unsuccessfulConnect) {
        this.openModalVkConnect();
        return;
      }
      if (this.maxCloseCountReached) return;
      if (!this.currentCourseIsValidForOpen) return;
      const lastOpenedAt = getLSItem(lastOpenedAtLSKey);
      const openDelayNotFinished =
        !!lastOpenedAt &&
        Date.now() - new Date(lastOpenedAt).getTime() < openDelayInMS;
      if (openDelayNotFinished) return;
      this.openModalVkConnect();
    },
    openModalVkConnect() {
      this.setVisibleModalNameIfPriorityIsHigher(MODALS.vkConnect);
      setLSItem(lastOpenedAtLSKey, Date.now());
    },
    openModalVkSuccessConnect() {
      this.setVisibleModalNameIfPriorityIsHigher(MODALS.vkConnectSuccess);
    },
  },
};
</script>

<template>
  <transition name="modal">
    <ModalVkConnectSuccess
      v-if="visibleModalName === $options.MODALS.vkConnectSuccess"
      @close="onClose"
    />
    <ModalVkConnect
      v-if="visibleModalName === $options.MODALS.vkConnect"
      :show-issue-button="unsuccessfulConnect"
      :show-input-url-button="showInputIdButton"
      @close="onClose"
    />
  </transition>
</template>

<script>
import { WSelect } from '@WebiumTeam/ui_kit';
import { addMonths, getMonth, getYear } from '~/services/datetime';

export const messages = {
  ru: {
    months: 'Месяцы',
    month0: 'Январь',
    month1: 'Февраль',
    month2: 'Март',
    month3: 'Апрель',
    month4: 'Май',
    month5: 'Июнь',
    month6: 'Июль',
    month7: 'Август',
    month8: 'Сентябрь',
    month9: 'Октябрь',
    month10: 'Ноябрь',
    month11: 'Декабрь',
  },
};

export default {
  components: {
    WSelect,
  },
  props: {
    selectedDate: {
      type: [Date, Object],
      default: () => {},
    },
    extremeDateTo: {
      type: Date,
      default: undefined,
    },
    extremeDateFrom: {
      type: Date,
      default: undefined,
    },
  },
  computed: {
    selectedMonth() {
      return `${getYear(this.selectedDate)}.${getMonth(this.selectedDate) + 1}`;
    },
    options() {
      const dates = [];
      for (
        let date = this.extremeDateFrom;
        date < this.extremeDateTo ||
        getMonth(date) === getMonth(this.extremeDateTo);
        date = addMonths({ value: date, monthCount: 1 })
      ) {
        dates.push({
          text: this.$t(`month${getMonth(date)}`),
          value: `${getYear(date)}.${getMonth(date) + 1}`,
          group: getYear(date),
        });
      }
      return dates;
    },
    name() {
      return this.$t(`month${getMonth(this.selectedDate)}`);
    },
  },
  i18n: { messages },
};
</script>

<template>
  <WSelect
    :selected-value="selectedMonth"
    :options="options"
    :placeholder="name"
    :close-text="$t('months')"
    :button-size="$device.isSmallScreen ? 'l' : 's'"
    @change="$emit('change', $event)"
  />
</template>

// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".modal-vk-connect-success__root-kfRmT{--modal-max-width:274px}.modal-vk-connect-success__inner-CDjiu{display:flex;flex-direction:column;align-items:center;justify-content:center;padding:40px;text-align:center}.modal-vk-connect-success__sticker-V2w-y{width:96px;margin-bottom:28px}.modal-vk-connect-success__title-VfMJh{margin-bottom:4px;font-size:20px;line-height:24px}.modal-vk-connect-success__buttons-oNLcj{display:flex;flex-direction:column;grid-gap:16px;gap:16px;min-width:85%;margin-top:32px}.modal-vk-connect-success__buttons-oNLcj>*{width:100%}@media (max-width:1023px){.modal-vk-connect-success__buttons-oNLcj{min-width:100%}.modal-vk-connect-success__buttons-oNLcj>*{width:100%}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "modal-vk-connect-success__root-kfRmT",
	"inner": "modal-vk-connect-success__inner-CDjiu",
	"sticker": "modal-vk-connect-success__sticker-V2w-y",
	"title": "modal-vk-connect-success__title-VfMJh",
	"buttons": "modal-vk-connect-success__buttons-oNLcj"
};
module.exports = ___CSS_LOADER_EXPORT___;

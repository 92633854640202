<script>
import { WButton, WIconButton, WLoading } from '@WebiumTeam/ui_kit';
import { createNamespacedHelpers } from 'vuex';
import { markAsViewedNotifications } from '~/api/notifications';
import BasePopover from '~/components/_base/popover/_base-popover.vue';
import BasePopoverMenu from '~/components/_base/popover/_base-popover-menu.vue';
import NotificationListItem from '~/components/notifications/notification-list-item.vue';

export const messages = {
  ru: {
    allNotifications: 'все уведомления',
    notifications: 'уведомления',
    markAllAsViewed: 'отметить все как прочитанные',
    emptyNotifications: 'здесь будут твои уведомления, а пока тут пусто',
    loading: 'Пару секунд...',
    notificationSettings: 'настройки уведомлений',
  },
};
const popoverMenuValues = {
  markAll: 'markAll',
  settings: 'settings',
};

const { mapActions: mapSettingsActions } = createNamespacedHelpers(
  'notification-settings'
);

const { mapState, mapActions, mapGetters } =
  createNamespacedHelpers('notification-list');

export default {
  components: {
    NotificationListItem,
    WButton,
    WIconButton,
    WLoading,
    BasePopover,
    BasePopoverMenu,
  },
  props: {
    isPopover: {
      type: Boolean,
      default: false,
    },
  },
  i18n: { messages },
  data() {
    return {
      viewedNotifications: [],
      viewedNotificationsCount: 0,
      notificationsViewedInterval: undefined,
    };
  },
  computed: {
    ...mapState(['items', 'nextPage', 'nonViewedCount']),
    ...mapGetters(['canLoadMoreItems', 'moreItemsLoading']),
    isLoadingAdditionalItemsDisabled() {
      return !this.canLoadMoreItems;
    },
    classes() {
      return {
        [this.$style._popover]: this.isPopover,
      };
    },
    popoverMenuItems() {
      return [
        { text: this.$t('markAllAsViewed'), value: popoverMenuValues.markAll },
        {
          text: this.$t('notificationSettings'),
          value: popoverMenuValues.settings,
        },
      ];
    },
    isEmptyBlockVisible() {
      return this.moreItemsLoading
        ? false
        : !this.items || this.items.length === 0;
    },
  },
  mounted() {
    this.viewedNotificationsCount = this.viewedNotifications.length;
    this.notificationsViewedInterval = setInterval(() => {
      if (this.viewedNotificationsCount === this.viewedNotifications.length) {
        this.markNotificationsAsViewed();
        this.viewedNotifications = [];
      }
      this.viewedNotificationsCount = this.viewedNotifications.length;
    }, 1000);
  },
  async beforeDestroy() {
    this.markNotificationsAsViewed();
    this.clearNotificationsViewedInterval();
    await this.fetchNotifications();
  },
  methods: {
    ...mapActions(['fetchNotifications', 'fetchMoreItems']),
    ...mapSettingsActions(['openSettingsModal']),
    async markAllAsViewed() {
      if (this.nonViewedCount !== 0) {
        await markAsViewedNotifications();
        await this.fetchNotifications();
      }
    },
    async markNotificationsAsViewed() {
      if (this.viewedNotifications.length > 0) {
        await markAsViewedNotifications({
          notificationIds: this.viewedNotifications,
        });
      }
    },
    clearNotificationsViewedInterval() {
      clearInterval(this.notificationsViewedInterval);
      this.notificationsViewedInterval = undefined;
    },
    addNotificationToViewed(item) {
      this.viewedNotifications.push(item.id);
    },
    fetchMore() {
      if (this.nextPage !== 1) this.fetchMoreItems();
    },
    selectContext(type) {
      if (type === popoverMenuValues.markAll) {
        this.markAllAsViewed();
      }
      if (type === popoverMenuValues.settings) {
        this.openSettings();
      }
    },
    openSettings() {
      this.openSettingsModal();
    },
  },
};
</script>

<template>
  <div :class="classes">
    <div :class="$style.header">
      <span :class="$style.title">{{
        isPopover ? $t('notifications') : $t('allNotifications')
      }}</span>

      <BasePopover placement="bottom">
        <WIconButton
          v-if="$device.isSmallScreen"
          theme="emptyDark"
          size="s"
          icon-name="menu-L"
        />
        <template #dropdown>
          <BasePopoverMenu :items="popoverMenuItems" @select="selectContext" />
        </template>
      </BasePopover>

      <div :class="$style.desktopButtons">
        <WButton
          :disabled="nonViewedCount === 0"
          :theme="!isPopover ? 'emptyDark' : 'emptyLight'"
          :size="!isPopover ? 's' : 'xs'"
          data-testid="mark-all-button"
          @click="markAllAsViewed"
        >
          {{ $t('markAllAsViewed') }}
        </WButton>
        <WIconButton
          data-testid="open-settings"
          :theme="!isPopover ? 'emptyDark' : 'emptyLight'"
          :size="!isPopover ? 's' : 'xs'"
          icon-name="options"
          @click="openSettings"
        />
      </div>
    </div>
    <ul
      v-infinite-scroll="fetchMore"
      infinite-scroll-disabled="isLoadingAdditionalItemsDisabled"
      infinite-scroll-distance="400"
      :class="$style.content"
    >
      <NotificationListItem
        v-for="(item, index) in items"
        :key="index"
        :item="item"
        @viewed="addNotificationToViewed"
      />
    </ul>
    <WLoading :show="moreItemsLoading" :class="$style.loading" />
    <div v-if="isEmptyBlockVisible" :class="$style.empty">
      <span> {{ $t('emptyNotifications') }} </span>
    </div>
  </div>
</template>

<style lang="postcss" module>
.content {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  background-color: var(--primary-100);
  border-radius: 8px;
  box-shadow: var(--card-shadow);
}

.title {
  font-size: 24px;
  line-height: 28px;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
  color: var(--primary-500);
}

.desktopButtons {
  display: flex;
  gap: 8px;
  align-items: center;
}

.empty {
  display: flex;
  justify-content: center;
  max-width: 230px;
  padding: 56px 0;
  margin: 0 auto;
  font-size: 16px;
  line-height: 20px;
  color: var(--primary-500);
  text-align: center;
  letter-spacing: 0.02em;
}

.loading {
  padding: 40px;
}

._popover {
  display: flex;
  flex-direction: column;

  & .header {
    position: relative;
    padding: 10px 10px 12px 18px;
    margin-bottom: 0;

    &::after {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 2px;
      content: '';
      background: var(--grey-200);
    }
  }

  & .title {
    margin-bottom: 2px;
    font-size: 18px;
    line-height: 22px;
  }

  & .content {
    max-height: calc(100% - 54px);
    overflow-x: hidden;
    overflow-y: auto;
    border-radius: 0;
    box-shadow: none;
  }
}

@media (--small-vp) {
  .header {
    justify-content: left;
    padding: 0;
  }

  .title {
    margin-right: 8px;
    font-size: 20px;
    line-height: 24px;
  }

  .desktopButtons {
    display: none;
  }

  ._popover {
    & .header {
      display: none;
    }

    & .content {
      max-height: 100%;
    }
  }
}
</style>

import { buildServerListModule } from '~/store-lib/server-list';
import { getGifts } from '~/api/motivation';

const storeModule = buildServerListModule(getGifts, {
  itemsFieldName: 'gifts',
  fetchMethodName: 'fetchGifts',
  responseItemsFieldName: false,
});

const { state, mutations, actions } = storeModule;

const getters = {
  ...storeModule.getters,
  giftById: (state) => (id) => state.gifts.find((gift) => gift.id === id),
};

export { state, mutations, getters, actions };

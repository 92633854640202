import { STREAK_DAY_STATE } from '~/constants/streaks';
import { getStreaksHistory } from '~/api/streaks';
import {
  formatDate,
  getCurrentDate,
  getDaysBetween,
  isDateBefore,
  isSameDay,
  subtractDays,
} from '~/services/datetime';
import { handleError } from '~/services/error-handling/error-handling';

const defaultFetchPeriodInDays = 5;
const dateFormat = 'YYYY-MM-DD';

export const state = () => ({
  history: [],
  isFetched: false,
  isLoading: false,
  fetchedRanges: [],
});

export const getters = {
  historyByPeriod:
    (state) =>
    ({ dateFrom, dateTo }) => {
      const dateFromObject = new Date(dateFrom).setHours(0, 0, 0, 0);
      const dateToObject = new Date(dateTo).setHours(0, 0, 0, 0);
      return state.history.filter(({ date }) => {
        const dateObject = new Date(date);
        return dateObject >= dateFromObject && dateObject <= dateToObject;
      });
    },
  historyItemByDate: (state) => (date) =>
    state.history.find(
      (item) =>
        formatDate({ value: item.date, format: dateFormat }) ===
        formatDate({ value: date, format: dateFormat })
    ),
  calendarDayItem: (state, getters, rootState) => (date) => {
    const historyItem = getters.historyItemByDate(date);
    const isPastDate = isDateBefore(
      new Date(date).setHours(0, 0, 0, 0),
      new Date().setHours(0, 0, 0, 0)
    );
    const isToday = isSameDay(Date.now(), date);
    let state;
    if (historyItem) {
      if (historyItem.isPaired) state = STREAK_DAY_STATE.paired;
      state =
        state ||
        (historyItem.isFrozen
          ? STREAK_DAY_STATE.freeze
          : STREAK_DAY_STATE.streak);
    } else if (isPastDate) {
      state = STREAK_DAY_STATE.missed;
    } else {
      state =
        rootState.streaks.buddyReceivedStreakToday && isToday
          ? STREAK_DAY_STATE.buddy
          : STREAK_DAY_STATE.empty;
    }
    const dayIndex = new Date(date).getDay();
    return {
      date,
      dayIndex,
      state,
      isPastDate,
    };
  },
  dateRangeHasBeenFetched:
    (state) =>
    ({ dateFrom, dateTo }) => {
      if (!state.isFetched) return false;
      const rangeDays = getDaysBetween(dateFrom, dateTo);
      return rangeDays.every((rangeDay) =>
        state.fetchedRanges.some(
          (fetchedRange) =>
            new Date(rangeDay) >= new Date(fetchedRange.dateFrom) &&
            new Date(rangeDay) <= new Date(fetchedRange.dateTo)
        )
      );
    },
};

export const mutations = {
  PUT_HISTORY_ITEMS(state, history) {
    history.forEach((item) => {
      const existingItemIndex = state.history.findIndex(
        (existingItem) => existingItem.date === item.date
      );
      if (existingItemIndex === -1) {
        state.history.push(item);
      } else {
        state.history.splice(existingItemIndex, 1, item);
      }
    });
    state.history.sort((a, b) => new Date(a.date) - new Date(b.date));
  },
  SET_IS_LOADING(state, value) {
    state.isLoading = !!value;
  },
  SET_IS_FETCHED(state, value) {
    state.isFetched = !!value;
  },
  PUT_FETCHED_RANGE(state, { dateFrom, dateTo }) {
    const isAlreadyFetchedRange = state.fetchedRanges.some(
      (fetchedRange) =>
        new Date(dateFrom).toString() === fetchedRange.dateFrom.toString() &&
        new Date(dateTo).toString() === fetchedRange.dateTo.toString()
    );
    if (isAlreadyFetchedRange) return;
    state.fetchedRanges.push({
      dateFrom: new Date(dateFrom),
      dateTo: new Date(dateTo),
    });
  },
};

export const actions = {
  async fetchStreaksHistory(
    { state, getters, commit },
    { dateFrom, dateTo, options = {} } = {}
  ) {
    try {
      if (state.isLoading) return;
      const { forceReload = false } = options;
      const params = { dateFrom, dateTo };
      if (!dateFrom && !dateTo) {
        params.dateTo = getCurrentDate();
        params.dateFrom = subtractDays({
          value: dateTo,
          dayCount: defaultFetchPeriodInDays - 1,
        });
      }
      if (
        !forceReload &&
        getters.dateRangeHasBeenFetched({
          dateFrom: params.dateFrom,
          dateTo: params.dateTo,
        })
      )
        return;
      commit('SET_IS_LOADING', true);
      const history = await getStreaksHistory(params);
      commit('PUT_HISTORY_ITEMS', history || []);
      commit('PUT_FETCHED_RANGE', { dateFrom, dateTo });
      commit('SET_IS_FETCHED', true);
    } catch (error) {
      handleError(error);
    } finally {
      commit('SET_IS_LOADING', false);
    }
  },
};

export const BASE_SIZES = ['xxl', 'xl', 'l', 'm', 's', 'xs', 'xxs'];

export const BASE_SIDES = ['top', 'right', 'bottom', 'left'];

export const BASE_DIRECTIONS = ['row', 'column'];

export const sizeValidator = (value) =>
  value === '' || BASE_SIZES.includes(value);

export const sideValidator = (value) =>
  value === '' || BASE_SIDES.includes(value);

export const directionValidator = (value) =>
  value === '' || BASE_DIRECTIONS.includes(value);

function calculateScrollBarWidth() {
  return window.innerWidth - document.body.clientWidth;
}

function setBodyPaddingEqualScrollbarWidth() {
  const scrollbarWidth = calculateScrollBarWidth();
  if (!scrollbarWidth) return;
  document.body.style.setProperty(
    '--body-padding-right',
    `${scrollbarWidth}px`
  );
}

function clearDisableScrollProperties() {
  document.body.style.setProperty('--body-overflow', 'initial');
  document.body.style.setProperty('--body-padding-right', 'initial');
}

export function disableBodyScroll() {
  setBodyPaddingEqualScrollbarWidth();
  document.body.style.setProperty('--body-overflow', 'hidden');
}

export function enableBodyScroll({ delay = 0 } = {}) {
  if (!delay) return clearDisableScrollProperties();

  return new Promise((resolve) => {
    setTimeout(() => {
      clearDisableScrollProperties();
      resolve();
    }, delay);
  });
}

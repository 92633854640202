<script>
import { WButton } from '@WebiumTeam/ui_kit';
import { createNamespacedHelpers } from 'vuex';
import { DOM_ELEMENT_SELECTORS } from '~/constants/dom-elements';
import { TOOLTIPS } from '~/constants/tooltips';
import { handleError } from '~/services/error-handling/error-handling';
import BasePopover from '~/components/_base/popover/_base-popover.vue';
import AttentionTooltip from '~/components/attention-tooltip.vue';
import NotificationList from '~/components/notifications/notification-list.vue';
import NotificationModalManager from '~/components/notifications/notification-modal-manager.vue';

export const messages = {
  ru: {
    notifications: 'Уведомления',
    showAll: 'Показать все уведомления',
    tooltipText:
      'ты всегда можешь настроить или отключить уведомления в настройках уведомлений',
    tooltipCloseButtonText: 'понятно',
  },
};

const { mapState, mapActions } = createNamespacedHelpers('notification-list');
const { mapActions: notificationModalActions } =
  createNamespacedHelpers('notification-modal');

export default {
  TOOLTIPS,
  i18n: { messages },
  components: {
    AttentionTooltip,
    NotificationList,
    WButton,
    BasePopover,
    NotificationModalManager,
  },
  data() {
    return {
      tooltipBoundary: undefined,
      isModalVisible: false,
    };
  },
  async fetch() {
    try {
      await this.fetchNotifications();
    } catch (error) {
      handleError(error);
    }
  },
  computed: {
    ...mapState(['items', 'nonViewedCount']),
    isCountVisible() {
      return this.nonViewedCount > 0;
    },
  },
  mounted() {
    this.tooltipBoundary = document.querySelector(
      `#${DOM_ELEMENT_SELECTORS.innerLayoutMainId}`
    );
  },
  methods: {
    ...mapActions(['fetchNotifications']),
    ...notificationModalActions(['openNotificationModal']),
  },
};
</script>

<template>
  <div :class="$style.root">
    <AttentionTooltip
      :tooltip-key="$options.TOOLTIPS.notificationsSettings"
      :text="$t('tooltipText')"
      :close-button-text="$t('tooltipCloseButtonText')"
      :popper-class="$style.attentionTooltipPopper"
      :boundary="tooltipBoundary"
      disabled-auto-scroll
      placement="bottom"
      max-width="280px"
      strategy="fixed"
    >
      <BasePopover
        :class="$style.popover"
        :popper-class="$style.popper"
        placement="bottom"
        show-arrow
      >
        <WButton :class="$style.button" size="m" theme="emptyLight" only-icon>
          <WIcon :class="$style.buttonIcon" name="bell_L" />
          <span v-if="isCountVisible" :class="$style.badge">
            {{ nonViewedCount }}
          </span>
        </WButton>
        <template #dropdown>
          <div :class="$style.popoverContent">
            <NotificationList :class="$style.popoverList" is-popover />
            <div :class="$style.bottom">
              <WButton
                tag="NuxtLink"
                :to="{ name: 'notifications' }"
                :aria-label="$t('notifications')"
                :class="$style.buttonAll"
                theme="emptyLight"
              >
                {{ $t('showAll') }}
              </WButton>
            </div>
          </div>
        </template>
      </BasePopover>
    </AttentionTooltip>
    <div :class="$style.linkWrapper">
      <WButton
        :aria-label="$t('notifications')"
        :class="$style.link"
        theme="emptyLight"
        size="l"
        only-icon
        @click="openNotificationModal"
      >
        <WIcon :class="$style.buttonIcon" name="bell_L" />
        <span v-if="isCountVisible" :class="$style.badge">
          {{ nonViewedCount }}
        </span>
      </WButton>
      <NotificationModalManager />
    </div>
  </div>
</template>

<style lang="postcss" module>
.root {
  & .button,
  & .link {
    position: relative;
  }
}

.buttonIcon {
  flex-shrink: 0;
  align-self: center;
  font-size: 24px;
}

.badge {
  position: absolute;
  top: 0;
  left: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 16px;
  height: 16px;
  padding: 4px;
  font-size: 12px;
  line-height: 13px;
  color: var(--primary-100);
  background-color: var(--danger-500);
  border-radius: 8px;
}

.popoverContent {
  display: flex;
  flex-direction: column;
  width: 484px;
  max-height: 640px;
  overflow: hidden;
  border-radius: 8px;
}

.popoverList {
  height: calc(100% - 40px);
  min-height: calc(100% - 40px);
}

.bottom {
  position: relative;
  display: flex;
  width: 100%;

  &::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 2px;
    content: '';
    background: var(--grey-200);
  }
}

.buttonAll {
  flex-grow: 1;
}

.linkWrapper {
  display: none;
}

.popper {
  margin-top: 10px;
}

.attentionTooltipPopper {
  top: 8px;
}

@media (--small-vp) {
  .popover {
    display: none;
  }

  .linkWrapper {
    display: inline-block;
  }

  .attentionTooltipPopper {
    top: 52px;
  }
}
</style>

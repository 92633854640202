// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".courses-prolong-button__root-Wg-M6{position:relative}.courses-prolong-button__icon-1AR4e{flex-shrink:0;font-size:24px}.courses-prolong-button__luringDot-sI6K7{margin-left:-6px;margin-top:4px}@media (max-width:1023px){.courses-prolong-button__luringDot-sI6K7{position:static;top:auto;right:auto;align-self:center;transform:none}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "courses-prolong-button__root-Wg-M6",
	"icon": "courses-prolong-button__icon-1AR4e",
	"luringDot": "courses-prolong-button__luringDot-sI6K7"
};
module.exports = ___CSS_LOADER_EXPORT___;

export const MODALS = {
  courseOnboarding: 'courseOnboarding',
  commonOnboarding: 'commonOnboarding',
  welcomePopup: 'welcomePopup',
  vkConnect: 'vkConnect',
  vkConnectSuccess: 'vkConnectSuccess',
  confirmation: 'confirmation',
  courseGoalChoice: 'courseGoalChoice',
  courseGoal: 'courseGoal',
  courseMyGoal: 'courseMyGoal',
  referralModal: 'referralModal',
  streaksPairedInviteModal: 'streaksPairedInviteModal',
};

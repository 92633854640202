import { addDays } from './add-days';

export function getDaysBetween(dateFrom, dateTo) {
  const days = [];
  let currentDate = dateFrom;
  while (new Date(currentDate) <= new Date(dateTo)) {
    const day = new Date(currentDate);
    days.push(day);
    currentDate = addDays({ value: day, dayCount: 1 });
  }
  return days;
}

import { isRequired } from '~/utils/is-required';
import { http } from '~/services/http';

export async function getWebinar({ courseId, webinarId }) {
  const requestUrl = `/api/v2/student/courses/${courseId}/webinars/${webinarId}/`;

  const response = await http.get(requestUrl);
  return response;
}

export async function completeWebinar({ courseId, webinarId }) {
  const requestUrl = `/api/v2/student/courses/${courseId}/webinars/${webinarId}/complete/`;

  const response = await http.post(requestUrl);
  return response;
}

export async function uncompleteWebinar({ courseId, webinarId }) {
  const requestUrl = `/api/v2/student/courses/${courseId}/webinars/${webinarId}/uncomplete/`;

  const response = await http.post(requestUrl);
  return response;
}

export async function completeAllWebinars({ courseId = isRequired() }) {
  const requestUrl = `/api/v2/student/courses/${courseId}/webinars-complete-all/`;

  const response = await http.post(requestUrl);
  return response;
}

export async function getWebinars({
  courseId = isRequired(),
  completed,
  page = 1,
  pageSize = 20,
}) {
  const params = {
    page,
    page_size: pageSize,
    ...(completed !== undefined ? { completed } : {}),
  };
  const url = `/api/v2/student/courses/${courseId}/webinars/`;

  const { results, count, next } = await http.get(url, { params });
  return {
    results,
    totalCount: count,
    hasNextPage: !!next,
  };
}

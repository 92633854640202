// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("./arrow-default.svg");
var ___CSS_LOADER_URL_IMPORT_1___ = require("./arrow-default-up.svg");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".motivation-new-xp-indicator__root-pfW8F{--background-color:var(--primary-100);--text-color:var(--primary-500);position:absolute;display:inline-flex;flex-shrink:0;align-items:center;justify-content:center;width:-moz-fit-content;width:fit-content;min-width:62px;height:32px;padding:0 10px;font-size:16px;line-height:1;color:var(--text-color);white-space:nowrap;background-color:var(--background-color);border-radius:8px;box-shadow:var(--card-shadow)}.motivation-new-xp-indicator__root-pfW8F.motivation-new-xp-indicator___right-6mKUi{--arrow-url:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");top:50%;left:100%;transform:translateX(13px) translateY(-50%)}.motivation-new-xp-indicator__root-pfW8F.motivation-new-xp-indicator___right-6mKUi:before{--width:5px;position:absolute;left:calc(var(--width)*-1);width:var(--width);height:32px;content:\"\";background-image:var(--arrow-url);background-repeat:no-repeat;background-position:50%}.motivation-new-xp-indicator__root-pfW8F.motivation-new-xp-indicator___bottom-ctLDo{--arrow-url:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");--_indicator-top-offset:10px;top:calc(100% + var(--_indicator-top-offset));right:6px;left:auto}.motivation-new-xp-indicator__root-pfW8F.motivation-new-xp-indicator___bottom-ctLDo:before{--height:5px;position:absolute;top:calc(var(--height)*-1);width:100%;height:var(--height);content:\"\";background-image:var(--arrow-url);background-repeat:no-repeat}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "motivation-new-xp-indicator__root-pfW8F",
	"_right": "motivation-new-xp-indicator___right-6mKUi",
	"_bottom": "motivation-new-xp-indicator___bottom-ctLDo"
};
module.exports = ___CSS_LOADER_EXPORT___;

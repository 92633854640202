/* eslint-disable unicorn/no-abusive-eslint-disable */
/* eslint-disable */
import { onClientAuthInit, onClientAuthLogin } from '~/services/auth-functions';

let i18n;
const carrotquestKey = '35641-c86bc1605179b4bbad347d4ab4';
const messages = {
  ru: {
    welcomeMessage:
      'Привет! Это команда технической поддержки, если у тебя возникли какие-то тех. сложности с сайтом — напиши в чат, мы поможем😉',
  },
};

function authenticateUser($auth) {
  if ($auth.loggedIn && $auth.user.supportChatId) {
    carrotquest.auth($auth.user.supportChatId, $auth.user.supportChatHash);
  }
}

function setTranslations() {
  Object.keys(messages).forEach((locale) => {
    i18n.mergeLocaleMessage(locale, messages[locale]);
  });
}

function executeVendorScript() {
  const config = {
    settings: {
      messenger_hide_collapsed: true,
      messenger_welcome_message: i18n.tc('welcomeMessage'),
    },
  };

  !(function () {
    function t(t, e) {
      return function () {
        window.carrotquestasync.push(t, arguments);
      };
    }
    if (typeof carrotquest === 'undefined') {
      const e = document.createElement('script');
      (e.type = 'text/javascript'),
        (e.async = !0),
        (e.src = '//cdn.carrotquest.app/api.min.js'),
        document.querySelectorAll('head')[0].appendChild(e),
        (window.carrotquest = {}),
        (window.carrotquestasync = []),
        (carrotquest.settings = {});
      for (
        let n = [
            'connect',
            'track',
            'identify',
            'auth',
            'onReady',
            'addCallback',
            'removeCallback',
            'trackMessageInteraction',
          ],
          a = 0;
        a < n.length;
        a++
      )
        carrotquest[n[a]] = t(n[a]);
    }
  })(),
    carrotquest.connect(carrotquestKey, config);
}

export default ({ app, route }) => {
  if (route.name === 'results') return;
  i18n = app.i18n;

  setTranslations();
  executeVendorScript();
  onClientAuthInit(authenticateUser);
  onClientAuthLogin(authenticateUser);
};

<script>
export default {
  props: {
    size: {
      type: String,
      default: 's',
      validate: (value) => ['xs', 's', 'l', 'xl'].includes(value),
    },
    color: {
      type: String,
      default: 'green',
      validate: (value) => ['green', 'gray', 'black', 'white'].includes(value),
    },
    clickable: {
      type: Boolean,
      default: false,
    },
    text: {
      type: String,
      default: '',
    },
  },
  computed: {
    classes() {
      return {
        [this.$style.root]: true,
        [this.$style._clickable]: this.clickable,
        [this.$style[`_${this.size}`]]: true,
        [this.$style[`_${this.color}`]]: true,
      };
    },
  },
};
</script>

<template>
  <button :class="classes" :disabled="!clickable" type="button">
    <slot>
      <span>{{ text }}</span>
    </slot>
    <WIcon :class="$style.icon" name="experience" />
  </button>
</template>

<style lang="postcss" module>
.root {
  display: inline-flex;
  gap: 4px;
  align-items: flex-start;
  justify-content: center;
  cursor: default;

  &._green {
    color: var(--success-600);
  }

  &._gray {
    color: var(--grey-600);
  }

  &._black {
    color: rgba(var(--primary-500-rgb) / 60%);
  }

  &._white {
    color: var(--primary-100);
  }

  &._clickable {
    position: relative;

    &::before {
      position: absolute;
      bottom: -1px;
      left: 0;
      display: block;
      width: 100%;
      height: 1px;
      content: '';
      background-color: var(--grey-400);
      transition: background-color var(--default-transition);
    }

    &:hover {
      &::before {
        background-color: var(--grey-500);
      }
    }
  }

  &._xs {
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.24px;

    & .icon {
      margin-top: 4px;
      font-size: 10px;
    }
  }

  &._s {
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.28px;

    & .icon {
      margin-top: 3px;
      font-size: 12px;
    }
  }

  &._l {
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.32px;

    & .icon {
      margin-top: 3px;
      font-size: 14px;
    }
  }

  &._xl {
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.4px;

    & .icon {
      margin-top: 6px;
      font-size: 14px;
    }
  }
}
</style>

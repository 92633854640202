export const STREAK_DAY_STATE = {
  empty: 'empty',
  missed: 'missed',
  freeze: 'freeze',
  streak: 'streak',
  paired: 'paired',
  buddy: 'buddy',
};

export const MINI_CALENDAR_DAYS_COUNT = 5;

export const DAILY_WORKOUT_TASKS_GOAL = 3;

export const MAX_FREEZE_COUNT = 7;

export const XP_REQUIRED_FOR_STREAK = 30;

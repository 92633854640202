import Middleware from './middleware'
import { Auth, authMiddleware, ExpiredAuthSessionError } from '~auth/runtime'

// Active schemes
import { LocalScheme } from '~auth/runtime'

Middleware.auth = authMiddleware

export default function (ctx, inject) {
  // Options
  const options = {
  "resetOnError": false,
  "ignoreExceptions": false,
  "scopeKey": "scope",
  "rewriteRedirects": true,
  "fullPathRedirect": false,
  "watchLoggedIn": true,
  "redirect": false,
  "vuex": {
    "namespace": "auth"
  },
  "cookie": {
    "prefix": "auth.",
    "options": {
      "path": "/",
      "domain": ".webium.ru",
      "maxAge": 1209600,
      "sameSite": "lax"
    }
  },
  "localStorage": false,
  "defaultStrategy": "local"
}

  // Create a new Auth instance
  const $auth = new Auth(ctx, options)

  // Register strategies
  // local
  $auth.registerStrategy('local', new LocalScheme($auth, {
  "user": {
    "property": false
  },
  "endpoints": {
    "login": {
      "url": "/api/v2/auth/token/",
      "method": "post",
      "property": "token"
    },
    "logout": false,
    "user": {
      "url": "/api/v2/users/me/"
    }
  },
  "name": "local"
}))

  // phone
  $auth.registerStrategy('phone', new LocalScheme($auth, {
  "user": {
    "property": false
  },
  "endpoints": {
    "login": false,
    "logout": false,
    "user": {
      "url": "/api/v2/users/me/"
    }
  },
  "name": "phone"
}))

  // verifyPhone
  $auth.registerStrategy('verifyPhone', new LocalScheme($auth, {
  "user": {
    "property": false
  },
  "endpoints": {
    "login": false,
    "logout": false,
    "user": {
      "url": "/api/v2/users/me/"
    }
  },
  "name": "verifyPhone"
}))

  // vkConnect
  $auth.registerStrategy('vkConnect', new LocalScheme($auth, {
  "user": {
    "property": false
  },
  "endpoints": {
    "login": {
      "url": "/api/v2/auth/vk-connect/",
      "method": "post",
      "property": "token"
    },
    "logout": false,
    "user": {
      "url": "/api/v2/users/me/"
    }
  },
  "name": "vkConnect"
}))

  // Inject it to nuxt context as $auth
  inject('auth', $auth)
  ctx.$auth = $auth

  // Initialize auth
  return $auth.init().catch(error => {
    if (process.client) {
      // Don't console log expired auth session errors. This error is common, and expected to happen.
      // The error happens whenever the user does an ssr request (reload/initial navigation) with an expired refresh
      // token. We don't want to log this as an error.
      if (error instanceof ExpiredAuthSessionError) {
        return
      }

      console.error('[ERROR] [AUTH]', error)
    }
  })
}

// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".common-onboarding-reminder__root-\\+TMuI{--base-button-padding:8px 12px 10px;display:flex;flex-direction:column;grid-gap:0;gap:0}.common-onboarding-reminder__title-rerL-{font-size:16px;line-height:20px;white-space:pre-line}.common-onboarding-reminder__progress-eUvjT{margin-top:8px}.common-onboarding-reminder__footer-k0rxi{display:flex;align-items:center;justify-content:space-between;width:100%;max-width:100%;margin-top:12px}.common-onboarding-reminder__caption-pV2oC{font-size:14px;line-height:18px;white-space:pre-line}.common-onboarding-reminder__popper-DKOd-{width:242px;margin-top:4px}.common-onboarding-reminder__popoverContent-ZrKZK{padding:12px 18px;font-size:14px;line-height:18px}.common-onboarding-reminder__xpSignInPopover-ge6Oe{margin:-3px 2px 0 0}.common-onboarding-reminder__popover-42M3q{align-self:flex-start}@media (max-width:1023px){.common-onboarding-reminder__root-\\+TMuI{min-height:90px}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "common-onboarding-reminder__root-+TMuI",
	"title": "common-onboarding-reminder__title-rerL-",
	"progress": "common-onboarding-reminder__progress-eUvjT",
	"footer": "common-onboarding-reminder__footer-k0rxi",
	"caption": "common-onboarding-reminder__caption-pV2oC",
	"popper": "common-onboarding-reminder__popper-DKOd-",
	"popoverContent": "common-onboarding-reminder__popoverContent-ZrKZK",
	"xpSignInPopover": "common-onboarding-reminder__xpSignInPopover-ge6Oe",
	"popover": "common-onboarding-reminder__popover-42M3q"
};
module.exports = ___CSS_LOADER_EXPORT___;

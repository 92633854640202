export const config = {
  position: 'bottom-right',
  duration: 5000,
  keepOnHover: true,
  className: 'wbm-toast',
  containerClass: 'wbm-toast-container',
  action: {
    text: '',
    onClick: (_, toastObject) => {
      toastObject.goAway(0);
    },
  },
};

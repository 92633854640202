// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".voice-record__root-VkL-Z{--island-padding:2px;--button-align-items:center;display:flex;align-items:center;border:2px solid var(--primary-500);box-shadow:var(--dropdown-shadow)}.voice-record__recIndicator-d-w17{width:8px;height:8px;margin:0 6px;background-color:var(--danger-500);border-radius:100%;animation-name:voice-record__pulse-Bfzhp;animation-duration:1.6s;animation-timing-function:ease-in;animation-iteration-count:infinite}.voice-record__duration-mble\\+{min-width:38px;margin-right:8px;letter-spacing:.02em}.voice-record__attachmentButton-WJA21{font-size:20px}@keyframes voice-record__pulse-Bfzhp{0%{background-color:var(--danger-500)}80%{background-color:var(--grey-300)}to{background-color:var(--danger-500)}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "voice-record__root-VkL-Z",
	"recIndicator": "voice-record__recIndicator-d-w17",
	"pulse": "voice-record__pulse-Bfzhp",
	"duration": "voice-record__duration-mble+",
	"attachmentButton": "voice-record__attachmentButton-WJA21"
};
module.exports = ___CSS_LOADER_EXPORT___;

import { getCourseLessonContentItems } from '~/api/student-courses';

export const state = () => ({
  courseId: undefined,
  lessonContentItems: [],
  currentContentGroup: undefined,
  selectedContentDate: undefined,
  isCurrentContentPaid: undefined,
});

export const mutations = {
  SET_COURSE_ID(state, courseId) {
    state.courseId = courseId;
  },
  SET_CURRENT_CONTENT_GROUP(state, group) {
    state.currentContentGroup = group;
  },
  RESET_LESSON_CONTENT_ITEMS(state) {
    state.lessonContentItems = [];
  },
  PUT_CONTENT_ITEM(state, contentItem) {
    const existingIndex = state.lessonContentItems.findIndex(
      (item) => item.id === contentItem.id
    );
    if (existingIndex === -1) {
      const insertIndex = state.lessonContentItems.findIndex(
        (item) =>
          new Date(item.lessonContentGroup.contentDate) >
          new Date(contentItem.lessonContentGroup.contentDate)
      );
      if (insertIndex === -1) {
        state.lessonContentItems.push(contentItem);
      } else {
        state.lessonContentItems.splice(insertIndex, 0, contentItem);
      }
    } else {
      state.lessonContentItems.splice(existingIndex, 1, contentItem);
    }
  },
  SELECT_CONTENT_DATE(state, contentDate) {
    state.selectedContentDate = contentDate;
  },
  SET_IS_CURRENT_CONTENT_PAID(state, value) {
    state.isCurrentContentPaid = !!value;
  },
};

export const actions = {
  selectContentDate({ state, commit, dispatch }, contentDate) {
    commit('SELECT_CONTENT_DATE', contentDate);
    dispatch('fetchLessonContentItems', {
      courseId: state.courseId,
      nearestToDate: contentDate,
    });
  },
  async initialize(
    { commit, dispatch },
    { contentGroup, courseId, isCurrentContentPaid }
  ) {
    const { contentDate } = contentGroup || {};
    commit('SET_CURRENT_CONTENT_GROUP', contentGroup);
    commit('SELECT_CONTENT_DATE', contentDate);
    if (isCurrentContentPaid !== undefined) {
      commit('SET_IS_CURRENT_CONTENT_PAID', isCurrentContentPaid);
    }
    await dispatch('fetchLessonContentItems', {
      courseId,
      nearestToDate: contentDate,
    });
  },
  async fetchLessonContentItems(
    { state, commit },
    { courseId, nearestToDate }
  ) {
    if (courseId !== state.courseId) {
      commit('SET_COURSE_ID', courseId);
      commit('RESET_LESSON_CONTENT_ITEMS');
    }
    const hasNextContent = state.lessonContentItems.some(
      (item) => item.lessonContentGroup.contentDate > nearestToDate
    );
    const hasPreviousContent = state.lessonContentItems.some(
      (item) => item.lessonContentGroup.contentDate < nearestToDate
    );
    if (hasNextContent && hasPreviousContent) return;
    const lessonContentItems = await getCourseLessonContentItems({
      courseId,
      nearestToDate,
    });
    lessonContentItems.forEach((contentItem) => {
      commit('PUT_CONTENT_ITEM', contentItem);
    });
  },
};

import { http } from '~/services/http';

export function completeCommonOnboarding() {
  const url = `/api/v2/student/surveys/onboarding/complete/`;
  return http.post(url);
}

export function closeCommonOnboarding() {
  const url = `/api/v2/student/surveys/onboarding/close/`;
  return http.post(url);
}

export function getIsCommonOnboardingCouldBeShown() {
  const url = `/api/v2/student/surveys/onboarding/could-be-shown/`;
  return http.get(url);
}

import { handleError } from '~/services/error-handling/error-handling';
import { buildShopModalUrl } from '~/services/shop';

export const VK_URL_UPDATE_MODAL_NAME = 'vkUrlUpdate';
export const VK_CONNECT_MODAL_NAME = 'vkBind';
export const VK_CONNECT_REDIRECT_PARAM = 'vkConnectRedirect';
export const VK_CONNECT_CTA_TYPE_PARAM = 'vkConnectCtaType';
export const VK_CONNECT_CTA_TYPES = {
  tutor: 'tutor',
};

const enrichOpenerUrlByVkData = (url, { ctaType, searchParams }) => {
  if (process.server) return '';
  const enrichedUrl = new URL(url);
  enrichedUrl.searchParams.set(VK_CONNECT_REDIRECT_PARAM, '1');
  if (ctaType) {
    if (Object.values(VK_CONNECT_CTA_TYPES).includes(ctaType)) {
      enrichedUrl.searchParams.set(VK_CONNECT_CTA_TYPE_PARAM, ctaType);
    } else {
      handleError(new Error(`Unknown VK connect CTA type "${ctaType}"`));
    }
  }
  if (searchParams) {
    Object.entries(searchParams).forEach(([key, value]) => {
      enrichedUrl.searchParams.set(key, value);
    });
  }
  return enrichedUrl;
};

export const buildVkConnectUrl = (url, { ctaType } = {}) => {
  if (process.server) return '';
  const urlObject = new URL(url || window.location.href);
  const openerUrl = enrichOpenerUrlByVkData(urlObject, { ctaType });
  const shopModalUrl = buildShopModalUrl(openerUrl, {
    modal: VK_CONNECT_MODAL_NAME,
  });
  return shopModalUrl.href;
};

export const buildVkUrlUpdateUrl = (url, { ctaType, searchParams } = {}) => {
  if (process.server) return '';
  const urlObject = new URL(url || window.location.href);
  const openerUrl = enrichOpenerUrlByVkData(urlObject, {
    ctaType,
    searchParams,
  });
  const shopModalUrl = buildShopModalUrl(openerUrl, {
    modal: VK_URL_UPDATE_MODAL_NAME,
  });
  return shopModalUrl.href;
};

export const isRedirectedByVkConnect = () => {
  if (process.server) return false;
  const url = new URL(window.location.href);
  return url.searchParams.has(VK_CONNECT_REDIRECT_PARAM);
};

export const getVkConnectCtaType = () => {
  if (process.server) return false;
  const url = new URL(window.location.href);
  return url.searchParams.get(VK_CONNECT_CTA_TYPE_PARAM);
};

export const hasVkSignature = (user) =>
  !!user?.vkId === true || !!user?.fakeVkId === true;

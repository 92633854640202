import { EVENT_BUS_KEYS } from '~/constants/event-bus';
import { eventBus } from '~/services/event-bus';

export const state = () => ({
  serverDate: undefined,
});

export const mutations = {
  SET_SERVER_DATE(state, date) {
    state.serverDate = date;
  },
};

export const actions = {
  setServerDate({ commit }, date) {
    commit('SET_SERVER_DATE', date);
    eventBus.$emit(EVENT_BUS_KEYS.serverDateUpdated);
  },
};

import libLinkifyString from 'linkify-string';
import 'linkify-plugin-mention';

export function linkifyString(string) {
  return libLinkifyString(string, {
    defaultProtocol: 'https',
    target: '_blank',
    formatHref: {
      mention: (href) => `https://vk.com${href}`,
    },
  });
}

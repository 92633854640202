export const state = () => ({
  notificationModalVisible: false,
});

export const mutations = {
  SET_MODAL_VISIBILITY(state, value) {
    state.notificationModalVisible = !!value;
  },
};

export const actions = {
  openNotificationModal({ commit }) {
    commit('SET_MODAL_VISIBILITY', true);
  },
  closeNotificationModal({ commit }) {
    commit('SET_MODAL_VISIBILITY', false);
  },
};

const gtm = {};

function gtmPush(params) {
  gtm.push({
    ...params,
  });
}

function gtmPushEvent(eventName, params) {
  gtmPush({
    event: eventName,
    ...params,
  });
}

function gtmPushEcommerceEvent(eventName, { ecommerce, ...otherParams }) {
  gtmPush({ ecommerce: undefined });
  gtmPushEvent(eventName, {
    ecommerce,
    ...otherParams,
  });
}

function init({ push }) {
  gtm.push = push;
}

export { init, gtmPush, gtmPushEvent, gtmPushEcommerceEvent };

export { gtmEvents } from './gtm-events';

<script>
import { WIconButton } from '@WebiumTeam/ui_kit';

export const messages = {
  ru: {
    text: 'перейти в админку',
  },
};

export default {
  components: {
    WIconButton,
  },
  i18n: {
    messages,
  },
  computed: {
    isVisible() {
      return (
        this.$auth.loggedIn &&
        this.$auth.user.role &&
        this.$auth.user.role !== 'user'
      );
    },
  },
};
</script>

<template>
  <WIconButton
    v-if="isVisible"
    tag="a"
    :href="$config.serviceUrls.ADMIN_URL"
    icon-name="external-link"
    icon-side="right"
    theme="system"
    :size="$device.isSmallScreen ? 's' : 'xs'"
  >
    {{ $t('text') }}
  </WIconButton>
</template>

<script>
import { WIconButton } from '@WebiumTeam/ui_kit';
import { VClosePopper } from 'floating-vue';
import { createNamespacedHelpers } from 'vuex';
import { SYSTEM_NOTIFICATION_SLUGS } from '~/constants/system-notifications';

const { mapState } = createNamespacedHelpers('streaks');
const animationTimeInSeconds = 6;

export const messages = {
  ru: {
    description: 'твой бадди позанимался, \nпарный стрик увеличен!',
  },
};

export default {
  i18n: { messages },
  directives: {
    'close-popper': VClosePopper,
  },
  components: { WIconButton },
  computed: {
    ...mapState(['currentPairedStreak']),
  },
  mounted() {
    this.$systemNotifications.markSystemNotificationsAsViewed([
      SYSTEM_NOTIFICATION_SLUGS.buddyReceivedStreak,
    ]);

    setTimeout(() => {
      this.close();
    }, animationTimeInSeconds * 1000);
  },
  methods: {
    close() {
      this.$systemNotifications.hideSystemNotification(
        SYSTEM_NOTIFICATION_SLUGS.buddyReceivedStreak
      );
    },
  },
};
</script>

<template>
  <div :class="$style.root">
    <WIconButton
      theme="emptyLight"
      size="xs"
      icon-name="close-S"
      :class="$style.closeButton"
      @click="close"
    />
    <div :class="$style.pictureWrapper">
      <img
        :class="$style.picture"
        :src="require(`./animation-small.svg`)"
        alt=""
      />
      <div :class="$style.countWrapper">
        <span :class="$style.count" data-testid="count">
          {{ currentPairedStreak }}
        </span>
        <span :class="$style.countStroke">{{ currentPairedStreak }}</span>
      </div>
    </div>
    <p :class="$style.description">{{ $t('description') }}</p>
  </div>
</template>

<style lang="postcss" module>
.root {
  display: flex;
  gap: 10px;
  align-items: center;
  width: 282px;
  padding: 2px 7px;
}

.closeButton {
  position: absolute;
  top: 2px;
  right: 2px;
}

.description {
  padding-right: 30px;
  white-space: break-spaces;
}

.pictureWrapper {
  position: relative;
  width: 48px;
  height: 64px;
}

.countWrapper {
  position: absolute;
  bottom: 7px;
  left: 50%;
  font-size: 18px;
  line-height: 1;
  transform: translateX(-50%);
}

.count {
  line-height: 1;
  color: var(--support-purple);
}

.countStroke {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  -webkit-text-stroke: 3px var(--primary-100);
}
</style>

<script>
import {
  Popper,
  PopperMethods,
  ThemeClass,
  hideAllPoppers,
} from 'floating-vue';
import BasePopoverContent from './_base-popper-content.vue';

const arrowVisibleHeight = 6;

export default {
  components: {
    Popper: Popper(),
    BasePopoverContent,
  },
  mixins: [PopperMethods, ThemeClass],
  inheritAttrs: false,
  props: {
    autoHide: {
      type: [Boolean, undefined],
      default: undefined,
    },
    theme: {
      type: String,
      default: 'dropdown',
    },
    placement: {
      type: String,
      default: 'bottom-start',
    },
    popperClass: {
      type: [String, Array],
      required: false,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    trigger: {
      type: [String, Array],
      default: () => ['click'],
    },
    hideTriggers: {
      type: [String, Array, Function],
      default: (triggers) => triggers,
    },
    showArrow: {
      type: Boolean,
      default: false,
    },
    inModal: {
      type: Boolean,
      default: false,
    },
    withOffset: {
      type: Boolean,
      default: false,
    },
    hidden: {
      type: Boolean,
      default: false,
    },
    overModal: {
      type: Boolean,
      default: false,
    },
    distance: {
      type: [Number, undefined],
      default: undefined,
    },
  },
  computed: {
    popperClasses() {
      const classes = [];
      if (this.popperClass) classes.push([this.popperClass].flat());
      if (!this.showArrow) classes.push('no-arrow');
      if (this.inModal) classes.push('in-modal');
      if (this.withOffset) classes.push('with-offset');

      return classes;
    },
    visibleDistance() {
      if (this.distance === undefined) return undefined;
      return this.showArrow
        ? this.distance + arrowVisibleHeight
        : this.distance;
    },
  },
  methods: {
    getTargetNodes() {
      return [...this.$refs.reference.children];
    },
    hideDropdown() {
      hideAllPoppers();
    },
  },
};
</script>

<template>
  <Popper
    ref="popper"
    v-slot="{
      popperId,
      isShown,
      shouldMountContent,
      skipTransition,
      hide,
      handleResize,
      onResize,
      classes,
      result,
    }"
    v-bind="$attrs"
    :auto-hide="autoHide"
    :theme="theme"
    :placement="placement"
    :disabled="disabled"
    :triggers="[trigger].flat()"
    :hide-triggers="hideTriggers"
    :target-nodes="getTargetNodes"
    :reference-node="() => $refs.reference"
    :popper-node="() => $refs.popperContent.$el"
    :popper-class="popperClasses"
    :distance="visibleDistance"
    v-on="$listeners"
  >
    <div
      ref="reference"
      class="v-popper"
      :class="[
        themeClass,
        {
          'v-popper--shown': isShown && !hidden,
        },
      ]"
    >
      <slot />
      <BasePopoverContent
        ref="popperContent"
        :class="{ 'v-popper--over-modal': overModal }"
        :popper-id="popperId"
        :theme="theme"
        :shown="isShown && !hidden"
        :mounted="shouldMountContent"
        :skip-transition="skipTransition"
        :auto-hide="autoHide"
        :handle-resize="handleResize"
        :classes="classes"
        :result="result"
        @hide="hide"
        @resize="onResize"
      >
        <slot name="dropdown" :shown="isShown && !hidden" />
      </BasePopoverContent>
    </div>
  </Popper>
</template>

<!-- eslint-disable vue-scoped-css/enforce-style-type-->
<style>
@import 'floating-vue/dist/style.css';
</style>

<!-- eslint-disable vue-scoped-css/enforce-style-type-->
<style lang="postcss">
/* stylelint-disable selector-class-pattern */
.v-popper--theme-dropdown {
  margin: 0;
}

.v-popper--theme-dropdown .v-popper__wrapper .v-popper__inner {
  font-size: 16px;
  line-height: 20px;
  color: var(--primary-500);
  background-color: var(--primary-100);
  border: 2px solid var(--primary-500);
  border-radius: 8px;
  box-shadow: var(--dropdown-shadow);
}

.v-popper--theme-dropdown .v-popper__arrow-outer {
  border-color: var(--primary-500);
}

.v-popper--theme-dropdown .v-popper__arrow-inner {
  border: var(--primary-500);
}

.v-popper__popper.v-popper--theme-dropdown {
  &:focus {
    outline: none;
  }
}

.no-arrow .v-popper__arrow-container {
  display: none;
}

.v-popper__popper.in-modal {
  z-index: calc(var(--w-overlay-z-index) + 1);
}

.v-popper__popper.with-offset {
  margin-left: 12px;
}

.v-popper--over-modal {
  z-index: calc(var(--w-overlay-z-index) + 1);
}
</style>

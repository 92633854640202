// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".streaks-paired-buddy-received-streak-today-popover-content__root-A1AhB{padding:12px 20px;font-size:14px;line-height:18px;white-space:break-spaces}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "streaks-paired-buddy-received-streak-today-popover-content__root-A1AhB"
};
module.exports = ___CSS_LOADER_EXPORT___;

<script>
import { createNamespacedHelpers } from 'vuex';
import BaseModalWrapper from '~/components/_base/_base-modal-wrapper.vue';
import NotificationSettings from '~/components/notifications/notification-settings.vue';

const { mapState, mapActions } = createNamespacedHelpers(
  'notification-settings'
);

export const messages = {
  ru: {
    title: 'настройки уведомлений',
  },
};

export default {
  i18n: {
    messages,
  },
  components: {
    BaseModalWrapper,
    NotificationSettings,
  },
  computed: {
    ...mapState(['modalVisible']),
  },
  methods: {
    ...mapActions(['closeSettingsModal']),
  },
};
</script>

<template>
  <BaseModalWrapper
    v-if="modalVisible && $auth.loggedIn"
    :class="$style.root"
    :title="$t('title')"
    @close="closeSettingsModal"
  >
    <div :class="$style.inner">
      <NotificationSettings
        :class="$style.settings"
        @cancel="closeSettingsModal"
      />
    </div>
  </BaseModalWrapper>
</template>

<style lang="postcss" module>
.inner {
  height: 100%;
}

@media (--small-vp) {
  .root {
    --modal-max-width: 100%;
  }
}
</style>

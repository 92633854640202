export const state = () => ({
  isNpsModalVisible: false,
});

export const mutations = {
  SET_IS_NPS_MODAL_VISIBLE(state, value) {
    state.isNpsModalVisible = !!value;
  },
};

export const actions = {
  setIsNpsModalVisible({ commit }, value) {
    commit('SET_IS_NPS_MODAL_VISIBLE', value);
  },
};

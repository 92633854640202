import debounce from 'lodash/debounce';
import {
  deleteSystemNotifications,
  getSystemNotifications,
} from '~/api/system-notifications';
import { handleError } from '~/services/error-handling/error-handling';

let systemNotificationSlugsForDebounce = [];
const debounceDelay = 750;

export const state = () => ({
  systemNotifications: undefined,
});

export const getters = {
  getSystemNotification: (state) => (slug) => {
    if (!state.systemNotifications || state.systemNotifications.length === 0)
      return undefined;
    const necessaryNotifications = state.systemNotifications
      .filter((notification) => notification.templateSlug === slug)
      .sort((a, b) => b.id - a.id);

    return necessaryNotifications.length > 0
      ? necessaryNotifications[0]
      : undefined;
  },
};

export const mutations = {
  SET_SYSTEM_NOTIFICATIONS(state, items) {
    state.systemNotifications = items;
  },
};
export const actions = {
  async markSystemNotificationsAsViewed(store, slugs) {
    try {
      await deleteSystemNotifications(slugs);
    } catch (error) {
      handleError(error);
    }
  },
  hideSystemNotification({ state, commit }, slug) {
    const systemNotifications = state.systemNotifications?.filter(
      (notification) => notification.templateSlug !== slug
    );
    commit('SET_SYSTEM_NOTIFICATIONS', systemNotifications);
  },
  setSystemNotificationSlugsToDebouncedFetch({ dispatch }, slugs) {
    const newArrayOfSlugs = [...systemNotificationSlugsForDebounce, ...slugs];
    systemNotificationSlugsForDebounce = newArrayOfSlugs;
    dispatch('debouncedFetchSystemNotifications');
  },
  async fetchSystemNotifications({ commit }, slugs) {
    try {
      const result = await getSystemNotifications(slugs);
      commit('SET_SYSTEM_NOTIFICATIONS', result);
    } catch (error) {
      handleError(error);
    }
  },
  debouncedFetchSystemNotifications: debounce(
    ({ dispatch }) => {
      dispatch('fetchSystemNotifications', systemNotificationSlugsForDebounce);
      systemNotificationSlugsForDebounce = [];
    },
    debounceDelay,
    { leading: false, trailing: true }
  ),
};

import { getBanners } from '~/api/banners';
import { handleError } from '~/services/error-handling/error-handling';
import { createAbortController } from '~/services/http';

let abortController = createAbortController();

export const state = () => ({
  banners: [],
  itemsLoading: false,
  lastFetchedCourseId: undefined,
});

export const mutations = {
  SET_BANNERS(state, banners) {
    state.banners = banners;
  },
  SET_ITEMS_LOADING(state, value) {
    state.itemsLoading = !!value;
  },
  SET_LAST_FETCHED_COURSE_ID(state, courseId) {
    state.lastFetchedCourseId = courseId;
  },
};

export const getters = {
  randomCommonBanner: (state) => {
    const commonBanners = state.banners.filter(
      (banner) => (banner.courses || []).length === 0 && !banner.bannerType
    );
    return commonBanners[Math.floor(Math.random() * commonBanners.length)];
  },
  getBanners:
    (state) =>
    ({ courseId, type } = {}) => {
      let result = state.banners;
      if (courseId)
        result = result.filter((banner) =>
          (banner.courses || []).includes(courseId)
        );
      if (type) result = result.filter((banner) => banner.bannerType === type);
      return result;
    },
};

export const actions = {
  async fetchBanners({ state, commit }, { courseId } = {}) {
    if (courseId && courseId === state.lastFetchedCourseId) return;
    if (!courseId && state.lastFetchedCourseId) return;
    if (state.itemsLoading) {
      abortController.abort();
    }
    abortController = createAbortController();
    try {
      commit('SET_ITEMS_LOADING', true);
      const banners = await getBanners(
        {
          courses: courseId,
        },
        { signal: abortController.signal }
      );
      commit('SET_LAST_FETCHED_COURSE_ID', courseId);
      commit('SET_BANNERS', banners);
      commit('SET_ITEMS_LOADING', false);
    } catch (error) {
      if (error.code === 'ERR_CANCELED') return;
      handleError(error);
      commit('SET_ITEMS_LOADING', false);
    }
  },
};

<script>
import { createNamespacedHelpers } from 'vuex';
import NotificationModal from '~/components/notifications/notification-modal.vue';

const { mapState, mapActions } = createNamespacedHelpers('notification-modal');

export default {
  components: {
    NotificationModal,
  },
  computed: {
    ...mapState(['notificationModalVisible']),
  },
  methods: {
    ...mapActions(['closeNotificationModal']),
  },
};
</script>

<template>
  <NotificationModal
    v-if="notificationModalVisible"
    @close="closeNotificationModal"
  />
</template>

import { isRequired } from '~/utils/is-required';
import { formatDate } from '~/services/datetime';
import { http } from '~/services/http';

const getTimetableUrl = '/api/v2/student/timetable/';

export async function getFullTimetable({ coursesIds, contentTypes } = {}) {
  const response = await http.get(getTimetableUrl, {
    params: {
      courses: coursesIds,
      content_type: contentTypes,
    },
  });
  return { results: response };
}

export async function getTimetableForDates(
  {
    dateFrom = isRequired(),
    dateTo = isRequired(),
    contentTypes,
    coursesIds,
    archived = false,
  } = {},
  { signal = undefined } = {}
) {
  const response = await http.get(getTimetableUrl, {
    signal,
    params: {
      content_type: contentTypes,
      courses: coursesIds,
      content_date__gte: formatDate({ value: dateFrom, format: 'YYYY-MM-DD' }),
      content_date__lte: formatDate({ value: dateTo, format: 'YYYY-MM-DD' }),
      allow_archived: archived,
    },
  });
  return { results: response };
}

export function getTimetableExtremeDates({
  contentTypes,
  coursesIds,
  archived = false,
} = {}) {
  return http.get('/api/v2/student/timetable/extreme-dates/', {
    params: {
      courses: coursesIds,
      content_type: contentTypes,
      allow_archived: archived,
    },
  });
}

<script>
import { WButton } from '@WebiumTeam/ui_kit';
import BaseModalWrapper from '~/components/_base/_base-modal-wrapper.vue';

export const messages = {
  ru: {
    title: 'подтверждение',
    description: 'вы уверены?',
    cancel: 'отмена',
    ok: 'да',
  },
};

export default {
  i18n: { messages },
  components: {
    BaseModalWrapper,
    WButton,
  },
  props: {
    title: {
      type: String,
      default() {
        return this.$t('title');
      },
    },
    description: {
      type: String,
      default() {
        return this.$t('description');
      },
    },
    confirmationText: {
      type: String,
      default() {
        return this.$t('ok');
      },
    },
  },
};
</script>

<template>
  <BaseModalWrapper
    :class="$style.root"
    :title="title"
    mobile-bottom
    @close="$emit('close')"
  >
    <div :class="$style.wrapper">
      <div>
        {{ description }}
      </div>
      <div :class="$style.actions">
        <WButton
          theme="secondary"
          :size="$device.isSmallScreen ? 'l' : 'm'"
          data-testid="cancel-button"
          @click="$emit('close')"
        >
          {{ $t('cancel') }}
        </WButton>
        <WButton
          :size="$device.isSmallScreen ? 'l' : 'm'"
          data-testid="ok-button"
          @click="$emit('ok')"
          >{{ confirmationText }}</WButton
        >
      </div>
    </div>
  </BaseModalWrapper>
</template>

<style lang="postcss" module>
.root {
  --modal-max-width: 380px;

  color: var(--text-main-color);
}

.wrapper {
  padding: 16px 24px 24px;
  font-size: 16px;
  line-height: 20px;
}

.actions {
  display: flex;
  gap: 16px;
  justify-content: flex-end;
  padding-top: 40px;
}

@media (--small-vp) {
  .wrapper {
    padding: 20px;
  }

  .actions {
    flex-direction: column-reverse;
    gap: 12px;
    padding-top: 32px;
  }
}
</style>

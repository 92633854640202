export const LOCALE = 'ru';

export const DATE_TIME_FORMATS = {
  [LOCALE]: {
    month: {
      month: 'long',
    },
    shortMonth: {
      month: 'short',
      day: 'numeric',
    },
    short: {
      month: 'long',
      day: 'numeric',
    },
    long: {
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    },
  },
};

export const NUMBER_FORMATS = {
  ru: {
    currency: {
      style: 'currency',
      currency: 'RUB',
      currencyDisplay: 'symbol',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    },
  },
};

export const I18N_OPTIONS = {
  locale: LOCALE,
  fallbackLocale: LOCALE,
  numberFormats: NUMBER_FORMATS,
  dateTimeFormats: DATE_TIME_FORMATS,
  pluralizationRules: {
    ru: (choice, choicesLength) => {
      let lastTwoDigitsOfChoice;

      if (choice < 100) {
        lastTwoDigitsOfChoice = choice;
      }

      if (choice >= 100) {
        lastTwoDigitsOfChoice = choice % 100;
      }

      if (lastTwoDigitsOfChoice === 0) {
        return 0;
      }

      const teen = lastTwoDigitsOfChoice > 10 && lastTwoDigitsOfChoice < 20;
      const endsWithOne = lastTwoDigitsOfChoice % 10 === 1;

      if (choicesLength < 4) {
        return !teen && endsWithOne ? 1 : 2;
      }
      if (!teen && endsWithOne) {
        return 1;
      }
      if (
        !teen &&
        lastTwoDigitsOfChoice % 10 >= 2 &&
        lastTwoDigitsOfChoice % 10 <= 4
      ) {
        return 2;
      }

      return choicesLength < 4 ? 2 : 3;
    },
  },
};

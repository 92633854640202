// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".banner-close-button__root-2Xb6g{position:absolute;top:8px;right:8px}@media (max-width:1023px){.banner-close-button__root-2Xb6g{top:0;right:0}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "banner-close-button__root-2Xb6g"
};
module.exports = ___CSS_LOADER_EXPORT___;

import { gtmEvents, gtmPushEvent } from '~/services/gtm/gtm';
import { getElementClickText } from './helpers/get-element-click-text';

const trackingDataAttribute = 'trackElementClick';

export function clickHandler({ target }) {
  if (typeof target.dataset[trackingDataAttribute] === 'undefined') return;

  gtmPushEvent(gtmEvents.elementClick, {
    clickText: getElementClickText(target),
  });
}

<script>
import { createNamespacedHelpers } from 'vuex';
import { MODALS } from '~/constants/modals';
import { SYSTEM_NOTIFICATION_SLUGS } from '~/constants/system-notifications';
import StreaksPairediInviteModal from '~/components/streaks/streaks-paired/streaks-paired-invite/streaks-paired-invite-modal.vue';

export const messages = {
  ru: {
    invite: 'пригласить бадди',
  },
};
const { mapActions } = createNamespacedHelpers('modals');

export default {
  i18n: { messages },
  components: { StreaksPairediInviteModal },
  methods: {
    ...mapActions(['setVisibleModalNameIfPriorityIsHigher']),
    openInviteModal() {
      this.$systemNotifications.hideSystemNotification(
        SYSTEM_NOTIFICATION_SLUGS.removedFromThePair
      );
      this.setVisibleModalNameIfPriorityIsHigher(
        MODALS.streaksPairedInviteModal
      );
    },
  },
};
</script>

<template>
  <div :class="$style.root">
    <button
      :class="$style.button"
      data-testid="invite-button"
      @click="openInviteModal"
    >
      <div :class="$style.iconWrapper">
        <WIcon :class="$style.icon" name="plus-invite" />
      </div>
      {{ $t('invite') }}
    </button>
    <StreaksPairediInviteModal />
  </div>
</template>

<style lang="postcss" module>
.root {
  --button-size: 56px;

  text-align: center;
}

.iconWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: var(--button-size);
  height: var(--button-size);
  border: 2px solid var(--grey-300);
  border-radius: 100%;
}

.button {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  color: var(--system-500);
  font-size: 14px;

  &:hover {
    & .iconWrapper {
      background-color: var(--grey-100);
    }
  }

  &:active {
    & .iconWrapper {
      background-color: var(--grey-200);
    }
  }
}

.icon {
  font-size: 24px;
}

@media (--small-vp) {
  .root {
    --button-size: 72px;
  }

  .button {
    font-size: 18px;
  }
}
</style>

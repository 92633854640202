// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".streaks-counter-button-desktop-popover-content__root-chQB\\+{display:flex;flex-direction:column}.streaks-counter-button-desktop-popover-content__content-8SsQ0{display:flex;justify-content:center;width:340px;padding-top:22px}.streaks-counter-button-desktop-popover-content__calendarMonth-oNej8{width:100%;margin-top:0;margin-right:16px;margin-left:16px}.streaks-counter-button-desktop-popover-content__calendarMini-MIoLE{margin:8px 0 24px}.streaks-counter-button-desktop-popover-content__footer-MbGg0{display:flex;justify-content:center;padding:16px 0 20px;border-top:2px solid var(--grey-200)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "streaks-counter-button-desktop-popover-content__root-chQB+",
	"content": "streaks-counter-button-desktop-popover-content__content-8SsQ0",
	"calendarMonth": "streaks-counter-button-desktop-popover-content__calendarMonth-oNej8",
	"calendarMini": "streaks-counter-button-desktop-popover-content__calendarMini-MIoLE",
	"footer": "streaks-counter-button-desktop-popover-content__footer-MbGg0"
};
module.exports = ___CSS_LOADER_EXPORT___;

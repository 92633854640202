<script>
import BaseRadiobutton from '~/components/_base/_base-radiobutton.vue';

export default {
  components: {
    BaseRadiobutton,
  },
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    options: {
      type: Array,
      required: true,
    },
    small: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    isItemSelected(option) {
      return (
        option.value === this.$attrs.value || option.label === this.$attrs.value
      );
    },
    getItemValue(option) {
      return Object.prototype.hasOwnProperty.call(option, 'value')
        ? option.value
        : option.label;
    },
    onClick(option, $event) {
      if (option.stopPropagation) {
        $event.stopPropagation();
      }
    },
  },
};
</script>

<template>
  <div :class="$style.root">
    <BaseRadiobutton
      v-for="(option, index) in options"
      :key="`${option.value}-${index}`"
      :class="$style.radioItem"
      :label="option.label"
      :value="getItemValue(option)"
      :radio-name="option.radioName"
      :selected="isItemSelected(option)"
      :small="small"
      @click.native="onClick(option, $event)"
      @keyup.native.enter="onClick(option, $event)"
    />
  </div>
</template>

<style lang="postcss" module>
.root {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.radioItem {
  &:not(:last-child) {
    margin-bottom: 16px;
  }
}
</style>

/* eslint-disable sonarjs/cognitive-complexity, no-nested-ternary */

import camelCase from 'lodash/camelCase';
import { getAchievements, markAchievementsAsViewed } from '~/api/motivation';
import { replacePlaceholder } from '~/utils/strings';
import { handleError } from '~/services/error-handling/error-handling';

const placeholders = ['completed_count'];

const getXpAmount = (achievement) =>
  achievement.displayXpAmount || achievement.xpAmount;

const fillAchievementCardPlaceholders = (text, achievement) => {
  let result = text;
  placeholders.forEach((placeholder) => {
    result = replacePlaceholder({
      text: result,
      placeholder,
      newValue: achievement[camelCase(placeholder)],
    });
  });
  return result;
};

const resolveAchievementsListCardItemData = ({
  currentAchievement,
  nextAchievement,
  isSingleAchievement,
}) => {
  let resolvedData = {};
  if (isSingleAchievement) {
    const xpAmount = getXpAmount(currentAchievement);
    resolvedData = {
      description: currentAchievement.isReceived
        ? fillAchievementCardPlaceholders(
            currentAchievement.finishedTaskText,
            currentAchievement
          )
        : fillAchievementCardPlaceholders(
            currentAchievement.taskText,
            currentAchievement
          ),
      xpLabel: xpAmount
        ? currentAchievement.isReceived
          ? xpAmount.toString()
          : `+${xpAmount}`
        : '',
      xpTooltip: currentAchievement.isReceived
        ? 'столько ты получил(а)<br> за эту ачивку'
        : 'столько ты получишь<br> за эту ачивку',
      progress: undefined,
    };
  } else if (nextAchievement) {
    resolvedData = {
      description: currentAchievement.isReceived
        ? fillAchievementCardPlaceholders(
            nextAchievement.taskText,
            nextAchievement
          )
        : fillAchievementCardPlaceholders(
            currentAchievement.taskText,
            currentAchievement
          ),
      xpLabel: currentAchievement.isReceived
        ? getXpAmount(nextAchievement)
          ? `+${getXpAmount(nextAchievement)}`
          : ''
        : getXpAmount(currentAchievement)
        ? `+${getXpAmount(currentAchievement)}`
        : '',
      xpTooltip: 'столько ты получишь за<br> переход на новый уровень',
      progress: {
        current: currentAchievement.completedCount || 0,
        max: currentAchievement.isReceived
          ? nextAchievement.requiredCount
          : currentAchievement.requiredCount,
      },
    };
  } else {
    resolvedData = {
      description: fillAchievementCardPlaceholders(
        currentAchievement.finishedTaskText,
        currentAchievement
      ),
      xpLabel: getXpAmount(currentAchievement)
        ? getXpAmount(currentAchievement).toString()
        : '',
      xpTooltip: 'столько ты получил(а) за<br> переход на этот уровень',
      progress: {
        current: currentAchievement.requiredCount,
        max: currentAchievement.requiredCount,
      },
    };
  }
  return resolvedData;
};

export const state = () => ({
  achievements: [],
  isLoading: false,
});

export const getters = {
  hasNewAchievements: (state) =>
    state.achievements.some((achievement) => achievement.isNew),
  achievementsGroupedByType: (state) => {
    const groups = {};
    state.achievements.forEach((achievement) => {
      const { achievementType } = achievement;
      if (!groups[achievementType]) {
        groups[achievementType] = [];
      }
      groups[achievementType].push(achievement);
    });
    Object.entries(groups).forEach(([type, achievements]) => {
      groups[type] = [...achievements].sort(
        (a, b) => (a.requiredCount || 0) - (b.requiredCount || 0)
      );
    });
    return groups;
  },
  achievementsGroupedByTypeCount: (state, getters) =>
    Object.keys(getters.achievementsGroupedByType).length,
  achievementsGroupedByTypeReceivedCount: (state, getters) =>
    Object.values(getters.achievementsGroupedByType).filter((achievements) =>
      achievements.some((achievement) => achievement.isReceived)
    ).length,
  achievementsFinishedCount: (state, getters) =>
    Object.values(getters.achievementsGroupedByType).filter((achievements) =>
      achievements.every((achievement) => achievement.isReceived)
    ).length,
  achievementsListCardItems: (state, getters) => {
    const groupedAchievements = getters.achievementsGroupedByType;
    return Object.values(groupedAchievements).map((achievements) => {
      const isSingleAchievement = achievements.length === 1;
      const firstNotReceivedAchievementIndex = achievements.findIndex(
        (achievement) => !achievement.isReceived
      );
      const currentAchievementIndex =
        firstNotReceivedAchievementIndex !== -1
          ? firstNotReceivedAchievementIndex !== 0
            ? firstNotReceivedAchievementIndex - 1
            : 0
          : achievements.length - 1;
      const currentAchievement = achievements[currentAchievementIndex];
      const nextAchievement = achievements[currentAchievementIndex + 1];
      const resolvedData = resolveAchievementsListCardItemData({
        currentAchievement,
        nextAchievement,
        isSingleAchievement,
      });
      return {
        id: currentAchievement.id,
        achievementType: currentAchievement.achievementType,
        stage: currentAchievement.stage,
        isNew: !!currentAchievement.isNew,
        isReceived: !!currentAchievement.isReceived,
        isLastInGroupReceived:
          currentAchievement.isReceived && !nextAchievement,
        image: currentAchievement.isReceived
          ? currentAchievement.image
          : currentAchievement.notReceivedImage,
        title: currentAchievement.name,
        description: resolvedData.description,
        xpLabel: resolvedData.xpLabel,
        xpTooltip: resolvedData.xpTooltip,
        progress: resolvedData.progress,
      };
    });
  },
};

export const mutations = {
  SET_ITEMS(state, achievements) {
    state.achievements = achievements || [];
  },
  SET_IS_LOADING(state, value) {
    state.isLoading = !!value;
  },
  UPDATE_ACHIEVEMENT(state, achievement) {
    const index = state.achievements.findIndex(
      (item) => item.id.toString() === achievement.id.toString()
    );
    if (index !== -1) {
      state.achievements.splice(index, 1, achievement);
    }
  },
};

export const actions = {
  async fetchAchievements({ state, commit }) {
    try {
      if (state.isLoading) return;
      commit('SET_IS_LOADING', true);
      const achievements = await getAchievements();
      commit('SET_ITEMS', achievements);
    } catch (error) {
      handleError(error);
    } finally {
      commit('SET_IS_LOADING', false);
    }
  },
  async markAchievementsAsViewed({ getters }) {
    if (!getters.hasNewAchievements) return;
    try {
      await markAchievementsAsViewed();
    } catch (error) {
      handleError(error);
    }
  },
  hideNewAchievementsIndicators({ state, commit }) {
    state.achievements.forEach((achievement) => {
      if (achievement.isNew) {
        commit('UPDATE_ACHIEVEMENT', {
          ...achievement,
          isNew: false,
        });
      }
    });
    commit('motivation/SET_HAS_NEW_ACHIEVEMENT', false, { root: true });
  },
};

export const GIFT_TYPE = {
  telegram_stickers: 'stickers',
  avatar_decoration: 'decor',
  profile_decoration: 'background',
};

export const ACTION_TYPE = {
  rate_homework: 'rate_homework',
  rate_mentor: 'rate_mentor',
  rate_webinar: 'rate_webinar',
  view_webinar: 'view_webinar',
  finish_homework_with_score: 'finish_homework_with_score',
  finish_real_exam: 'finish_real_exam',
  finish_homework_before_deadline: 'finish_homework_before_deadline',
  finish_daily_workout_tasks: 'finish_daily_workout_tasks',
  finish_onboarding: 'finish_onboarding',
};

export const ACHIEVEMENT_TYPE = {
  invite_referral: 'invite_referral',
  finish_onboarding: 'finish_onboarding',
};

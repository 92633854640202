export const COURSE_TYPE_SLUGS = {
  base: 'osnova',
  base20: 'osnova-2',
  extra: 'extra',
  fresh: 'fresh',
  refresh: 'refresh',
  practice: 'prokachka',
  free: 'besplatnye',
  summerFresh: 'letniy-fresh',
};

import dayjs from 'dayjs';

export function timeAgo(date) {
  const isInvalidDate = Number.isNaN(Date.parse(date));

  const isFutureDate = Date.parse(new Date()) - Date.parse(date) < 0;

  if (isInvalidDate || isFutureDate) return '';

  return dayjs(date).fromNow();
}

// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".streaks-paired-award-buddy-popover-content__root-yVZ5Z{display:flex;grid-gap:10px;gap:10px;align-items:center;width:282px;padding:2px 7px}.streaks-paired-award-buddy-popover-content__closeButton-xUjOI{position:absolute;top:2px;right:2px}.streaks-paired-award-buddy-popover-content__description-yT6ep{padding-right:30px;white-space:break-spaces}.streaks-paired-award-buddy-popover-content__pictureWrapper-T9\\+pw{position:relative;width:48px;height:64px}.streaks-paired-award-buddy-popover-content__countWrapper-rTFh2{position:absolute;bottom:7px;left:50%;font-size:18px;line-height:1;transform:translateX(-50%)}.streaks-paired-award-buddy-popover-content__count-172Q4{line-height:1;color:var(--support-purple)}.streaks-paired-award-buddy-popover-content__countStroke-\\+nyRS{position:absolute;top:0;left:0;z-index:-1;-webkit-text-stroke:3px var(--primary-100)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "streaks-paired-award-buddy-popover-content__root-yVZ5Z",
	"closeButton": "streaks-paired-award-buddy-popover-content__closeButton-xUjOI",
	"description": "streaks-paired-award-buddy-popover-content__description-yT6ep",
	"pictureWrapper": "streaks-paired-award-buddy-popover-content__pictureWrapper-T9+pw",
	"countWrapper": "streaks-paired-award-buddy-popover-content__countWrapper-rTFh2",
	"count": "streaks-paired-award-buddy-popover-content__count-172Q4",
	"countStroke": "streaks-paired-award-buddy-popover-content__countStroke-+nyRS"
};
module.exports = ___CSS_LOADER_EXPORT___;

import { MODALS } from '~/constants/modals';

// Modals defined above are more prioritized
export const MODALS_PRIORITY = [
  [MODALS.courseOnboarding],
  [
    MODALS.courseGoal,
    MODALS.courseGoalChoice,
    MODALS.courseMyGoal,
    MODALS.confirmation,
  ],
  [MODALS.vkConnect, MODALS.vkConnectSuccess],
];

export const state = () => ({
  visibleModalName: undefined,
});

export const mutations = {
  SET_VISIBLE_MODAL_NAME(state, name) {
    state.visibleModalName = name;
  },
};

export const actions = {
  closeModal({ commit }) {
    commit('SET_VISIBLE_MODAL_NAME', undefined);
  },
  setVisibleModalName({ commit }, name) {
    commit('SET_VISIBLE_MODAL_NAME', name);
  },
  setVisibleModalNameIfPriorityIsHigher({ state, commit }, name) {
    if (!state.visibleModalName) {
      commit('SET_VISIBLE_MODAL_NAME', name);
      return;
    }
    const currentModalGroupIndex = MODALS_PRIORITY.findIndex((group) =>
      group.includes(state.visibleModalName)
    );
    const newModalGroupIndex = MODALS_PRIORITY.findIndex((group) =>
      group.includes(name)
    );

    if (newModalGroupIndex > currentModalGroupIndex) return;
    commit('SET_VISIBLE_MODAL_NAME', name);
  },
};

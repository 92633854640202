import dayjs from 'dayjs';
import { daysAmountInMonth } from './days-amount-in-month';
import { formatDate } from './format-date';

export function allDaysOfMonth({ value, format = 'YYYY-MM-DD' }) {
  const date = dayjs(value);
  const dateMonth = Number(formatDate({ value: date, format: 'M' }));
  const dateYear = Number(formatDate({ value: date, format: 'YYYY' }));
  const daysAmount = daysAmountInMonth(value);

  return [...Array.from({ length: daysAmount })].map((day, index) => {
    const dayDateString = `${dateYear}-${dateMonth}-${index + 1}`;
    return {
      date: formatDate({ value: dayDateString, format }),
    };
  });
}

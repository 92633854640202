import { buildServerListWithLoadMoreModule } from '~/store-lib/server-list-with-load-more';
import { getCourses, restoreCourse } from '~/api/student-courses';

const storeModule = buildServerListWithLoadMoreModule((params = {}) =>
  getCourses({ ...params, archived: true })
);

const { state, mutations, getters } = storeModule;

const actions = {
  ...storeModule.actions,
  async restore({ state, commit, dispatch }, id) {
    await restoreCourse(id);
    const items = state.items.filter((item) => item.id !== id);
    commit('SET_ITEMS', items);
    commit('SET_TOTAL_COUNT', state.totalCount - 1);
    dispatch('timetable/resetFetchedData', true, {
      root: true,
    });
  },
};

export { state, mutations, getters, actions };

// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".attention-tooltip__inner-VH\\+i6{display:flex;flex-direction:column;grid-gap:12px;gap:12px;align-items:flex-start}@media (max-width:1023px){.attention-tooltip__root-mrR1S{padding:0 12px}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inner": "attention-tooltip__inner-VH+i6",
	"root": "attention-tooltip__root-mrR1S"
};
module.exports = ___CSS_LOADER_EXPORT___;

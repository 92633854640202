import { diffInDays } from './diff-in-days';
import { endOfMonth } from './end-of-month';
import { formatDate } from './format-date';
import { startOfMonth } from './start-of-month';
import { startOfWeek } from './start-of-week';
import { subtractDays } from './subtract-days';
import { subtractMonths } from './subtract-months';

function getNDaysBefore({ value, dayCount, format }) {
  return [...Array.from({ length: dayCount })]
    .map((day, index) => ({
      date: formatDate({
        value: subtractDays({ value, dayCount: index }),
        format,
      }),
    }))
    .reverse();
}

export function previousMonthDaysFromStartOfWeek({
  value,
  format = 'YYYY-MM-DD',
}) {
  const selectedMonthFirstDay = startOfMonth(value);
  const selectedMonthStartOfWeekOfFirstDay = startOfWeek(selectedMonthFirstDay);
  const previousMonthLastDayDate = endOfMonth(
    subtractMonths({
      value: selectedMonthFirstDay,
      monthCount: 1,
    })
  );
  const visibleNumberOfDaysFromPreviousMonth = Math.abs(
    diffInDays(selectedMonthFirstDay, selectedMonthStartOfWeekOfFirstDay)
  );

  return getNDaysBefore({
    value: previousMonthLastDayDate,
    dayCount: visibleNumberOfDaysFromPreviousMonth,
    format,
  });
}

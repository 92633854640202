function buildLoadScript({ skipErrors = false } = {}) {
  return (source) =>
    new Promise((resolve, reject) => {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.addEventListener('load', resolve);
      if (!skipErrors) {
        script.addEventListener('error', reject);
      }
      script.src = source;
      document.body.append(script);
    });
}

const loadScript = buildLoadScript();
// skipping errors could be useful for advertising stuff that is fine to be blocked
const recklesslyLoadScriptWithSkippingErrors = buildLoadScript({
  skipErrors: true,
});

export { loadScript, recklesslyLoadScriptWithSkippingErrors };

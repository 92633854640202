import { buildServerListModule } from '~/store-lib/server-list';
import { getLevels } from '~/api/motivation';

const storeModule = buildServerListModule(getLevels, {
  itemsFieldName: 'levels',
  fetchMethodName: 'fetchLevels',
  itemsLoadingFieldName: 'areLevelsLoading',
  fetchOnlyOnce: true,
  responseItemsFieldName: false,
});

export const { state, mutations, getters, actions } = storeModule;

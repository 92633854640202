// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".notification-list-item__root-YS8r5{position:relative;display:flex;grid-gap:16px;gap:16px;padding:18px;background-color:var(--primary-100);transition:var(--default-transition)}.notification-list-item__root-YS8r5:not(:last-child):after{position:absolute;bottom:0;left:0;width:100%;height:2px;content:\"\";background:var(--grey-200)}.notification-list-item__round-XcENS{display:flex;align-items:center;justify-content:center;min-width:40px;height:40px;overflow:hidden;background-color:var(--grey-200);border-radius:20px}.notification-list-item__cover-9TtDc,.notification-list-item__round-XcENS{width:40px}.notification-list-item__content-puQ-9{display:flex;flex-direction:column;color:var(--primary-500);letter-spacing:.02em}.notification-list-item__title-zKF7d{margin-bottom:2px;font-size:16px;line-height:20px}.notification-list-item__date-C-Ccr{margin-bottom:8px;font-size:14px;line-height:16px;color:var(--grey-600)}.notification-list-item__description-zjRk9{font-size:14px;line-height:18px;word-break:break-word;white-space:pre-line}.notification-list-item__description-zjRk9 a{color:var(--system-500)}.notification-list-item___notViewed-VPfiR{background-color:var(--grey-100)}.notification-list-item__button-xf1QZ{align-self:flex-start;margin-top:12px}.notification-list-item__attachments-6Rp6f{display:flex;flex-direction:column;grid-row-gap:4px;row-gap:4px;margin-top:12px}.notification-list-item__attachment-N-VMX{display:flex;grid-column-gap:8px;-moz-column-gap:8px;column-gap:8px;align-items:center;font-size:14px;line-height:18px;color:var(--system-500);letter-spacing:.02em}.notification-list-item__attachmentIcon-kP0LU{font-size:24px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "notification-list-item__root-YS8r5",
	"round": "notification-list-item__round-XcENS",
	"cover": "notification-list-item__cover-9TtDc",
	"content": "notification-list-item__content-puQ-9",
	"title": "notification-list-item__title-zKF7d",
	"date": "notification-list-item__date-C-Ccr",
	"description": "notification-list-item__description-zjRk9",
	"_notViewed": "notification-list-item___notViewed-VPfiR",
	"button": "notification-list-item__button-xf1QZ",
	"attachments": "notification-list-item__attachments-6Rp6f",
	"attachment": "notification-list-item__attachment-N-VMX",
	"attachmentIcon": "notification-list-item__attachmentIcon-kP0LU"
};
module.exports = ___CSS_LOADER_EXPORT___;

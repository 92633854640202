// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".streaks-award-popover-content__root-jYRNZ{position:relative;width:380px;padding:8px 24px 24px}.streaks-award-popover-content__content-nUD7K,.streaks-award-popover-content__contentWrapper-0ALyV,.streaks-award-popover-content__root-jYRNZ{display:flex;flex-direction:column;align-items:center}.streaks-award-popover-content__content-nUD7K,.streaks-award-popover-content__contentWrapper-0ALyV{grid-gap:12px;gap:12px;width:100%;text-align:center}.streaks-award-popover-content__pictureWrapper-BqNUZ{position:relative;width:256px;height:224px}.streaks-award-popover-content__picture-\\+pQ35{max-width:100%}.streaks-award-popover-content__title-ybHoi{font-size:20px;line-height:24px}.streaks-award-popover-content__description-JxM-b{font-size:14px;line-height:18px;white-space:break-spaces}.streaks-award-popover-content__button-1zk6I{width:100%;margin-top:12px}.streaks-award-popover-content__closeButton-oCdeb{position:absolute;top:8px;right:8px}.streaks-award-popover-content__countWrapper-uYEOg{position:absolute;bottom:0;left:50%;font-size:80px;line-height:1;transform:translateX(-50%)}@media (max-width:1023px){.streaks-award-popover-content__root-jYRNZ{max-width:100%}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "streaks-award-popover-content__root-jYRNZ",
	"content": "streaks-award-popover-content__content-nUD7K",
	"contentWrapper": "streaks-award-popover-content__contentWrapper-0ALyV",
	"pictureWrapper": "streaks-award-popover-content__pictureWrapper-BqNUZ",
	"picture": "streaks-award-popover-content__picture-+pQ35",
	"title": "streaks-award-popover-content__title-ybHoi",
	"description": "streaks-award-popover-content__description-JxM-b",
	"button": "streaks-award-popover-content__button-1zk6I",
	"closeButton": "streaks-award-popover-content__closeButton-oCdeb",
	"countWrapper": "streaks-award-popover-content__countWrapper-uYEOg"
};
module.exports = ___CSS_LOADER_EXPORT___;

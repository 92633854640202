<script>
import { WDrawer, WIconButton } from '@WebiumTeam/ui_kit';
import SideBarMenu from '~/components/side-bar-menu.vue';
import SupportControls from '~/components/support-controls.vue';
import TheNavbarLinks from './the-navbar-links.vue';

export const messages = {
  ru: {
    myProfile: 'мой профиль',
  },
};

export default {
  components: {
    WDrawer,
    WIconButton,
    SideBarMenu,
    SupportControls,
    TheNavbarLinks,
  },
  data() {
    return {
      isVisible: false,
    };
  },
  methods: {
    show() {
      this.isVisible = true;
      this.$emit('show');
    },
    hide() {
      this.isVisible = false;
      this.$emit('hide');
    },
  },
  i18n: { messages },
};
</script>

<template>
  <WDrawer side="right" hide-header :visible.sync="isVisible">
    <div :class="$style.menu">
      <WIconButton
        :class="$style.btnClose"
        icon-name="close-L"
        theme="emptyDark"
        size="l"
        data-testid="close-button"
        @click="hide"
      />
      <div :class="$style.content">
        <template v-if="$auth.loggedIn">
          <a
            :href="$config.serviceUrls.SHOP_PROFILE_URL"
            :class="$style.avatarLink"
          >
            <div :class="$style.userAvatar">
              <WIcon name="avatar" size="medium" />
            </div>
            <span>{{ $t('myProfile') }}</span>
          </a>
        </template>
        <SideBarMenu />
        <TheNavbarLinks direction="column" :excluded="['home']" />
        <SupportControls fixed tablet />
      </div>
    </div>
  </WDrawer>
</template>

<style lang="postcss" module>
.menu {
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 100%;
  color: var(--primary-500);
}

.btnClose {
  align-self: flex-end;
  margin: 6px;
}

.content {
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
}

.avatarLink {
  position: relative;
  display: flex;
  gap: 0 12px;
  align-items: center;
  width: 100%;
  height: 56px;
  padding: 8px 21px;

  & span {
    font-size: 20px;
    line-height: 24px;
  }

  &:not(:last-child) {
    margin-bottom: 10px;
  }

  &::before {
    position: absolute;
    bottom: -10px;
    left: 0;
    width: 100%;
    height: 2px;
    content: '';
    background-color: var(--grey-200);
  }
}

.userAvatar {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
}
</style>

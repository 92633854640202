<script>
import { WIconButton } from '@WebiumTeam/ui_kit';

export default {
  components: { WIconButton },
  props: {
    modifier: {
      type: String,
      required: true,
      validate(value) {
        return ['vk', 'tg', 'tt'].includes(value);
      },
    },
  },
  computed: {
    classes() {
      return {
        [this.$style.root]: true,
        [this.$style[`_${this.modifier}`]]: true,
      };
    },
  },
};
</script>

<template>
  <WIconButton :class="classes" v-bind="$attrs" v-on="$listeners" />
</template>

<style lang="postcss" module>
.root {
  &._vk {
    background-color: var(--vk-color);

    &:hover {
      background-color: var(--vk-color-hover);
    }

    &:active {
      background-color: var(--vk-color-active);
    }
  }

  &._tg {
    background-color: var(--tg-color);

    &:hover {
      background-color: var(--tg-color-hover);
    }

    &:active {
      background-color: var(--tg-color-active);
    }
  }

  &._tt {
    background-color: var(--tt-color);

    &:hover {
      background-color: var(--tt-color-hover);
    }

    &:active {
      background-color: var(--tt-color-active);
    }
  }
}
</style>

import Vue from 'vue';
import { TOOLTIPS } from '~/constants/tooltips';
import { getLSItem, setLSItem } from '~/services/local-storage';

// if you change its value, users will see all tooltips again
const closedStateKey = (key) => `tooltip_${key}_closed`;

const getInitialTooltipsVisibilityState = () => {
  const tooltips = {};
  Object.keys(TOOLTIPS).forEach((tooltipKey) => {
    tooltips[tooltipKey] = false;
  });
  return tooltips;
};

export const state = () => ({
  tooltips: getInitialTooltipsVisibilityState(),
});

export const getters = {
  isTooltipShown: (state) => (key) => !!state.tooltips[key],
};

export const mutations = {
  SET_VISIBILITY(state, { key, value }) {
    Vue.set(state.tooltips, key, !!value);
  },
};

export const actions = {
  show({ commit }, key) {
    const hasBeenClosed = !!getLSItem(closedStateKey(key));
    if (hasBeenClosed) return;
    commit('SET_VISIBILITY', { key, value: true });
  },
  hide({ commit, dispatch }, { key, options = {} }) {
    const { hideForever = true } = options;
    if (hideForever) dispatch('markAsHidden', key);
    commit('SET_VISIBILITY', { key, value: false });
  },
  markAsHidden(_, key) {
    setLSItem(closedStateKey(key), 1);
  },
};

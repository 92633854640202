import { buildServerListWithLoadMoreModule } from '~/store-lib/server-list-with-load-more';
import { getNotifications } from '~/api/notifications';
import {
  onClientAuthLogin,
  onClientAuthLogout,
} from '~/services/auth-functions';

const baseListModule = buildServerListWithLoadMoreModule(getNotifications);

export const notificationListAuthEventHandlersPlugin = (store) => {
  onClientAuthLogin(() => {
    store.dispatch('notification-list/fetchNotifications');
  });

  onClientAuthLogout(() => {
    store.dispatch('notification-list/resetNotifications');
  });
};

export const { getters } = baseListModule;

export const state = () => ({
  ...baseListModule.state(),
  nonViewedCount: 0,
});

export const mutations = {
  ...baseListModule.mutations,
  SET_NON_VIEWED_COUNT(state, count) {
    state.nonViewedCount = count;
  },
};

export const actions = {
  ...baseListModule.actions,
  async fetchNotifications({ commit, state, rootState }, params) {
    if (
      state.firstPageLoading ||
      state.nextPageLoading ||
      !rootState.auth.loggedIn
    ) {
      return;
    }

    commit('RESET_PAGINATION');
    commit('RESET_TOTAL_COUNT');
    commit('SET_FIRST_PAGE_LOADING', true);
    const { results, hasNextPage, totalCount, nonViewedCount } =
      await getNotifications(params);
    commit('SET_ITEMS', results);
    commit('SET_TOTAL_COUNT', totalCount);
    commit('SET_NON_VIEWED_COUNT', nonViewedCount);
    commit('INCREMENT_NEXT_PAGE');
    commit('SET_HAS_NEXT_PAGE', hasNextPage);
    commit('SET_FIRST_PAGE_LOADING', false);
  },
  resetNotifications({ commit }) {
    commit('SET_ITEMS', []);
    commit('RESET_PAGINATION');
    commit('RESET_TOTAL_COUNT');
    commit('SET_FIRST_PAGE_LOADING', false);
    commit('SET_NON_VIEWED_COUNT', 0);
  },
};

// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".the-navbar-menu__menu-jJ3SQ{position:relative;display:flex;flex-direction:column;min-height:100%;color:var(--primary-500)}.the-navbar-menu__btnClose-jEwWM{align-self:flex-end;margin:6px}.the-navbar-menu__content-JZw2m{display:flex;flex-direction:column;overflow-x:hidden}.the-navbar-menu__avatarLink-VKokI{position:relative;display:flex;grid-gap:0 12px;gap:0 12px;align-items:center;width:100%;height:56px;padding:8px 21px}.the-navbar-menu__avatarLink-VKokI span{font-size:20px;line-height:24px}.the-navbar-menu__avatarLink-VKokI:not(:last-child){margin-bottom:10px}.the-navbar-menu__avatarLink-VKokI:before{position:absolute;bottom:-10px;left:0;width:100%;height:2px;content:\"\";background-color:var(--grey-200)}.the-navbar-menu__userAvatar-Lp2VY{display:flex;align-items:center;justify-content:center;width:40px;height:40px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"menu": "the-navbar-menu__menu-jJ3SQ",
	"btnClose": "the-navbar-menu__btnClose-jEwWM",
	"content": "the-navbar-menu__content-JZw2m",
	"avatarLink": "the-navbar-menu__avatarLink-VKokI",
	"userAvatar": "the-navbar-menu__userAvatar-Lp2VY"
};
module.exports = ___CSS_LOADER_EXPORT___;

export function convertToRoman(number) {
  const roman = {
    M: 1000,
    CM: 900,
    D: 500,
    CD: 400,
    C: 100,
    XC: 90,
    L: 50,
    XL: 40,
    X: 10,
    IX: 9,
    V: 5,
    IV: 4,
    I: 1,
  };
  let result = '';
  let leftNumber = number;
  // eslint-disable-next-line no-restricted-syntax
  for (const index of Object.keys(roman)) {
    const romanDigitRepetitions = Math.floor(leftNumber / roman[index]);
    leftNumber -= romanDigitRepetitions * roman[index];
    result += index.repeat(romanDigitRepetitions);
  }
  return result;
}

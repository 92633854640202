import isEqual from 'lodash/isEqual';
import {
  getTimetableExtremeDates,
  getTimetableForDates,
} from '~/api/timetable';
import {
  getCurrentDate,
  getDaysBetween,
  subtractDays,
} from '~/services/datetime';
import { handleError } from '~/services/error-handling/error-handling';
import { createAbortController } from '~/services/http';
import { sortTimetableItems } from '~/services/timetable';

let abortController = createAbortController();

export const state = () => ({
  items: [],
  itemsLoading: false,
  hasBeenFetched: false,
  fetchingDateFrom: undefined,
  fetchingDateTo: undefined,
  extremeDateFrom: undefined,
  extremeDateTo: undefined,
  filters: {},
  fetchedRanges: [],
  extremeDatesLoading: false,
});

export const getters = {
  totalCount: (state) => (state.items.length > 0 ? state.items.length : 0),
  isEmpty: (state, getters) =>
    getters.totalCount === 0 && state.itemsLoading === false,
  itemsByDate: (state) => {
    const itemsByDate = {};
    state.items.forEach((item) => {
      const itemDate = item.lessonContentGroup.contentDate;
      itemsByDate[itemDate] = itemsByDate[itemDate] || [];
      itemsByDate[itemDate].push(item);
    });
    return itemsByDate;
  },
  sortedDayItems: (state, getters) => (date) => {
    const items = getters.itemsByDate[date] || [];
    return sortTimetableItems(items);
  },
  hasPastEvents: (state) => (date) => {
    const firstDate = state.extremeDateFrom;
    if (!firstDate) return false;
    const valueDate = new Date((date || '').toString()).setUTCHours(0, 0, 0, 0);
    const compareDate = new Date(firstDate).setUTCHours(0, 0, 0, 0);
    return valueDate >= compareDate;
  },
  hasFutureEvents: (state) => (date) => {
    const lastDate = state.extremeDateTo;
    if (!lastDate) return false;
    const valueDate = new Date((date || '').toString()).setUTCHours(0, 0, 0, 0);
    const compareDate = new Date(lastDate);
    return valueDate <= compareDate;
  },
  timetableEndReached: (state, getters) => (date) => {
    if (getters.hasFutureEvents(date)) return false;
    const yesterday = subtractDays({
      value: getCurrentDate(),
      dayCount: 1,
    });
    const valueDate = new Date((date || '').toString()).setUTCHours(0, 0, 0, 0);
    return valueDate >= new Date(yesterday);
  },
  dateRangeHasBeenFetched:
    (state) =>
    ({ dateFrom, dateTo }) => {
      if (!state.hasBeenFetched) return false;
      const rangeDays = getDaysBetween(dateFrom, dateTo);
      return rangeDays.every((rangeDay) =>
        state.fetchedRanges.some(
          (fetchedRange) =>
            new Date(rangeDay) >= new Date(fetchedRange.dateFrom) &&
            new Date(rangeDay) <= new Date(fetchedRange.dateTo)
        )
      );
    },
};

export const mutations = {
  PUT_ITEMS(state, items) {
    const ids = new Set(state.items.map((item) => item.id));
    state.items.push(...items.filter((item) => !ids.has(item.id)));
  },
  PUT_FETCHED_RANGE(state, { dateFrom, dateTo }) {
    state.fetchedRanges.push({
      dateFrom: new Date(dateFrom),
      dateTo: new Date(dateTo),
    });
  },
  SET_ITEMS_LOADING(state, value) {
    state.itemsLoading = !!value;
  },
  SET_HAS_BEEN_FETCHED(state) {
    state.hasBeenFetched = true;
  },
  SET_EXTREME_DATE_FROM(state, date) {
    state.extremeDateFrom = date ? new Date(date) : new Date();
  },
  SET_EXTREME_DATE_TO(state, date) {
    state.extremeDateTo = date ? new Date(date) : new Date();
  },
  SET_FETCHING_DATE_FROM(state, date) {
    state.fetchingDateFrom = date;
  },
  SET_FETCHING_DATE_TO(state, date) {
    state.fetchingDateTo = date;
  },
  SET_FILTERS(state, filters) {
    state.filters = filters;
  },
  RESET_FETCHED_DATA(state) {
    state.extremeDateTo = undefined;
    state.extremeDateFrom = undefined;
    state.fetchingDateTo = undefined;
    state.fetchingDateFrom = undefined;
    state.hasBeenFetched = false;
    state.items = [];
    state.fetchedRanges = [];
  },
  SET_EXTREME_DATES_LOADING(state, value) {
    state.extremeDatesLoading = !!value;
  },
};

export const actions = {
  resetFetchedData({ commit }) {
    commit('RESET_FETCHED_DATA');
  },
  async fetchExtremeDates({ state, commit }) {
    if (state.extremeDatesLoading) return;
    commit('SET_EXTREME_DATES_LOADING', true);
    const { firstLessonContentDate, lastLessonContentDate } =
      await getTimetableExtremeDates({
        ...state.filters,
      });
    commit('SET_EXTREME_DATE_FROM', firstLessonContentDate);
    commit('SET_EXTREME_DATE_TO', lastLessonContentDate);
    commit('SET_EXTREME_DATES_LOADING', false);
  },
  async fetchItems(
    { state, getters, commit, dispatch },
    { dateFrom, dateTo, filters = {} }
  ) {
    if (state.itemsLoading) {
      abortController.abort();
    }
    abortController = createAbortController();
    if (!isEqual(filters, state.filters)) {
      commit('RESET_FETCHED_DATA');
    }
    if (getters.dateRangeHasBeenFetched({ dateFrom, dateTo })) return;
    try {
      const dateRangeIsAlreadyFetching =
        (dateFrom || dateTo) &&
        state.fetchingDateFrom === dateFrom &&
        state.fetchingDateTo === dateTo;
      const extremeDatesHasBeenFetched = !!(
        state.extremeDateFrom && state.extremeDateTo
      );

      commit('SET_ITEMS_LOADING', true);
      commit('SET_FETCHING_DATE_FROM', dateFrom);
      commit('SET_FETCHING_DATE_TO', dateTo);
      commit('SET_FILTERS', filters);

      const [{ results }] = await Promise.all([
        getTimetableForDates(
          { dateFrom, dateTo, ...filters },
          { signal: abortController.signal }
        ),
        ...(extremeDatesHasBeenFetched || dateRangeIsAlreadyFetching
          ? []
          : [dispatch('fetchExtremeDates')]),
      ]);

      commit('PUT_ITEMS', results);
      commit('SET_ITEMS_LOADING', false);
      commit('PUT_FETCHED_RANGE', { dateFrom, dateTo });
      commit('SET_HAS_BEEN_FETCHED', true);
      commit('SET_FETCHING_DATE_FROM', undefined);
      commit('SET_FETCHING_DATE_TO', undefined);
    } catch (error) {
      if (error.code === 'ERR_CANCELED') return;
      commit('SET_ITEMS_LOADING', false);
      handleError(error);
    }
  },
};

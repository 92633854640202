export default ({ store }, inject) => {
  inject('tooltips', {
    show(key) {
      store.dispatch('tooltips/show', key);
    },
    hide(key, options = {}) {
      store.dispatch('tooltips/hide', { key, options });
    },
  });
};

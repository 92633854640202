<template>
  <div :class="$style.root">
    <component :is="codeComponent" v-if="codeComponent" />
    <ErrorDescription
      v-else
      :code="error.statusCode"
      :message="error.message"
    />
  </div>
</template>

<script>
import Error404 from '~/components/error/error-404.vue';
import ErrorDescription from '~/components/error/error-description.vue';

const errorComponentByCode = {
  404: Error404,
};

export const messages = {
  ru: {
    title: 'Ошибка {code}',
  },
};

export default {
  i18n: { messages },
  components: { ErrorDescription },
  props: {
    error: { type: Object, required: true },
  },
  head() {
    return {
      title: this.$t('title', { code: this.error.statusCode }),
    };
  },
  computed: {
    codeComponent() {
      const { statusCode } = this.error;
      return errorComponentByCode[statusCode];
    },
  },
};
</script>

<style lang="postcss" module>
.root {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
</style>

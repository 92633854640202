// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".month-calendar-day-item__root-GjomB{--day-label-size:24px;position:relative;min-height:100px;padding:4px;font-size:16px;background-color:var(--content-bg-main-color)}.month-calendar-day-item__dayLabel-oOUML{display:flex;align-items:center;justify-content:flex-start;height:var(--day-label-size);padding:3px;margin-bottom:4px}.month-calendar-day-item___today-ogFUt .month-calendar-day-item__dayLabel-oOUML{justify-content:center;width:var(--day-label-size);color:var(--text-main-inverse-color);background-color:var(--content-bg-main-inverse-color);border-radius:50%}.month-calendar-day-item___interactive-0g-Km{cursor:pointer}.month-calendar-day-item___interactive-0g-Km:hover{background-color:var(--grey-100)}.month-calendar-day-item___interactive-0g-Km:active{background-color:var(--grey-200)}@media (max-width:767px){.month-calendar-day-item__root-GjomB{min-height:78px;padding:3px 4px}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "month-calendar-day-item__root-GjomB",
	"dayLabel": "month-calendar-day-item__dayLabel-oOUML",
	"_today": "month-calendar-day-item___today-ogFUt",
	"_interactive": "month-calendar-day-item___interactive-0g-Km"
};
module.exports = ___CSS_LOADER_EXPORT___;

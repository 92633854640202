// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".inner__root-eSqCl{--sidebar-width:192px;--inner-gap:54px;display:flex;flex-direction:column}.inner__wrapper-\\+6VGU{display:flex;grid-gap:var(--inner-gap);gap:var(--inner-gap);width:var(--max-content-width);max-width:100%;padding:0 var(--page-padding);margin:var(--navbar-margin) auto 0 auto}.inner__sidebar-u2\\+70{--gap:32px;display:flex;flex-direction:column;flex-grow:0;flex-shrink:0;width:var(--sidebar-width);padding-bottom:var(--gap)}.inner__banner-SXizA{margin-top:24px}.inner__main-l0Mwr{display:flex;flex-direction:column;flex-grow:1;max-width:calc(100% - var(--sidebar-width) - var(--inner-gap));min-height:var(--main-content-min-height);padding-bottom:80px}.inner__desktopOnboardingReminder-fR6pE{margin-top:16px}.inner__mobileOnboardingReminder--VU8A{margin-bottom:12px}.inner__toastMessagesContainer-slfcg{position:fixed;right:24px;bottom:24px;z-index:var(--base-toast-message-z-index);display:flex;flex-direction:column;grid-gap:12px;gap:12px;align-items:flex-end;justify-content:flex-end}@media (max-width:1359px){.inner__root-eSqCl{--inner-gap:36px}}@media (max-width:1023px){.inner__root-eSqCl{--navbar-margin:12px;--sidebar-width:0;--inner-gap:0}.inner__sidebar-u2\\+70{display:none}.inner__main-l0Mwr{width:100%}.inner__toastMessagesContainer-slfcg{right:12px;bottom:auto;left:12px;top:24px;grid-gap:8px;gap:8px}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "inner__root-eSqCl",
	"wrapper": "inner__wrapper-+6VGU",
	"sidebar": "inner__sidebar-u2+70",
	"banner": "inner__banner-SXizA",
	"main": "inner__main-l0Mwr",
	"desktopOnboardingReminder": "inner__desktopOnboardingReminder-fR6pE",
	"mobileOnboardingReminder": "inner__mobileOnboardingReminder--VU8A",
	"toastMessagesContainer": "inner__toastMessagesContainer-slfcg"
};
module.exports = ___CSS_LOADER_EXPORT___;

<script>
import { createNamespacedHelpers } from 'vuex';
import { DAILY_WORKOUT_TASKS_GOAL } from '~/constants/streaks';
import BaseSegmentedProgress from '~/components/_base/_base-segmented-progress.vue';

const { mapState } = createNamespacedHelpers('streaks');

export const messages = {
  ru: {
    task: 'заданий | задание | задания | заданий',
    text: 'еще {count} {task}{br} до стрика!',
  },
};

const initialProgressUpdateDelay = 250;

export default {
  DAILY_WORKOUT_TASKS_GOAL,
  components: { BaseSegmentedProgress },
  i18n: {
    messages,
  },
  data() {
    return {
      currentProgressValue: 0,
    };
  },
  computed: {
    ...mapState(['dailyTasksCount']),
    text() {
      return this.$t('text', {
        count: this.tasksCountLeft,
        task: this.$tc('task', this.tasksCountLeft),
        br: '<br/>',
      });
    },
    tasksCountLeft() {
      return DAILY_WORKOUT_TASKS_GOAL - this.dailyTasksCount;
    },
  },
  watch: {
    dailyTasksCount: {
      immediate: true,
      handler(value) {
        setTimeout(
          () => {
            this.currentProgressValue = value;
          },
          value > 1 ? 0 : initialProgressUpdateDelay
        );
      },
    },
  },
};
</script>

<template>
  <div :class="$style.root">
    <p :class="$style.text" v-html="text"></p>
    <BaseSegmentedProgress
      :class="$style.progress"
      :segments="[$options.DAILY_WORKOUT_TASKS_GOAL]"
      :value="currentProgressValue"
    />
  </div>
</template>

<style lang="postcss" module>
.root {
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 136px;
  padding: 16px;
  text-align: center;
}

.text {
  font-size: 14px;
  line-height: 18px;
}

.progress {
  --completed-color: var(--streaks-active-color);
}
</style>

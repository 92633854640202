<script>
import { WButton } from '@WebiumTeam/ui_kit';
import { buildVkConnectUrl } from '~/services/vk-connect';

export const messages = {
  ru: {
    text: 'привязать страницу',
  },
};

export default {
  components: {
    WButton,
  },
  props: {
    text: {
      type: String,
      default: '',
    },
    sticker: {
      type: String,
      default: 'default',
    },
    medium: {
      type: Boolean,
      default: false,
    },
    ctaType: {
      type: String,
      required: false,
      default: '',
    },
    target: {
      type: String,
      default: '_blank',
      validate(value) {
        return ['_blank', '_self'].includes(value);
      },
    },
    hideSticker: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: 'm',
    },
  },
  i18n: {
    messages,
  },
  computed: {
    classes() {
      return {
        [this.$style.root]: true,
        [this.$style._medium]: this.medium,
      };
    },
    vkConnectUrl() {
      if (process.server) return '';
      return buildVkConnectUrl(window.location.href, { ctaType: this.ctaType });
    },
  },
};
</script>

<template>
  <div :class="classes">
    <img
      v-if="!hideSticker"
      :class="[$style.sticker, $style[`_${sticker}`]]"
      alt=""
      :src="require(`./sticker-${sticker}.svg`)"
    />
    <WButton
      :class="$style.button"
      v-bind="$attrs"
      tag="a"
      :size="size"
      :href="vkConnectUrl"
      :target="target"
    >
      <span>{{ text || $t('text') }}</span>
    </WButton>
  </div>
</template>

<style lang="postcss" module>
.root {
  position: relative;
  width: fit-content;
}

.sticker {
  position: absolute;
  top: -10px;
  right: -16px;
  z-index: 1;
  width: 32px;

  &._inverse {
    right: -20px;
  }
}

.button {
  width: inherit;
  text-transform: none;
}

._medium {
  & .sticker {
    top: -11px;
    right: -9px;
    width: 42px;
  }
}

@media (--small-vp) {
  .sticker {
    top: -10px;
    right: -8px;
    width: 38px;
  }
}
</style>

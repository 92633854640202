// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".notification-button-popover__root-lhZO2 .notification-button-popover__button-ZBDV6,.notification-button-popover__root-lhZO2 .notification-button-popover__link-X29Of{position:relative}.notification-button-popover__buttonIcon-g4abU{flex-shrink:0;align-self:center;font-size:24px}.notification-button-popover__badge-ZeW6d{position:absolute;top:0;left:16px;align-items:center;justify-content:center;min-width:16px;height:16px;padding:4px;font-size:12px;line-height:13px;color:var(--primary-100);background-color:var(--danger-500)}.notification-button-popover__badge-ZeW6d,.notification-button-popover__popoverContent-\\+Mt\\+w{display:flex;border-radius:8px}.notification-button-popover__popoverContent-\\+Mt\\+w{flex-direction:column;width:484px;max-height:640px;overflow:hidden}.notification-button-popover__popoverList-SRco\\+{height:calc(100% - 40px);min-height:calc(100% - 40px)}.notification-button-popover__bottom-tO9HY{position:relative;display:flex;width:100%}.notification-button-popover__bottom-tO9HY:after{position:absolute;top:0;left:0;width:100%;height:2px;content:\"\";background:var(--grey-200)}.notification-button-popover__buttonAll-v5GoD{flex-grow:1}.notification-button-popover__linkWrapper-mcwpy{display:none}.notification-button-popover__popper-1LXj2{margin-top:10px}.notification-button-popover__attentionTooltipPopper-pGwKH{top:8px}@media (max-width:1023px){.notification-button-popover__popover-IzzVB{display:none}.notification-button-popover__linkWrapper-mcwpy{display:inline-block}.notification-button-popover__attentionTooltipPopper-pGwKH{top:52px}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "notification-button-popover__root-lhZO2",
	"button": "notification-button-popover__button-ZBDV6",
	"link": "notification-button-popover__link-X29Of",
	"buttonIcon": "notification-button-popover__buttonIcon-g4abU",
	"badge": "notification-button-popover__badge-ZeW6d",
	"popoverContent": "notification-button-popover__popoverContent-+Mt+w",
	"popoverList": "notification-button-popover__popoverList-SRco+",
	"bottom": "notification-button-popover__bottom-tO9HY",
	"buttonAll": "notification-button-popover__buttonAll-v5GoD",
	"linkWrapper": "notification-button-popover__linkWrapper-mcwpy",
	"popper": "notification-button-popover__popper-1LXj2",
	"attentionTooltipPopper": "notification-button-popover__attentionTooltipPopper-pGwKH",
	"popover": "notification-button-popover__popover-IzzVB"
};
module.exports = ___CSS_LOADER_EXPORT___;

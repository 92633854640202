import { http } from '~/services/http';

export function getLastInfo() {
  const url = `/api/v2/student/motivation/last-info/`;
  return http.get(url);
}

export function getGifts() {
  const url = `/api/v2/student/motivation/gifts/`;
  return http.get(url);
}

export function getLevels() {
  const url = `/api/v2/student/motivation/levels/`;
  return http.get(url);
}

export function getAchievements() {
  const url = `/api/v2/student/motivation/achievements/`;
  return http.get(url);
}

export function getActions() {
  const url = `/api/v2/student/motivation/actions/`;
  return http.get(url);
}

export function markGiftsAsViewed() {
  const url = `/api/v2/student/motivation/gifts/mark-all-as-viewed/`;
  return http.post(url);
}

export function markAchievementsAsViewed() {
  const url = `/api/v2/student/motivation/achievements/mark-all-as-old/`;
  return http.post(url);
}

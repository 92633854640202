// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".streaks-statistics-header__root-ck7P9{display:flex;font-size:14px;line-height:18px}.streaks-statistics-header__headerRow-NFY\\+a{display:flex;grid-gap:4px;gap:4px;align-items:center}.streaks-statistics-header__maxCount-R9rYv{color:var(--streaks-active-color)}.streaks-statistics-header__fireIcon-D2Q2c{position:relative;top:-2px;right:-1px}@media (max-width:1023px){.streaks-statistics-header__root-ck7P9{font-size:16px}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "streaks-statistics-header__root-ck7P9",
	"headerRow": "streaks-statistics-header__headerRow-NFY+a",
	"maxCount": "streaks-statistics-header__maxCount-R9rYv",
	"fireIcon": "streaks-statistics-header__fireIcon-D2Q2c"
};
module.exports = ___CSS_LOADER_EXPORT___;

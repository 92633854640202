<script>
import { openTgConnectModal } from '~/services/tg-connect';

export const messages = {
  ru: {
    allow: 'получать уведомления',
    deny: 'запретить уведомления',
  },
};

export default {
  i18n: {
    messages,
  },
  props: {
    forceAllowButton: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    needToLinkTg() {
      if (!this.$auth.loggedIn) return true;
      const { tgId, isTgNotificationsAllowed } = this.$auth.user;
      return !tgId || !isTgNotificationsAllowed;
    },
    showAllowButton() {
      return this.forceAllowButton || this.needToLinkTg;
    },
  },
  methods: {
    onAllowClick() {
      const eventName = 'allow';
      if (this.needToLinkTg) {
        openTgConnectModal(this.$auth, () => {
          this.$emit(eventName);
        });
      } else {
        this.$emit(eventName);
      }
    },
    onDenyClick() {
      this.$emit('deny');
    },
  },
};
</script>

<template>
  <div :class="$style.root">
    <button
      v-if="showAllowButton"
      :class="$style.allowButton"
      data-testid="button-allow"
      @click="onAllowClick"
    >
      <WIcon name="telegram-icon" />
      <span>{{ $t('allow') }}</span>
    </button>
    <button
      v-else
      :class="$style.denyButton"
      data-testid="button-deny"
      @click="onDenyClick"
    >
      <WIcon name="check-S" />
      <span>{{ $t('deny') }}</span>
    </button>
  </div>
</template>

<style lang="postcss" module>
.allowButton {
  color: var(--primary-100);
  background-color: var(--tg-color);

  &:hover {
    background-color: var(--tg-color-hover);
  }

  &:active {
    background-color: var(--tg-color-active);
  }
}

.denyButton {
  color: var(--primary-500);
  background-color: var(--grey-200);

  &:hover {
    background-color: var(--grey-300);
  }

  &:active {
    background-color: var(--grey-400);
  }
}

.root {
  & .allowButton,
  & .denyButton {
    display: inline-flex;
    gap: 4px;
    align-items: center;
    height: 30px;
    padding: 3px 12px 3px 8px;
    font-size: 14px;
    line-height: 18px;
    border-radius: 8px;

    & svg {
      font-size: 24px;
    }

    & span {
      margin-top: -2px;
    }
  }
}
</style>

export const http = {};

export const createAbortController = () =>
  process.client
    ? new AbortController()
    : {
        abort: () => {},
        signal: {
          addEventListener: () => {},
          removeEventListener: () => {},
        },
      };

export const init = ({ get, post, put, patch, delete_ }) => {
  http.get = get;
  http.post = post;
  http.put = put;
  http.patch = patch;
  http.delete = delete_;
};

// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".the-navbar-menu-button__root-dP3aQ{color:var(--text-main-color)}.the-navbar-menu-button__popper-Hm2AM{margin-top:5px}@media (max-width:1023px){.the-navbar-menu-button__root-dP3aQ{width:100%}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "the-navbar-menu-button__root-dP3aQ",
	"popper": "the-navbar-menu-button__popper-Hm2AM"
};
module.exports = ___CSS_LOADER_EXPORT___;

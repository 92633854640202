<script>
import { createNamespacedHelpers } from 'vuex';
import StreaksMinXpRequired from '~/components/streaks/streaks-min-xp-required.vue';

const { mapState, mapGetters } = createNamespacedHelpers('streaks');

export const messages = {
  ru: {
    recordStreak: 'это твой рекордный стрик, не потеряй его',
    notRecordStreak: 'твой рекордный стрик — {maxStreak}. сможешь побить его?',
  },
};

export default {
  components: {
    StreaksMinXpRequired,
  },
  computed: {
    ...mapState(['currentStreak', 'maxUserStreak', 'streakReceivedToday']),
    ...mapGetters(['isRecordStreak']),
    hasStreak() {
      return this.currentStreak > 0;
    },
  },
  i18n: {
    messages,
  },
};
</script>

<template>
  <div :class="$style.root">
    <span v-if="streakReceivedToday" :class="$style.headerRow">
      <template v-if="isRecordStreak">
        <img
          :src="require('../images/record-streak.svg')"
          width="32"
          height="32"
          alt=""
        />
        {{ $t('recordStreak') }}
      </template>
      <template v-else>
        <i18n path="notRecordStreak" tag="span">
          <template #maxStreak>
            <img
              :src="require('../images/icon-fire.svg')"
              width="16"
              height="16"
              alt=""
              :class="$style.fireIcon"
            />
            <span :class="$style.maxCount">{{ maxUserStreak }}</span>
          </template>
        </i18n>
      </template>
    </span>
    <StreaksMinXpRequired v-else :has-streak="hasStreak" />
  </div>
</template>

<style lang="postcss" module>
.root {
  display: flex;
  font-size: 14px;
  line-height: 18px;
}

.headerRow {
  display: flex;
  gap: 4px;
  align-items: center;
}

.maxCount {
  color: var(--streaks-active-color);
}

.fireIcon {
  position: relative;
  top: -2px;
  right: -1px;
}

@media (--small-vp) {
  .root {
    font-size: 16px;
  }
}
</style>

// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".streaks-paired-invite-button__root-Tt4e0{--button-size:56px;text-align:center}.streaks-paired-invite-button__iconWrapper-DliMt{justify-content:center;width:var(--button-size);height:var(--button-size);border:2px solid var(--grey-300);border-radius:100%}.streaks-paired-invite-button__button-lLFpx,.streaks-paired-invite-button__iconWrapper-DliMt{display:flex;align-items:center}.streaks-paired-invite-button__button-lLFpx{flex-direction:column;grid-gap:20px;gap:20px;color:var(--system-500);font-size:14px}.streaks-paired-invite-button__button-lLFpx:hover .streaks-paired-invite-button__iconWrapper-DliMt{background-color:var(--grey-100)}.streaks-paired-invite-button__button-lLFpx:active .streaks-paired-invite-button__iconWrapper-DliMt{background-color:var(--grey-200)}.streaks-paired-invite-button__icon-rr5\\+U{font-size:24px}@media (max-width:1023px){.streaks-paired-invite-button__root-Tt4e0{--button-size:72px}.streaks-paired-invite-button__button-lLFpx{font-size:18px}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "streaks-paired-invite-button__root-Tt4e0",
	"iconWrapper": "streaks-paired-invite-button__iconWrapper-DliMt",
	"button": "streaks-paired-invite-button__button-lLFpx",
	"icon": "streaks-paired-invite-button__icon-rr5+U"
};
module.exports = ___CSS_LOADER_EXPORT___;

<script>
import { createNamespacedHelpers } from 'vuex';
import { directionValidator } from '~/constants/ui';
import { logout } from '~/services/auth-functions';

const { mapGetters: mapFeatureTogglesGetters } =
  createNamespacedHelpers('feature-toggles');

export const messages = {
  ru: {
    home: 'моя страница',
    chooseCourse: 'подобрать курс',
    login: 'войти',
    logout: 'выйти',
  },
};

export default {
  i18n: { messages },
  props: {
    excluded: {
      type: Array,
      default: () => [],
    },
    direction: {
      type: String,
      default: '',
      validate: directionValidator,
    },
  },
  computed: {
    ...mapFeatureTogglesGetters(['isFeatureEnabled']),
    classes() {
      return {
        [this.$style.root]: true,
        [this.$style[`_${this.direction}`]]: !!this.direction,
      };
    },
  },
  methods: {
    async logout() {
      await logout(this.$config.serviceUrls.MAIN_AUTH_URL);
    },
    isVisible(name) {
      if (this.excluded.includes(name)) return false;
      if (name === 'home') return this.$auth.loggedIn;
      return true;
    },
  },
};
</script>

<template>
  <ul :class="classes">
    <li v-if="isVisible('home')" :class="[$style.item, $style._desktopOnly]">
      <NuxtLink to="/">{{ $t('home') }}</NuxtLink>
    </li>
    <li v-if="isVisible('chooseCourse')" :class="$style.item">
      <a :href="$config.serviceUrls.SHOP_SPRING_FLOW_URL">
        {{ $t('chooseCourse') }}
      </a>
    </li>
    <li v-if="$device.isSmallScreen" :class="$style.item">
      <NuxtLink v-if="!$auth.loggedIn" :to="{ name: 'auth' }">
        {{ $t('login') }}
      </NuxtLink>
      <button v-else @click.prevent="logout">
        {{ $t('logout') }}
      </button>
    </li>
  </ul>
</template>

<style lang="postcss" module>
.item {
  & a,
  & button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 15px 0 17px;
    font-size: 16px;
    line-height: 20px;
    color: var(--primary-500);
    letter-spacing: 0.02em;
    transition: var(--default-transition);
  }
}

@media (hover: hover) {
  .item {
    & a,
    & button {
      &:hover {
        color: var(--primary-200);
      }

      &:active {
        color: var(--primary-500);
      }
    }
  }
}

.root {
  display: flex;
  gap: 48px;
  align-items: center;
  padding: 0;

  &._column {
    flex-direction: column;
    gap: 0;
    align-items: flex-start;

    & .item {
      display: inline-flex;
      align-items: center;
      height: var(--navbar-height);
    }
  }
}

@media (--small-vp) {
  .item {
    & a,
    & button {
      align-items: flex-start;
      justify-content: flex-start;
      width: 100%;
      height: var(--navbar-height);
      padding: 14px 30px;
      font-size: 20px;
      line-height: 24px;
    }
  }

  .root {
    width: 100%;
    padding: 8px 0;

    &._column {
      & .item {
        width: 100%;
      }
    }
  }

  ._desktopOnly {
    display: none;
  }
}
</style>

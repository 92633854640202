// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".month-calendar-weekdays__root-C83C7{display:grid;grid-template-columns:repeat(7,1fr);font-size:16px;font-weight:400;line-height:20px;color:var(--calendar-secondary-color);letter-spacing:.02em}.month-calendar-weekdays__weekday-I6chE,.month-calendar-weekdays__weekday-I6chE.month-calendar-weekdays___left-c0ZDh{padding-left:8px}.month-calendar-weekdays__weekday-I6chE.month-calendar-weekdays___center-vs0dr{justify-self:center;padding:0}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "month-calendar-weekdays__root-C83C7",
	"weekday": "month-calendar-weekdays__weekday-I6chE",
	"_left": "month-calendar-weekdays___left-c0ZDh",
	"_center": "month-calendar-weekdays___center-vs0dr"
};
module.exports = ___CSS_LOADER_EXPORT___;

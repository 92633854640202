// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".motivation-new-level-message__info-53vco{--motivation-image-width:82px;--motivation-image-height:82px;--motivation-info-padding:6px 46px 6px 12px;--motivation-title-font-size:18px;--motivation-title-line-height:22px}@media (max-width:1023px){.motivation-new-level-message__info-53vco{--motivation-image-width:72px;--motivation-image-height:72px;--motivation-info-padding:6px 28px 6px 12px;--motivation-title-font-size:16px;--motivation-title-line-height:20px}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"info": "motivation-new-level-message__info-53vco"
};
module.exports = ___CSS_LOADER_EXPORT___;

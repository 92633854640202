// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".button-social__root-bwBw8.button-social___vk-MHUcV{background-color:var(--vk-color)}.button-social__root-bwBw8.button-social___vk-MHUcV:hover{background-color:var(--vk-color-hover)}.button-social__root-bwBw8.button-social___vk-MHUcV:active{background-color:var(--vk-color-active)}.button-social__root-bwBw8.button-social___tg-mlfIj{background-color:var(--tg-color)}.button-social__root-bwBw8.button-social___tg-mlfIj:hover{background-color:var(--tg-color-hover)}.button-social__root-bwBw8.button-social___tg-mlfIj:active{background-color:var(--tg-color-active)}.button-social__root-bwBw8.button-social___tt-1mvEj{background-color:var(--tt-color)}.button-social__root-bwBw8.button-social___tt-1mvEj:hover{background-color:var(--tt-color-hover)}.button-social__root-bwBw8.button-social___tt-1mvEj:active{background-color:var(--tt-color-active)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "button-social__root-bwBw8",
	"_vk": "button-social___vk-MHUcV",
	"_tg": "button-social___tg-mlfIj",
	"_tt": "button-social___tt-1mvEj"
};
module.exports = ___CSS_LOADER_EXPORT___;

import snakeCase from 'lodash/snakeCase';

const namespace = 'lms';

const normalizeKey = (key) => `${namespace}.${snakeCase(key)}`;

export function setLSItem(key, value) {
  localStorage.setItem(
    normalizeKey(key),
    typeof value === 'object' ? JSON.stringify(value) : value
  );
}

export function getLSItem(key) {
  const value = localStorage.getItem(normalizeKey(key));
  try {
    return JSON.parse(value);
  } catch {
    return value;
  }
}

export function removeLSItem(key) {
  localStorage.removeItem(normalizeKey(key));
}

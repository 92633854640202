// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._base-segmented-progress__root-4gHRt{--segments-gap:6px;--border-radius:4px;--label-font-size:16px;--label-line-height:20px;--completed-color:var(--success-500);display:flex;grid-gap:var(--segments-gap);gap:var(--segments-gap);width:100%}._base-segmented-progress__root-4gHRt._base-segmented-progress___m-6q4Xe,._base-segmented-progress__root-4gHRt._base-segmented-progress___s-OgA66{--height:4px}._base-segmented-progress__root-4gHRt._base-segmented-progress___l-6u5sy{--height:6px}._base-segmented-progress__segment-DpshG{position:relative;display:flex;flex-grow:1;height:var(--height);max-height:var(--height);background-color:var(--grey-300);border-radius:var(--border-radius)}._base-segmented-progress__label-v5\\+8Z{font-size:var(--label-font-size);line-height:var(--label-line-height);text-align:center;color:var(--grey-600)}._base-segmented-progress__step-2LrAE{display:block;height:inherit;border-radius:inherit;background-color:var(--completed-color);transition:all .3s ease-in}._base-segmented-progress__segmentWrapper-99o4u{display:flex;flex-direction:column;grid-gap:12px;gap:12px;width:100%}._base-segmented-progress__segmentWrapper-99o4u._base-segmented-progress___current-6qUiW ._base-segmented-progress__label-v5\\+8Z{color:var(--primary-500)}._base-segmented-progress__segmentWrapper-99o4u._base-segmented-progress___current-6qUiW ._base-segmented-progress__segment-DpshG{background-color:var(--primary-100);box-shadow:0 0 0 2px var(--primary-500)}._base-segmented-progress__segmentWrapper-99o4u._base-segmented-progress___completed-t7FZD ._base-segmented-progress__label-v5\\+8Z{color:var(--completed-color)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "_base-segmented-progress__root-4gHRt",
	"_m": "_base-segmented-progress___m-6q4Xe",
	"_s": "_base-segmented-progress___s-OgA66",
	"_l": "_base-segmented-progress___l-6u5sy",
	"segment": "_base-segmented-progress__segment-DpshG",
	"label": "_base-segmented-progress__label-v5+8Z",
	"step": "_base-segmented-progress__step-2LrAE",
	"segmentWrapper": "_base-segmented-progress__segmentWrapper-99o4u",
	"_current": "_base-segmented-progress___current-6qUiW",
	"_completed": "_base-segmented-progress___completed-t7FZD"
};
module.exports = ___CSS_LOADER_EXPORT___;

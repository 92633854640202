import { createNanoEvents } from 'nanoevents';
import { handleError } from '~/services/error-handling/error-handling';
import { userHasPermissionByRole } from './role-permissions';

let isInited;
let nuxtInstance;
const emitter = createNanoEvents();
const events = {
  init: 'init',
  login: 'login',
  logout: 'logout',
};

function safelyCallEventCallback(callback) {
  try {
    callback(nuxtInstance.$auth);
  } catch (error) {
    handleError(error, { captureError: true, showToast: false });
  }
}

export const init = (usedNuxtInstance) => {
  nuxtInstance = usedNuxtInstance;
  return new Promise((resolve) => {
    setTimeout(() => {
      isInited = true;
      emitter.emit(events.init);
      resolve();
    });
  });
};

export async function login({ username, password }) {
  await nuxtInstance.$auth.loginWith('local', { data: { username, password } });
  emitter.emit(events.login);
}

export async function vkConnectAuth({ uuid, token }) {
  await nuxtInstance.$auth.loginWith('vkConnect', {
    data: { uuid, token },
  });
  emitter.emit(events.login);
}

export async function logout(redirectToUrl) {
  await nuxtInstance.$auth.logout();
  emitter.emit(events.logout);
  window.location.assign(redirectToUrl);
}

export function onClientAuthLogin(callback) {
  if (process.server) return undefined;

  return emitter.on(events.login, () => {
    safelyCallEventCallback(callback);
  });
}

export function onClientAuthLogout(callback) {
  if (process.server) return undefined;

  return emitter.on(events.logout, () => {
    safelyCallEventCallback(callback);
  });
}

export function onClientAuthInit(callback) {
  if (process.server) return undefined;

  if (isInited) {
    safelyCallEventCallback(callback);
  }
  return emitter.on(events.init, () => {
    safelyCallEventCallback(callback);
  });
}

export function userHasPermission(permissionKey) {
  if (!nuxtInstance?.$auth.loggedIn) return false;
  const userRole = nuxtInstance.$auth.user.role;
  return userHasPermissionByRole(permissionKey, userRole);
}

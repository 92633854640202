<script>
export default {
  props: {
    image: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    level: {
      type: String,
      default: '',
    },
  },
};
</script>

<template>
  <div :class="$style.root">
    <div :class="$style.imgWrapper">
      <div :class="$style.imgBox">
        <img :src="image" width="112" height="80" alt="" />
      </div>
      <span v-if="level" :class="$style.level">{{ level }}</span>
    </div>
    <div :class="$style.content">
      <p :class="$style.title">{{ title }}</p>
      <p :class="$style.description" v-html="description"></p>
    </div>
  </div>
</template>

<style lang="postcss" module>
.root {
  display: flex;
  gap: 0 12px;
  align-items: center;
  padding: var(--motivation-info-padding, 6px 14px 6px 6px);
}

.imgWrapper {
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  align-items: center;
}

.imgBox {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  width: var(--motivation-image-width, 112px);
  height: var(--motivation-image-height, 80px);

  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.content {
  display: flex;
  flex-direction: column;
  gap: 4px 0;
}

.title {
  font-size: var(--motivation-title-font-size, 16px);
  line-height: var(--motivation-title-line-height, 20px);
}

.description {
  font-size: 14px;
  line-height: 18px;
  color: var(--grey-600);

  & a {
    color: var(--system-500);
  }

  & span {
    display: inline-flex;
    gap: 2px;
    align-items: center;
    color: var(--success-600);
    white-space: nowrap;
  }
}

.level {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-top: -12px;
  padding: 0 6px;
  color: var(--primary-500);
  text-align: center;
  font-size: 12px;
  line-height: 18px;
  white-space: nowrap;
  border-radius: 11px;
  border: 2px solid var(--primary-100);
  background-color: var(--warning-500);
}

@media (--small-vp) {
  .root {
    padding: var(--motivation-info-padding, 14px 14px 14px 6px);
  }
}
</style>

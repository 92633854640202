const addRoutesTrailingSlashes = (routes) =>
  routes.map((route) => {
    const modified = {
      ...route,
      path: route.path.slice(-1) === '/' ? route.path : `${route.path}/`,
      pathToRegexpOptions: {
        ...route.pathToRegexpOptions,
        strict: true,
      },
    };
    if (Array.isArray(route.children)) {
      modified.children = addRoutesTrailingSlashes(route.children);
    }
    return modified;
  });

module.exports = {
  addRoutesTrailingSlashes,
};

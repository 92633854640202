<script>
export default {
  props: {
    item: {
      required: true,
      type: Object,
    },
  },
  computed: {
    iconName() {
      return `day-${this.item.state}`;
    },
  },
};
</script>

<template>
  <div>
    <img :src="require(`./images/${iconName}.svg`)" alt="" />
  </div>
</template>

<script>
import { XP_REQUIRED_FOR_STREAK } from '~/constants/streaks';
import BasePopover from '~/components/_base/popover/_base-popover.vue';
import XpSign from '~/components/xp-sign.vue';

const messages = {
  ru: {
    experience: 'опыт',
    text: 'набери {xpSign} чтобы {streakStatus} стрик',
    start: 'начать',
    continue: 'продолжить',
    popover: {
      title: 'стрик увеличивается, когда ты получаешь {xpSign} за:',
      text: '<ul><li>просмотр вебинаров</li><li>решение домашек и пробников</li><li>решение ежедневных заданий</li></ul>',
    },
  },
};

export default {
  XP_REQUIRED_FOR_STREAK,
  components: {
    XpSign,
    BasePopover,
  },
  props: {
    hasStreak: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    streakText() {
      return this.hasStreak ? this.$t('continue') : this.$t('start');
    },
  },
  i18n: {
    messages,
  },
};
</script>

<template>
  <i18n :class="$style.root" path="text" tag="span">
    <template #xpSign>
      <BasePopover
        :popper-class="$style.popper"
        placement="bottom"
        :trigger="['hover', 'click']"
        show-arrow
      >
        <XpSign clickable>
          {{ $options.XP_REQUIRED_FOR_STREAK }}
        </XpSign>
        <template #dropdown>
          <div :class="$style.popoverContent">
            <i18n path="popover.title" tag="p" :class="$style.xpSignWrapper">
              <template #xpSign>
                <XpSign :class="$style.xpSignInPopover">
                  {{ $t('experience') }}
                </XpSign>
              </template>
            </i18n>
            <div
              :class="$style.popoverListWrapper"
              v-html="$t('popover.text')"
            ></div>
          </div>
        </template>
      </BasePopover>
    </template>
    <template #streakStatus>
      {{ streakText }}
    </template>
  </i18n>
</template>

<style lang="postcss" module>
.root {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  & > div {
    padding: 0 4px;
  }
}

.popper {
  z-index: var(--base-tooltip-z-index);
  margin-top: 4px;
}

.popoverContent {
  width: 248px;
  padding: 11px 18px;
  font-size: 14px;
}

.popoverListWrapper {
  & ul {
    display: flex;
    flex-direction: column;
    gap: 6px;
    margin-top: 8px;
    padding-left: 16px;
    list-style-type: disc;

    & li {
      font-size: 14px;
      line-height: 18px;
    }
  }
}

.xpSignInPopover {
  margin-right: 2px;
}

.xpSignWrapper {
  & button {
    position: relative;
    bottom: 1.5px;
    left: 1px;
  }
}
</style>

import { getFeatureToggles } from '~/api/feature-toggles';
import {
  onClientAuthLogin,
  onClientAuthLogout,
} from '~/services/auth-functions';
import { handleError } from '~/services/error-handling/error-handling';

const debugFeatureTogglesUrlParameter = 'debugFeatureToggles';

export const featureTogglesPlugin = (store) => {
  onClientAuthLogin(() => {
    store.dispatch('feature-toggles/loadFeatureToggles');
  });
  onClientAuthLogout(() => {
    store.dispatch('feature-toggles/resetFeatureToggles');
  });
};

export const state = () => ({
  items: [],
  itemsLoaded: false,
});

export const mutations = {
  SET_FEATURE_TOGGLES(state, value) {
    state.items = value;
  },
  SET_ITEMS_LOADED(state, value) {
    state.itemsLoaded = value;
  },
  RESET(state) {
    state.items = [];
    state.itemsLoaded = false;
  },
};

export const actions = {
  async loadFeatureToggles({ state, commit, rootState }) {
    if (state.itemsLoaded || !rootState.auth.loggedIn) return;
    try {
      const searchParams = new URLSearchParams(window.location.search);
      const debugFeatureTogglesFromUrl = searchParams.get(
        debugFeatureTogglesUrlParameter
      );
      const debugFeatureToggles = debugFeatureTogglesFromUrl
        ? debugFeatureTogglesFromUrl.split(',')
        : undefined;
      const data = await getFeatureToggles();
      commit('SET_ITEMS_LOADED', true);
      commit('SET_FEATURE_TOGGLES', [
        ...(data || []),
        ...(debugFeatureToggles || []),
      ]);
    } catch (error) {
      handleError(error);
    }
  },
  resetFeatureToggles({ commit }) {
    commit('RESET');
  },
};

export const getters = {
  isFeatureEnabled: (state) => (feature) => state.items.includes(feature),
};

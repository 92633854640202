// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".course-onboarding-modal__root-d0fmd{--modal-max-width:700px;--w-overlay-content-margin:80px 0 0}.course-onboarding-modal__inner-gXaKK{--modal-padding-x:40px;position:relative;display:flex;flex-direction:column;padding:32px var(--modal-padding-x) 40px}.course-onboarding-modal__title-jv3f3{margin-right:16px;margin-bottom:16px;font-size:28px;line-height:34px}@media (max-width:1023px){.course-onboarding-modal__root-d0fmd{--modal-max-width:100%;--w-overlay-content-margin:auto 0;--modal-mobile-top-space:62px}.course-onboarding-modal__inner-gXaKK{--modal-padding-x:20px;padding:20px var(--modal-padding-x) 20px}.course-onboarding-modal__title-jv3f3{margin-right:0}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "course-onboarding-modal__root-d0fmd",
	"inner": "course-onboarding-modal__inner-gXaKK",
	"title": "course-onboarding-modal__title-jv3f3"
};
module.exports = ___CSS_LOADER_EXPORT___;

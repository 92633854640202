<script>
import { Tooltip } from 'floating-vue';

const availablePlacements = new Set([
  'auto',
  'auto-start',
  'auto-end',
  'top',
  'top-start',
  'top-end',
  'bottom',
  'bottom-start',
  'bottom-end',
  'left',
  'left-start',
  'left-end',
  'right',
  'right-start',
  'right-end',
]);

export default {
  components: {
    VTooltip: Tooltip,
  },
  props: {
    text: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    placement: {
      type: String,
      default: 'bottom',
      validate: (value) => availablePlacements.has(value),
    },
    distance: {
      type: Number,
      default: 8,
    },
    overflowPadding: {
      type: Number,
      default: 10,
    },
    shown: {
      type: Boolean,
      default: false,
    },
    triggers: {
      type: Array,
      default: () => ['hover'],
    },
    theme: {
      type: String,
      default: 'base-tooltip',
    },
    popperClass: {
      type: [String, Array],
      required: false,
      default: '',
    },
    boundary: {
      type: Element,
      default: undefined,
    },
    strategy: {
      type: String,
      default: 'absolute',
    },
  },
  computed: {
    isHidden() {
      return this.disabled || (!this.text && !this.$slots.text);
    },
  },
  methods: {
    computePosition() {
      const popperInstance = this.$refs.tooltip?.$children?.[0];
      if (popperInstance) {
        popperInstance.$_computePosition();
      }
    },
  },
};
</script>

<template>
  <VTooltip
    ref="tooltip"
    :placement="placement"
    :distance="distance"
    :shown="shown"
    :disabled="isHidden"
    :triggers="triggers"
    :boundary="boundary"
    :overflow-padding="overflowPadding"
    :theme="theme"
    :popper-class="popperClass"
    :strategy="strategy"
  >
    <slot></slot>
    <template #popper>
      <slot name="text">
        <span v-html="text"></span>
      </slot>
    </template>
  </VTooltip>
</template>

<!-- eslint-disable vue-scoped-css/enforce-style-type -->
<style lang="postcss">
/* stylelint-disable selector-class-pattern */
.v-popper--theme-base-tooltip .v-popper__inner {
  --_default-max-width: var(--base-tooltip-max-width);
  --_used-max-width: var(--max-width, var(--_default-max-width));

  max-width: var(--_used-max-width);
  padding: 5px 10px 6px;
  font-size: 13px;
  font-weight: normal;
  color: var(--primary-100);
  background-color: var(--primary-400);
  border-radius: 4px;
}

.v-popper--theme-base-tooltip .v-popper__arrow-outer {
  border-color: var(--primary-400);
}

.v-popper--theme-attention .v-popper__inner {
  --_default-inner-padding: 16px 20px;
  --_used-inner-padding: var(--inner-padding, var(--_default-inner-padding));

  width: fit-content;
  padding: var(--_used-inner-padding);
  font-size: 14px;
  line-height: 18px;
  color: var(--primary-100);
  text-align: left;
  background-color: var(--system-500);
  border-radius: 8px;
  box-shadow: var(--dropdown-shadow);
}

.v-popper--theme-attention .v-popper__arrow-outer {
  border-color: var(--system-500);
}

.v-popper__popper.v-popper--theme-base-tooltip {
  z-index: var(--base-tooltip-z-index);
}

.v-popper__popper[data-popper-placement^='bottom'] .v-popper__arrow-inner,
.v-popper__popper[data-popper-placement^='bottom'] .v-popper__arrow-outer {
  border-top-width: 1px;
}

.v-popper__popper[data-popper-placement^='top'] .v-popper__arrow-inner,
.v-popper__popper[data-popper-placement^='top'] .v-popper__arrow-outer {
  top: -1px;
}
</style>

<script>
import {
  VK_NOTIFICATIONS_EVENT_NAME_ALLOWED,
  VK_NOTIFICATIONS_EVENT_NAME_DENIED,
} from '~/constants/vk-notifications-community';
import { loadScript } from '~/utils/load-script';

const scriptUrl = 'https://vk.com/js/api/openapi.js?169';
const height = 30;
const width = 185;
const defaultElementId = 'vk-allow-messages-from-community';

export default {
  props: {
    elementId: {
      type: String,
      default: undefined,
    },
  },
  computed: {
    styles() {
      return {
        height: `${height}px`,
      };
    },
    usedElementId() {
      return this.elementId || defaultElementId;
    },
  },
  async mounted() {
    await loadScript(scriptUrl);
    this.initWidget();
  },
  destroyed() {
    this.unsubscribeEvents();
  },
  methods: {
    initWidget() {
      window.VK.Widgets.AllowMessagesFromCommunity(
        this.usedElementId,
        { height, width },
        this.$config.vkWebiumCareCommunityId
      );
      this.subscribeEvents();
    },
    subscribeEvents() {
      window.VK.Observer.subscribe(
        VK_NOTIFICATIONS_EVENT_NAME_ALLOWED,
        this.onAllow
      );
      window.VK.Observer.subscribe(
        VK_NOTIFICATIONS_EVENT_NAME_DENIED,
        this.onDeny
      );
    },
    unsubscribeEvents() {
      window.VK.Observer.unsubscribe(
        VK_NOTIFICATIONS_EVENT_NAME_ALLOWED,
        this.onAllow
      );
      window.VK.Observer.unsubscribe(
        VK_NOTIFICATIONS_EVENT_NAME_DENIED,
        this.onDeny
      );
    },
    updateIsVkNotificationsAllowed(value) {
      if (!this.$auth.loggedIn) return;
      this.$auth.setUser({
        ...this.$auth.user,
        isVkNotificationsAllowed: !!value,
      });
    },
    onAllow(userId) {
      this.updateIsVkNotificationsAllowed(true);
      this.$emit('allow', userId);
    },
    onDeny(userId) {
      this.updateIsVkNotificationsAllowed(false);
      this.$emit('deny', userId);
    },
  },
};
</script>

<template>
  <div :id="usedElementId" :style="styles"></div>
</template>

// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".banner-sidebar__root-19FMR{width:100%}.banner-sidebar__link-BxBQ8,.banner-sidebar__root-19FMR{display:flex}.banner-sidebar__link-BxBQ8{align-items:center;justify-content:center;transition:opacity var(--default-transition)}.banner-sidebar__link-BxBQ8:hover{opacity:.7}.banner-sidebar__link-BxBQ8 img{border-radius:var(--standard-border-radius)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "banner-sidebar__root-19FMR",
	"link": "banner-sidebar__link-BxBQ8"
};
module.exports = ___CSS_LOADER_EXPORT___;

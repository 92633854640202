/* eslint-disable sonarjs/no-duplicate-string */
import { AVAILABLE_NOTIFICATION_TYPES } from '~/constants/notifications';
import { isRequired } from '~/utils/is-required';
import { http } from '~/services/http';

const getDefaultSettingsConfig = () => {
  const config = {};
  AVAILABLE_NOTIFICATION_TYPES.forEach((type) => {
    config[type] = true;
  });
  return config;
};

export async function getNotification({ notificationId = isRequired() } = {}) {
  const requestUrl = `/api/v2/student/notifications/${notificationId}/`;
  const response = await http.get(requestUrl);

  return response;
}

export async function getNotifications({ page = 1, pageSize = 16 } = {}) {
  const requestUrl = '/api/v2/student/notifications/';

  const response = await http.get(requestUrl, {
    params: {
      page,
      page_size: pageSize,
    },
  });
  return {
    results: response.results,
    totalCount: response.count,
    hasNextPage: !!response.next,
    nonViewedCount: response.nonViewedCount,
  };
}

export async function getNotificationSettings() {
  const requestUrl = '/api/v2/student/notifications-config/';

  const { templates } = await http.get(requestUrl);
  const result = templates.map((template) => ({
    ...template,
    config: template.config || getDefaultSettingsConfig(),
  }));
  return result || [];
}

export async function updateNotificationSettings(settings) {
  const requestUrl = '/api/v2/student/notifications-config/';

  const response = await http.put(requestUrl, { templates: settings });
  return response.templates || [];
}

export async function markAsViewedNotifications({ notificationIds } = {}) {
  const requestUrl = '/api/v2/student/notifications-mark-all-as-viewed/';

  const response = await http.post(requestUrl, {
    notificationIds,
  });
  return response;
}

export async function markAsViewedNotification({ notificationId }) {
  const requestUrl = `/api/v2/student/notifications/${notificationId}/mark-as-viewed/`;

  const response = await http.post(requestUrl);
  return response;
}

// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".default__main-LmveU{max-width:var(--max-content-width);min-height:calc(100vh - var(--navbar-height));padding:0 24px;margin:32px auto 0}@media (max-width:1023px){.default__main-LmveU{--top-margin:20px;min-height:calc(100vh - var(--navbar-height) - var(--top-margin));padding:0 12px;margin-top:var(--top-margin)}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": "default__main-LmveU"
};
module.exports = ___CSS_LOADER_EXPORT___;

import { TYPEFORM_READY_STORAGE_KEY } from '~/constants/local-storage';
import { saveTypeformAnswer } from '~/api/surveys';
import { setLSItem } from '~/services/local-storage';

const TYPEFORM_MODIFIER = 'typeFormHidden';

function initGlobalFunctions() {
  window.wbmTypeFormOnSubmit = ({ formId }) => {
    saveTypeformAnswer(formId);
    window.document.body.classList.add(TYPEFORM_MODIFIER);
  };

  window.wbmHideTypeForm = () => {
    window.document.body.classList.add(TYPEFORM_MODIFIER);
  };

  window.wbmShowTypeForm = () => {
    window.document.body.classList.remove(TYPEFORM_MODIFIER);
  };

  window.wbmTypeFormOnReady = ({ formId }) => {
    const typeFormKey = `${TYPEFORM_READY_STORAGE_KEY}_${formId}_loaded_at`;
    setLSItem(typeFormKey, Date.now());
  };
}

export default () => {
  initGlobalFunctions();
};

import { getBuildInfo } from '~/api/inner-api';
import { getLSItem, setLSItem } from '~/services/local-storage';

const fetchDelayInMS = 30 * 60 * 1000;
const buildVersionLSKey = 'build_version';
const lastBuildVersionFetchLSKey = 'build_version_fetched_at';

const captureBuildReloadEvent = (
  { $sentry, $auth },
  { lastFetchedAt, currentBuildVersion, actualBuildVersion }
) => {
  const timestamp = Date.now();
  $sentry.withScope((scope) => {
    scope.setLevel('info');
    scope.setContext('Additional info', {
      'User ID': $auth.user?.id,
      'Old version': currentBuildVersion,
      'New version': actualBuildVersion,
      'Time passed since last reload':
        timestamp -
        (lastFetchedAt ? Number.parseInt(lastFetchedAt, 10) : timestamp),
    });
    $sentry.captureEvent({
      message: 'Build version is expired',
    });
  });
};

const fetchBuildVersion = async () => {
  const { version } = await getBuildInfo();
  if (version) {
    setLSItem(buildVersionLSKey, version);
  }
  setLSItem(lastBuildVersionFetchLSKey, Date.now());
  return version;
};

const handleBuildReload = async ({ $sentry, $auth }) => {
  const lastFetchedAt = getLSItem(lastBuildVersionFetchLSKey);
  const hasFreshFetchedVersion =
    lastFetchedAt &&
    Date.now() - Number.parseInt(lastFetchedAt, 10) < fetchDelayInMS;
  if (hasFreshFetchedVersion) return;

  const currentBuildVersion = getLSItem(buildVersionLSKey);
  const actualBuildVersion = await fetchBuildVersion();
  const isBuildVersionExpired =
    currentBuildVersion &&
    actualBuildVersion &&
    currentBuildVersion.toString() !== actualBuildVersion.toString();
  if (isBuildVersionExpired) {
    captureBuildReloadEvent(
      { $sentry, $auth },
      { lastFetchedAt, currentBuildVersion, actualBuildVersion }
    );
  }
};

export default ({ app }) => {
  window.onNuxtReady(async () => {
    const { $auth, $sentry } = app;
    await fetchBuildVersion();
    window.addEventListener('focus', () => {
      handleBuildReload({ $sentry, $auth });
    });
  });
};

<script>
import { WButton } from '@WebiumTeam/ui_kit';
import { createNamespacedHelpers } from 'vuex';
import AdminButton from '~/components/admin-button.vue';
import CoursesProlongButton from '~/components/courses-prolong-button.vue';
import MotivationLuringDotIndicator from '~/components/motivation/motivation-luring-dot-indicator.vue';
import MotivationNewXpIndicator from '~/components/motivation/motivation-new-xp-indicator/motivation-new-xp-indicator.vue';

const { mapGetters: mapMotivationGetters } =
  createNamespacedHelpers('motivation');

export const messages = {
  ru: {
    home: 'Главная',
    timetable: 'Расписание',
    courses: 'Мои курсы',
    achievements: 'Достижения',
    bonuses: 'Бонусы и акции',
  },
};

export default {
  components: {
    AdminButton,
    WButton,
    MotivationLuringDotIndicator,
    MotivationNewXpIndicator,
    CoursesProlongButton,
  },
  i18n: { messages },
  computed: {
    ...mapMotivationGetters(['isLuringDotVisible']),
    links() {
      return [
        {
          to: { name: 'dashboard' },
          icon: 'home',
          label: this.$t('home'),
        },
        {
          to: { name: 'timetable' },
          icon: 'calendar-navbar',
          label: this.$t('timetable'),
        },
        {
          to: { name: 'courses' },
          icon: 'courses',
          label: this.$t('courses'),
        },
        {
          to: { name: 'achievements' },
          icon: 'achievements',
          label: this.$t('achievements'),
          key: 'motivation',
        },
        {
          native: true,
          href: this.$config.serviceUrls.SHOP_PROMOTIONS_URL,
          icon: 'bonuses',
          label: this.$t('bonuses'),
        },
      ].filter((link) => !link.hidden);
    },
  },
};
</script>

<template>
  <div :class="$style.root">
    <WButton
      v-for="(link, index) in links"
      :key="index"
      :tag="link.native ? 'a' : 'NuxtLink'"
      :to="link.to"
      :href="link.href"
      :class="$style.link"
      :active-class="$style._active"
      target="_self"
      theme="emptyLight"
    >
      <WIcon :name="link.icon" size="medium" />
      <div :class="$style.labelWrapper">
        <span> {{ link.label }} </span>
        <template v-if="link.key === 'motivation'">
          <MotivationLuringDotIndicator />
          <MotivationNewXpIndicator />
        </template>
      </div>
    </WButton>
    <CoursesProlongButton :class="$style.prolong" />
    <div :class="$style.externalButtons">
      <AdminButton :class="$style.externalButton" />
    </div>
  </div>
</template>

<style lang="postcss" module>
.root {
  --link-padding: 8px;

  display: flex;
  flex-direction: column;
  gap: 2px;
  margin-left: calc(var(--link-padding) * -1);

  & .labelWrapper {
    position: relative;
    display: flex;
    align-items: center;
  }

  & .link,
  & .prolong {
    --base-button-padding: var(--link-padding);

    z-index: 1;
    gap: 14px;
    align-items: center;
    justify-content: flex-start;
    text-align: initial;
    border: none;

    &._active,
    &:hover:not(:has(button:hover)) {
      background-color: var(--grey-300);
    }

    & > span {
      margin-bottom: 3px;
      text-align: initial;
    }

    & svg {
      margin-left: -2px;
    }
  }
}

.externalButtons {
  padding-top: 8px;

  &:empty {
    display: none;
  }
}

.externalButton {
  align-self: center;
  margin: 4px 0;
}

.popper {
  --inner-padding: 8px 20px 12px 8px;

  left: 8px;
}

@media (--small-vp) {
  .labelWrapper,
  .prolong {
    margin-top: -2px;
    font-size: 20px;
    line-height: 24px;
  }

  .root {
    --link-padding: 0;

    gap: 0;
    padding: 8px 22px;
    border-bottom: 2px solid var(--grey-200);

    & .link,
    & .prolong {
      --base-button-padding: 8px;

      gap: 20px;
      height: 56px;

      &._active,
      &:hover:not(:has(button:hover)) {
        background-color: var(--primary-100);
      }

      & svg {
        align-self: center;
      }
    }
  }

  .externalButtons {
    margin-top: 0;
  }

  .adminButton {
    align-self: auto;
  }
}
</style>

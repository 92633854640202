// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".attach-button__attach-cB\\+qS{display:inline-flex;flex-shrink:0;align-items:center;justify-content:center;min-width:32px;min-height:32px;padding:2px;font-size:24px;cursor:pointer;border:2px solid transparent;border-radius:6px;transition:background-color var(--default-transition)}.attach-button__attach-cB\\+qS:hover{background-color:var(--grey-200)}.attach-button__attach-cB\\+qS:active{background-color:var(--grey-300)}.attach-button__inputFile-F-ptY{position:absolute;width:1px;height:1px;padding:0;margin:-1px;overflow:hidden;clip:rect(0 0 0 0);border:0}.attach-button__menuItems-NYtWp{--button-justify-content:flex-start;--button-align-items:center;display:flex;flex-direction:column}.attach-button__menuItems-NYtWp .attach-button__option-jdA\\+Y{justify-content:flex-start;width:156px}.attach-button__voiceRecord-K24o8{position:absolute;top:-8px;right:-8px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"attach": "attach-button__attach-cB+qS",
	"inputFile": "attach-button__inputFile-F-ptY",
	"menuItems": "attach-button__menuItems-NYtWp",
	"option": "attach-button__option-jdA+Y",
	"voiceRecord": "attach-button__voiceRecord-K24o8"
};
module.exports = ___CSS_LOADER_EXPORT___;

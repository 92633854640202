import Vue from 'vue';
import Router from 'vue-router';
import routes from './routes';
import { addRoutesTrailingSlashes } from './utils/add-routes-trailing-slashes';

Vue.use(Router);

export const routerConfig = {
  mode: 'history',
  routes: addRoutesTrailingSlashes(routes),
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve({
            selector: to.hash,
            behavior: 'smooth',
          });
        }, 1000);
      });
    }
    if (savedPosition) {
      return savedPosition;
    }
    return { x: 0, y: 0 };
  },
};

export function createRouter() {
  return new Router(routerConfig);
}

import { buildServerListModule } from '~/store-lib/server-list';
import { ACTION_TYPE } from '~/constants/motivation';
import { getActions } from '~/api/motivation';
import { handleError } from '~/services/error-handling/error-handling';

const storeModule = buildServerListModule(getActions, {
  itemsFieldName: 'actions',
  fetchMethodName: 'fetchActions',
  fetchOnlyOnce: true,
  responseItemsFieldName: false,
});

const { state, mutations, actions } = storeModule;

const getters = {
  ...storeModule.getters,
  actionByType: (state) => (type) => {
    if (!ACTION_TYPE[type])
      handleError(new Error(`Unknown motivation action "${type}"`));
    return state.actions.find((action) => action.actionType === type);
  },
};

export { state, mutations, getters, actions };

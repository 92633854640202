<script>
import { TOOLTIPS } from '~/constants/tooltips';
import AttentionTooltip from '~/components/attention-tooltip.vue';
import StreaksCalendarMonth from '~/components/streaks/streaks-calendar/streaks-calendar-month.vue';
import StreaksFreezeCounter from '~/components/streaks/streaks-freeze-counter.vue';
import StreaksStatistics from '~/components/streaks/streaks-statistics/streaks-statistics.vue';

export const messages = {
  ru: {
    tooltipText:
      '<p>здесь ты можешь отслеживать свой стрик<br>на календаре.</p><div style="height: 6px;"></div><p>занимайся ежедневно, чтобы стрик увеличивался. если  пропустишь день занятий — стрик обнулится</p>',
    tooltipCloseButtonText: 'понятно',
  },
};

export default {
  TOOLTIPS,
  i18n: {
    messages,
  },
  components: {
    AttentionTooltip,
    StreaksStatistics,
    StreaksCalendarMonth,
    StreaksFreezeCounter,
  },
  mounted() {
    this.$tooltips.show(TOOLTIPS.streaksCalendarMobile);
  },
};
</script>

<template>
  <div :class="$style.root">
    <AttentionTooltip
      :popper-class="$style.attentionTooltipPopper"
      :tooltip-key="$options.TOOLTIPS.streaksCalendarMobile"
      :close-button-text="$t('tooltipCloseButtonText')"
      :text="$t('tooltipText')"
      disabled-auto-scroll
      placement="bottom"
      max-width="280px"
    >
      <StreaksStatistics theme="modal" hide-mini-calendar />
    </AttentionTooltip>
    <div :class="$style.calendarWrapper">
      <StreaksCalendarMonth />
      <div :class="$style.calendarFooter">
        <StreaksFreezeCounter />
      </div>
    </div>
  </div>
</template>

<style lang="postcss" module>
.root {
  --calendar-padding-x: 16px;

  display: flex;
  flex-direction: column;
}

.attentionTooltipPopper {
  top: -20px;
  z-index: var(--base-tooltip-z-index);
}

.calendarWrapper {
  margin: 0 var(--calendar-padding-x) 32px;
  padding: 24px 16px 4px;
  border: solid 2px var(--grey-300);
  border-radius: 8px;
}

.calendarFooter {
  position: relative;
  left: calc(-1 * var(--calendar-padding-x));
  display: flex;
  justify-content: center;
  align-items: center;
  width: calc(100% + 2 * var(--calendar-padding-x));
  height: 56px;
  border-top: solid 2px var(--grey-200);
}
</style>

// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".motivation-info__root-oqaE4{display:flex;grid-gap:0 12px;gap:0 12px;align-items:center;padding:6px 14px 6px 6px;padding:var(--motivation-info-padding,6px 14px 6px 6px)}.motivation-info__imgWrapper-4Afv0{flex-direction:column}.motivation-info__imgBox-4YVKX,.motivation-info__imgWrapper-4Afv0{display:flex;flex-shrink:0;align-items:center}.motivation-info__imgBox-4YVKX{justify-content:center;width:112px;width:var(--motivation-image-width,112px);height:80px;height:var(--motivation-image-height,80px)}.motivation-info__imgBox-4YVKX img{width:100%;height:100%;-o-object-fit:cover;object-fit:cover}.motivation-info__content-6xQLy{display:flex;flex-direction:column;grid-gap:4px 0;gap:4px 0}.motivation-info__title-zmZsX{font-size:16px;font-size:var(--motivation-title-font-size,16px);line-height:20px;line-height:var(--motivation-title-line-height,20px)}.motivation-info__description-ZO5CY{font-size:14px;line-height:18px;color:var(--grey-600)}.motivation-info__description-ZO5CY a{color:var(--system-500)}.motivation-info__description-ZO5CY span{grid-gap:2px;gap:2px;color:var(--success-600)}.motivation-info__description-ZO5CY span,.motivation-info__level-pac5Q{display:inline-flex;align-items:center;white-space:nowrap}.motivation-info__level-pac5Q{justify-content:center;margin-top:-12px;padding:0 6px;color:var(--primary-500);text-align:center;font-size:12px;line-height:18px;border-radius:11px;border:2px solid var(--primary-100);background-color:var(--warning-500)}@media (max-width:1023px){.motivation-info__root-oqaE4{padding:14px 14px 14px 6px;padding:var(--motivation-info-padding,14px 14px 14px 6px)}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "motivation-info__root-oqaE4",
	"imgWrapper": "motivation-info__imgWrapper-4Afv0",
	"imgBox": "motivation-info__imgBox-4YVKX",
	"content": "motivation-info__content-6xQLy",
	"title": "motivation-info__title-zmZsX",
	"description": "motivation-info__description-ZO5CY",
	"level": "motivation-info__level-pac5Q"
};
module.exports = ___CSS_LOADER_EXPORT___;

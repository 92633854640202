// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".left-right-pagination__tooltip-Aj0A-,.left-right-pagination__tooltip-Aj0A->*{height:100%}.left-right-pagination__root-j8n6j{display:flex;grid-gap:0;gap:0;height:inherit}.left-right-pagination__root_joined-WBl8c .left-right-pagination__button_left-6Wh5H{border-right:none;border-top-right-radius:0;border-bottom-right-radius:0}.left-right-pagination__root_joined-WBl8c .left-right-pagination__button_right-YEauP{border-top-left-radius:0;border-bottom-left-radius:0}.left-right-pagination__root_joined-WBl8c.left-right-pagination__root_hasContent-V1SjH .left-right-pagination__button_right-YEauP{border-left:none}@media (max-width:1023px){.left-right-pagination__root-j8n6j{grid-gap:0;gap:0}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tooltip": "left-right-pagination__tooltip-Aj0A-",
	"root": "left-right-pagination__root-j8n6j",
	"root_joined": "left-right-pagination__root_joined-WBl8c",
	"button_left": "left-right-pagination__button_left-6Wh5H",
	"button_right": "left-right-pagination__button_right-YEauP",
	"root_hasContent": "left-right-pagination__root_hasContent-V1SjH"
};
module.exports = ___CSS_LOADER_EXPORT___;

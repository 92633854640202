<script>
export default {
  props: {
    value: {
      type: [Boolean, String],
      default: '',
    },
    radioName: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    selected: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: '',
    },
  },
  computed: {
    classes() {
      return {
        [this.$style.root]: true,
        [this.$style._small]: this.small,
        [this.$style._selected]: this.selected,
        [this.$style._disabled]: this.disabled,
        [this.$style[`_${this.type}`]]: this.type !== '',
      };
    },
  },
  methods: {
    clickRadiobutton() {
      if (!this.disabled && this.type === '') {
        this.$parent.$emit('change', this.value);
      }
    },
  },
};
</script>

<template>
  <label :class="classes">
    <input
      type="radio"
      :name="radioName"
      :value="value"
      :checked="selected"
      class="visually-hidden"
      @change="clickRadiobutton"
    />
    <span :class="$style.dot"></span>
    <span v-if="label" :class="$style.label">{{ label }}</span>
  </label>
</template>

<style lang="postcss" module>
.dot {
  position: relative;
  flex-shrink: 0;
  width: 20px;
  height: 20px;
  background-color: var(--primary-100);
  border: 2px solid var(--primary-500);
  border-radius: 50%;
  transition: background-color var(--default-transition);

  &::before {
    position: absolute;
    top: 50%;
    left: 50%;
    display: block;
    width: 10px;
    height: 10px;
    content: none;
    background-color: var(--primary-500);
    border-radius: 50%;
    transform: translate(-50%, -50%);
  }

  &:not(:last-child) {
    margin-right: 12px;
  }
}

.label {
  margin-top: -2px;
  font-size: 16px;
  line-height: 20px;
}

._small {
  & .dot {
    width: 16px;
    height: 16px;

    &::before {
      width: 8px;
      height: 8px;
    }
  }

  & .label {
    margin-top: -3px;
  }
}

._disabled {
  pointer-events: none;

  & .dot {
    border-color: var(--grey-600);

    &::before {
      background-color: var(--grey-600);
    }
  }

  & .label {
    color: var(--grey-600);
  }
}

._warning {
  pointer-events: none;

  & .dot {
    border-color: var(--danger-500);

    &::before {
      background-color: var(--danger-500);
    }
  }

  & .label {
    color: var(--danger-500);
  }
}

._success {
  pointer-events: none;

  & .dot {
    border-color: var(--success-500);

    &::before {
      background-color: var(--success-500);
    }
  }

  & .label {
    color: var(--success-500);
  }
}

._selected {
  cursor: default;

  & .dot {
    &::before {
      content: '';
    }
  }
}

.root {
  display: inline-flex;
  align-items: var(--radiobutton-align-items, flex-start);
  justify-content: flex-start;
  cursor: pointer;

  &:hover {
    & .dot {
      background-color: var(--hover-gray-color);
    }
  }

  &._selected {
    cursor: default;

    &:hover {
      & .dot {
        background-color: var(--primary-100);
      }
    }
  }
}

@media (--x-small-vp) {
  .root {
    &:hover {
      & .dot {
        background-color: var(--primary-100);
      }
    }
  }
}
</style>

// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".copy-button__copiedText-znhU0{position:absolute;display:none}.copy-button__copiedText-znhU0 svg{margin-right:5px;font-size:24px}.copy-button__button-1LyJX{width:100%}.copy-button__root-dskW1{display:flex;align-items:center;justify-content:center}.copy-button__root-dskW1.copy-button___copied-hv1rb .copy-button__copiedText-znhU0{display:block}.copy-button__root-dskW1.copy-button___copied-hv1rb .copy-button__button-1LyJX{opacity:0}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"copiedText": "copy-button__copiedText-znhU0",
	"button": "copy-button__button-1LyJX",
	"root": "copy-button__root-dskW1",
	"_copied": "copy-button___copied-hv1rb"
};
module.exports = ___CSS_LOADER_EXPORT___;

// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".streaks-counter__root-NxvWB{--text-color:var(--grey-500);display:flex;flex-direction:column;align-items:center;justify-content:center}.streaks-counter__iconWrapper-\\+CneM{position:relative}.streaks-counter___s-AVhRw{--icon-size:28px;--count-size:15px}.streaks-counter___m-j4iz5,.streaks-counter___s-AVhRw{--text-stroke-width:4px}.streaks-counter___m-j4iz5{--icon-size:32px;--count-size:16px}.streaks-counter___l-rhnE5{--icon-size:72px;--count-size:44px;--label-size:14px;--text-stroke-width:6px}.streaks-counter___l-rhnE5,.streaks-counter___xl-WdaqJ{--label-gap:20px}.streaks-counter___xl-WdaqJ{--icon-size:88px;--count-size:48px;--label-size:18px;--text-stroke-width:8px}.streaks-counter___active-a3pHy{--text-color:var(--streaks-active-color)}.streaks-counter___active-a3pHy.streaks-counter___paired-Oaqqe{--text-color:var(--paired-streaks-active-color)}.streaks-counter__icon-Bh0tu{width:var(--icon-size);height:var(--icon-size)}.streaks-counter__countWrapper-apVlv{position:absolute;bottom:32%;left:50%;font-size:var(--count-size);line-height:1;transform:translate(-50%,100%)}.streaks-counter__label--9XlZ{margin-top:var(--label-gap);font-size:var(--label-size);line-height:1.2;color:var(--text-color)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "streaks-counter__root-NxvWB",
	"iconWrapper": "streaks-counter__iconWrapper-+CneM",
	"_s": "streaks-counter___s-AVhRw",
	"_m": "streaks-counter___m-j4iz5",
	"_l": "streaks-counter___l-rhnE5",
	"_xl": "streaks-counter___xl-WdaqJ",
	"_active": "streaks-counter___active-a3pHy",
	"_paired": "streaks-counter___paired-Oaqqe",
	"icon": "streaks-counter__icon-Bh0tu",
	"countWrapper": "streaks-counter__countWrapper-apVlv",
	"label": "streaks-counter__label--9XlZ"
};
module.exports = ___CSS_LOADER_EXPORT___;

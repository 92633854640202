<script>
import { ROUTE_META_KEYS } from '~/routing/routes';
import { createNamespacedHelpers } from 'vuex';
import { MODALS } from '~/constants/modals';
import { ONBOARDING_URL_PARAM } from '~/constants/onboarding';
import { closeCommonOnboarding } from '~/api/onboarding';
import { carrotquestEmulateButtonClick } from '~/services/carrotquest';
import { handleError } from '~/services/error-handling/error-handling';
import CommonOnboardingModal from '~/components/common-onboarding/common-onboarding-modal.vue';

const { mapState, mapGetters, mapActions } =
  createNamespacedHelpers('common-onboarding');
const { mapState: mapModalsState, mapActions: mapModalsActions } =
  createNamespacedHelpers('modals');
const { mapActions: mapStudentGreetingSurveyModalActions } =
  createNamespacedHelpers('student-greeting-survey-modal');

const supportChatOpenTimeout = 1000;

export default {
  components: {
    CommonOnboardingModal,
  },
  data() {
    return {
      initialStepKey: this.$route.query[ONBOARDING_URL_PARAM],
      studentGreetingSurveyHasBeenTriggered: false,
    };
  },
  computed: {
    ...mapState(['isInitialized', 'couldBeShown', 'needSupportChatAfterClose']),
    ...mapGetters(['currentStepKey', 'isAllStepsCompleted']),
    ...mapModalsState(['visibleModalName']),
    isModalVisible() {
      return (
        this.isInitialized && this.visibleModalName === MODALS.commonOnboarding
      );
    },
  },
  watch: {
    isModalVisible: {
      handler(value) {
        if (value) {
          this.addStepParamToUrl(this.currentStepKey);
        }
      },
    },
  },
  async mounted() {
    await this.initializeOnboarding({
      initialStepKey: this.initialStepKey,
    });
    this.handleAutoOpen();
  },
  methods: {
    ...mapActions([
      'initializeOnboarding',
      'resetOnboarding',
      'completeOnboarding',
      'fetchIsCouldBeShown',
      'resolveCurrentStepGlobalIndex',
      'ignoreSupportChatAfterClose',
    ]),
    ...mapModalsActions([
      'closeModal',
      'setVisibleModalNameIfPriorityIsHigher',
    ]),
    ...mapStudentGreetingSurveyModalActions([
      'openStudentGreetingSurveyModalIfShouldBeVisible',
    ]),
    addStepParamToUrl(stepKey) {
      if (this.$route.query[ONBOARDING_URL_PARAM] === stepKey) return;
      this.$router.replace({
        query: {
          ...this.$route.query,
          [ONBOARDING_URL_PARAM]: stepKey,
        },
      });
    },
    removeStepParamFromUrl() {
      this.$router.replace({
        query: {
          ...this.$route.query,
          [ONBOARDING_URL_PARAM]: undefined,
        },
      });
    },
    open() {
      this.setVisibleModalNameIfPriorityIsHigher(MODALS.commonOnboarding);
    },
    close() {
      this.removeStepParamFromUrl();
      this.closeModal();
      this.resetOnboarding();
      this.handleOpenStudentGreetingSurvey();
      this.handleOpenSupportChat();
    },
    onClose() {
      try {
        closeCommonOnboarding();
      } catch (error) {
        handleError(error);
      } finally {
        this.close();
      }
    },
    async onComplete() {
      await this.completeOnboarding();
      this.close();
    },
    onStepChange(stepKey) {
      this.addStepParamToUrl(stepKey);
    },
    handleAutoOpen() {
      const isRouteWithOnboardingAutoShow =
        this.$route.meta?.[ROUTE_META_KEYS.useCommonOnboarding];
      const autoShow =
        !this.isAllStepsCompleted &&
        isRouteWithOnboardingAutoShow &&
        (this.couldBeShown || this.initialStepKey);
      if (autoShow) {
        this.open();
      } else if (isRouteWithOnboardingAutoShow) {
        this.openStudentGreetingSurveyModalIfShouldBeVisible();
      }
    },
    handleOpenStudentGreetingSurvey() {
      if (this.studentGreetingSurveyHasBeenTriggered) return;
      this.openStudentGreetingSurveyModalIfShouldBeVisible();
      this.studentGreetingSurveyHasBeenTriggered = true;
    },
    handleOpenSupportChat() {
      setTimeout(() => {
        if (this.needSupportChatAfterClose) {
          carrotquestEmulateButtonClick('vk-notifications-help');
          this.ignoreSupportChatAfterClose();
        }
      }, supportChatOpenTimeout);
    },
  },
};
</script>

<template>
  <transition name="modal">
    <CommonOnboardingModal
      v-if="isModalVisible"
      @close="onClose"
      @complete="onComplete"
      @step-change="onStepChange"
    />
  </transition>
</template>

const DEFAULT_VK_NOTIFICATIONS_COMMUNITY_ID = '204371224';
const VK_NOTIFICATIONS_EVENT_NAME_ALLOWED =
  'widgets.allowMessagesFromCommunity.allowed';
const VK_NOTIFICATIONS_EVENT_NAME_DENIED =
  'widgets.allowMessagesFromCommunity.denied';

module.exports = {
  DEFAULT_VK_NOTIFICATIONS_COMMUNITY_ID,
  VK_NOTIFICATIONS_EVENT_NAME_ALLOWED,
  VK_NOTIFICATIONS_EVENT_NAME_DENIED,
};

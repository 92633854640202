<script>
import { WButton, WIconButton, WOverlay } from '@WebiumTeam/ui_kit';
import { TABLET_MAX_WIDTH } from '~/constants/adaptive';
import { disableBodyScroll, enableBodyScroll } from '~/services/body-scroll';

export default {
  components: {
    WOverlay,
    WButton,
    WIconButton,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    fullscreenBreakpoint: {
      type: Number,
      default: TABLET_MAX_WIDTH,
    },
    mobileBottom: {
      type: Boolean,
      default: false,
    },
    closeDescription: {
      type: String,
      default: '',
    },
    stretch: {
      type: Boolean,
      default: false,
    },
    contentStyle: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    dark: {
      type: Boolean,
      default: false,
    },
    hideClose: {
      type: Boolean,
      default: false,
    },
    hideHeader: {
      type: Boolean,
      default: false,
    },
    hideHeaderBorder: {
      type: Boolean,
      default: false,
    },
    keepHeaderHeight: {
      type: Boolean,
      default: false,
    },
    externalButtonText: {
      type: String,
      default: '',
    },
    externalButtonPosition: {
      type: String,
      default: 'top',
      validator: (value) => ['top', 'bottom'].includes(value),
    },
  },
  computed: {
    isFullscreen() {
      return this.$device.windowWidth <= this.fullscreenBreakpoint;
    },
    rootClasses() {
      return {
        [this.$style.root]: true,
        [this.$style._fullscreen]: !!this.isFullscreen && !this.mobileBottom,
        [this.$style._mobileBottom]: this.mobileBottom,
        [this.$style._stretch]: this.stretch,
        [this.$style._dark]: this.dark,
        [this.$style._noHeader]: this.hideHeader,
        [this.$style._keepHeaderHeight]: !!this.keepHeaderHeight,
      };
    },
    headerClasses() {
      return {
        [this.$style.header]: true,
        [this.$style._noTitle]: !!this.title === false && !this.$slots.header,
        [this.$style._noHeaderBorder]: this.hideHeaderBorder,
      };
    },
    externalButtonClasses() {
      return {
        [this.$style.externalButton]: true,
        [this.$style[`_${this.externalButtonPosition}`]]: true,
      };
    },
  },
  mounted() {
    disableBodyScroll();
  },
  destroyed() {
    enableBodyScroll();
  },
};
</script>

<template>
  <WOverlay :fullscreen-content="isFullscreen" @close="$emit('close')">
    <div :class="rootClasses" data-testid="root">
      <WButton
        v-if="externalButtonText"
        :class="externalButtonClasses"
        theme="transparent"
        :size="$device.isSmallScreen ? 's' : 'm'"
        @click="$emit('external-button-click')"
      >
        {{ externalButtonText }}
      </WButton>
      <div v-if="!hideHeader" :class="headerClasses">
        <p v-if="title" :class="$style.title">{{ title }}</p>
        <slot name="header" />
        <WIconButton
          :class="$style.closeHeaderButton"
          theme="emptyLight"
          icon-name="close-L"
          data-testid="close-header-button"
          @click="$emit('close')"
        >
          <template v-if="closeDescription" slot="beforeIcon">
            <div :class="$style.closeDescription">
              {{ closeDescription }}
            </div>
          </template>
        </WIconButton>
      </div>
      <div :class="$style.content" :style="contentStyle">
        <slot :small="isFullscreen" />
      </div>
      <button
        v-if="!hideClose"
        :class="$style.closeButton"
        data-testid="close-button"
        @click="$emit('close')"
      >
        <WIcon v-if="mobileBottom" :class="$style.closeIcon" name="close-L" />
        <WIcon v-else :class="$style.closeIcon" name="close" />
        <div v-if="closeDescription" :class="$style.closeDescription">
          {{ closeDescription }}
        </div>
      </button>
    </div>
  </WOverlay>
</template>

<style lang="postcss" module>
.root {
  --_default-width: 630px;
  --_default-modal-display: block;
  --_used-width: var(--modal-max-width, var(--_default-width));
  --_default-border-radius: 8px;
  --_used-border-radius: var(
    --modal-border-radius,
    var(--_default-border-radius)
  );
  --_default-background-color: var(--content-bg-main-color);
  --_used-background-color: var(
    --modal-background-color,
    var(--_default-background-color)
  );

  position: relative;
  display: var(--modal-display, var(--_default-modal-display));
  flex-direction: column;
  width: var(--_used-width);
  max-width: 100%;
  background-color: var(--_used-background-color);
  border-radius: var(--_used-border-radius);
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 24px;
  padding-bottom: 14px;
  background-color: var(--title-bg-main-color);
  border-bottom: 2px solid var(--grey-400);
  border-radius: var(--_used-border-radius) var(--_used-border-radius) 0 0;

  &._noTitle {
    display: none;
  }

  & .closeDescription {
    display: none;
    margin-right: 6px;
    font-size: 20px;
    color: var(--grey-600);
  }
}

.content {
  max-height: 100%;
  overflow: auto;
}

.title {
  font-size: 20px;
  line-height: 24px;
  color: var(--text-main-color);
  text-transform: var(--base-modal-wrapper-text-transform, lowercase);
  letter-spacing: 0.02em;
}

.closeButton {
  position: absolute;
  top: 0;
  left: calc(100% + 25px);
  z-index: 1;
  display: flex;
  align-items: center;
  color: var(--grey-600);
  outline: none;
  transition: color var(--default-transition);

  & .closeDescription {
    position: relative;
    bottom: 2px;
    margin-left: 13px;
    font-size: 20px;
  }

  &:hover,
  &:focus {
    color: var(--grey-400);
  }

  &:active {
    color: var(--primary-100);
  }
}

.closeHeaderButton {
  display: none;
}

.closeIcon {
  font-size: 22px;
}

.root._fullscreen {
  height: 100%;
  border-radius: 0;

  & .closeButton {
    display: none;
  }

  & .closeHeaderButton {
    display: block;
    margin-left: auto;
  }

  & .header {
    background-color: var(--_used-background-color);
    border-bottom: 2px solid var(--grey-200);
    border-radius: 0;

    &._noTitle {
      display: flex;
      border-bottom: none;
    }

    &._noHeaderBorder {
      border-bottom: none;
    }
  }

  & .content {
    max-height: calc(100% - 60px);
  }

  &._noHeader {
    & .content {
      max-height: 100%;
    }
  }
}

.root._dark {
  --modal-background-color: var(--primary-500);

  & svg {
    color: var(--primary-100);
  }
}

.root._keepHeaderHeight {
  padding-top: var(--header-height);
}

.externalButton {
  position: absolute;
  right: 0;

  &._top {
    top: -49px;
    right: 13px;
  }

  &._bottom {
    bottom: -49px;
  }
}

@media (--small-vp) {
  .root {
    --header-height: 62px;
  }

  .header {
    min-height: var(--header-height);
    padding: 8px;
    padding-left: 20px;

    & .closeDescription {
      display: inline-block;
    }
  }

  .closeButton {
    top: 18px;
    right: 24px;
    left: auto;
    color: var(--primary-500);
  }

  .root._mobileBottom {
    display: flex;
    flex-direction: column;
    align-self: flex-end;
    overflow: hidden;
    width: 100%;
    max-height: calc(100% - var(--modal-mobile-top-space, 60px));
    border-radius: var(--border-radius) var(--border-radius) 0 0;

    & .content {
      height: 100%;
      max-height: calc(100% - var(--modal-mobile-top-space, 60px));
      overflow: auto;
    }

    & .header {
      background-color: var(--grey-200);
    }

    & .closeButton {
      top: 18px;
      right: 18px;
    }
  }

  .root._stretch {
    & .content {
      height: calc(100vh - var(--modal-mobile-top-space, 60px));
    }
  }
}
</style>

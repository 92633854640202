// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("./thanks-bg-toast.svg");
var ___CSS_LOADER_URL_IMPORT_1___ = require("./thanks-bg-drawer.svg");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".nps-manager__npsFormToastMessage-t-e7y{width:-moz-fit-content;width:fit-content}.nps-manager__thanksToastMessage-eTIkf{width:456px}.nps-manager__thanksToastContent-w6tkL{display:flex;flex-direction:column;grid-gap:4px;gap:4px;padding:24px 32px;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-repeat:no-repeat;background-position:100%}.nps-manager__thanksTitle-dJCea{font-size:28px;line-height:34px}.nps-manager__thanksText-Wgenv{font-size:18px;line-height:22px}.nps-manager__thanksDrawerContent-E\\+\\+Wa{padding:20px 0 32px;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");background-repeat:no-repeat;background-position:100%}@media (max-width:1023px){.nps-manager__thanksText-Wgenv{font-size:16px;line-height:20px}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"npsFormToastMessage": "nps-manager__npsFormToastMessage-t-e7y",
	"thanksToastMessage": "nps-manager__thanksToastMessage-eTIkf",
	"thanksToastContent": "nps-manager__thanksToastContent-w6tkL",
	"thanksTitle": "nps-manager__thanksTitle-dJCea",
	"thanksText": "nps-manager__thanksText-Wgenv",
	"thanksDrawerContent": "nps-manager__thanksDrawerContent-E++Wa"
};
module.exports = ___CSS_LOADER_EXPORT___;

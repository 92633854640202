import { buildServerListWithLoadMoreModule } from '~/store-lib/server-list-with-load-more';
import { EVENT_BUS_KEYS } from '~/constants/event-bus';
import {
  completeAllWebinars,
  completeWebinar,
  getWebinars,
  uncompleteWebinar,
} from '~/api/webinars';
import { eventBus } from '~/services/event-bus';

const webinarsList = buildServerListWithLoadMoreModule(getWebinars);

export const {
  state,
  mutations: listMutations,
  getters: listGetters,
  actions: listActions,
} = webinarsList;

export const getters = {
  ...listGetters,
  incompletedCount(state) {
    return state.items.filter((item) => item.isCompleted === false).length;
  },
};

export const mutations = {
  ...listMutations,
  COMPLETE_ALL(state) {
    state.items = state.items.map((item) => ({ ...item, isCompleted: true }));
  },
  COMPLETE(state, webinarId) {
    const index = state.items.findIndex((item) => item.id === webinarId);
    if (index !== -1) {
      state.items[index].isCompleted = true;
    }
  },
  UNCOMPLETE(state, webinarId) {
    const index = state.items.findIndex((item) => item.id === webinarId);
    if (index !== -1) {
      state.items[index].isCompleted = false;
    }
  },
};

export const actions = {
  ...listActions,
  async completeAll({ commit }, courseId) {
    await completeAllWebinars({ courseId });
    commit('COMPLETE_ALL');
  },
  async complete({ commit }, { courseId, webinarId }) {
    await completeWebinar({ courseId, webinarId });
    commit('COMPLETE', webinarId);
    eventBus.$emit(EVENT_BUS_KEYS.webinarWatched);
  },
  async uncomplete({ commit }, { courseId, webinarId }) {
    await uncompleteWebinar({ courseId, webinarId });
    commit('UNCOMPLETE', webinarId);
  },
};

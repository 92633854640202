<script>
import { WButton, WDrawer } from '@WebiumTeam/ui_kit';
import kebabCase from 'lodash/kebabCase';
import { createNamespacedHelpers } from 'vuex';
import { ONBOARDING_STEPS } from '~/constants/onboarding';
import BaseModalWrapper from '~/components/_base/_base-modal-wrapper.vue';
import BaseSegmentedProgress from '~/components/_base/_base-segmented-progress.vue';

const { mapState, mapGetters, mapActions } =
  createNamespacedHelpers('common-onboarding');

const cachedStepComponents = {};

export const messages = {
  ru: {
    skip: 'пропустить',
    progressLabels: {
      [ONBOARDING_STEPS.vkConnect]: 'подключи уведомления',
      [ONBOARDING_STEPS.notifications]: 'подключи уведомления',
      [ONBOARDING_STEPS.survey]: 'выбери стиль обучения',
    },
  },
};

export default {
  i18n: {
    messages,
  },
  components: {
    BaseSegmentedProgress,
    WButton,
    WDrawer,
    BaseModalWrapper,
  },
  computed: {
    ...mapState(['currentStepGlobalIndex']),
    ...mapGetters([
      'onboardingSegments',
      'currentStepIndex',
      'currentStepKey',
      'nextStepKey',
      'hasNextAvailableStep',
      'isAllStepsCompleted',
      'skippedSegmentIndexes',
    ]),
    wrapperComponent() {
      return this.$device.isSmallScreen ? WDrawer : BaseModalWrapper;
    },
    wrapperProps() {
      return this.$device.isSmallScreen
        ? {
            visible: true,
            rounded: true,
            hideHeader: true,
            side: 'bottom',
          }
        : {
            hideClose: true,
            hideHeader: true,
          };
    },
    progressSegments() {
      return this.onboardingSegments.map((segment) => ({
        label: this.$t(`progressLabels.${segment[0].key}`),
        value: segment.length,
      }));
    },
    currentStepComponent() {
      if (cachedStepComponents[this.currentStepKey])
        return cachedStepComponents[this.currentStepKey];
      const componentName = `step-${kebabCase(this.currentStepKey)}`;
      cachedStepComponents[this.currentStepKey] = () =>
        import(`./steps/${componentName}.vue`);
      return cachedStepComponents[this.currentStepKey];
    },
  },
  methods: {
    ...mapActions(['goToNextStep', 'goToPreviousStep']),
    onNextStep() {
      if (this.isAllStepsCompleted) {
        this.$emit('complete');
      } else if (this.hasNextAvailableStep) {
        this.goToNextStep();
        this.$emit('step-change', this.currentStepKey);
      } else {
        this.$emit('close');
      }
    },
    onPreviousStep() {
      this.goToPreviousStep();
      this.$emit('step-change', this.currentStepKey);
    },
  },
};
</script>

<template>
  <component :is="wrapperComponent" :class="$style.root" v-bind="wrapperProps">
    <div :class="$style.inner">
      <div :class="$style.header">
        <BaseSegmentedProgress
          :class="$style.segmentedProgress"
          :skipped-segments="skippedSegmentIndexes"
          :segments="progressSegments"
          :value="currentStepGlobalIndex"
          :size="$device.isSmallScreen ? 's' : 'm'"
          highlight-current-segment
        />
      </div>
      <div :class="$style.body">
        <component
          :is="currentStepComponent"
          v-if="currentStepKey"
          :has-next-available-step="hasNextAvailableStep"
          :current-step-index="currentStepIndex"
          :next-step-key="nextStepKey"
          @next-step="onNextStep"
          @previous-step="onPreviousStep"
        />
      </div>
    </div>
    <WButton
      :class="$style.skipButton"
      size="xs"
      theme="transparent"
      data-testid="do-later-button"
      @click="onNextStep"
    >
      {{ $t('skip') }}
    </WButton>
  </component>
</template>

<style lang="postcss" module>
.root {
  --modal-max-width: 700px;
  --w-overlay-content-margin: 80px 0 0;
}

.inner {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.header {
  padding: 24px 40px 20px;
  border-bottom: solid 2px var(--grey-200);
}

.body {
  padding: 24px 40px 32px;
}

.skipButton {
  position: absolute;
  top: calc(100% + 16px);
  right: 0;
}

.segmentedProgress {
  --segments-gap: 12px;
  --label-font-size: 16px;
  --label-line-height: 20px;

  height: 56px;
  padding: 8px 0 12px;
}

@media (--small-vp) {
  .root {
    --w-drawer-height: calc(100% - 62px);
  }

  .segmentedProgress {
    --segments-gap: 16px;
    --label-font-size: 14px;
    --label-line-height: 18px;
  }

  .header {
    padding: 12px 20px 8px;
  }

  .body {
    padding: 20px 20px 12px;
  }

  .skipButton {
    position: fixed;
    top: 13px;
    right: 13px;
  }
}
</style>

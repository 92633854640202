import { EVENT_BUS_KEYS } from '~/constants/event-bus';
import { getLastInfo, markGiftsAsViewed } from '~/api/motivation';
import { onClientAuthLogin } from '~/services/auth-functions';
import { handleError } from '~/services/error-handling/error-handling';
import { eventBus } from '~/services/event-bus';

export const state = () => ({
  lastFetchedLevel: undefined,
  lastGotXp: 0,
  sumGotXp: 0,
  hasNewGifts: false,
  hasNewLevel: false,
  achievementsReceivedNow: [],
  hasNewAchievement: false,
});

const levelsStoreName = 'motivation-levels';

const eventBusKeysForFetch = [
  EVENT_BUS_KEYS.mentorRated,
  EVENT_BUS_KEYS.lessonRated,
  EVENT_BUS_KEYS.webinarWatched,
  EVENT_BUS_KEYS.homeworkCompleted,
  EVENT_BUS_KEYS.commonOnboardingFinished,
];

export const getters = {
  initialXp(state, getters, rootState) {
    return rootState.auth?.user?.motivationXp || 0;
  },
  currentXp(state, getters) {
    return getters.initialXp + state.sumGotXp;
  },
  currentLevelIndex(state, getters, rootState) {
    const { levels } = rootState[levelsStoreName];
    const nextLevelIndex = (levels || []).findIndex(
      (level) => Number(level.xpRequired) > getters.currentXp
    );
    if (levels?.length && nextLevelIndex === -1) return levels.length - 1;
    return nextLevelIndex !== -1 ? nextLevelIndex - 1 : -1;
  },
  currentLevel(state, getters, rootState) {
    const { levels } = rootState[levelsStoreName];
    if (getters.currentLevelIndex === -1) return undefined;
    return levels?.[getters.currentLevelIndex];
  },
  nextLevel(state, getters, rootState) {
    const { levels } = rootState[levelsStoreName];
    return levels?.[getters.currentLevelIndex + 1];
  },
  progressRange: (state, getters, rootState) => (index) => {
    const { levels } = rootState[levelsStoreName];
    if (!levels?.length) return [0, 0];

    const cardLevel = levels[index];
    const nextCardLevel = levels[index + 1];
    const previousCardLevel = levels[index - 1];

    const maxXp = nextCardLevel
      ? nextCardLevel.xpRequired - cardLevel.xpRequired
      : cardLevel.xpRequired - previousCardLevel.xpRequired;

    let currentXp;
    if (
      getters.currentXp >= cardLevel.xpRequired &&
      index !== getters.currentLevelIndex
    ) {
      currentXp = maxXp;
    } else if (
      index === levels.length - 1 &&
      getters.currentLevelIndex === index
    ) {
      currentXp = maxXp;
    } else {
      currentXp = Math.max(0, getters.currentXp - cardLevel.xpRequired);
    }

    return [currentXp, maxXp];
  },
  xpAmountByActionType:
    (state, getters, rootState, rootGetters) => (actionType) => {
      const actionByType = rootGetters['motivation-actions/actionByType'];
      const action = actionByType(actionType);
      return action?.xpAmount || 0;
    },
  isLuringDotVisible(state) {
    return state.hasNewGifts || state.hasNewAchievement;
  },
};

export const mutations = {
  SET_LAST_GOT_XP(state, xpAmount) {
    state.lastGotXp = xpAmount;
  },
  COUNT_SUM_GOT_XP(state, xpAmount) {
    state.sumGotXp += xpAmount;
  },
  SET_HAS_NEW_GIFTS(state, hasNewGifts) {
    state.hasNewGifts = !!hasNewGifts;
  },
  SET_HAS_NEW_ACHIEVEMENT(state, hasNewAchievement) {
    state.hasNewAchievement = !!hasNewAchievement;
  },
  SET_HAS_NEW_LEVEL(state, hasNewLevel) {
    state.hasNewLevel = !!hasNewLevel;
  },
  SET_LAST_FETCHED_LEVEL(state, userLevel) {
    state.lastFetchedLevel = userLevel;
  },
  SET_ACHIEVEMENTS_RECEIVED_NOW(state, achievementsReceivedNow) {
    state.achievementsReceivedNow = achievementsReceivedNow || [];
  },
};

export const actions = {
  markNewLevelAsViewed({ commit }) {
    commit('SET_HAS_NEW_LEVEL', false);
  },
  markNewGiftsAsViewed({ state, commit }) {
    if (!state.hasNewGifts) return;
    try {
      commit('SET_HAS_NEW_GIFTS', false);
      markGiftsAsViewed();
    } catch (error) {
      handleError(error);
    }
  },
  async fetchMotivationInfo(
    { commit, dispatch, rootState },
    { ignoreXpAmount = false } = {}
  ) {
    try {
      if (!rootState.auth.loggedIn) return;
      dispatch('motivation-actions/fetchActions', {}, { root: true });
      const {
        isLevelUpgraded,
        hasNewGift,
        xpAmount,
        userLevel,
        achievementsReceivedNow,
        hasNewAchievement,
      } = await getLastInfo();
      commit('SET_LAST_FETCHED_LEVEL', userLevel);
      commit('SET_HAS_NEW_LEVEL', isLevelUpgraded);
      commit('SET_HAS_NEW_GIFTS', hasNewGift);
      commit('SET_ACHIEVEMENTS_RECEIVED_NOW', achievementsReceivedNow);
      commit('SET_HAS_NEW_ACHIEVEMENT', hasNewAchievement);
      if (xpAmount && !ignoreXpAmount) {
        commit('SET_LAST_GOT_XP', xpAmount);
        commit('COUNT_SUM_GOT_XP', xpAmount);
      }
      if (hasNewGift) {
        dispatch('motivation-gifts/fetchGifts', {}, { root: true });
      }
    } catch (error) {
      handleError(error);
    }
  },
  markAchievementReceivedNowAsViewed({ state, commit }, achievementId) {
    if (state.achievementsReceivedNow.length === 0) return;
    try {
      const achievementsReceivedNow = state.achievementsReceivedNow.filter(
        (achievement) => achievement.id.toString() !== achievementId.toString()
      );
      commit('SET_ACHIEVEMENTS_RECEIVED_NOW', achievementsReceivedNow);
    } catch (error) {
      handleError(error);
    }
  },
};

export const motivationPlugin = (store) => {
  if (process.server) return;
  const fetchMotivationInfo = () =>
    store.dispatch('motivation/fetchMotivationInfo', {
      ignoreXpAmount: true,
    });
  window.onNuxtReady(fetchMotivationInfo);
  onClientAuthLogin(fetchMotivationInfo);
  eventBusKeysForFetch.forEach((eventKey) => {
    eventBus.$on(eventKey, () => {
      store.dispatch('motivation/fetchMotivationInfo');
    });
  });
};

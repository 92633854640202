// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".vk-connect-cta-button__root-hFGoP{position:relative;width:-moz-fit-content;width:fit-content}.vk-connect-cta-button__sticker-\\+LO\\+H{position:absolute;top:-10px;right:-16px;z-index:1;width:32px}.vk-connect-cta-button__sticker-\\+LO\\+H.vk-connect-cta-button___inverse-ad9iw{right:-20px}.vk-connect-cta-button__button-2e4gN{width:inherit;text-transform:none}.vk-connect-cta-button___medium-rdq7b .vk-connect-cta-button__sticker-\\+LO\\+H{top:-11px;right:-9px;width:42px}@media (max-width:1023px){.vk-connect-cta-button__sticker-\\+LO\\+H{top:-10px;right:-8px;width:38px}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "vk-connect-cta-button__root-hFGoP",
	"sticker": "vk-connect-cta-button__sticker-+LO+H",
	"_inverse": "vk-connect-cta-button___inverse-ad9iw",
	"button": "vk-connect-cta-button__button-2e4gN",
	"_medium": "vk-connect-cta-button___medium-rdq7b"
};
module.exports = ___CSS_LOADER_EXPORT___;

export const EVENT_BUS_KEYS = {
  webinarWatched: 'webinar-watched',
  homeworkCompleted: 'homework-completed',
  mentorRated: 'mentor-rated',
  lessonRated: 'lesson-rated',
  workoutTaskSolved: 'workout-task-solved',
  commonOnboardingFinished: 'common-onboarding-finished',
  serverDateUpdated: 'server-date-updated',
  buddyAdded: 'buddy-added',
  buddyRemoved: 'buddy-removed',
  prolongCoursesButtonClick: 'prolong-courses-button-click',
};

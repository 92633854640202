// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._base-luring-dot__root-DDl6f{width:16px;height:16px;background-color:var(--content-bg-main-color);border:6px solid var(--danger-500);border-radius:100%}._base-luring-dot___alternative-5Roj8{background-color:var(--danger-500);border:2px solid var(--content-bg-main-color)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "_base-luring-dot__root-DDl6f",
	"_alternative": "_base-luring-dot___alternative-5Roj8"
};
module.exports = ___CSS_LOADER_EXPORT___;

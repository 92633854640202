// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".month-calendar__root-990HV{--border-width:2px;--border-color:var(--grey-300);position:relative}.month-calendar__root-990HV .month-calendar__loading-cwH9I{border-radius:8px}.month-calendar__toolbar-bMBGS{display:flex;align-items:center;min-height:36px;margin-bottom:16px}.month-calendar__toolbar-bMBGS>*{height:inherit}.month-calendar__content-cTZJy{position:relative;padding:var(--border-width)}.month-calendar__monthSelector-burvA{max-width:168px}.month-calendar__dateSelector-QkNtD{margin:0 12px}.month-calendar__weekdays-gSB2X{margin-bottom:8px}.month-calendar__daysGrid-9MmE1{position:relative;display:grid;grid-template-columns:repeat(7,1fr);height:100%}.month-calendar__daysGridItem-eOt2\\+{min-width:0;margin-top:calc(var(--border-width)*-1);margin-left:calc(var(--border-width)*-1);border:var(--border-width) solid var(--border-color);transition:var(--default-transition)}.month-calendar__daysGridItem-eOt2\\+:first-of-type{border-top-left-radius:var(--standard-border-radius)}.month-calendar__daysGridItem-eOt2\\+:nth-of-type(7){border-top-right-radius:var(--standard-border-radius)}.month-calendar__daysGridItem-eOt2\\+:nth-last-of-type(7){border-bottom-left-radius:var(--standard-border-radius)}.month-calendar__daysGridItem-eOt2\\+:last-of-type{border-bottom-right-radius:var(--standard-border-radius)}@media (max-width:1023px){.month-calendar__dateSelector-QkNtD{margin-left:8px}.month-calendar__toolbar-bMBGS{height:auto}.month-calendar__weekdays-gSB2X{margin:0 -4px 8px}.month-calendar__daysGrid-9MmE1,.month-calendar__weekdays-gSB2X{width:calc(100% + 8px)}.month-calendar__daysGrid-9MmE1{margin:0 -4px}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "month-calendar__root-990HV",
	"loading": "month-calendar__loading-cwH9I",
	"toolbar": "month-calendar__toolbar-bMBGS",
	"content": "month-calendar__content-cTZJy",
	"monthSelector": "month-calendar__monthSelector-burvA",
	"dateSelector": "month-calendar__dateSelector-QkNtD",
	"weekdays": "month-calendar__weekdays-gSB2X",
	"daysGrid": "month-calendar__daysGrid-9MmE1",
	"daysGridItem": "month-calendar__daysGridItem-eOt2+"
};
module.exports = ___CSS_LOADER_EXPORT___;

<template>
  <ErrorDescription :code="404" :header="$t('header')" :message="$t('message')">
    <template #action>
      <WButton tag="NuxtLink" :to="{ name: 'index' }" size="l">{{
        $t('actionText')
      }}</WButton>
    </template>
  </ErrorDescription>
</template>

<script>
import { WButton } from '@WebiumTeam/ui_kit';
import ErrorDescription from './error-description.vue';

export const messages = {
  ru: {
    header: 'Страница не найдена',
    message: 'Не переживай, просто нажми на кнопку ниже',
    actionText: 'Вернуться на главную',
  },
};

export default {
  components: { WButton, ErrorDescription },
  i18n: { messages },
};
</script>

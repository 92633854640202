import Vue from 'vue';
import Toasted from 'vue-toasted';
import { config } from './config';
import './toasts.css';

Vue.use(Toasted, config);

export default (context, inject) => {
  inject('toast', Vue.toasted);
};

<script>
import { addMonths, subtractMonths } from '~/services/datetime';
import TimetableLeftRightPagination from '~/components/timetable/timetable-left-right-pagination.vue';

export default {
  components: {
    TimetableLeftRightPagination,
  },
  props: {
    selectedDate: {
      type: [Date, Object],
      required: true,
    },
  },
  methods: {
    selectPreviousMonth() {
      const newDate = subtractMonths({
        value: this.selectedDate,
        monthCount: 1,
      });
      this.$emit('date-selected', newDate);
    },
    selectNextMonth() {
      const newDate = addMonths({
        value: this.selectedDate,
        monthCount: 1,
      });
      this.selectDate(newDate);
    },
    selectDate(date) {
      this.$emit('date-selected', date);
    },
  },
};
</script>

<template>
  <TimetableLeftRightPagination
    :size="$device.isSmallScreen ? 'l' : 's'"
    :value="selectedDate"
    @click-left="selectPreviousMonth"
    @click-right="selectNextMonth"
  />
</template>

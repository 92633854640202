var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Popper',_vm._g(_vm._b({ref:"popper",attrs:{"auto-hide":_vm.autoHide,"theme":_vm.theme,"placement":_vm.placement,"disabled":_vm.disabled,"triggers":[_vm.trigger].flat(),"hide-triggers":_vm.hideTriggers,"target-nodes":_vm.getTargetNodes,"reference-node":function () { return _vm.$refs.reference; },"popper-node":function () { return _vm.$refs.popperContent.$el; },"popper-class":_vm.popperClasses,"distance":_vm.visibleDistance},scopedSlots:_vm._u([{key:"default",fn:function(ref){
  var popperId = ref.popperId;
  var isShown = ref.isShown;
  var shouldMountContent = ref.shouldMountContent;
  var skipTransition = ref.skipTransition;
  var hide = ref.hide;
  var handleResize = ref.handleResize;
  var onResize = ref.onResize;
  var classes = ref.classes;
  var result = ref.result;
return [_c('div',{ref:"reference",staticClass:"v-popper",class:[
      _vm.themeClass,
      {
        'v-popper--shown': isShown && !_vm.hidden,
      } ]},[_vm._t("default"),_vm._v(" "),_c('BasePopoverContent',{ref:"popperContent",class:{ 'v-popper--over-modal': _vm.overModal },attrs:{"popper-id":popperId,"theme":_vm.theme,"shown":isShown && !_vm.hidden,"mounted":shouldMountContent,"skip-transition":skipTransition,"auto-hide":_vm.autoHide,"handle-resize":handleResize,"classes":classes,"result":result},on:{"hide":hide,"resize":onResize}},[_vm._t("dropdown",null,{"shown":isShown && !_vm.hidden})],2)],2)]}}],null,true)},'Popper',_vm.$attrs,false),_vm.$listeners))}
var staticRenderFns = []

export { render, staticRenderFns }
import { featureTogglesPlugin } from './feature-toggles';
import { motivationPlugin } from './motivation';
import { notificationListAuthEventHandlersPlugin } from './notification-list';
import { windowSizeEventHandlersPlugin } from './responsive';
import { streaksPlugin } from './streaks';
import { timetablePreferencesPlugin } from './timetable-preferences';

export const plugins = [
  featureTogglesPlugin,
  windowSizeEventHandlersPlugin,
  timetablePreferencesPlugin,
  notificationListAuthEventHandlersPlugin,
  motivationPlugin,
  streaksPlugin,
];

<script>
export default {
  props: {
    items: {
      type: Array,
      required: true,
    },
    multiLine: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    classes() {
      return {
        [this.$style.root]: true,
        [this.$style._multiLine]: this.multiLine,
      };
    },
  },
  methods: {
    selectItem(item) {
      const value = Object.prototype.hasOwnProperty.call(item, 'value')
        ? item.value
        : item.text;
      this.$emit('select', value);
    },
  },
};
</script>

<template>
  <div :class="classes">
    <button
      v-for="(item, index) in items"
      :key="`${item.value}${index}`"
      :class="$style.item"
      @click="selectItem(item)"
    >
      <span>{{ item.text }}</span>
    </button>
  </div>
</template>

<style lang="postcss" module>
.root {
  width: min-content;
  padding: 2px;
}

.item {
  width: 100%;
  padding: 8px 12px;
  padding-bottom: 10px;
  font-size: 16px;
  line-height: 20px;
  color: var(--text-main-color);
  letter-spacing: 0.02em;
  white-space: nowrap;
  border-radius: 6px;

  &:hover {
    background-color: var(--grey-100);
  }

  &:active {
    background-color: var(--grey-200);
  }

  &:not(:last-child) {
    margin-bottom: 2px;
  }
}

.radioItem {
  flex-shrink: 0;
  margin-top: 2px;
  margin-right: 15px;
}

._radio {
  & .item {
    display: flex;
  }

  & span {
    white-space: initial;
  }
}

._multiLine {
  width: auto;

  & .item {
    white-space: normal;
  }
}
</style>

import { isRequired } from '~/utils/is-required';
import { http } from '~/services/http';

export function getSystemNotifications(templateSlugs = isRequired()) {
  const url = `/api/v2/system-notifications/`;
  const params = { template_slugs: templateSlugs.join(',') };
  return http.get(url, { params });
}

export function deleteSystemNotifications(templateSlugs = isRequired()) {
  const url = `/api/v2/system-notifications/bulk-delete/`;
  return http.post(url, { templateSlugs });
}

import cloneDeep from 'lodash/cloneDeep';
import Vue from 'vue';
import {
  getCourseProgressSubThemes,
  getCourseProgressThemes,
} from '~/api/workout';

export const state = () => ({
  loading: false,
  courseId: undefined,
  progressThemes: [],
  progressSubThemes: undefined,
});

export const mutations = {
  SET_COURSE_ID(state, value) {
    state.courseId = value;
  },
  SET_LOADING(state, value) {
    state.loading = !!value;
  },
  SET_PROGRESS_THEMES(state, value) {
    state.progressThemes = value;
  },
  RESET_PROGRESS_THEMES(state) {
    state.progressThemes = [];
  },
  UPDATE_PROGRESS_THEME(state, progressTheme) {
    const index = state.progressThemes.findIndex(
      (item) => item.theme.id === progressTheme.theme.id
    );

    if (index === -1) return;

    Vue.set(state.progressThemes, index, progressTheme);
  },
  SET_PROGRESS_SUBTHEMES(state, progressSubthemes) {
    state.progressSubThemes = progressSubthemes;
  },
  UPDATE_PROGRESS_SUBTHEME(state, progressSubthemes) {
    if (!state.progressSubThemes) return;
    Object.keys(state.progressSubThemes).forEach((themeId) => {
      const index = state.progressSubThemes[themeId]?.findIndex(
        (item) => item.subTheme.id === progressSubthemes.subTheme.id
      );
      if (index !== -1) {
        const subThemes = cloneDeep(state.progressSubThemes[themeId]);
        subThemes[index] = progressSubthemes;

        Vue.set(state.progressSubThemes, themeId, subThemes);
      }
    });
  },
  RESET_PROGRESS_SUBTHEMES(state) {
    state.progressSubThemes = undefined;
  },
};

export const actions = {
  async loadProgressThemes({ state, commit }, { courseId } = {}) {
    if (!courseId || state.loading) return;
    if (state.courseId === courseId && state.progressThemes) return;
    commit('RESET_PROGRESS_THEMES');
    commit('RESET_PROGRESS_SUBTHEMES');
    commit('SET_LOADING', true);

    const courseProgressThemes = await getCourseProgressThemes({ courseId });

    commit('SET_LOADING', false);
    commit('SET_COURSE_ID', courseId);
    commit('SET_PROGRESS_THEMES', courseProgressThemes);
  },
  async loadProgressSubThemes(
    { state, commit, getters },
    { courseId, themeId } = {}
  ) {
    if (!courseId || state.loading) return;

    const subThemes = getters.progressSubThemesByThemeId({
      themeId,
    });
    if (subThemes) return;
    commit('SET_LOADING', true);

    const courseProgressSubThemes = await getCourseProgressSubThemes({
      courseId,
      themeId,
    });
    const newSubThemes = cloneDeep(state.progressSubThemes) || {};
    newSubThemes[themeId] = courseProgressSubThemes;

    commit('SET_LOADING', false);
    commit('SET_PROGRESS_SUBTHEMES', newSubThemes);
  },
  updateProgressThemesScore({ commit, getters }, { subThemes, score }) {
    subThemes.forEach((subThemeId) => {
      const progressTheme = getters.progressThemeBySubThemeId({
        subThemeId,
      });
      if (progressTheme) {
        const newProgressThemeData = { ...progressTheme };
        newProgressThemeData.userScore += score;
        newProgressThemeData.prevWeekUserScore += score;

        commit('UPDATE_PROGRESS_THEME', newProgressThemeData);
      }
      const progressSubTheme = getters.progressSubThemeById({
        subThemeId,
      });
      if (progressSubTheme) {
        const newProgressSubThemeData = { ...progressSubTheme };
        newProgressSubThemeData.userScore += score;
        newProgressSubThemeData.prevWeekUserScore += score;

        commit('UPDATE_PROGRESS_SUBTHEME', newProgressSubThemeData);
      }
    });
  },
};

export const getters = {
  progressThemeBySubThemeId:
    (state) =>
    ({ subThemeId }) =>
      state.progressThemes?.find((theme) =>
        theme.progressSubThemes.find(
          (progressSubTheme) => progressSubTheme.subTheme.id === subThemeId
        )
      ),

  progressSubThemesByThemeId:
    (state) =>
    ({ themeId }) =>
      state.progressSubThemes && state.progressSubThemes[themeId],

  progressSubThemeById:
    (state) =>
    ({ subThemeId }) => {
      if (!state.progressSubThemes) return undefined;
      let progressSubTheme;
      Object.keys(state.progressSubThemes).forEach((themeId) => {
        progressSubTheme =
          state.progressSubThemes[themeId]?.find(
            (item) => item.subTheme.id === subThemeId
          ) || progressSubTheme;
      });

      return progressSubTheme;
    },

  progressSubThemeByIds:
    (state) =>
    ({ themeId, subThemeId }) => {
      const themes = state.progressSubThemes?.[+themeId];
      if (!themes) return false;
      return themes.find((item) => item.subTheme.id === +subThemeId);
    },
};

<script>
import { WIconButton } from '@WebiumTeam/ui_kit';

export const messages = {
  ru: {
    defaultButtonText: 'скопировать ссылку',
    defaultSuccessText: 'ссылка скопирована',
  },
};

export default {
  i18n: {
    messages,
  },
  components: { WIconButton },
  props: {
    copyContent: {
      type: String,
      required: true,
    },
    buttonText: {
      type: String,
      default() {
        return this.$t('defaultButtonText');
      },
    },
    successText: {
      type: String,
      default() {
        return this.$t('defaultSuccessText');
      },
    },
    hideSuccessTextTimeout: {
      type: Number,
      default: 1500,
    },
    iconName: {
      type: String,
      default: 'copy',
    },
    size: {
      type: String,
      default: 'm',
    },
    withoutAnimation: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isCopied: false,
    };
  },
  computed: {
    rootClasses() {
      return {
        [this.$style.root]: true,
        [this.$style._copied]: this.isCopied,
      };
    },
  },
  methods: {
    onButtonClick() {
      navigator.clipboard.writeText(this.copyContent);
      if (this.withoutAnimation) return;
      this.isCopied = true;
      setTimeout(() => {
        this.isCopied = false;
      }, this.hideSuccessTextTimeout);
    },
  },
};
</script>

<template>
  <div :class="rootClasses">
    <span :class="$style.copiedText" data-testid="success-text">
      <WIcon name="done" />
      {{ successText }}
    </span>
    <WIconButton
      :icon-name="iconName"
      :class="$style.button"
      data-testid="copy-button"
      :size="size"
      v-bind="$attrs"
      @click="onButtonClick"
    >
      {{ buttonText }}
    </WIconButton>
  </div>
</template>

<style lang="postcss" module>
.copiedText {
  position: absolute;
  display: none;

  & svg {
    margin-right: 5px;
    font-size: 24px;
  }
}

.button {
  width: 100%;
}

.root {
  display: flex;
  align-items: center;
  justify-content: center;

  &._copied {
    & .copiedText {
      display: block;
    }

    & .button {
      opacity: 0;
    }
  }
}
</style>

import { handleError } from '~/services/error-handling/error-handling';

export function buildServerListModule(
  getItemsMethod,
  {
    fetchOnlyOnce = false,
    allowParallelRequests = false,
    itemsFieldName = 'items',
    itemsLoadingFieldName = 'itemsLoading',
    fetchMethodName = 'fetchItems',
    responseItemsFieldName = 'results',
  } = {}
) {
  return {
    state: () => ({
      [itemsFieldName]: [],
      [itemsLoadingFieldName]: false,
      hasBeenFetched: false,
    }),
    getters: {
      totalCount: (state) =>
        state[itemsFieldName].length > 0 ? state[itemsFieldName].length : 0,
      isEmpty: (state, getters) =>
        getters.totalCount === 0 && state[itemsLoadingFieldName] === false,
    },
    mutations: {
      SET_ITEMS(state, items) {
        state[itemsFieldName] = items;
      },
      SET_ITEMS_LOADING(state, itemsLoading) {
        state[itemsLoadingFieldName] = itemsLoading;
      },
      SET_HAS_BEEN_FETCHED(state, value) {
        state.hasBeenFetched = !!value;
      },
    },
    actions: {
      async [fetchMethodName]({ commit, state }, params) {
        if (!allowParallelRequests && state[itemsLoadingFieldName]) return;
        if (fetchOnlyOnce && state.hasBeenFetched) return;
        try {
          commit('SET_ITEMS_LOADING', true);
          const response = await getItemsMethod(params);
          const results = responseItemsFieldName
            ? response[responseItemsFieldName]
            : response;
          commit('SET_ITEMS', results);
          commit('SET_HAS_BEEN_FETCHED', true);
        } catch (error) {
          handleError(error);
        } finally {
          commit('SET_ITEMS_LOADING', false);
        }
      },
    },
  };
}

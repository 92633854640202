import Vue from 'vue';
import { GROWTHBOOK_CLIENT_KEY } from '~/constants/growthbook';
import { GrowthBookVuePlugin } from '~/utils/growthbook';
import {
  onClientAuthInit,
  onClientAuthLogin,
} from '~/services/auth-functions/auth-functions';

function initialize($growthBook, $auth) {
  $growthBook({ ...$auth.user });
}

export default (context, inject) => {
  Vue.use(GrowthBookVuePlugin, {
    featuresEndpoint: `https://cdn.growthbook.io/api/features/${GROWTHBOOK_CLIENT_KEY}`,
    inject,
  });

  onClientAuthInit(($auth) => initialize(context.$growthBook, $auth));
  onClientAuthLogin(($auth) => initialize(context.$growthBook, $auth));
};

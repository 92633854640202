import { addDays } from './add-days';
import { addMonths } from './add-months';
import { diffInDays } from './diff-in-days';
import { endOfMonth } from './end-of-month';
import { endOfWeek } from './end-of-week';
import { formatDate } from './format-date';
import { startOfMonth } from './start-of-month';

function getNDaysAfter({ value, dayCount, format }) {
  return [...Array.from({ length: dayCount })].map((day, index) => ({
    date: formatDate({
      value: addDays({ value, dayCount: index }),
      format,
    }),
  }));
}

export function nextMonthDaysUntilEndOfWeek({ value, format = 'YYYY-MM-DD' }) {
  const selectedMonthFirstDay = startOfMonth(value);
  const selectedMonthLastDay = endOfMonth(value);
  const selectedMonthEndOfWeekOfLastDay = endOfWeek(selectedMonthLastDay);
  const nextMonthFirstDayDate = startOfMonth(
    addMonths({
      value: selectedMonthFirstDay,
      monthCount: 1,
    })
  );
  const visibleNumberOfDaysFromNextMonth = Math.abs(
    diffInDays(selectedMonthLastDay, selectedMonthEndOfWeekOfLastDay)
  );

  return getNDaysAfter({
    value: nextMonthFirstDayDate,
    dayCount: visibleNumberOfDaysFromNextMonth,
    format,
  });
}

// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".streaks-calendar-month__root-MHNUk{--border-width:0;margin-bottom:12px}.streaks-calendar-month__toolbar-vsB5p{justify-content:center;height:-moz-fit-content;height:fit-content}.streaks-calendar-month__pagination-QOpDV,.streaks-calendar-month__toolbar-vsB5p{display:flex;width:100%}.streaks-calendar-month__pagination-QOpDV{align-items:center;margin:0 30px;padding-bottom:4px}.streaks-calendar-month__paginationLabel-EqIdz{width:100%;color:var(--primary-500);font-size:20px;line-height:24px;text-align:center}@media (max-width:1023px){.streaks-calendar-month__pagination-QOpDV{margin:0 20px}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "streaks-calendar-month__root-MHNUk",
	"toolbar": "streaks-calendar-month__toolbar-vsB5p",
	"pagination": "streaks-calendar-month__pagination-QOpDV",
	"paginationLabel": "streaks-calendar-month__paginationLabel-EqIdz"
};
module.exports = ___CSS_LOADER_EXPORT___;

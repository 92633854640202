// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".pure__root-e74Th{--background-brand-rgb:255 246 237;--background-brand:rgb(var(--background-brand-rgb));min-height:100vh;background-color:var(--background-brand)}.pure__main-OZu8b{max-width:var(--max-content-width);min-height:968px;margin:0 auto}@media (max-width:1023px){.pure__main-OZu8b{min-height:100vh;padding:0 12px;overflow:hidden}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "pure__root-e74Th",
	"main": "pure__main-OZu8b"
};
module.exports = ___CSS_LOADER_EXPORT___;

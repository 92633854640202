function prependSlashIfNeeded(value) {
  return value[0] === '/' ? value : `/${value}`;
}
function removePrependingSlash(value) {
  return value[0] === '/' ? value.slice(1) : value;
}

function appendTrailingSlashIfNeeded(value) {
  return value.endsWith('/') ? value : `${value}/`;
}

function removeTrailingSlash(value) {
  return value.endsWith('/') ? value.slice(0, -1) : value;
}

function replacePlaceholder({ text, placeholder, newValue }) {
  return text.replace(new RegExp(`{{\\s*${placeholder}\\s*}}`, 'g'), newValue);
}

module.exports = {
  prependSlashIfNeeded,
  removePrependingSlash,
  appendTrailingSlashIfNeeded,
  removeTrailingSlash,
  replacePlaceholder,
};

<template>
  <div :class="$style.root">
    <IconsSprite />
    <TheNavbar :class="$style.header" />
    <div :id="wrapperId" :class="$style.wrapper">
      <aside v-if="!$device.isSmallScreen" :class="$style.sidebar">
        <SideBarMenu />
        <CommonOnboardingReminder :class="$style.desktopOnboardingReminder" />
        <BannerSidebar :class="$style.banner" sidebar />
      </aside>
      <main :id="mainId" :class="$style.main">
        <CommonOnboardingReminder
          v-if="isMobileOnboardingReminderVisible"
          :class="$style.mobileOnboardingReminder"
        />
        <Nuxt />
      </main>
    </div>
    <VkConnectManager />
    <NpsManager />
    <CourseOnboardingModalManager />
    <CommonOnboardingModalManager />
    <NotificationSettingsModal />
    <MotivationNewLevelMessage />
    <MotivationNewAchievementMessages />
    <TelegramLinkBanner />
    <PortalTarget
      :class="$style.toastMessagesContainer"
      name="toast-messages"
      multiple
    ></PortalTarget>
  </div>
</template>

<script>
import { ROUTE_META_KEYS } from '~/routing/routes';
import { createNamespacedHelpers } from 'vuex';
import { DOM_ELEMENT_SELECTORS } from '~/constants/dom-elements';
import BannerSidebar from '~/components/banner-sidebar.vue';
import CommonOnboardingModalManager from '~/components/common-onboarding/common-onboarding-modal-manager.vue';
import CommonOnboardingReminder from '~/components/common-onboarding/common-onboarding-reminder.vue';
import CourseOnboardingModalManager from '~/components/course-onboarding-modal/course-onboarding-modal-manager.vue';
import IconsSprite from '~/components/icon/icons-sprite.vue';
import MotivationNewAchievementMessages from '~/components/motivation/motivation-new-achievement-messages.vue';
import MotivationNewLevelMessage from '~/components/motivation/motivation-new-level-message/motivation-new-level-message.vue';
import NotificationSettingsModal from '~/components/notifications/notification-settings-modal.vue';
import NpsManager from '~/components/nps/nps-manager.vue';
import SideBarMenu from '~/components/side-bar-menu.vue';
import TelegramLinkBanner from '~/components/telegram-link-banner/telegram-link-banner.vue';
import TheNavbar from '~/components/the-navbar/the-navbar.vue';
import VkConnectManager from '~/components/vk-connect-manager.vue';

const { mapActions: mapBannersActions } = createNamespacedHelpers('banners');

export default {
  components: {
    CommonOnboardingModalManager,
    SideBarMenu,
    IconsSprite,
    TheNavbar,
    CommonOnboardingReminder,
    BannerSidebar,
    NotificationSettingsModal,
    VkConnectManager,
    NpsManager,
    MotivationNewLevelMessage,
    CourseOnboardingModalManager,
    MotivationNewAchievementMessages,
    TelegramLinkBanner,
  },
  computed: {
    isMobileOnboardingReminderVisible() {
      return (
        this.$device.isSmallScreen &&
        !!this.$route.meta[ROUTE_META_KEYS.useCommonOnboarding]
      );
    },
    wrapperId() {
      return DOM_ELEMENT_SELECTORS.innerLayoutWrapperId;
    },
    mainId() {
      return DOM_ELEMENT_SELECTORS.innerLayoutMainId;
    },
  },
  watch: {
    '$route.params': {
      immediate: true,
      handler(newParams, oldParams) {
        const paramsDataNotChanged =
          oldParams &&
          Object.keys(oldParams).length === 0 &&
          newParams &&
          Object.keys(newParams).length === 0;
        if (paramsDataNotChanged) return;
        this.fetchBanners({ courseId: newParams?.id });
      },
    },
  },
  methods: {
    ...mapBannersActions(['fetchBanners']),
  },
};
</script>

<style lang="postcss" module>
.root {
  --sidebar-width: 192px;
  --inner-gap: 54px;

  display: flex;
  flex-direction: column;
}

.wrapper {
  display: flex;
  gap: var(--inner-gap);
  width: var(--max-content-width);
  max-width: 100%;
  padding: 0 var(--page-padding);
  margin: var(--navbar-margin) auto 0 auto;
}

.sidebar {
  --gap: 32px;

  display: flex;
  flex-direction: column;
  flex-grow: 0;
  flex-shrink: 0;
  width: var(--sidebar-width);
  padding-bottom: var(--gap);
}

.banner {
  margin-top: 24px;
}

.main {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  max-width: calc(100% - var(--sidebar-width) - var(--inner-gap));
  min-height: var(--main-content-min-height);
  padding-bottom: 80px;
}

.desktopOnboardingReminder {
  margin-top: 16px;
}

.mobileOnboardingReminder {
  margin-bottom: 12px;
}

.toastMessagesContainer {
  position: fixed;
  right: 24px;
  bottom: 24px;
  z-index: var(--base-toast-message-z-index);
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: flex-end;
  justify-content: flex-end;
}

@media (--large-vp) {
  .root {
    --inner-gap: 36px;
  }
}

@media (--small-vp) {
  .root {
    --navbar-margin: 12px;
    --sidebar-width: 0;
    --inner-gap: 0;
  }

  .sidebar {
    display: none;
  }

  .main {
    width: 100%;
  }

  .toastMessagesContainer {
    right: 12px;
    bottom: initial;
    left: 12px;
    top: 24px;
    gap: 8px;
  }
}
</style>

<script>
import { createNamespacedHelpers } from 'vuex';
import {
  MINI_CALENDAR_DAYS_COUNT,
  STREAK_DAY_STATE,
} from '~/constants/streaks';
import {
  addDays,
  getCurrentDate,
  isSameDay,
  subtractDays,
} from '~/services/datetime';
import BasePopover from '~/components/_base/popover/_base-popover.vue';
import StreaksCalendarMiniDayItem from '~/components/streaks/streaks-calendar-mini/streaks-calendar-mini-day-item.vue';
import StreaksPairedReceivedStreakTodayPopoverContent from '~/components/streaks/streaks-paired/streaks-paired-buddy-received-streak-today-popover-content.vue';

const { mapState: mapStreaksState } = createNamespacedHelpers('streaks');
const { mapState, mapGetters, mapActions } =
  createNamespacedHelpers('streaks-history');

export const messages = {
  ru: {
    days: {
      0: 'вс',
      1: 'пн',
      2: 'вт',
      3: 'ср',
      4: 'чт',
      5: 'пт',
      6: 'сб',
    },
  },
};

export default {
  i18n: {
    messages,
  },
  components: {
    BasePopover,
    StreaksCalendarMiniDayItem,
    StreaksPairedReceivedStreakTodayPopoverContent,
  },
  computed: {
    ...mapState(['isFetched']),
    ...mapGetters(['historyByPeriod', 'calendarDayItem']),
    ...mapStreaksState(['isLastInfoFetched']),
    days() {
      const dateTo = getCurrentDate();
      const dateFrom = subtractDays({
        value: dateTo,
        dayCount: MINI_CALENDAR_DAYS_COUNT - 1,
      });
      const historyByPeriod = this.historyByPeriod({
        dateFrom,
        dateTo,
      }).sort((item1, item2) => new Date(item1.date) - new Date(item2.date));
      const days = [];
      const firstDate =
        historyByPeriod.length === 0
          ? Date.now()
          : new Date(historyByPeriod[0].date);
      for (let index = 0; index < MINI_CALENDAR_DAYS_COUNT; index += 1) {
        const date = addDays({
          value: firstDate,
          dayCount: index,
        });
        days.push(this.calendarDayItem(date));
      }
      return days;
    },
  },
  watch: {
    isLastInfoFetched: {
      immediate: true,
      handler(value) {
        if (value) {
          this.handleFetch();
        }
      },
    },
  },
  methods: {
    ...mapActions(['fetchStreaksHistory']),
    handleFetch() {
      if (this.isFetched) return;
      this.fetchStreaksHistory();
    },
    getItemClasses(day) {
      return {
        [this.$style.item]: true,
        [this.$style[`_${day.state}`]]: true,
        [this.$style._current]: isSameDay(Date.now(), day.date),
      };
    },
    isBuddyReceivedStreakDay(day) {
      return day.state === STREAK_DAY_STATE.buddy;
    },
    wrapperComponent(day) {
      return this.isBuddyReceivedStreakDay(day) ? BasePopover : 'div';
    },
  },
};
</script>

<template>
  <div :class="$style.root">
    <component
      :is="wrapperComponent(day)"
      v-for="day in days"
      :key="day.dayIndex"
      :distance="4"
      placement="bottom"
      trigger="hover"
      show-arrow
    >
      <div data-testid="day-item" :class="getItemClasses(day)">
        <StreaksCalendarMiniDayItem :item="day" />
        <span :class="$style.dayName">{{ $t(`days.${day.dayIndex}`) }}</span>
      </div>
      <template v-if="isBuddyReceivedStreakDay(day)" #dropdown>
        <StreaksPairedReceivedStreakTodayPopoverContent />
      </template>
    </component>
  </div>
</template>

<style lang="postcss" module>
.root {
  display: flex;
  gap: 24px;
}

.item {
  --border-color: transparent;

  display: flex;
  flex-direction: column;
  gap: 2px;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 64px;
  border: solid 2px var(--border-color);
  border-radius: 64px;

  &,
  &._missed,
  &._empty {
    --font-color: var(--grey-500);
  }

  &._freeze {
    --font-color: var(--streaks-freeze-color);
  }

  &._streak {
    --font-color: var(--streaks-active-color);
  }

  &._current {
    --border-color: var(--grey-400);
  }

  &._buddy,
  &._paired {
    --font-color: var(--support-purple);
  }
}

.dayName {
  font-size: 14px;
  line-height: 18px;
  color: var(--font-color);
}
</style>

const middleware = {}

middleware['authenticated'] = require('../middleware/authenticated.js')
middleware['authenticated'] = middleware['authenticated'].default || middleware['authenticated']

middleware['fetch-feature-toggles'] = require('../middleware/fetch-feature-toggles.js')
middleware['fetch-feature-toggles'] = middleware['fetch-feature-toggles'].default || middleware['fetch-feature-toggles']

middleware['is-feature-enabled'] = require('../middleware/is-feature-enabled.js')
middleware['is-feature-enabled'] = middleware['is-feature-enabled'].default || middleware['is-feature-enabled']

middleware['streaks'] = require('../middleware/streaks.js')
middleware['streaks'] = middleware['streaks'].default || middleware['streaks']

export default middleware

<script>
import { createNamespacedHelpers } from 'vuex';
import StreaksCalendarMonth from '~/components/streaks/streaks-calendar/streaks-calendar-month.vue';
import StreaksFreezeCounter from '~/components/streaks/streaks-freeze-counter.vue';

const { mapGetters: mapFeatureTogglesGetters } =
  createNamespacedHelpers('feature-toggles');

export default {
  components: {
    StreaksFreezeCounter,
    StreaksCalendarMonth,
  },
  computed: {
    ...mapFeatureTogglesGetters(['isFeatureEnabled']),
  },
};
</script>

<template>
  <div :class="$style.root">
    <div :class="$style.content">
      <StreaksCalendarMonth :class="$style.calendarMonth" />
    </div>
    <div :class="$style.footer">
      <StreaksFreezeCounter />
    </div>
  </div>
</template>

<style lang="postcss" module>
.root {
  display: flex;
  flex-direction: column;
}

.content {
  display: flex;
  justify-content: center;
  width: 340px;
  padding-top: 22px;
}

.calendarMonth {
  width: 100%;
  margin-top: 0;
  margin-right: 16px;
  margin-left: 16px;
}

.calendarMini {
  margin: 8px 0 24px;
}

.footer {
  display: flex;
  justify-content: center;
  padding: 16px 0 20px;
  border-top: solid 2px var(--grey-200);
}
</style>

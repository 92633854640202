<script>
import { WButton, WIconButton } from '@WebiumTeam/ui_kit';
import { VClosePopper } from 'floating-vue';
import { createNamespacedHelpers } from 'vuex';

const { mapState } = createNamespacedHelpers('streaks');

export const messages = {
  ru: {
    commonTitle: 'твой стрик стал больше',
    commonDescription: 'продолжай заниматься, чтобы поддерживать огонь знаний',
    streakReceivedButtonText: 'я смогу!',
    randomTitle: {
      1: 'двойной успех!',
      2: 'идёте к успеху!',
      3: 'вау! к-к-комбо стрик',
      4: 'ура, +1 к парному стрику',
      5: 'у вас всё получится!',
      6: 'ещё один день занятий — так держать!',
      7: 'топ-вузы уже ждут вас',
      8: 'отлично! держите этот темп',
      9: 'командная работа на отлично!',
      10: 'супер-результат х2',
      11: 'мы вами гордимся',
      12: 'ну какие вы крутые!!!',
      13: 'вместе вы — сила!',
      14: 'вы — команда мечты',
      15: 'вы просто супер!',
      16: 'восхищаемся вашим упорством',
    },
  },
};

export default {
  i18n: {
    messages,
  },
  directives: {
    'close-popper': VClosePopper,
  },
  components: {
    WButton,
    WIconButton,
  },
  data() {
    return {
      title: this.getRandomTitle(),
    };
  },
  computed: {
    ...mapState(['currentPairedStreak']),
    animationName() {
      return 'animation-default';
    },
  },
  methods: {
    getRandomTranslation({ randomI18nKey, commonI18nKey }) {
      const randomTranslations = Object.values(
        messages[this.$i18n.locale][randomI18nKey]
      );
      const randomTranslationsCount = randomTranslations.length;
      const randomTranslationIndex = Math.floor(
        Math.random() * randomTranslationsCount
      );
      const randomTranslation = randomTranslations[randomTranslationIndex];
      return randomTranslation || this.$t(commonI18nKey);
    },
    getRandomTitle() {
      return this.getRandomTranslation({
        randomI18nKey: 'randomTitle',
        commonI18nKey: 'commonTitle',
      });
    },
  },
};
</script>

<template>
  <div :class="$style.root">
    <WIconButton
      v-close-popper
      theme="emptyLight"
      size="xs"
      icon-name="close-S"
      :class="$style.closeButton"
    />
    <div :class="$style.contentWrapper">
      <div :class="$style.pictureWrapper">
        <img :class="$style.picture" :src="require(`./animation.svg`)" alt="" />
        <div :class="$style.countWrapper">
          <span :class="$style.count" data-testid="count">
            {{ currentPairedStreak }}
          </span>
          <span :class="$style.countStroke">{{ currentPairedStreak }}</span>
        </div>
      </div>
      <div :class="$style.content" data-testid="content-default">
        <strong :class="$style.title" v-html="title"></strong>
        <p :class="$style.description" v-html="$t('commonDescription')"></p>
        <WButton v-close-popper :class="$style.button">
          {{ $t('streakReceivedButtonText') }}
        </WButton>
      </div>
    </div>
  </div>
</template>

<style lang="postcss" module>
.root {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 380px;
  padding: 8px 24px 24px;
}

.contentWrapper,
.content {
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: center;
  width: 100%;
  text-align: center;
}

.pictureWrapper {
  position: relative;
  width: 256px;
  height: 224px;
}

.picture {
  max-width: 100%;
}

.title {
  font-size: 20px;
  line-height: 24px;
}

.description {
  font-size: 14px;
  line-height: 18px;
  white-space: break-spaces;
}

.button {
  width: 100%;
  margin-top: 12px;
}

.closeButton {
  position: absolute;
  top: 8px;
  right: 8px;
}

.countWrapper {
  position: absolute;
  bottom: 0;
  left: 50%;
  font-size: 80px;
  line-height: 1;
  transform: translateX(-50%);
}

.count {
  line-height: 1;
  color: var(--support-purple);
}

.countStroke {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  -webkit-text-stroke: 12px var(--primary-100);
}

@media (--small-vp) {
  .root {
    max-width: 100%;
  }
}
</style>

const pad = (number, size) => `000${number}`.slice(size * -1);

export function secondsToTimecode(timeInSeconds) {
  const time = Number.parseFloat(timeInSeconds).toFixed(3);
  const hours = Math.floor(time / 60 / 60);
  const minutes = Math.floor(time / 60) % 60;
  const seconds = Math.floor(time - minutes * 60);

  return `${pad(hours, 2)}:${pad(minutes, 2)}:${pad(seconds, 2)}`;
}

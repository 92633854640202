<script>
import { createNamespacedHelpers } from 'vuex';
import { addMonths, subtractMonths } from '~/services/datetime';
import { getMonthCalendarDays, getTodayDate } from '~/services/timetable';
import MonthCalendar from '~/components/calendars/month-calendar.vue';
import LeftRightPagination from '~/components/left-right-pagination.vue';
import StreaksCalendarDayContent from '~/components/streaks/streaks-calendar/streaks-calendar-day-content.vue';

const { mapState, mapActions } = createNamespacedHelpers('streaks-history');

export const messages = {
  ru: {
    months: {
      month0: 'январь',
      month1: 'февраль',
      month2: 'март',
      month3: 'апрель',
      month4: 'май',
      month5: 'июнь',
      month6: 'июль',
      month7: 'август',
      month8: 'сентябрь',
      month9: 'октябрь',
      month10: 'ноябрь',
      month11: 'декабрь',
    },
  },
};

export default {
  i18n: {
    messages,
  },
  components: {
    StreaksCalendarDayContent,
    LeftRightPagination,
    MonthCalendar,
  },
  provide() {
    const dayItemSize = '44px';
    return {
      dayItemStyle: {
        maxWidth: dayItemSize,
        maxHeight: dayItemSize,
        minHeight: dayItemSize,
        minWidth: dayItemSize,
        display: 'flex',
        border: 'none',
        alignItems: 'center',
        justifyContent: 'center',
        background: 'transparent',
        margin: '0 auto',
      },
    };
  },
  props: {
    externalSelectedDate: {
      type: [Date, Object, String],
      default: '',
    },
  },
  data() {
    return {
      selectedDate: undefined,
    };
  },
  computed: {
    ...mapState(['isLoading']),
    monthLabel() {
      const fullYear = new Date(this.selectedDate).getFullYear();
      const month = new Date(this.selectedDate).getMonth();
      const monthLabel = this.$t(`months.month${month}`);
      return `${monthLabel} ${fullYear}`;
    },
  },
  watch: {
    externalSelectedDate: {
      handler(date) {
        this.onDateChange(date);
      },
    },
  },
  created() {
    this.selectDate(getTodayDate());
  },
  methods: {
    ...mapActions(['fetchStreaksHistory']),
    async handleFetchItems(date) {
      const days = getMonthCalendarDays({
        selectedDate: date,
      });
      const dateFrom = days[0].date;
      const dateTo = days[days.length - 1].date;
      await this.fetchStreaksHistory({
        dateFrom,
        dateTo,
      });
    },
    selectDate(date) {
      this.selectedDate = date;
      this.handleFetchItems(date);
    },
    onDateChange(date) {
      this.selectDate(date);
    },
    onPreviousMonth() {
      const date = subtractMonths({
        value: this.selectedDate,
        monthCount: 1,
      });
      this.selectDate(date);
    },
    onNextMonth() {
      const date = addMonths({
        value: this.selectedDate,
        monthCount: 1,
      });
      this.selectDate(date);
    },
  },
};
</script>

<template>
  <MonthCalendar
    :class="$style.root"
    :is-loading="isLoading"
    :external-selected-date="selectedDate"
    weekdays-label-position="center"
    hide-day-item-number
    @date-change="onDateChange"
  >
    <div slot="toolbar" :class="$style.toolbar">
      <LeftRightPagination
        split
        :class="$style.pagination"
        :size="$device.isSmallScreen ? 'l' : 'xs'"
        @click-left="onPreviousMonth"
        @click-right="onNextMonth"
      >
        <span :class="$style.paginationLabel" data-testid="heading-date">
          {{ monthLabel }}
        </span>
      </LeftRightPagination>
    </div>
    <template #dayItem="{ dateString }">
      <StreaksCalendarDayContent
        :date-string="dateString"
        calendar-type="month"
      />
    </template>
  </MonthCalendar>
</template>

<style lang="postcss" module>
.root {
  --border-width: 0;

  margin-bottom: 12px;
}

.toolbar {
  display: flex;
  justify-content: center;
  width: 100%;
  height: fit-content;
}

.pagination {
  display: flex;
  align-items: center;
  width: 100%;
  margin: 0 30px;
  padding-bottom: 4px;
}

.paginationLabel {
  width: 100%;
  color: var(--primary-500);
  font-size: 20px;
  line-height: 24px;
  text-align: center;
}

@media (--small-vp) {
  .pagination {
    margin: 0 20px;
  }
}
</style>

import findLastIndex from 'lodash/findLastIndex';
import { COURSE_ITEM_TYPE } from '~/constants/course-item-type';
import {
  addDays,
  allDaysOfMonth,
  diffInDays,
  formatDate,
  nextMonthDaysUntilEndOfWeek,
  previousMonthDaysFromStartOfWeek,
  startOfWeek,
} from '~/services/datetime';

const DAYS_IN_WEEK_DEFAULT = 7;

const routeNameByLessonContentType = {
  [COURSE_ITEM_TYPE.homework]: 'course-content-homework',
  [COURSE_ITEM_TYPE.webinar]: 'course-content-webinar',
};

const routeContentIdParameterByLessonContentType = {
  [COURSE_ITEM_TYPE.homework]: 'homeworkId',
  [COURSE_ITEM_TYPE.webinar]: 'webinarId',
};

const hasTime = (item) => !!item.content?.startTime;

const getTopicId = (item) => item.lessonContentGroup?.course?.topic?.id;

export const sortTimetableItems = (items) => {
  let sortedItems = [...items];
  // Sort items
  sortedItems.sort((a, b) =>
    (a.content?.startTime || '').localeCompare(b.content?.startTime || '')
  );
  // Reorder items (move items with time (webinars) to start of topic group)
  const processedTopics = new Set();
  const reorderedItems = [];
  const itemsWithTime = sortedItems.filter((item) => hasTime(item));
  let itemsWithoutTime = sortedItems.filter((item) => !hasTime(item));
  itemsWithTime.forEach((item) => {
    const topicId = getTopicId(item);
    if (!processedTopics.has(topicId)) {
      reorderedItems.push(item);
      const itemsByTopicWithoutTime = itemsWithoutTime.filter(
        (itemWithoutTime) => getTopicId(itemWithoutTime) === topicId
      );
      itemsWithoutTime = itemsWithoutTime.filter(
        (itemWithoutTime) => getTopicId(itemWithoutTime) !== topicId
      );
      const itemsByTopicIndex = reorderedItems.length;
      reorderedItems.splice(itemsByTopicIndex, 0, ...itemsByTopicWithoutTime);
      processedTopics.add(topicId);
    } else {
      const findLastWebinar = (element) =>
        getTopicId(element) === getTopicId(item) && hasTime(element);

      const processedTopicIndex = findLastIndex(
        reorderedItems,
        findLastWebinar
      );

      reorderedItems.splice(processedTopicIndex + 1, 0, item);
    }
  });

  if (itemsWithoutTime.length > 0) {
    reorderedItems.push(...itemsWithoutTime);
  }

  sortedItems = reorderedItems;
  return sortedItems;
};

export const sortTimelineItems = (items) => {
  const sortedItems = [...items];
  sortedItems.sort((a, b) =>
    (a.content?.startTime || '').localeCompare(b.content?.startTime || '')
  );
  let reorderedItems = [];
  const itemsWithTime = sortedItems.filter((item) => hasTime(item));
  const itemsWithoutTime = sortedItems.filter((item) => !hasTime(item));
  reorderedItems = [...itemsWithTime, ...itemsWithoutTime];
  return reorderedItems;
};

export const buildRouteToItemContent = (item) => {
  const buildParams = () => {
    const contentIdParameterName =
      routeContentIdParameterByLessonContentType[item.lessonContentType];
    return {
      id: item.lessonContentGroup.course.id,
      [contentIdParameterName]: item.content.id,
    };
  };
  return {
    name: routeNameByLessonContentType[item.lessonContentType],
    params: buildParams(),
  };
};

export const getWeekCalendarDays = ({
  selectedDate,
  daysInWeek = DAYS_IN_WEEK_DEFAULT,
}) => {
  const startOfWeekDate = startOfWeek(selectedDate);
  const selectedWeekDayNumber = diffInDays(selectedDate, startOfWeekDate) + 1;
  const isSelectedDateOutOfRange = selectedWeekDayNumber > daysInWeek;
  const isCutWeek = daysInWeek < DAYS_IN_WEEK_DEFAULT;
  const startDate =
    isCutWeek || isSelectedDateOutOfRange ? selectedDate : startOfWeekDate;
  return [...Array.from({ length: daysInWeek })].map((day, index) => ({
    date: formatDate({
      value: addDays({ value: startDate, dayCount: index }),
      format: 'YYYY-MM-DD',
    }),
  }));
};

export const getMonthCalendarDays = ({ selectedDate }) => {
  const previousMonthDays = previousMonthDaysFromStartOfWeek({
    value: selectedDate,
    format: 'YYYY-MM-DD',
  }).map((day) => ({
    ...day,
    isCurrentMonth: false,
  }));
  const currentMonthDays = allDaysOfMonth({ value: selectedDate });
  const nextMonthDays = nextMonthDaysUntilEndOfWeek({
    value: selectedDate,
    format: 'YYYY-MM-DD',
  }).map((day) => ({
    ...day,
    isCurrentMonth: false,
  }));
  return [...previousMonthDays, ...currentMonthDays, ...nextMonthDays];
};

export const getTodayDate = () => new Date(new Date().setUTCHours(0, 0, 0, 0));

export const getYesterdayDate = () => {
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);

  return new Date(yesterday.setUTCHours(0, 0, 0, 0));
};

export const getContentTimeLabel = (timeString) => {
  if (!timeString) return '';
  const timeParts = timeString.split(':');
  const humanTime =
    timeParts.length > 1 ? `${timeParts[0]}:${timeParts[1]}` : timeString;
  return `${humanTime} мск`;
};

import jwtDecode from 'jwt-decode';
import { gtmPush } from '~/services/gtm/gtm';

export function gtmPushUserId($auth) {
  const userPublicId = $auth.loggedIn ? $auth.user.publicId : undefined;
  let impersonatorUserId;
  if ($auth.loggedIn) {
    const token = $auth.strategy.token.get().replace('Bearer ', '');
    const decodedToken = jwtDecode(token);
    impersonatorUserId = decodedToken.impersonator_public_id;
  }

  gtmPush({
    user_id: impersonatorUserId || userPublicId,
    impersonator_user_id: impersonatorUserId,
  });
}

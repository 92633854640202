<template>
  <div :class="$style.root">
    <IconsSprite />
    <TheNavbar />
    <div :class="$style.main">
      <Nuxt />
    </div>
    <StudentGreetingSurveyManager />
    <NotificationSettingsModal />
  </div>
</template>

<script>
import IconsSprite from '~/components/icon/icons-sprite.vue';
import NotificationSettingsModal from '~/components/notifications/notification-settings-modal.vue';
import StudentGreetingSurveyManager from '~/components/student-greeting-survey-manager.vue';
import TheNavbar from '~/components/the-navbar/the-navbar.vue';

export default {
  components: {
    IconsSprite,
    TheNavbar,
    NotificationSettingsModal,
    StudentGreetingSurveyManager,
  },
};
</script>

<style lang="postcss" module>
.main {
  max-width: var(--max-content-width);
  min-height: calc(100vh - var(--navbar-height));
  padding: 0 24px;
  margin: 32px auto 0;
}

@media (--small-vp) {
  .main {
    --top-margin: 20px;

    min-height: calc(100vh - var(--navbar-height) - var(--top-margin));
    padding: 0 12px;
    margin-top: var(--top-margin);
  }
}
</style>

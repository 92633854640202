import { EVENT_BUS_KEYS } from '~/constants/event-bus';
import { ONBOARDING_STEPS } from '~/constants/onboarding';
import {
  completeCommonOnboarding,
  getIsCommonOnboardingCouldBeShown,
} from '~/api/onboarding';
import { handleError } from '~/services/error-handling/error-handling';
import { eventBus } from '~/services/event-bus';
import { hasVkSignature } from '~/services/vk-connect';

const getFirstAvailableStepIndex = (steps, startIndex = -1) =>
  steps.findIndex(
    (step, index) =>
      index > startIndex && !step.isCompleted && step.isDisabled !== true
  );

export const state = () => ({
  isLoading: false,
  isInitializing: false,
  isInitialized: false,
  isFinishedNow: false,
  isHidden: false,
  couldBeShown: false,
  currentStepGlobalIndex: 0,
  initialCompletedStepIndexes: [],
  initialCompletedSegmentIndexes: [],
  needSupportChatAfterClose: false,
});

export const getters = {
  allSegments(state, getters, rootState) {
    const { user } = rootState.auth;
    const onboardingSurveyQuestions =
      rootState['onboarding-survey'].questions || [];
    return [
      [
        {
          key: ONBOARDING_STEPS.vkConnect,
          isCompleted: hasVkSignature(user),
        },
        {
          key: ONBOARDING_STEPS.notifications,
          isCompleted:
            !!user.isVkNotificationsAllowed || !!user.isTgNotificationsAllowed,
        },
      ],
      [
        ...onboardingSurveyQuestions.map((question) => ({
          key: ONBOARDING_STEPS.survey,
          isCompleted: !!question.userAnswer,
        })),
      ],
    ];
  },
  onboardingSegments(state, getters) {
    const visibleSegments = [];
    let globalIndexCounter = 0;
    getters.allSegments.forEach((segment) => {
      const allStepsCompleted = segment.every((step) => step.isCompleted);
      if (allStepsCompleted) {
        visibleSegments.push(segment);
      } else {
        const notInitiallyCompletedSteps = segment.filter(
          (_, stepIndex) =>
            !state.initialCompletedStepIndexes.includes(
              globalIndexCounter + stepIndex
            )
        );
        visibleSegments.push(notInitiallyCompletedSteps);
      }
      globalIndexCounter += segment.length;
    });
    return visibleSegments.filter((segment) => !!segment && segment.length > 0);
  },
  onboardingSteps(state, getters) {
    return getters.onboardingSegments.flat();
  },
  currentStepKey(state, getters) {
    return getters.onboardingSteps[state.currentStepGlobalIndex]?.key;
  },
  nextStepKey(state, getters) {
    const nextStep = getters.onboardingSteps[state.currentStepGlobalIndex + 1];
    return nextStep?.key;
  },
  currentStepIndex(state, getters) {
    const segmentValues = getters.onboardingSegments.map(
      (segment) => segment.length
    );
    const previousStepsValue = segmentValues
      .slice(0, getters.currentSegmentIndex)
      .reduce((sum, current) => sum + current, 0);
    return state.currentStepGlobalIndex - previousStepsValue;
  },
  currentSegmentIndex(state, getters) {
    return getters.onboardingSegments.findIndex((segment) =>
      segment.some((step) => step.key === getters.currentStepKey)
    );
  },
  isAllStepsCompleted(state, getters) {
    return getters.onboardingSteps.every((step) => !!step.isCompleted);
  },
  hasNextAvailableStep(state, getters) {
    return (
      getFirstAvailableStepIndex(
        getters.onboardingSteps,
        state.currentStepGlobalIndex
      ) !== -1
    );
  },
  skippedSegmentIndexes(state, getters) {
    const result = [...state.initialCompletedSegmentIndexes];
    getters.onboardingSegments.forEach((segment, index) => {
      const isSegmentDisabled = segment.every(
        (step) => !step.isCompleted || step.isDisabled
      );
      if (isSegmentDisabled && !result.includes(index)) {
        result.push(index);
      }
    });
    return result;
  },
};

export const mutations = {
  SET_IS_LOADING(state, value) {
    state.isLoading = !!value;
  },
  SET_IS_HIDDEN(state, value) {
    state.isHidden = !!value;
  },
  SET_IS_INITIALIZED(state, value) {
    state.isInitialized = !!value;
  },
  SET_IS_INITIALIZING(state, value) {
    state.isInitializing = !!value;
  },
  SET_COULD_BE_SHOWN(state, value) {
    state.couldBeShown = !!value;
  },
  SET_CURRENT_STEP_GLOBAL_INDEX(state, value) {
    state.currentStepGlobalIndex = value;
  },
  SET_INITIAL_COMPLETED_SEGMENT_INDEXES(state, value) {
    state.initialCompletedSegmentIndexes = value || [];
  },
  SET_INITIAL_COMPLETED_STEP_INDEXES(state, value) {
    state.initialCompletedStepIndexes = value || [];
  },
  SET_NEED_SUPPORT_CHAT_AFTER_CLOSE(state, value) {
    state.needSupportChatAfterClose = !!value;
  },
  SET_IS_FINISHED_NOW(state, value) {
    state.isFinishedNow = !!value;
  },
};

export const actions = {
  async initializeOnboarding(
    { state, commit, dispatch },
    { initialStepKey = '' } = {}
  ) {
    if (state.isInitializing) return;
    try {
      commit('SET_IS_INITIALIZING', true);
      await dispatch('fetchIsCouldBeShown');
      if (!state.isHidden) {
        await dispatch('onboarding-survey/fetchQuestions', {}, { root: true });
      }
      await dispatch('resetOnboarding', { initialStepKey });
      commit('SET_IS_INITIALIZED', true);
    } catch (error) {
      handleError(error);
    } finally {
      commit('SET_IS_INITIALIZING', false);
    }
  },
  resetOnboarding({ dispatch }, { initialStepKey } = {}) {
    dispatch('resolveInitialCompletedStepIndexes');
    dispatch('resolveCurrentStepGlobalIndex', initialStepKey);
    dispatch('resolveInitialCompletedSegmentIndexes');
  },
  openSupportChatAfterClose({ commit }) {
    commit('SET_NEED_SUPPORT_CHAT_AFTER_CLOSE', true);
  },
  ignoreSupportChatAfterClose({ commit }) {
    commit('SET_NEED_SUPPORT_CHAT_AFTER_CLOSE', false);
  },
  goToNextStep({ state, getters, commit }) {
    const totalStepsCount = getters.onboardingSteps.length;
    if (this.currentStepGlobalIndex + 1 >= totalStepsCount) return;
    const nextAvailableStepIndex = getFirstAvailableStepIndex(
      getters.onboardingSteps,
      state.currentStepGlobalIndex
    );
    commit(
      'SET_CURRENT_STEP_GLOBAL_INDEX',
      nextAvailableStepIndex !== -1
        ? nextAvailableStepIndex
        : this.currentStepGlobalIndex + 1
    );
  },
  goToPreviousStep({ state, commit }) {
    if (this.currentStepGlobalIndex === 0) return;
    commit('SET_CURRENT_STEP_GLOBAL_INDEX', state.currentStepGlobalIndex - 1);
  },
  async fetchIsCouldBeShown({ state, commit }) {
    if (state.isLoading) return;
    try {
      commit('SET_IS_LOADING', true);
      const { couldBeShown, isHidden } =
        await getIsCommonOnboardingCouldBeShown();
      commit('SET_COULD_BE_SHOWN', couldBeShown);
      commit('SET_IS_HIDDEN', isHidden);
    } catch (error) {
      handleError(error);
    } finally {
      commit('SET_IS_LOADING', false);
    }
  },
  resolveCurrentStepGlobalIndex({ getters, commit }, stepKey) {
    const firstNotCompletedStepIndex = getters.onboardingSteps.findIndex(
      (step) => !step.isCompleted
    );
    const currentStepGlobalIndex = stepKey
      ? getters.onboardingSteps.findIndex(
          (step) => step.key === stepKey && !step.isCompleted
        )
      : firstNotCompletedStepIndex;
    commit(
      'SET_CURRENT_STEP_GLOBAL_INDEX',
      currentStepGlobalIndex || firstNotCompletedStepIndex
    );
  },
  resolveInitialCompletedStepIndexes({ state, getters, commit }) {
    const result = getters.allSegments
      .flat()
      .map((step, index) => (step.isCompleted ? index : undefined))
      .filter((value) => value !== undefined);
    commit('SET_INITIAL_COMPLETED_STEP_INDEXES', result);
    return state.initialCompletedStepIndexes;
  },
  resolveInitialCompletedSegmentIndexes({ state, getters, commit }) {
    const result = [];
    getters.onboardingSegments.forEach((segment, segmentIndex) => {
      if (segment.every((step) => !!step.isCompleted)) {
        result.push(segmentIndex);
      }
    });
    commit('SET_INITIAL_COMPLETED_SEGMENT_INDEXES', result);
    return state.initialCompletedSegmentIndexes;
  },
  async completeOnboarding({ commit, getters }) {
    if (!getters.isAllStepsCompleted) return;
    try {
      await completeCommonOnboarding();
      commit('SET_IS_FINISHED_NOW', true);
      eventBus.$emit(EVENT_BUS_KEYS.commonOnboardingFinished);
    } catch (error) {
      handleError(error);
    }
  },
};

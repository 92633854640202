<script>
import { WModalSheet } from '@WebiumTeam/ui_kit';
import { createNamespacedHelpers } from 'vuex';
import { getIsNpsCouldBeShown, saveNpsClose } from '~/api/surveys';
import BaseToastMessage from '~/components/_base/_base-toast-message.vue';
import NpsForm from '~/components/nps/nps-form.vue';

const { mapActions } = createNamespacedHelpers('nps-modal');
const { mapState: mapMentorsSatisfactionState } = createNamespacedHelpers(
  'mentors-satisfaction-modal'
);
const { mapState: mapStudentGreetingSurveyModal } = createNamespacedHelpers(
  'student-greeting-survey-modal'
);

const thanksToastMessageRef = 'thanksToastMessage';
const npsFormToastMessageRef = 'npmFormToastMessage';

export const messages = {
  ru: {
    thanksTitle: 'спасибо!',
    thanksText: 'ты делаешь нас лучше',
  },
};

export default {
  thanksToastMessageRef,
  npsFormToastMessageRef,
  i18n: {
    messages,
  },
  components: {
    BaseToastMessage,
    WModalSheet,
    NpsForm,
  },
  data() {
    return {
      isAnswerSent: false,
      isThanksVisible: false,
      couldBeShown: false,
      npsFormWasClosed: false,
    };
  },
  computed: {
    ...mapMentorsSatisfactionState(['mentorsSatisfactionModalVisible']),
    ...mapStudentGreetingSurveyModal(['studentGreetingSurveyModalVisible']),
    isVisible() {
      return (
        !this.npsFormWasClosed &&
        this.couldBeShown &&
        !this.isAnswerSent &&
        !(
          this.$device.isSmallScreen && this.studentGreetingSurveyModalVisible
        ) &&
        !this.mentorsSatisfactionModalVisible
      );
    },
  },
  watch: {
    isThanksVisible(value) {
      if (value) {
        const thanksToastMessage = this.$refs[thanksToastMessageRef];
        if (thanksToastMessage) {
          thanksToastMessage.show();
        }
      }
    },
    isVisible: {
      immediate: true,
      handler(value) {
        this.setIsNpsModalVisible(value);
        const npsFormToastMessage = this.$refs[npsFormToastMessageRef];
        if (!npsFormToastMessage) return;
        if (value) {
          npsFormToastMessage.show();
        } else {
          npsFormToastMessage.hide();
        }
      },
    },
  },
  async created() {
    this.couldBeShown = await getIsNpsCouldBeShown();
  },
  methods: {
    ...mapActions(['setIsNpsModalVisible']),
    onNpsFormClose() {
      if (this.npsFormWasClosed) return;
      this.npsFormWasClosed = true;
      if (!this.isAnswerSent) {
        saveNpsClose();
      }
    },
    onSuccess() {
      this.isAnswerSent = true;
      this.$nextTick(() => {
        this.isThanksVisible = true;
      });
    },
  },
};
</script>

<template>
  <div>
    <template v-if="$device.isSmallScreen">
      <WModalSheet :title="$t('thanksTitle')" :visible.sync="isThanksVisible">
        <div
          :class="$style.thanksDrawerContent"
          data-testid="modal-success-content"
        >
          <p :class="$style.thanksText">{{ $t('thanksText') }}</p>
        </div>
      </WModalSheet>
      <WModalSheet
        :visible="isVisible"
        data-testid="nps-form-modal"
        @close="onNpsFormClose"
      >
        <NpsForm @success="onSuccess" />
      </WModalSheet>
    </template>
    <template v-else>
      <Portal to="toast-messages">
        <BaseToastMessage
          :ref="$options.thanksToastMessageRef"
          :class="$style.thanksToastMessage"
          auto-hide
        >
          <div
            :class="$style.thanksToastContent"
            data-testid="toast-success-content"
          >
            <strong :class="$style.thanksTitle">{{ $t('thanksTitle') }}</strong>
            <p :class="$style.thanksText">{{ $t('thanksText') }}</p>
          </div>
        </BaseToastMessage>
        <BaseToastMessage
          :ref="$options.npsFormToastMessageRef"
          :auto-hide="false"
          :class="$style.npsFormToastMessage"
          data-testid="nps-form-toast"
          @close="onNpsFormClose"
        >
          <NpsForm @success="onSuccess" />
        </BaseToastMessage>
      </Portal>
    </template>
  </div>
</template>

<style lang="postcss" module>
.npsFormToastMessage {
  width: fit-content;
}

.thanksToastMessage {
  width: 456px;
}

.thanksToastContent {
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 24px 32px;
  background-image: url('./thanks-bg-toast.svg');
  background-repeat: no-repeat;
  background-position: right;
}

.thanksTitle {
  font-size: 28px;
  line-height: 34px;
}

.thanksText {
  font-size: 18px;
  line-height: 22px;
}

.thanksDrawerContent {
  padding: 20px 0 32px;
  background-image: url('./thanks-bg-drawer.svg');
  background-repeat: no-repeat;
  background-position: right;
}

@media (--small-vp) {
  .thanksText {
    font-size: 16px;
    line-height: 20px;
  }
}
</style>

<script>
import { WButton } from '@WebiumTeam/ui_kit';
import { stripHtml } from 'string-strip-html';
import IntersectionObserver from 'vue-intersect';
import { createNamespacedHelpers } from 'vuex';
import { timeAgo } from '~/services/datetime';
import { linkifyString } from '~/services/linkify';
import { buildCartUrl } from '~/services/shop';

export const messages = {
  ru: {
    webium: 'Вебиум',
  },
};

const { mapActions } = createNamespacedHelpers('notification-modal');

export default {
  components: {
    IntersectionObserver,
    WButton,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  i18n: { messages },
  data() {
    return {
      text: '',
      isViewed: false,
    };
  },
  computed: {
    classes() {
      return {
        [this.$style.root]: true,
        [this.$style._notViewed]: !this.item.isViewed,
      };
    },
    title() {
      const { author, course } = this.item;
      let title = this.$t('webium');
      if (author && course) {
        title = `${course.name}, ${author.firstName} ${author.lastName}`;
      } else if (author) {
        title = `${author.firstName} ${author.lastName}`;
      } else if (course) {
        title = course.name;
      }
      return title;
    },
    coverUrl() {
      const { author, course } = this.item;
      return course?.cover || author?.avatar;
    },
    formattedDate() {
      return timeAgo(this.item.created);
    },
    taskRouteParams() {
      const { homeworkId, courseId, taskId } = this.item.buttonParams;

      return {
        name: 'course-content-homework',
        params: {
          id: courseId,
          homeworkId,
        },
        query: {
          taskId,
        },
      };
    },
    webinarRouteParams() {
      const { webinarId, courseId } = this.item.buttonParams;

      return {
        name: 'course-content-webinar',
        params: {
          id: courseId,
          webinarId,
        },
      };
    },
    homeworkRouteParams() {
      const { homeworkId, courseId } = this.item.buttonParams;

      return {
        name: 'course-content-homework',
        params: {
          id: courseId,
          homeworkId,
        },
      };
    },
    courseRouteParams() {
      const { courseId } = this.item.buttonParams;

      return {
        name: 'course-dashboard',
        params: {
          id: courseId,
        },
      };
    },
    prolongCourseLink() {
      const { coursePublicId, paymentMonth } = this.item.buttonParams;

      return {
        href: buildCartUrl([
          {
            publicId: coursePublicId,
            months: [paymentMonth],
          },
        ]),
      };
    },
    linkRouteParams() {
      if (!this.item.buttonParams) return {};

      const {
        coursePublicId,
        paymentMonth,
        webinarId,
        homeworkId,
        courseId,
        taskId,
      } = this.item.buttonParams;

      if (coursePublicId && paymentMonth) return this.prolongCourseLink;
      if (homeworkId && courseId && taskId) return this.taskRouteParams;
      if (homeworkId && courseId) return this.homeworkRouteParams;
      if (webinarId && courseId) return this.webinarRouteParams;
      if (courseId) return this.courseRouteParams;

      return {};
    },
  },
  created() {
    this.text = linkifyString(stripHtml(this.item.text).result);
  },
  methods: {
    ...mapActions(['closeNotificationModal']),
    markAsViewed() {
      if (!this.item.isViewed && !this.isViewed) {
        this.$emit('viewed', this.item);
        this.isViewed = true;
      }
    },
  },
};
</script>

<template>
  <IntersectionObserver :threshold="[0.7]" @enter="markAsViewed">
    <li :class="classes">
      <div :class="$style.round">
        <img v-if="coverUrl" :class="$style.cover" :src="coverUrl" alt="" />
      </div>
      <div :class="$style.content">
        <span :class="$style.title">{{ title }}</span>
        <span :class="$style.date">{{ formattedDate }}</span>
        <div :class="$style.description" v-html="text" />
        <div v-if="item.attachments" :class="$style.attachments">
          <a
            v-for="attachment in item.attachments"
            :key="attachment.id"
            :class="$style.attachment"
            :href="attachment.file"
            download=""
            target="_blank"
          >
            <WIcon :class="$style.attachmentIcon" name="download" />
            {{ attachment.name }}
          </a>
        </div>
        <WButton
          v-if="linkRouteParams.href"
          tag="a"
          :href="linkRouteParams.href"
          :class="$style.button"
          :size="$device.isSmallScreen ? 's' : 'xs'"
          data-testid="button"
          @click="closeNotificationModal"
          >{{ item.buttonParams.label }}</WButton
        >
        <WButton
          v-else-if="linkRouteParams.name"
          tag="NuxtLink"
          :to="linkRouteParams"
          :class="$style.button"
          :size="$device.isSmallScreen ? 's' : 'xs'"
          data-testid="button"
          @click.native="closeNotificationModal"
        >
          {{ item.buttonParams.label }}
        </WButton>
      </div>
    </li>
  </IntersectionObserver>
</template>

<style lang="postcss" module>
.root {
  position: relative;
  display: flex;
  gap: 16px;
  padding: 18px;
  background-color: var(--primary-100);
  transition: var(--default-transition);

  &:not(:last-child)::after {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    content: '';
    background: var(--grey-200);
  }
}

.round {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  min-width: 40px;
  height: 40px;
  overflow: hidden;
  background-color: var(--grey-200);
  border-radius: 20px;
}

.cover {
  width: 40px;
}

.content {
  display: flex;
  flex-direction: column;
  color: var(--primary-500);
  letter-spacing: 0.02em;
}

.title {
  margin-bottom: 2px;
  font-size: 16px;
  line-height: 20px;
}

.date {
  margin-bottom: 8px;
  font-size: 14px;
  line-height: 16px;
  color: var(--grey-600);
}

.description {
  font-size: 14px;
  line-height: 18px;
  word-break: break-word;
  white-space: pre-line;

  & a {
    color: var(--system-500);
  }
}

._notViewed {
  background-color: var(--grey-100);
}

.button {
  align-self: flex-start;
  margin-top: 12px;
}

.attachments {
  display: flex;
  flex-direction: column;
  row-gap: 4px;
  margin-top: 12px;
}

.attachment {
  display: flex;
  column-gap: 8px;
  align-items: center;
  font-size: 14px;
  line-height: 18px;
  color: var(--system-500);
  letter-spacing: 0.02em;
}

.attachmentIcon {
  font-size: 24px;
}
</style>

/**
 * Example of use in the component
 * mounted() {
 *   this.$growthBook().then((growthBook) => {
 *     if (!growthBook) return;
 *     const isEnabled = growthBook.isOn('test-on-off');
 *     const userGroup = growthBook.getFeatureValue('test-ab');
 *   });
 *  },
 * */

import { GrowthBook } from '@growthbook/growthbook';
import { LAST_TRACKED_PURCHASE_UUID_STORAGE_KEY } from '~/constants/local-storage';
import { handleError } from '~/services/error-handling/error-handling';
import { gtmEvents, gtmPushEvent } from '~/services/gtm/gtm';
import { getLSItem } from '~/services/local-storage';

const getFeaturesJson = (featuresEndpoint) =>
  fetch(featuresEndpoint).then((response) => response.json());

const extractGAСlientId = () => {
  try {
    const gaCookie = document?.cookie.match(/_ga=(.+?);/);
    if (!gaCookie) return false;
    return gaCookie[1].split('.').slice(-2).join('.');
  } catch (error) {
    handleError(error, { showToast: false });
  }

  return '';
};

const trackJsonError = (json) => {
  try {
    const error = new Error(`Error in GrowthBook: "${JSON.stringify(json)}"`);
    handleError(error, { showToast: false });
  } catch (error) {
    handleError(error, { showToast: false });
  }
};

export const GrowthBookVuePlugin = {
  install(Vue, { featuresEndpoint, inject, enableDevelopmentMode = false }) {
    let growthBook;
    let loading = false;
    const initGrowthbook = ({
      publicId = undefined,
      reinitCounter = 1,
      // eslint-disable-next-line sonarjs/cognitive-complexity
    } = {}) => {
      const reinitLimit = 5;
      if (growthBook) {
        const attributes = growthBook.getAttributes();
        if (!attributes.id && publicId) {
          growthBook.setAttributes({
            ...attributes,
            id: publicId,
          });
        }
        return Promise.resolve(growthBook);
      }
      const gaСlientId = extractGAСlientId();
      if ((!gaСlientId && reinitCounter < reinitLimit) || loading) {
        return new Promise((resolve) => {
          setTimeout(() => {
            resolve(
              initGrowthbook({ publicId, reinitCounter: reinitCounter + 1 })
            );
          }, 200);
        });
      }
      if (loading) return Promise.resolve(growthBook);
      loading = true;

      return getFeaturesJson(featuresEndpoint)
        .then((json) => {
          if (!json || json.status !== 200) {
            trackJsonError(json);
          }
          growthBook = new GrowthBook({
            enableDevMode: enableDevelopmentMode,
            trackingCallback: (experiment, result) => {
              const experimentData = {
                experimentId: experiment.key,
                variationId: result.key,
              };
              gtmPushEvent(gtmEvents.experimentViewed, experimentData);
            },
          });
          growthBook.setFeatures(json.features);
          const lastTrackedPurchase = process.client
            ? getLSItem(LAST_TRACKED_PURCHASE_UUID_STORAGE_KEY)
            : undefined;
          const attributes = {
            deviceId: gaСlientId,
          };
          if (publicId) attributes.id = publicId;
          if (lastTrackedPurchase) attributes.hasTrackedPurchase = true;
          growthBook.setAttributes(attributes);
          loading = false;
          return growthBook;
        })
        .catch((error) => {
          handleError(error, { showToast: false });
          loading = false;
          return undefined;
        });
    };
    inject('growthBook', initGrowthbook);
  },
};

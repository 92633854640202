import Vue from 'vue';
import VueResizeObserver from 'vue-resize-observer';

Vue.use(VueResizeObserver);

Vue.directive('click-outside', {
  bind(element, binding, vnode) {
    // eslint-disable-next-line no-param-reassign
    element.clickOutsideEvent = (event) => {
      if (!(element === event.target || element.contains(event.target))) {
        vnode.context[binding.expression](event);
      }
    };
    document.body.addEventListener('click', element.clickOutsideEvent);
  },
  unbind(element) {
    document.body.removeEventListener('click', element.clickOutsideEvent);
  },
});

Vue.directive('hide-if-empty', {
  bind: (element) => {
    if (element.children.length === 0) {
      // eslint-disable-next-line no-param-reassign
      element.style.display = 'none';
    }
  },
});

<script>
import { createNamespacedHelpers } from 'vuex';
import StudentGreetingSurveyModal from '~/components/student-greeting-survey-modal.vue';

const { mapState, mapActions } = createNamespacedHelpers(
  'student-greeting-survey-modal'
);

export default {
  components: {
    StudentGreetingSurveyModal,
  },
  computed: {
    ...mapState(['studentGreetingSurveyModalVisible']),
  },
  methods: {
    ...mapActions(['closeStudentGreetingSurveyModal']),
  },
};
</script>

<template>
  <StudentGreetingSurveyModal
    v-if="studentGreetingSurveyModalVisible"
    @close="closeStudentGreetingSurveyModal"
  />
</template>

import { buildServerListModule } from '~/store-lib/server-list';
import { getAllCourses } from '~/api/student-courses';
import { getCourseProlongMonths } from '~/services/course';

const storeModule = buildServerListModule(getAllCourses);

const { state, mutations, actions } = storeModule;

const getters = {
  ...storeModule.getters,
  coursesForProlong(state) {
    return state.items
      .filter((item) => !item.hasDatesDisabled && item.courseType.isBasic)
      .map((item) => ({
        publicId: item.publicId,
        months: getCourseProlongMonths(item),
      }))
      .filter((item) => item.months.length > 0);
  },
  coursesForProlongExist(state, getters) {
    return getters.coursesForProlong.length > 0;
  },
};

export { state, mutations, getters, actions };

// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._base-popover-menu__root-sozHl{width:-moz-min-content;width:min-content;padding:2px}._base-popover-menu__item-7a7pE{width:100%;padding:8px 12px 10px;font-size:16px;line-height:20px;color:var(--text-main-color);letter-spacing:.02em;white-space:nowrap;border-radius:6px}._base-popover-menu__item-7a7pE:hover{background-color:var(--grey-100)}._base-popover-menu__item-7a7pE:active{background-color:var(--grey-200)}._base-popover-menu__item-7a7pE:not(:last-child){margin-bottom:2px}._base-popover-menu__radioItem-8GuBo{flex-shrink:0;margin-top:2px;margin-right:15px}._base-popover-menu___radio-Ks7JA ._base-popover-menu__item-7a7pE{display:flex}._base-popover-menu___radio-Ks7JA span{white-space:normal}._base-popover-menu___multiLine-kfQrv{width:auto}._base-popover-menu___multiLine-kfQrv ._base-popover-menu__item-7a7pE{white-space:normal}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "_base-popover-menu__root-sozHl",
	"item": "_base-popover-menu__item-7a7pE",
	"radioItem": "_base-popover-menu__radioItem-8GuBo",
	"_radio": "_base-popover-menu___radio-Ks7JA",
	"_multiLine": "_base-popover-menu___multiLine-kfQrv"
};
module.exports = ___CSS_LOADER_EXPORT___;

// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("./images/bullet.svg");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".telegram-link-banner__root-U0vjk{z-index:2;position:fixed;left:24px;bottom:24px;display:flex;flex-direction:column;width:350px;padding:20px;background-color:var(--primary-100);border-radius:8px;border:2px solid var(--primary-500);box-shadow:var(--dropdown-shadow)}.telegram-link-banner__closeButton-ovzOf{position:absolute;top:2px;right:2px}.telegram-link-banner__header-p\\+ihr{display:flex;grid-gap:8px;gap:8px;margin-bottom:12px}.telegram-link-banner__sticker-qDlVn{width:72px;height:72px}.telegram-link-banner__title-fQX2P{margin-top:8px;font-size:20px;line-height:24px}.telegram-link-banner__content-loSM- ul{display:flex;flex-direction:column;grid-gap:4px;gap:4px;margin:0 0 0 20px;padding:0}.telegram-link-banner__content-loSM- ul li{font-size:16px;line-height:20px;list-style-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ")}.telegram-link-banner__content-loSM- ul li span{position:relative;top:-4px}.telegram-link-banner__content-loSM- ul li::marker{position:relative;top:4px}.telegram-link-banner__button-\\+azqm{width:100%;margin-top:20px}@media (max-width:1023px){.telegram-link-banner__root-U0vjk{z-index:var(--base-toast-message-z-index);right:12px;bottom:12px;left:12px;width:auto}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "telegram-link-banner__root-U0vjk",
	"closeButton": "telegram-link-banner__closeButton-ovzOf",
	"header": "telegram-link-banner__header-p+ihr",
	"sticker": "telegram-link-banner__sticker-qDlVn",
	"title": "telegram-link-banner__title-fQX2P",
	"content": "telegram-link-banner__content-loSM-",
	"button": "telegram-link-banner__button-+azqm"
};
module.exports = ___CSS_LOADER_EXPORT___;

<script>
import { WButton, WLink } from '@WebiumTeam/ui_kit';
import {
  VK_CONNECT_CTA_TYPES,
  buildVkUrlUpdateUrl,
} from '~/services/vk-connect';
import BaseModalWrapper from '~/components/_base/_base-modal-wrapper.vue';
import VkConnectCtaButton from '~/components/vk-connect-cta-button/vk-connect-cta-button.vue';

export const messages = {
  ru: {
    title: 'получай помощь от наставника',
    description:
      'привяжи страницу ВК, чтобы получать<br>помощь от наставника в любое время',
    ctaButtonText: 'привязать страницу ВК',
    issueButtonText: 'не могу привязать ВК',
    updateVkUrl: 'ввести ссылку на страницу ВК',
  },
};

export default {
  i18n: {
    messages,
  },
  components: {
    WButton,
    WLink,
    BaseModalWrapper,
    VkConnectCtaButton,
  },
  props: {
    showIssueButton: {
      type: Boolean,
      default: false,
    },
    showInputUrlButton: {
      type: Boolean,
      default: false,
    },
    ctaType: {
      type: String,
      default: VK_CONNECT_CTA_TYPES.tutor,
    },
  },
  computed: {
    vkUrlUpdateUrl() {
      return buildVkUrlUpdateUrl(window.location.href, {
        ctaType: this.ctaType,
      });
    },
  },
  methods: {
    onIssueClick() {
      this.$emit('close');
    },
  },
};
</script>

<template>
  <BaseModalWrapper
    :class="$style.root"
    :mobile-bottom="$device.isSmallScreen"
    @close="$emit('close')"
  >
    <div :class="$style.inner">
      <img :class="$style.sticker" alt="" :src="require(`./sticker.svg`)" />
      <strong :class="$style.title">{{ $t('title') }}</strong>
      <p :class="$style.description" v-html="$t('description')"></p>
      <div :class="$style.buttons">
        <VkConnectCtaButton
          target="_self"
          :text="$t('ctaButtonText')"
          :cta-type="ctaType"
        />
        <WButton
          v-if="showInputUrlButton"
          id="input-vk-url-button"
          data-testid="input-vk-url-button"
          theme="tetriary"
          :size="$device.isSmallScreen ? 'l' : 'm'"
          tag="a"
          :href="vkUrlUpdateUrl"
        >
          {{ $t('updateVkUrl') }}
        </WButton>
        <WLink
          v-if="showIssueButton"
          id="issue-button"
          :class="$style.issueButton"
          tag="button"
          data-testid="issue-button"
          @click="onIssueClick"
        >
          {{ $t('issueButtonText') }}
        </WLink>
      </div>
    </div>
  </BaseModalWrapper>
</template>

<style lang="postcss" module>
.root {
  --modal-max-width: 370px;
}

.inner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px;
  text-align: center;
}

.sticker {
  width: 174px;
  margin-bottom: 28px;
}

.title {
  margin-bottom: 4px;
  font-size: 20px;
  line-height: 24px;
}

.description {
  font-size: 14px;
  line-height: 18px;
  color: var(--grey-600);
}

.buttons {
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
  min-width: 85%;
  margin-top: 32px;

  & > * {
    width: 100%;
    text-transform: none;

    & a {
      text-transform: none;
    }
  }
}

.issueButton {
  width: fit-content;
}

@media (--small-vp) {
  .buttons {
    min-width: 100%;

    & > * {
      width: 100%;
    }
  }
}
</style>

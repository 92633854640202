import { COURSE_TYPE_SLUGS } from '~/constants/course-types';

const septemberFlowCourseDate = '2023-09-01';

function parseCourseMonth(courseMonth) {
  const [fullYear, monthNumber] = courseMonth.split('.');
  const monthIndex = Number.parseInt(monthNumber, 10) - 1;
  return [fullYear, monthIndex];
}

export function dateToCourseMonth(date) {
  const fullYear = date.getFullYear();
  const monthIndex = date.getMonth();
  const monthNumber = monthIndex + 1;
  return `${fullYear}.${monthNumber}`;
}

export function courseMonthToDate(courseMonth) {
  const [fullYear, monthIndex] = parseCourseMonth(courseMonth);
  return new Date(Number.parseInt(fullYear, 10), monthIndex);
}

export function sortCourseMonths(courseMonths) {
  return courseMonths
    .map((dateString) => courseMonthToDate(dateString))
    .sort((a, b) => a - b)
    .map((date) => dateToCourseMonth(date));
}

export function getCourseAllMonths(course) {
  return sortCourseMonths([
    ...(course.purchasedMonths || []),
    ...(course.unpurchasedMonths || []),
  ]);
}

export function filterAvailableCourseMonths(courseMonths) {
  const currentDate = new Date();
  const compareDate = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    1
  );
  return courseMonths
    .map((courseDate) => courseMonthToDate(courseDate))
    .filter((date) => date >= compareDate)
    .map((date) => dateToCourseMonth(date));
}

export function dateForProlong(isWholeMonth = false) {
  const dayWhenStartProlongNextMonth = 25;
  const currentDate = new Date();
  const dayOfTheMonth = currentDate.getDate();
  const monthForCompare =
    dayOfTheMonth < dayWhenStartProlongNextMonth
      ? currentDate.getMonth()
      : currentDate.getMonth() + 1;
  return new Date(
    currentDate.getFullYear(),
    monthForCompare,
    isWholeMonth ? 1 : dayWhenStartProlongNextMonth
  );
}

export function filterProlongCourseMonths(courseMonths) {
  const compareDate = dateForProlong(true);
  const courseForCompare = courseMonths.map((courseDate) =>
    courseMonthToDate(courseDate)
  );

  const futureMonths = courseForCompare.filter((date) => compareDate <= date);
  const prolongCourseMonths = courseForCompare.filter(
    (date) => date <= compareDate
  );
  const isActualMonthNotPaid = !!prolongCourseMonths.some((date) =>
    futureMonths.includes(date)
  );

  if (!isActualMonthNotPaid && futureMonths.length > 0)
    prolongCourseMonths.push(futureMonths[0]);

  return prolongCourseMonths.map((date) => dateToCourseMonth(date));
}

export function getCourseNextUnpaidMonth(course) {
  return filterAvailableCourseMonths(course.unpurchasedMonths)[0];
}

export function getCourseProlongMonths(course) {
  return filterProlongCourseMonths(course.unpurchasedMonths);
}

export function isCourseAllMonthsPaid(course) {
  return (
    course.purchasedMonths.length > 0 && course.unpurchasedMonths.length === 0
  );
}

export function isCourseLastMonthPaid(course) {
  const allMonths = getCourseAllMonths(course);
  if (allMonths.length === 0) return false;
  const lastMonths = allMonths[allMonths.length - 1];

  return course.purchasedMonths.includes(lastMonths);
}

export function isCourseCurrentMonthPaid(course) {
  return course?.purchasedMonths.includes(dateToCourseMonth(new Date()));
}

export function isCourseStarted(course) {
  const courseAllMonths = getCourseAllMonths(course);
  if (courseAllMonths.length === 0) return true;

  const currentDate = new Date();
  const courseFirstMonth = courseAllMonths[0];
  const courseFirstDate = courseMonthToDate(courseFirstMonth);
  return (
    courseFirstDate.getFullYear() < currentDate.getFullYear() ||
    (courseFirstDate.getFullYear() === currentDate.getFullYear() &&
      courseFirstDate.getMonth() <= currentDate.getMonth())
  );
}

export function getCourseFirstDate(course) {
  const courseAllMonths = getCourseAllMonths(course);
  if ((courseAllMonths || []).length === 0) return undefined;
  const [fullYear, monthIndex] = parseCourseMonth(courseAllMonths[0]);
  return new Date(fullYear, monthIndex, 1, 0, 0, 0, 0);
}

export function getCourseLastDate(course) {
  const courseAllMonths = getCourseAllMonths(course);
  if ((courseAllMonths || []).length === 0) return undefined;
  const [fullYear, monthIndex] = parseCourseMonth(courseAllMonths.slice(-1)[0]);
  return new Date(fullYear, monthIndex + 1, 0, 23, 59, 59);
}

export function isCourseFinished(course) {
  const courseAllMonths = getCourseAllMonths(course);
  if (courseAllMonths.length === 0) return false;

  const currentDate = new Date();
  const courseLastDate = getCourseLastDate(course);
  return (
    courseLastDate.getFullYear() < currentDate.getFullYear() ||
    (courseLastDate.getFullYear() === currentDate.getFullYear() &&
      courseLastDate.getMonth() < currentDate.getMonth())
  );
}

export function isCourseWorkoutAccessPaidFor(course) {
  return (
    isCourseCurrentMonthPaid(course) ||
    (isCourseLastMonthPaid(course) && isCourseFinished(course))
  );
}

export function isCourseFree(course) {
  return course?.courseType?.slug === COURSE_TYPE_SLUGS.free;
}

export function isCourseFresh(course) {
  return course?.courseType?.slug === COURSE_TYPE_SLUGS.fresh;
}

export function getCourseExamType(course) {
  return course.topic.examType.slug;
}

export function isBaseCourseSeptemberFlow(course) {
  const courseType = course?.courseType?.slug;
  if (courseType !== COURSE_TYPE_SLUGS.base) return false;

  const courseMonths = [...course.unpurchasedMonths, ...course.purchasedMonths];
  return courseMonths.includes(
    dateToCourseMonth(new Date(septemberFlowCourseDate))
  );
}

export function getCourseAllMentors(course) {
  return course.studyGroup?.mentors || [];
}

export function getCourseMainMentor(course) {
  const courseAllMentors = getCourseAllMentors(course);
  if ((courseAllMentors || []).length === 0) return undefined;
  return courseAllMentors.find((mentor) => mentor.isMainMentor);
}

export function getCoursePriorityMentor(course) {
  const courseAllMentors = getCourseAllMentors(course);
  if ((courseAllMentors || []).length === 0) return undefined;
  const mainMentor = getCourseMainMentor(course);
  return mainMentor || courseAllMentors[0];
}

export function splitLessonContentItemsByGroup(items) {
  const groups = [];
  items.forEach((item) => {
    const { id, contentDate } = item.lessonContentGroup;
    const existingGroup = groups.find((group) => group.id === id);
    if (existingGroup) {
      existingGroup.lessonContentItems.push(item);
    } else {
      groups.push({
        id,
        contentDate,
        lessonContentItems: [item],
      });
    }
  });
  return groups;
}

export function areConsultationsAvailableOnCourse(course) {
  return course?.areIndividualConsultationsAvailable;
}

import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import localeData from 'dayjs/plugin/localeData';
import relativeTime from 'dayjs/plugin/relativeTime';
import timezone from 'dayjs/plugin/timezone';
import updateLocale from 'dayjs/plugin/updateLocale';
import utc from 'dayjs/plugin/utc';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import weekday from 'dayjs/plugin/weekday';
import { LOCALE } from '~/constants/i18n';
import 'dayjs/locale/ru';

const i18n = {
  ru: {
    monthsShort: [
      'янв',
      'фев',
      'мар',
      'апр',
      'май',
      'июн',
      'июл',
      'авг',
      'сен',
      'окт',
      'ноя',
      'дек',
    ],
  },
};

export function setupDayjs() {
  dayjs.extend(utc);
  dayjs.extend(weekday);
  dayjs.extend(timezone);
  dayjs.extend(weekOfYear);
  dayjs.extend(relativeTime);
  dayjs.extend(localeData);
  dayjs.extend(updateLocale);
  dayjs.extend(duration);
  dayjs.locale(LOCALE);
  Object.keys(i18n).forEach((locale) =>
    dayjs.updateLocale(locale, i18n[locale])
  );
}

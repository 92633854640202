// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".streaks-paired-invite-modal__root-13QwS{--modal-max-width:600px;--w-overlay-content-margin:160px 0 0;text-align:left}.streaks-paired-invite-modal__content-sITHS{display:flex;flex-direction:column;grid-gap:12px;gap:12px;align-items:flex-start;padding:32px;color:var(--primary-500)}.streaks-paired-invite-modal__image-c6Dba{margin-bottom:10px;max-width:100%}.streaks-paired-invite-modal__title-Z48--{font-size:28px;line-height:34px}@media (max-width:1023px){.streaks-paired-invite-modal__root-13QwS{--max-height:calc(100vh - 130px)}.streaks-paired-invite-modal__content-sITHS{padding:8px 20px 35px}.streaks-paired-invite-modal__image-c6Dba{width:100%;margin-bottom:6px}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "streaks-paired-invite-modal__root-13QwS",
	"content": "streaks-paired-invite-modal__content-sITHS",
	"image": "streaks-paired-invite-modal__image-c6Dba",
	"title": "streaks-paired-invite-modal__title-Z48--"
};
module.exports = ___CSS_LOADER_EXPORT___;

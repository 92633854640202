import dayjs from 'dayjs';

export function timeUntil(date) {
  const isInvalidDate = Number.isNaN(Date.parse(date));

  const isPastDate = Date.parse(new Date()) - Date.parse(date) > 0;

  if (isInvalidDate || isPastDate) return '';

  return dayjs(date).fromNow();
}

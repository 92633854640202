import { buildShopModalUrl } from '~/services/shop';

export const TG_CONNECT_MODAL_NAME = 'tgBind';

export const TG_LINK_SUCCESS_EVENT = 'tg_linked_success';

export const buildTgConnectUrl = (url) => {
  if (process.server) return '';
  const shopModalUrl = buildShopModalUrl(url, {
    modal: TG_CONNECT_MODAL_NAME,
  });
  return shopModalUrl.href;
};

export const openTgConnectModal = ($auth, callback) => {
  const url = buildTgConnectUrl(window.location.href);
  const linkWindow = window.open(url, '_blank');
  window.addEventListener('message', (event) => {
    if (event.source === linkWindow && event.data === TG_LINK_SUCCESS_EVENT) {
      linkWindow.close();
      $auth.setUser({
        ...$auth.user,
        isTgNotificationsAllowed: true,
      });
      if (typeof callback === 'function') {
        callback();
      }
      $auth.fetchUser();
    }
  });
};

import {
  getNotificationSettings,
  updateNotificationSettings,
} from '~/api/notifications';

export const state = () => ({
  settings: [],
  loading: false,
  modalVisible: false,
});

export const mutations = {
  SET_SETTINGS(state, settings) {
    state.settings = settings;
  },
  SET_MODAL_VISIBILITY(state, value) {
    state.modalVisible = !!value;
  },
  SET_LOADING(state, value) {
    state.loading = !!value;
  },
};

export const actions = {
  async fetchNotificationsSettings({ commit }) {
    commit('SET_LOADING', true);
    const settings = await getNotificationSettings();
    commit('SET_LOADING', false);
    commit('SET_SETTINGS', settings);
  },
  openSettingsModal({ commit }) {
    commit('SET_MODAL_VISIBILITY', true);
  },
  closeSettingsModal({ commit }) {
    commit('SET_MODAL_VISIBILITY', false);
  },
  async saveSettings({ commit }, data) {
    commit('SET_LOADING', true);
    const settings = await updateNotificationSettings(data);
    commit('SET_LOADING', false);
    commit('SET_SETTINGS', settings);
  },
};

// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".streaks-counter-button__button-G8DL7{--base-button-padding:4px 6px 0;border:none}.streaks-counter-button__popover-CKFW-{z-index:calc(var(--typeform-z-index) + 1);margin-top:10px}.streaks-counter-button__attentionTooltip-YY41u{margin-top:6px}.streaks-counter-button__pairRemoveButton-zAPjm{--base-popper-z-index:999999;width:100%;margin-right:14px;text-align:right}.streaks-counter-button__streaksWrapper-ZCga0{display:flex;grid-gap:12px;gap:12px}@media (max-width:1023px){.streaks-counter-button__button-G8DL7{--base-button-padding:6px 4px 0}.streaks-counter-button__streaksWrapper-ZCga0{grid-gap:8px;gap:8px}.streaks-counter-button__popover-CKFW-.streaks-counter-button___newPairStreak-VmDhd,.streaks-counter-button__popover-CKFW-.streaks-counter-button___newStreak-a7HE6{--side-gap:12px;width:calc(100vw - var(--side-gap));padding-right:var(--side-gap)}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": "streaks-counter-button__button-G8DL7",
	"popover": "streaks-counter-button__popover-CKFW-",
	"attentionTooltip": "streaks-counter-button__attentionTooltip-YY41u",
	"pairRemoveButton": "streaks-counter-button__pairRemoveButton-zAPjm",
	"streaksWrapper": "streaks-counter-button__streaksWrapper-ZCga0",
	"_newPairStreak": "streaks-counter-button___newPairStreak-VmDhd",
	"_newStreak": "streaks-counter-button___newStreak-a7HE6"
};
module.exports = ___CSS_LOADER_EXPORT___;

<script>
import autosize from 'v-autosize';
import AttachButton from '~/components/attach-button.vue';

export default {
  components: {
    AttachButton,
  },
  directives: {
    autosize,
  },
  model: {
    prop: 'text',
    event: 'update',
  },
  props: {
    text: {
      type: [String, Number],
      default: '',
    },
    maxLength: {
      type: Number,
      default: undefined,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: '',
    },
    attach: {
      type: Boolean,
      default: false,
    },
    attachExtended: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    ariaLabel: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    subType: {
      type: String,
      default: '',
    },
    rows: {
      type: Number,
      default: 1,
    },
    maxHeight: {
      type: Number,
      default: undefined,
    },
    fixHeight: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      value: undefined,
      maxHeightScroll: false,
      height: 'auto',
      isFocused: false,
    };
  },
  computed: {
    classes() {
      return {
        [this.$style.root]: true,
        [this.$style._readonly]: this.readonly,
        [this.$style._disabled]: this.disabled,
        [this.$style._attach]: this.attach,
        [this.$style._focused]: this.isFocused,
        [this.$style[`_${this.subType}`]]: this.subType !== '',
      };
    },
    rootStyle() {
      const style = {};
      if (this.maxLength) {
        style.marginBottom = '4px';
      }
      return style;
    },
    textareaStyle() {
      const style = {};
      if (this.fixHeight) {
        style.maxHeight = `calc(${this.rows} * var(--line-height))`;
      } else if (this.maxHeight) {
        style.maxHeight = `${this.maxHeight}px`;
      }
      return style;
    },
    remainingLength() {
      return Math.max(this.maxLength - (this.text?.length || 0), 0);
    },
  },
  watch: {
    text(value) {
      this.value = value;
    },
    value(value) {
      this.$emit('input', value);
    },
  },
  created() {
    this.value = this.text;
  },
  methods: {
    focus() {
      this.$refs.textarea.focus();
    },
    onInput(event) {
      const inputValue = event.target.value;
      const newValue =
        this.maxLength && this.maxLength < inputValue.length
          ? inputValue.slice(0, this.maxLength)
          : inputValue;
      this.$emit('update', newValue);
    },
    onFocus() {
      this.isFocused = true;
    },
    onBlur() {
      this.isFocused = false;
    },
  },
};
</script>

<template>
  <label :class="classes" :style="rootStyle">
    <textarea
      ref="textarea"
      v-model="value"
      v-autosize
      :class="$style.textarea"
      :readonly="readonly"
      :disabled="disabled"
      :placeholder="placeholder"
      :maxlength="maxLength"
      :rows="rows"
      :aria-label="ariaLabel"
      :style="textareaStyle"
      @focus="onFocus"
      @blur="onBlur"
      @input="onInput"
    ></textarea>
    <span v-if="maxLength" :class="$style.remainingLeft">
      {{ remainingLength }}
    </span>
    <div
      v-if="!readonly && attach"
      :class="$style.wrapper"
      data-testid="attach"
    >
      <AttachButton
        :attach-extended="attachExtended"
        :multiple="multiple"
        @attach="$emit('attach', $event)"
        @start-processing="$emit('start-processing')"
        @stop-processing="$emit('stop-processing')"
      />
    </div>
  </label>
</template>

<style lang="postcss" module>
.root {
  --border-color: var(--grey-400);

  position: relative;
  display: flex;
  width: 100%;
  min-height: 40px;
  padding: 6px 10px 10px;
  border: 2px solid var(--border-color);
  border-radius: 8px;
}

.textarea {
  --line-height: 20px;

  width: 100%;
  font-size: 16px;
  line-height: var(--line-height);
  color: var(--text-main-color);
  letter-spacing: 0.02em;
  resize: none;
  scrollbar-width: thin;
  scrollbar-color: var(--primary-500) var(--grey-400);

  &::placeholder {
    color: var(--grey-600);
  }

  &:focus {
    outline: none;
  }

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background-color: var(--grey-400);
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--primary-500);
    border-radius: 4px;
  }
}

.wrapper {
  position: absolute;
  right: 2px;
  bottom: 2px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.label {
  display: inline-flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  min-width: 32px;
  min-height: 32px;
  padding: 2px;
  font-size: 24px;
  cursor: pointer;
  border: 2px solid transparent;
  border-radius: 6px;
  transition: background-color var(--default-transition);

  &:hover {
    background-color: var(--grey-200);
  }

  &:active {
    background-color: var(--grey-300);
  }
}

.inputFile {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0 0 0 0);
  border: 0;
}

.remainingLeft {
  position: absolute;
  right: -2px;
  bottom: -24px;
  font-size: 14px;
  line-height: 18px;
  color: var(--text-secondary-color);
}

._readonly {
  &.root {
    background-color: var(--grey-100);
  }
}

._disabled {
  &.root {
    color: var(--grey-600);
    background-color: var(--grey-100);
    border-color: var(--grey-200);

    & .textarea {
      opacity: 0.7;
    }
  }
}

._success {
  &.root {
    background-color: var(--success-200);
    border-color: var(--success-500);

    &:hover {
      border-color: var(--success-500);
    }
  }
}

._attach {
  &.root {
    padding: 6px 40px 10px 10px;
  }

  &._readonly {
    padding: 6px 10px 10px;
    background-color: var(--grey-100);
    border: 2px solid var(--grey-200);
  }
}

._focused {
  &.root {
    --border-color: var(--primary-500);
  }
}

._defaultAnswer {
  & .textarea:disabled {
    color: var(--primary-500);
    -webkit-text-fill-color: var(--primary-500);
    opacity: 1;
  }
}

@media (--small-vp) {
  .wrapper {
    bottom: 0;
  }
}
</style>

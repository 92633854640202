<script>
export default {
  props: {
    number: {
      type: Number,
      default: 0,
    },
    theme: {
      type: String,
      default: 'grey',
      validator: (value) => ['grey', 'orange', 'purple'].includes(value),
    },
  },
  computed: {
    rootClasses() {
      return {
        [this.$style.root]: true,
        [this.$style[`_${this.theme}`]]: true,
      };
    },
  },
};
</script>

<template>
  <div :class="rootClasses">
    <span :class="$style.count">{{ number }}</span>
    <span :class="$style.countStroke">{{ number }}</span>
  </div>
</template>

<style lang="postcss" module>
.root {
  position: relative;
  font-size: inherit;
  line-height: inherit;
}

.count {
  line-height: 1;
}

.countStroke {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  -webkit-text-stroke: var(--text-stroke-width, 12px) var(--primary-100);
}

._grey {
  & .count {
    color: var(--grey-500);
  }
}

._orange {
  & .count {
    color: var(--streaks-active-color);
  }
}

._purple {
  & .count {
    color: var(--paired-streaks-active-color);
  }
}
</style>
